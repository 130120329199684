import hexToRgba from "hex-to-rgba"
import defaultsOrg from "../../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    textarea: {
        backgroundColor: "transparent",
        border: `none`,
        color: defaults.colors.neutrals[800],
        fontSize: defaults.fontSizes[14],
        letterSpacing: 0,
        outline: 0,
        padding: defaults.spacing[16],
        width: "100%",

        "&::placeholder": {
            color: hexToRgba(defaults.colors.neutrals[800], 0.5),
        },
    },
    textareaContainer: {
        alignItems: "center",
        display: "flex",

        backgroundColor: defaults.colors.neutrals[100],
        border: `1px solid ${defaults.colors.neutrals[500]}`,
        borderRadius: defaults.cornerRadius.normal,
        boxShadow: defaults.shadows.small.tight,
        outline: 0,
        transition: defaults.transitions.interactions.fast,
    },

    disabled: {
        opacity: 0.5,
    },

    focused: {
        border: `1px solid ${defaults.colors.primary[400]}`,
        boxShadow: defaults.shadows.small.wide,
    },

    hasError: {
        borderColor: defaults.colors.accents.error[400],
    },

    notice: {
        borderColor: defaults.colors.accents.notice["500"],
    },

    "kind-dark": {
        backgroundColor: defaults.colors.neutrals[900],
        border: `1px solid ${defaults.colors.neutrals[900]}`,

        "& textarea": {
            color: defaults.colors.neutrals[100],

            "&::placeholder": {
                color: defaults.colors.neutrals[700],
            },
        },
    },
})

export default styles
