import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    navigationContainer: {
        zIndex: defaults.zIndex.Navigation,

        "&$noZIndex": {
            zIndex: "auto",
        },
    },
    navigationDark: {
        backgroundColor: defaults.colors.neutrals[900],
    },
    navigationFixed: {
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        transition: defaults.transitions.interactions.fast,
    },
    navigationFixedBottom: {
        bottom: 0,
        left: 0,
        position: "fixed",
        right: 0,
    },
    navigationLight: {
        backgroundColor: defaults.colors.neutrals[100],
    },
    navigationTransparent: {
        backgroundColor: "transparent",
    },
    navigationNavItem: {
        "& a": {
            color: defaults.colors.neutrals[900],
            fontSize: defaults.fontSizes[16],
            fontStyle: "normal",
            fontWeight: "normal",
            lineHeight: defaults.lineHeights.m,
            textDecoration: "none",

            "&:hover": {
                color: defaults.colors.neutrals[700],
            },
        },
    },
    navigationNavItemActive: {
        borderBottom: 1,
        borderBottomStyle: "solid",
    },
    navigationNavItems: {
        "&$right": {
            marginLeft: "auto",
        },
        "&$noPadding": {
            padding: 0,
        },
    },
    navigationNavUl: {
        alignItems: "center",
        display: "flex",
        listStyle: "none",
        margin: 0,
        padding: 0,

        "&> li": {
            display: "inline-block",
            marginLeft: defaults.spacing[16],
            marginRight: defaults.spacing[16],

            "&:first-child": {
                marginLeft: 0,
            },
            "&:last-child": {
                marginRight: 0,
            },
        },

        "&$right": {
            justifyContent: "flex-end",
        },
    },

    noZIndex: {},
    noPadding: {},
    right: {},
})

export default styles
