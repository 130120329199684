import classNames from "classnames"
import React, { createContext, useContext } from "react"
import { GridColumns } from "../defaults"
import { Loader } from "../Loader"
import { ValuesOf } from "../typings/clrhs"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ITableContext {
    classes: Record<any, string>
    tight?: boolean
}
const TableContext = createContext<ITableContext | null>(null)

interface ITableStyleProps {
    children?: React.ReactNode
    /**
     * Role of table
     */
    role?: string
}

interface ITableProps extends ITableStyleProps {
    className?: string | string[]
    /**
     * Fixes the layout of the table
     */
    fixedLayout?: boolean
    /**
     * Tightens up the row height of the table
     * @default false
     */
    tight?: boolean
    /**
     * Add's zebra stripes to table
     * @default true
     */
    striped?: boolean
    /**
     * Shows loading state of table
     * @default false
     */
    isLoading?: boolean
    /**
     * Make content scrollable within container
     * @default undefined
     */
    scroll?: "horizontal" | "none"
}

const useStyles = makeStyles(styles, "Table", true)
function Table(props: ITableProps) {
    const classes = useStyles()
    const {
        tight = false,
        striped = true,
        role,
        className,
        fixedLayout,
        children,
        isLoading = false,
        scroll,
    } = props

    const tableClasses = classNames(
        classes.table,
        {
            [classes.fixedLayout]: fixedLayout,
            [classes.noStripes]: !striped,
        },
        className
    )

    const containerClasses = classNames(classes.container, {
        [classes.horizontalScroll]: scroll === "horizontal",
    })

    return (
        <TableContext.Provider
            value={{
                classes,
                tight,
            }}
        >
            <div className={containerClasses}>
                {isLoading && <Loader fillToParent size={24} />}
                <table className={tableClasses} role={role}>
                    {children}
                </table>
            </div>
        </TableContext.Provider>
    )
}

const useTableContext = () => {
    const context = useContext(TableContext)
    if (!context) {
        throw new Error(
            `Table compound components cannot be rendered outside a <Table /> component`
        )
    }

    return context
}

interface IWidthProps {
    xs?: ValuesOf<typeof GridColumns>
    sm?: ValuesOf<typeof GridColumns>
    md?: ValuesOf<typeof GridColumns>
    lg?: ValuesOf<typeof GridColumns>
    xl?: ValuesOf<typeof GridColumns>
}

interface IHeadProps extends ITableProps {
    /**
     * Show shadow on head section
     * @default true
     */
    shadow?: boolean
    /**
     * Show border on head section
     * @default false
     */
    border?: boolean
}
function Head(props: IHeadProps) {
    const { classes } = useTableContext()
    const { shadow = true, border = false } = props

    const headClasses = classNames(classes.head, {
        [classes.noHeadShadow]: !shadow,
        [classes.border]: border,
    })
    return <thead className={headClasses}>{props.children}</thead>
}

interface IHeaderProps extends ITableProps, IWidthProps {
    textAlign?: "left" | "center" | "right"
    noWrap?: boolean
    colSpan?: number
}

function Header(props: IHeaderProps) {
    const { classes, tight } = useTableContext()

    const { textAlign = "left", noWrap = false, colSpan } = props

    const headerClasses = classNames(classes.header, {
        [classes.textAlign]: textAlign,
        [classes[textAlign]]: textAlign,
        [classes.noWrap]: noWrap,
        [classes[`table-cell-xs-${props.xs}`]]: props.xs !== undefined,
        [classes[`table-cell-sm-${props.sm}`]]: props.sm !== undefined,
        [classes[`table-cell-md-${props.md}`]]: props.md !== undefined,
        [classes[`table-cell-lg-${props.lg}`]]: props.lg !== undefined,
        [classes[`table-cell-xl-${props.xl}`]]: props.xl !== undefined,
        [classes.tight]: tight,
    })

    return (
        <th className={headerClasses} colSpan={colSpan}>
            {props.children}
        </th>
    )
}

interface IBodyProps extends ITableProps {}
function Body(props: IBodyProps) {
    const { classes } = useTableContext()

    return <tbody className={classes.body}>{props.children}</tbody>
}

interface IRowProps extends ITableProps {
    onClick?: (_e: React.MouseEvent) => void
}
function Row(props: IRowProps) {
    const { classes } = useTableContext()

    const rowClasses = classNames(classes.row, {
        [classes.clickable]: props.onClick,
    })

    return (
        <tr onClick={props.onClick} className={rowClasses}>
            {props.children}
        </tr>
    )
}

interface ICellProps extends ITableProps, IWidthProps {
    textAlign?: "left" | "center" | "right"
    textIndent?: boolean
    noWrap?: boolean
    highlighted?: boolean
    style?: React.CSSProperties
    colSpan?: number
}
function Cell(props: ICellProps) {
    const { classes, tight } = useTableContext()
    const { textAlign = "left", style, colSpan, className } = props

    const cellClasses = classNames(classes.cell, className, {
        [classes.textAlign]: textAlign,
        [classes.textIndent]: props.textIndent,
        [classes[textAlign]]: textAlign,
        [classes.highlighted]: props.highlighted,
        [classes.noWrap]: props.noWrap,
        [classes[`table-cell-xs-${props.xs}`]]: props.xs !== undefined,
        [classes[`table-cell-sm-${props.sm}`]]: props.sm !== undefined,
        [classes[`table-cell-md-${props.md}`]]: props.md !== undefined,
        [classes[`table-cell-lg-${props.lg}`]]: props.lg !== undefined,
        [classes[`table-cell-xl-${props.xl}`]]: props.xl !== undefined,
        [classes.tight]: tight,
    })

    return (
        <td className={cellClasses} style={style} colSpan={colSpan}>
            {props.children}
        </td>
    )
}

Table.Head = Head
Table.Header = Header
Table.Body = Body
Table.Row = Row
Table.Cell = Cell

export { Table }
