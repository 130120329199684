import React, { useContext } from "react"
import { BoxEdges } from "../BoxEdges"
import { Button } from "../Button"
import { Grid } from "../Grid"
import { Heading } from "../Heading"
import { CrossIcon } from "../Icon"
import { ThemeContext } from "../ThemeProvider"
import devMode from "../utils/devMode"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface IPopoverHeaderProps {
    icon?: JSX.Element
    heading?: string
    onCloseClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>, ref?: string | undefined) => void
}

const useStyles = makeStyles(styles, "PopoverHeader", true)
const PopoverHeader = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<IPopoverHeaderProps>
>((props, ref) => {
    const { defaults } = useContext(ThemeContext)
    const classes = useStyles()

    return (
        <div className={classes.container} ref={ref}>
            <BoxEdges p={12} shadowCustom={defaults.shadows.bottom}>
                <Grid row justify="space-between" alignItems="center" noGutters>
                    <Grid item>
                        <Grid row alignItems="center" noGutters>
                            {props.icon && (
                                <Grid item auto>
                                    <BoxEdges mr={8}>{props.icon}</BoxEdges>
                                </Grid>
                            )}
                            <Grid item>
                                <Heading level={6}>{props.heading}</Heading>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item auto>
                        <Button onClick={props.onCloseClick} light tight prefix={<CrossIcon />} />
                    </Grid>
                </Grid>
            </BoxEdges>
        </div>
    )
})

if (devMode) {
    PopoverHeader.displayName = "PopoverHeader"
}

export { PopoverHeader }
