import React from "react"
import { Icon } from "../types"

const MastercardColoredIcon: React.FC<Icon> = (props) => {
    const { size = 24, ...otherProps } = props
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <g clipPath="url(#clip0)">
                <path d="M15.2441 6.58301H8.75452V18.2458H15.2441V6.58301Z" fill="#FF5F00" />
                <path
                    d="M9.16677 12.4197C9.16588 11.2966 9.42046 10.1881 9.91124 9.17802C10.402 8.16791 11.1161 7.28268 11.9995 6.58931C10.9054 5.72943 9.5914 5.19472 8.20774 5.04629C6.82408 4.89787 5.42658 5.14172 4.17495 5.74997C2.92332 6.35822 1.86806 7.30633 1.12979 8.48594C0.391511 9.66556 0 11.0291 0 12.4207C0 13.8123 0.391511 15.1758 1.12979 16.3554C1.86806 17.535 2.92332 18.4831 4.17495 19.0914C5.42658 19.6996 6.82408 19.9435 8.20774 19.7951C9.5914 19.6466 10.9054 19.1119 11.9995 18.2521C11.1159 17.5585 10.4016 16.6729 9.91079 15.6624C9.42 14.6519 9.16557 13.543 9.16677 12.4197V12.4197Z"
                    fill="#EB001B"
                />
                <path
                    d="M24 12.4186C24 13.8103 23.6084 15.1739 22.87 16.3536C22.1316 17.5332 21.0761 18.4813 19.8244 19.0895C18.5726 19.6976 17.1749 19.9413 15.7912 19.7927C14.4075 19.644 13.0935 19.1091 11.9994 18.2489C12.8824 17.5549 13.5964 16.6695 14.0873 15.6593C14.5782 14.6491 14.8332 13.5407 14.8332 12.4176C14.8332 11.2944 14.5782 10.186 14.0873 9.17581C13.5964 8.16565 12.8824 7.28017 11.9994 6.58618C13.0936 5.72592 14.4078 5.19093 15.7917 5.04237C17.1756 4.89381 18.5734 5.13768 19.8253 5.74609C21.0771 6.35451 22.1325 7.30291 22.8708 8.48287C23.609 9.66282 24.0004 11.0267 24 12.4186Z"
                    fill="#F79E1B"
                />
            </g>
        </svg>
    )
}

export { MastercardColoredIcon }
