import { create, Jss } from "jss"
import jssPluginSyntaxCamelCase from "jss-plugin-camel-case"
import jssPluginSyntaxDefaultUnit from "jss-plugin-default-unit"
import jssPluginSyntaxGlobal from "jss-plugin-global"
import jssPluginSyntaxNested from "jss-plugin-nested"
import jssPluginPropsSort from "jss-plugin-props-sort"
import React, { useContext } from "react"
import defaults from "../defaults"
import { createGenerateClassName } from "../utils/styles/createGenerateClassName"

interface IThemeProvider {
    defaults: typeof defaults
    children: React.ReactNode
}

interface IThemeContext {
    defaults: typeof defaults
    jss: Jss
    generateClassName: ReturnType<typeof createGenerateClassName>
    sheetsManager: Map<any, any>
}

const jss = create({
    plugins: [
        jssPluginSyntaxGlobal(),
        jssPluginSyntaxNested(),
        jssPluginSyntaxCamelCase(),
        jssPluginSyntaxDefaultUnit(),
        jssPluginPropsSort(),
    ],
})

const generateClassName = createGenerateClassName()
const sheetsManager = new Map()

const defaultContext = {
    defaults,
    jss,
    generateClassName,
    sheetsManager,
}

export const ThemeContext = React.createContext<IThemeContext>(defaultContext)

export const ThemeProvider: React.FC<IThemeProvider> = (props) => {
    const { defaults, children } = props
    const outerContext = useContext(ThemeContext)
    const context = { ...outerContext, defaults }

    return <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
}
