import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const ApplicationSignedStepIcon: React.FC<Icon> = (props) => {
    const { size = 24, ...otherProps } = props
    const { defaults } = useContext(ThemeContext)
    return (
        <svg
            style={{
                marginLeft: -(0.34 * (size as number)),
            }}
            width={1.34 * (size as number)}
            height={size}
            viewBox="0 0 67 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42 47C54.1503 47 64 37.1503 64 25C64 12.8497 54.1503 3 42 3C29.8497 3 20 12.8497 20 25C20 37.1503 29.8497 47 42 47ZM42 50C55.8071 50 67 38.8071 67 25C67 11.1929 55.8071 0 42 0C28.1929 0 17 11.1929 17 25C17 38.8071 28.1929 50 42 50Z"
                fill="#E8EDF2"
            />
            <path
                d="M60.0385 28.32L48.0077 35.3379C46.6744 36.1157 45 35.1539 45 33.6103V19.5745C45 18.0309 46.6744 17.0692 48.0077 17.8469L60.0385 24.8648C61.3615 25.6366 61.3615 27.5482 60.0385 28.32Z"
                fill={defaults.colors.neutrals[500]}
            />
            <path
                d="M39.6432 36.9404L34.53 40.1362C33.864 40.5524 33 40.0736 33 39.2882V32.8967C33 32.1112 33.864 31.6324 34.53 32.0487L39.6432 35.2444C40.2699 35.6361 40.2699 36.5487 39.6432 36.9404Z"
                fill={defaults.colors.neutrals[500]}
            />
            <path
                d="M38.6742 22.9361L30.5369 28.1144C29.8712 28.538 29 28.0598 29 27.2707V16.9141C29 16.125 29.8712 15.6468 30.5369 16.0704L38.6742 21.2487C39.2917 21.6417 39.2917 22.5431 38.6742 22.9361Z"
                fill={defaults.colors.neutrals[500]}
            />
            <path
                d="M58.0385 26.32L46.0077 33.3379C44.6744 34.1157 43 33.1539 43 31.6103V17.5745C43 16.0309 44.6744 15.0692 46.0077 15.8469L58.0385 22.8648C59.3615 23.6366 59.3615 25.5482 58.0385 26.32Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                d="M37.6432 34.9404L32.53 38.1362C31.864 38.5524 31 38.0736 31 37.2882V30.8967C31 30.1112 31.864 29.6324 32.53 30.0487L37.6432 33.2444C38.2699 33.6361 38.2699 34.5487 37.6432 34.9404Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                d="M36.6742 20.9361L28.5369 26.1144C27.8712 26.538 27 26.0598 27 25.2707V14.9141C27 14.125 27.8712 13.6468 28.5369 14.0704L36.6742 19.2487C37.2917 19.6417 37.2917 20.5431 36.6742 20.9361Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 22.0924C14 21.5401 14.4477 21.0924 15 21.0924H23C23.5523 21.0924 24 21.5401 24 22.0924C24 22.6447 23.5523 23.0924 23 23.0924H15C14.4477 23.0924 14 22.6447 14 22.0924Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-1.2567e-07 18.0924C-5.62645e-08 17.5401 0.447715 17.0924 1 17.0924L24 17.0924C24.5523 17.0924 25 17.5401 25 18.0924C25 18.6447 24.5523 19.0924 24 19.0924L1 19.0924C0.447715 19.0924 -1.95076e-07 18.6447 -1.2567e-07 18.0924Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 36.0924C20 35.5401 20.4477 35.0924 21 35.0924H26.5C27.0523 35.0924 27.5 35.5401 27.5 36.0924C27.5 36.6447 27.0523 37.0924 26.5 37.0924H21C20.4477 37.0924 20 36.6447 20 36.0924Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 32.0924C10 31.5401 10.4477 31.0924 11 31.0924L27 31.0924C27.5523 31.0924 28 31.5401 28 32.0924C28 32.6447 27.5523 33.0924 27 33.0924L11 33.0924C10.4477 33.0924 10 32.6447 10 32.0924Z"
                fill={defaults.colors.neutrals[900]}
            />
        </svg>
    )
}

export { ApplicationSignedStepIcon }
