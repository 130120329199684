import React from "react"
import { BoxEdges } from "../"

interface ISectionContentProps {
    children: React.ReactNode
}
export const SectionContent: React.FC<ISectionContentProps> = (props) => {
    const { children } = props

    return <BoxEdges py={24}>{children}</BoxEdges>
}
