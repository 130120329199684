import classNames from "classnames"
import { Classes } from "jss"
import React, {
    createContext,
    ForwardRefExoticComponent,
    PropsWithoutRef,
    RefAttributes,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import { ColorWeights } from "../defaults"
import { Label } from "../Label"
import { Separator } from "../Separator"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface IListContext {
    classes: Classes<keyof ReturnType<typeof styles>>
}
// @ts-ignore
const ListContext = createContext<IListContext>()
// @ts-ignore
const ListItemContext = createContext<IListContext>()

interface IListProps {
    children?: React.ReactNode
    dimmed?: boolean
    type?: "unordered" | "ordered"
}

const useStyles = makeStyles(styles, "List", true)
function List(props: IListProps) {
    const { type = "unordered", dimmed = false } = props
    const classes = useStyles()

    const listClasses = classNames([
        classes.container,
        { [classes.dimmed]: dimmed, [classes.olContainer]: type === "ordered" },
    ])

    const contextValue = useMemo(
        () => ({
            classes,
        }),
        [classes]
    )

    return (
        <ListContext.Provider value={contextValue}>
            {type === "unordered" && <ul className={listClasses}>{props.children}</ul>}
            {type === "ordered" && <ol className={listClasses}>{props.children}</ol>}
        </ListContext.Provider>
    )
}

const useListContext = () => {
    const context = useContext(ListContext) as IListContext

    if (!context) {
        throw new Error(
            `List compound components cannot be rendered outside the <List /> component`
        )
    }

    return context
}

const useListItemContext = () => {
    const context = useContext(ListItemContext) as IListContext

    if (!context) {
        throw new Error(
            `ListItem compound components cannot be rendered outside a <ListItem /> component`
        )
    }
    return context
}

interface IListItemProps extends IListProps {
    separatorColorWeight?: ColorWeights
    onClick?: (_e: React.MouseEvent) => void
    onKeyPress?: (_e: React.KeyboardEvent) => void
    style?: React.CSSProperties
    active?: boolean
    hover?: boolean
}

interface ListItemComponent<P> extends ForwardRefExoticComponent<P> {
    Icon: typeof ListItemIcon
    Content: typeof ListItemContent
    Actions: typeof ListItemActions
}

const ListItem = React.forwardRef<HTMLLIElement, IListItemProps>((props, ref) => {
    const { classes } = useListContext()
    const [hover, setHover] = useState(() => {
        return props.hover !== undefined ? props.hover : false
    })
    const [active, setActive] = useState(() => {
        return props.active !== undefined ? props.active : false
    })

    const itemClasses = classNames({
        [classes.itemWrapper]: true,
        [classes.hover]: hover,
        [classes.active]: active,
        ["clickable"]: props.onClick,
    })

    useEffect(() => {
        if (props.active !== undefined && props.active !== active) {
            setActive(props.active)
        }

        if (props.hover !== undefined && props.hover !== hover) {
            setHover(props.hover)
        }
    }, [props.active, props.hover])

    const contextValue = useMemo(
        () => ({
            classes,
        }),
        [classes]
    )

    const handleMouseEnter = useCallback(() => {
        setHover(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
        setHover(false)
    }, [])

    return (
        <ListItemContext.Provider value={contextValue}>
            <li
                className={itemClasses}
                onClick={props.onClick}
                onKeyPress={props.onKeyPress}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={props.style}
                ref={ref}
                tabIndex={0}
            >
                <div className={classes.item}>{props.children}</div>
            </li>
            {props.separatorColorWeight && <Separator colorWeight={props.separatorColorWeight} />}
        </ListItemContext.Provider>
    )
}) as ListItemComponent<PropsWithoutRef<IListItemProps> & RefAttributes<HTMLLIElement>>

interface IListItemIconProps extends IListProps {}
function ListItemIcon(props: IListItemIconProps) {
    const { classes } = useListItemContext()

    return <div className={classes.icon}>{props.children}</div>
}

interface IListItemContentProps extends IListProps {
    primary?: string | React.ReactNode
    secondary?: string | React.ReactNode
}
function ListItemContent(props: IListItemContentProps) {
    const { classes } = useListItemContext()
    const { primary, secondary, children } = props

    const primaryElements = useMemo(() => {
        return typeof primary === "string" ? <Label margin="none">{primary}</Label> : primary
    }, [primary])

    const secondaryElements = useMemo(() => {
        return !secondary ? null : typeof secondary === "string" ? (
            <Label margin="none">{secondary}</Label>
        ) : (
            secondary
        )
    }, [secondary])

    return (
        <div className={classNames(classes.content)}>
            {primaryElements && primaryElements}
            {secondaryElements && secondaryElements}
            {children}
        </div>
    )
}

interface IListItemActionsProps extends IListProps {}
function ListItemActions(props: IListItemActionsProps) {
    const { classes } = useListItemContext()

    return <div className={classes.actions}>{props.children}</div>
}

ListItem.Icon = ListItemIcon
ListItem.Content = ListItemContent
ListItem.Actions = ListItemActions

List.Item = ListItem

export { List }
