import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    actions: {
        display: "flex",
        flexGrow: 0,
        justifyContent: "flex-end",

        "&:last-child": {
            paddingLeft: defaults.spacing[8],
            paddingRight: 0,
        },
    },
    clickable: {},
    container: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        listStyle: "none",
        margin: defaults.spacing[0],
        padding: 0,
    },
    olContainer: {
        counterReset: "clrhs-list-ordered",

        "& $itemWrapper": {
            display: "flex",
            counterIncrement: "clrhs-list-ordered",

            "&:before": {
                content: `counter(clrhs-list-ordered)`,
                margin: defaults.spacing["8"],
                marginRight: defaults.spacing["8"] + defaults.grid.gutterWidth,
                height: 20,
                width: 22,
                lineHeight: "19px",
                fontSize: defaults.fontSizes["14"],
                fontWeight: defaults.fontWeights.bold,
                borderRadius: defaults.cornerRadius.rounded,
                backgroundColor: defaults.colors.neutrals["900"],
                color: defaults.colors.neutrals["100"],
                boxSizing: "border-box",
                textAlign: "center",
            },
        },
    },
    content: {
        flexGrow: 1,

        "&> *:first-child": {
            paddingTop: 0,
        },
        "&> *:last-child": {
            paddingBottom: 0,
        },
    },
    dimmed: {
        "& $item": {
            opacity: 0.6,
        },
    },
    icon: {
        flexGrow: 0,

        "&:first-child": {
            paddingLeft: 0,
            paddingRight: defaults.spacing[8],
        },
        "&:last-child": {
            paddingLeft: defaults.spacing[8],
            paddingRight: 0,
        },
    },
    item: {
        alignItems: "center",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        padding: `${defaults.spacing[12]}px 0px`,
        position: "relative",
        textAlign: "left",
        transition: defaults.transitions.interactions.fast,
        width: "100%",
    },
    itemBackground: {
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
        transition: defaults.transitions.interactions.fast,
        zIndex: 1,
    },
    itemWrapper: {
        borderRadius: defaults.cornerRadius.normal,
        position: "relative",
        width: "100%",

        "&:focus": {
            outline: 0,
        },

        "&.clickable, &.clickable *": {
            cursor: "pointer",

            "&:focus": {
                boxShadow: `inset 0 0 0 1px ${defaults.colors.primary[400]}`,
                outline: 0,
            },
        },

        "&.clickable": {
            "&$hover": {
                backgroundColor: defaults.colors.neutrals[200],
            },
            "&:active, &$active": {
                backgroundColor: defaults.colors.neutrals[300],
            },
        },

        "&:last-child": {
            borderBottom: 0,
        },
    },
    text: {
        flexGrow: 1,
    },

    hover: {},
    active: {},
})

export default styles
