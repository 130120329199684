import { motion, useAnimation, Variants } from "framer-motion"
import React, { MutableRefObject, useEffect, useState } from "react"

interface IFadeProps {
    show: boolean
    children: React.ReactNode
    onShowStart?: () => void
    onShowEnd?: () => void
    onHideStart?: () => void
    onHideEnd?: () => void
    ref?: MutableRefObject<HTMLDivElement>
}

const fadeVariants: Variants = {
    show: { opacity: [0, 1], transition: { duration: 0.2 } },
    hidden: { opacity: [1, 0], transition: { duration: 0.1 } },
}

export const Fade = React.forwardRef<HTMLDivElement, IFadeProps>((props, ref) => {
    const { onHideStart, onHideEnd, onShowEnd, onShowStart, show: externalShow } = props
    const [renderFade, setRenderFade] = useState(false)

    const animationControls = useAnimation()

    useEffect(() => {
        if (externalShow && !renderFade) {
            setRenderFade(true)
        }
    }, [externalShow])

    useEffect(() => {
        if (externalShow && renderFade) {
            if (onShowStart) {
                onShowStart()
            }
            animationControls.start("show").finally(() => {
                if (onShowEnd) {
                    onShowEnd()
                }
            })
        } else if (!externalShow && renderFade) {
            if (onHideStart) {
                onHideStart()
            }
            animationControls.start("hidden").finally(() => {
                if (onHideEnd) {
                    onHideEnd()
                }

                setRenderFade(false)
            })
        }
    }, [externalShow, renderFade, onShowEnd])

    if (!renderFade) {
        return null
    }

    return (
        <motion.div animate={animationControls} variants={fadeVariants} ref={ref}>
            {props.children}
        </motion.div>
    )
})
Fade.displayName = "Fade"
