import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

interface ExtraProps {
    borderColor?: string
    borderSize?: number
}

const FilledCircleIcon: React.FC<Icon & ExtraProps> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        color = defaults.colors.neutrals[900],
        borderColor = "transparent",
        borderSize = 1,
        size = 24,
    } = props
    return (
        <div
            style={{
                backgroundColor: color,
                border: `${borderSize}px solid ${borderColor}`,
                borderRadius: "50%",
                height: size,
                width: size,
            }}
        />
    )
}

export { FilledCircleIcon }
