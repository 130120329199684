const styles = {
    separator: {},

    horizontal: {
        height: 1,
        width: "100%",
    },
    vertical: {
        height: "100%",
        width: 1,
    },
}

export default styles
