import { Property } from "csstype"
// @ts-ignore
import CalibreWebMedium from "./Fonts/CalibreWeb-Medium.woff"
// @ts-ignore
import CalibreWebRegular from "./Fonts/CalibreWeb-Regular.woff"
// @ts-ignore
import CalibreWebSemibold from "./Fonts/CalibreWeb-Semibold.woff"
// @ts-ignore
import CircularMedium from "./Fonts/lineto-circular-pro-medium.woff"
// @ts-ignore
import SourceCodeProMediumItalic from "./Fonts/SourceCodePro-Medium-Italic.woff"
// @ts-ignore
import SourceCodeProMedium from "./Fonts/SourceCodePro-Medium.woff"
// @ts-ignore
import SourceCodeProSemiboldItalic from "./Fonts/SourceCodePro-Semibold-Italic.woff"
// @ts-ignore
import SourceCodeProSemibold from "./Fonts/SourceCodePro-Semibold.woff"

type ValuesOf<T extends any[]> = T[number]

// Adding values should not be necessary. Please keep them to a minimal.
// If needed please keep to the base value 16.
const spacing = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    6: 6,
    8: 8,
    10: 10,
    12: 12,
    16: 16,
    24: 24,
    32: 32,
    40: 40,
    48: 48,
    64: 64,
    96: 96,
    128: 128,
    192: 192,
    256: 256,
    384: 384,
    512: 512,
    640: 640,
    768: 768,
}

export type Spacings = keyof typeof spacing

// Adding values should not be necessary. Please keep them to a minimal.
const fontSizes = {
    8: 8,
    10: 10,
    11: 11,
    12: 12,
    13: 13,
    14: 14,
    15: 15,
    16: 16,
    18: 18,
    20: 20,
    24: 24,
    30: 30,
    36: 36,
    48: 48,
    60: 60,
    72: 72,
}

const gridGutterWidth = 24
export const GridColumns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
export type GridSizes = ValuesOf<typeof GridColumns>
const gridBreakpoints = {
    // Sorted ASC by size. That's important.
    xs: 0,
    // tslint:disable-next-line:object-literal-sort-keys
    sm: 540,
    md: 960,
    lg: 1140,
    xl: 1440,
    xxl: 1860,
}
export type GridBreakpoints = keyof typeof gridBreakpoints
const gridMaxContainerWidth = gridBreakpoints.xl

export type ColorWeights = 900 | 800 | 700 | 600 | 500 | 400 | 300 | 200 | 100
export type ColorNames =
    | keyof Omit<typeof colors, "accents">
    | "accents-success"
    | "accents-warning"
    | "accents-error"
    | "accents-notice"

// Disable tslint, as the order of properies makes more sense with this order
// tslint:disable:object-literal-sort-keys
const colors = {
    primary: {
        900: "#153557",
        700: "#424E7F",
        600: "#224693",
        500: "#295ACC",
        400: "#92ADE7",
        300: "#CED9F7",
        200: "#EEF2FE",
        100: "#F5F8FE",
        0: "#F8FAFF",
    },
    neutrals: {
        900: "#1B2431",
        800: "#273346",
        700: "#636F7F",
        600: "#B7C6D7",
        500: "#D8E0E9",
        400: "#E8EDF2",
        300: "#F2F4F6",
        200: "#F6F8FA",
        100: "#FFFFFF",
    },
    accents: {
        success: {
            900: "#175439",
            700: "#299867",
            500: "#4BCE93",
            300: "#A3E6C8",
            200: "#CFF2E2",
            100: "#DFF6EC",
        },
        warning: {
            900: "#604C20",
            700: "#C4A650",
            500: "#F2C359",
            400: "#F6E2A7",
            300: "#FBECC1",
            200: "#FDF5E3",
            100: "#FEFBF1",
        },
        error: {
            900: "#602020",
            700: "#A73029",
            600: "#B92D2E",
            500: "#CE3334",
            400: "#F57070",
            300: "#F2A7A5",
            200: "#F6E7E6",
            100: "#FCF3F3",
        },
        notice: {
            900: "#803900",
            700: "#C25700",
            600: "#D66000",
            500: "#FF7200",
            400: "#FFB67A",
            300: "#FFDABD",
            200: "#FFECDB",
            100: "#FFF2E7",
        },
    },
}

const gradients = {
    standard: "linear-gradient(90deg, #5F79E6 0%, #677EDC 100%)",
    neutrals: {
        200: "linear-gradient(90deg, #F6F8FA 0%, #F8FAFB 100%)",
    },
    primary: {
        500: "linear-gradient(90deg, #295ACC 0%, #3767D7 100%)",
    },
}

const fonts = [
    {
        family: "Calibre",
        style: "normal",
        weight: 400,
        path: CalibreWebRegular,
        format: "woff",
    },
    {
        family: "Calibre",
        style: "medium",
        weight: 500,
        path: CalibreWebMedium,
        format: "woff",
    },
    {
        family: "Calibre",
        style: "bold",
        weight: 600,
        path: CalibreWebSemibold,
        format: "woff",
    },
    {
        family: "Circular",
        style: "normal",
        weight: 400,
        path: CircularMedium,
        format: "woff",
    },
    {
        family: "SourceCodePro-noDot",
        style: "normal",
        weight: 400,
        path: SourceCodeProMedium,
        format: "woff",
    },
    {
        family: "SourceCodePro-noDot",
        style: "italic",
        weight: 400,
        path: SourceCodeProMediumItalic,
        format: "woff",
    },
    {
        family: "SourceCodePro-noDot",
        style: "normal",
        weight: 500,
        path: SourceCodeProSemibold,
        format: "woff",
    },
    {
        family: "SourceCodePro-noDot",
        style: "italic",
        weight: 500,
        path: SourceCodeProSemiboldItalic,
        format: "woff",
    },
]

const fontFamilies = {
    primary: "'Calibre', sans-serif",
    secondary: '"Circular", "Arial", sans-serif',
    mono: "SourceCodePro-noDot",
}

interface LineHeights {
    none: 0
    s: 1
    m: 1.25
    l: 1.5
    xl: 2
}

const lineHeights: LineHeights = {
    none: 0,
    s: 1,
    m: 1.25,
    l: 1.5,
    xl: 2,
}

interface FontWeights {
    normal: 400
    medium: 500
    semibold: 600
    bold: 700
}

const fontWeights: FontWeights = {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
}

export interface CornerRadius {
    normal: 3
    rounded: 9999
    large: 6
}

const cornerRadius: CornerRadius = {
    normal: 3,
    rounded: 9999,
    large: 6,
}

interface Shadows {
    bottom: Property.BoxShadow
    right: Property.BoxShadow
    top: Property.BoxShadow
    left: Property.BoxShadow
    small: {
        tight: Property.BoxShadow
        wide: Property.BoxShadow
    }
    large: {
        tight: Property.BoxShadow
        wide: Property.BoxShadow
    }
}

const transitions = {
    curve: {
        accelerated: "cubic-bezier(.61,.84,.55,.96)",
    },
    interactions: {
        fast: "0.25s ease all",
    },
}

const shadows: Shadows = {
    bottom: "0 20px 40px rgba(10, 20, 30, 0.05)",
    right: "20px 0 40px rgba(10, 20, 30, 0.05)",
    top: "0 -20px 40px rgba(10, 20, 30, 0.05)",
    left: "-20px 0 40px rgba(10, 20, 30, 0.05)",
    small: {
        tight: "0 2px 3px rgba(0,0,0,.04)",
        wide: "0 8px 13px rgba(0,15,30,.04)",
    },
    large: {
        tight: "0px 10px 10px rgba(0, 0, 0, 0.02)",
        wide: "0 10px 35px rgba(0,15,30,.20)",
    },
}

const zIndex = {
    LoadingScreen: 12000,
    Select: 11000,
    Modal: 10000,
    DropdownTrigger: 9100,
    Dropdown: 9000,
    Popover: 8000,
    Tour: 7000,
    Sidebar: 6000,
    Navigation: 5000,
    InlineSidebar: 4000,
    SectionsHeader: 3000,
    Snackbar: 2000,
}

const navigation = {
    height: 60,
}

const slider = {
    height: spacing["4"],
    padding: `${spacing["16"]}px 0`,
    railColor: colors.neutrals["500"],
    trackColor: colors.primary["500"],
    pointer: {
        bgColor: colors.neutrals["100"],
        color: colors.primary["500"],
        width: spacing["4"],
        size: spacing["4"] + 1,
    },
    mark: {
        size: spacing["4"] + 1,
        width: spacing["2"],
        color: colors.neutrals["500"],
        label: {
            marginTop: spacing["8"],
        },
    },
}

export type FontSizes = keyof typeof fontSizes

export default {
    fontFamilies: {
        ...fontFamilies,
    },
    fontSizes: {
        ...fontSizes,
    },
    colors: {
        ...colors,
    },
    gradients: {
        ...gradients,
    },
    transitions: {
        ...transitions,
    },
    cornerRadius: {
        ...cornerRadius,
    },
    shadows: {
        ...shadows,
    },
    slider: {
        ...slider,
    },
    spacing: {
        ...spacing,
    },
    lineHeights: {
        ...lineHeights,
    },
    fontWeights: {
        ...fontWeights,
    },
    zIndex: {
        ...zIndex,
    },
    navigation: {
        ...navigation,
    },
    grid: {
        breakpoints: gridBreakpoints,
        gutterWidth: gridGutterWidth,
        maxContainerWidth: gridMaxContainerWidth,
    },
    fonts,
}
