import classnames from "classnames"
import React from "react"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ISpacerProps {
    size?: "xxsmall" | "xsmall" | "small" | "medium" | "large" | "xlarge" | "xxlarge" | number
}

const useStyles = makeStyles(styles, "Spacer", true)
export const Spacer: React.FC<ISpacerProps> = ({ size = "medium" }) => {
    const classes = useStyles()

    if (typeof size === "number") {
        return <div style={{ width: size, height: size }} />
    }

    // @ts-ignore - @TODO
    const className = classnames(classes[`${size}`])
    return <div className={className} />
}
