import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        display: "none",
        height: "100vh",
        backgroundColor: defaults.colors.neutrals[100],
        boxShadow: defaults.shadows.right,
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: defaults.zIndex.Sidebar,
        maxWidth: 300,
    },

    open: {
        display: "flex",
    },
})

export default styles
