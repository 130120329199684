import hex2rgba from "hex-to-rgba"
import React, { useContext } from "react"
import { Grid } from "../Grid"
import { Loader } from "../Loader"
import { ThemeContext } from "../ThemeProvider"

interface ILoadingScreenProps {
    hidden?: boolean
    noBackground?: boolean
}

export const LoadingScreen: React.FC<ILoadingScreenProps> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { hidden = false, noBackground = false } = props

    return (
        <>
            {hidden === false && (
                <>
                    <Grid
                        row
                        alignItems="center"
                        justify="center"
                        style={{
                            backgroundColor: !noBackground
                                ? hex2rgba(defaults.colors.neutrals[200], 0.7)
                                : undefined,
                            display: "flex",
                            height: "100%",
                            left: 0,
                            position: "absolute",
                            top: 0,
                            width: "100%",
                            zIndex: defaults.zIndex.LoadingScreen,
                        }}
                    />
                    <Grid
                        row
                        alignItems="center"
                        justify="center"
                        style={{
                            display: "flex",
                            height: "100vh",
                            left: 0,
                            minHeight: "100vh",
                            minWidth: "100vw",
                            position: "fixed",
                            top: 0,
                            width: "100vw",
                            zIndex: defaults.zIndex.LoadingScreen + 1,
                        }}
                    >
                        <Grid item auto>
                            <Loader size={42} />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    )
}
