import { useImperativeHandle, useRef } from "react"

// https://github.com/facebook/react/issues/14099#issuecomment-659298422
export default function useCallbackRef<T extends (...args: any[]) => any>(fn: T): T {
    const ref = useRef<T>()
    useImperativeHandle(ref, () => fn) // Assign fn to ref.current, async-safe

    return useRef(((...args: any[]) => {
        const currentFn = ref.current
        if (!currentFn) {
            throw new Error(
                `Callback retrived from 'useCallbackRef() cannot be called from useLayoutEffect()`
            )
        }

        return currentFn(...args)
    }) as T).current
}
