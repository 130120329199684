import { JssStyle } from "jss"
import defaultsOrg, { GridColumns } from "../defaults"
import createBreakpoints, { breakpointValues } from "./createBreakpoints"

const styles = (defaults: typeof defaultsOrg) => {
    const grid = {
        gridSizes: ["auto", true, 0, ...GridColumns],
    }
    const breakpoints = createBreakpoints(defaults.grid.breakpoints)

    const styles = {
        "align-content-center": {
            alignContent: "center",
        },
        "align-content-flex-end": {
            alignContent: "flex-end",
        },
        "align-content-flex-start": {
            alignContent: "flex-start",
        },
        "align-content-space-around": {
            alignContent: "space-around",
        },
        "align-content-space-between": {
            alignContent: "space-between",
        },
        "align-items-baseline": {
            alignItems: "baseline",
        },
        "align-items-center": {
            alignItems: "center",
        },
        "align-items-flex-end": {
            alignItems: "flex-end",
        },
        "align-items-flex-start": {
            alignItems: "flex-start",
        },
        "align-self-auto": {
            alignSelf: "auto",
        },
        "align-self-baseline": {
            alignSelf: "baseline",
        },
        "align-self-center": {
            alignSelf: "center",
        },
        "align-self-flex-end": {
            alignSelf: "flex-end",
        },
        "align-self-flex-start": {
            alignSelf: "flex-start",
        },
        container: {
            boxSizing: "border-box",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: defaults.grid.maxContainerWidth,
            paddingLeft: defaults.grid.gutterWidth / 2,
            paddingRight: defaults.grid.gutterWidth / 2,
            width: "100%",

            "&$fluid": {
                maxWidth: "none",
            },

            "&$noGutters": {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        "direction-column": {
            flexDirection: "column",
        },
        "direction-column-reverse": {
            flexDirection: "column-reverse",
        },
        "direction-row": {
            flexDirection: "row",
        },
        "direction-row-reverse": {
            flexDirection: "row-reverse",
        },
        fixed: {
            position: "fixed",
        },
        fluid: {},
        item: {
            boxSizing: "border-box",
            flexBasis: 0,
            flexGrow: 1,
            margin: 0,
            maxWidth: "100%",
            paddingLeft: defaults.grid.gutterWidth / 2,
            paddingRight: defaults.grid.gutterWidth / 2,
            position: "relative",
            width: "100%",

            "&$fixed": {
                position: "fixed",
            },

            "&$noGutters": {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        "justify-center": {
            justifyContent: "center",
        },
        "justify-flex-end": {
            justifyContent: "flex-end",
        },
        "justify-space-around": {
            justifyContent: "space-around",
        },
        "justify-space-between": {
            justifyContent: "space-between",
        },
        "justify-space-evenly": {
            justifyContent: "space-evenly",
        },
        noGutters: {},
        row: {
            display: "flex",
            flexWrap: "wrap",
            marginLeft: -defaults.grid.gutterWidth / 2,
            marginRight: -defaults.grid.gutterWidth / 2,

            "&$noGutters": {
                marginLeft: 0,
                marginRight: 0,

                "& > $item": {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
        },
        "wrap-nowrap": {
            flexWrap: "nowrap",
        },
        "wrap-wrap-reverse": {
            flexWrap: "wrap-reverse",
        },
    }

    // Create breakpoint styles
    breakpoints.keys.forEach((breakpointKey) => {
        const currentBreakpointStyles: Record<string, JssStyle | string> = {}

        grid.gridSizes.forEach((size) => {
            const key = `grid-${breakpointKey}-${size}`

            if (size === true) {
                currentBreakpointStyles[key] = {
                    flexBasis: 0,
                    flexGrow: 1,
                    maxWidth: "100%",
                    display: "block",
                }
                const emptyObj = {
                    [key]: {},
                }
                Object.assign(styles, emptyObj)

                return
            }

            if (size === "auto") {
                currentBreakpointStyles[key] = {
                    flexBasis: "auto",
                    flexGrow: 0,
                    flexShrink: 0,
                    maxWidth: "100%",
                    width: "auto",
                    display: "block",
                }

                const emptyObj = {
                    [key]: {},
                }
                Object.assign(styles, emptyObj)

                return
            }

            if (size === 0) {
                currentBreakpointStyles[key] = {
                    display: "none",
                }

                const emptyObj = {
                    [key]: {},
                }
                Object.assign(styles, emptyObj)

                return
            }

            // Only keep 6 significant numbers.
            const width = `${Math.round(((size as number) / GridColumns.length) * 10e6) / 10e4}%`

            currentBreakpointStyles[key] = {
                flexBasis: width,
                flexGrow: 0,
                maxWidth: width,
                display: "block",
            }

            const emptyObj = {
                [key]: {},
            }
            Object.assign(styles, emptyObj)
        })

        if (breakpointKey === "xs") {
            Object.assign(styles, currentBreakpointStyles)
        } else {
            currentBreakpointStyles[`grid-containerSize-${breakpointKey}`] = {
                // @ts-ignore - TS does not allow breakpointKey
                width: defaults.grid.breakpoints[breakpointKey],
            }
            const emptyObj = {
                [`grid-containerSize-${breakpointKey}`]: {},
            }
            Object.assign(styles, emptyObj)
            ;(styles as any)[breakpoints.min(breakpointKey as breakpointValues)] =
                currentBreakpointStyles
        }
    })

    // Create grow styles
    grid.gridSizes.forEach((size) => {
        if (typeof size !== "number") {
            return
        }

        // @ts-ignore
        styles[`grow-${size}`] = {
            flexGrow: size,
        }
    })

    // Append auto
    Object.assign(styles, {
        auto: {
            flexBasis: "auto",
            flexGrow: 0,
            flexShrink: 0,
            maxWidth: "100%",
            width: "auto",

            "& $item": {
                flexBasis: "auto",
                width: "auto",
            },
        },
    })

    return styles
}

export default styles
