import orgDefaults from "../../defaults"

export default (defaults: typeof orgDefaults) => ({
    container: {
        display: "inline-block",
        width: "100%",
        height: defaults.slider.height,
        padding: defaults.slider.padding,
        cursor: "pointer",
        position: "relative",
        boxSizing: "border-box",
        touchAction: "none",
    },
    hasMarkLabels: {
        marginBottom: defaults.slider.mark.label.marginTop + 2,
    },
    rail: {
        position: "absolute",
        width: "100%",
        height: defaults.slider.height,
        display: "block",
        borderRadius: defaults.cornerRadius.rounded,
        backgroundColor: defaults.slider.railColor,
    },
    track: {
        position: "absolute",
        height: defaults.slider.height,
        display: "block",
        borderRadius: defaults.cornerRadius.rounded,
        backgroundColor: defaults.slider.trackColor,
        left: 0,
    },
    pointer: {
        borderRadius: defaults.cornerRadius.rounded,
        borderWidth: defaults.slider.pointer.width,
        borderStyle: "solid",
        borderColor: defaults.slider.pointer.color,
        backgroundColor: defaults.slider.pointer.bgColor,
        height: defaults.slider.pointer.size,
        width: defaults.slider.pointer.size,
        position: "absolute",
        marginTop:
            ((defaults.slider.pointer.size + defaults.slider.pointer.width * 2) / 2) * -1 +
            (defaults.slider.pointer.size - defaults.slider.pointer.width),
        marginLeft:
            ((defaults.slider.pointer.size + defaults.slider.pointer.width * 2) / 2) * -1 +
            (defaults.slider.pointer.size - defaults.slider.pointer.width),
        transition: "transform .1s",
        zIndex: 1,

        "&:hover": {
            transform: "scale(1.17)",
        },

        "&:focus": {
            backgroundColor: defaults.slider.pointer.color,
            transform: "scale(1.3)",
            outline: "none",
        },
    },
    mark: {
        position: "absolute",
        width: defaults.slider.mark.width,
        marginTop: defaults.slider.height - 1,
        height: defaults.slider.mark.size,
        borderRadius: defaults.slider.mark.width / 2,
        backgroundColor: defaults.slider.mark.color,

        "&[data-position='100']": {
            right: 0,
        },
    },
    markLabel: {
        position: "absolute",
        whiteSpace: "nowrap",
        transform: "translate(-50%)",
        fontSize: "0.9rem",
        marginTop: defaults.slider.mark.label.marginTop + defaults.slider.height,
        marginLeft: defaults.slider.mark.width / 2, // Transform -50% centers to the "first" pixel, this levels it to match the center of "mark"
        userSelect: "none",
        cursor: "pointer",

        "&:hover": {
            transform: "translate(-50%) scale(1.1)",
        },

        "&[data-position='100']": {
            right: 0,
            transform: "none",
            marginLeft: 0,

            "&:hover": {
                transform: "scale(1.1)",
            },
        },
    },
    animate: {
        "& $markLabel": {
            transition: "all .1s ease",
        },
        "& $track": {
            transition: "width .1s",
        },
        "& $pointer": {
            transition: "transform .1s, left .1s",
        },
    },
    hasError: {
        "& $rail": {
            backgroundColor: defaults.colors.accents.error["400"],
        },
    },
    disabled: {
        opacity: 0.5,
        pointerEvents: "none",
    },
})
