import React, { useRef } from "react"
import useCallbackRef from "../utils/useCallbackRef"

interface ISelectableTextProps {
    children: React.ReactNode
}

export const SelectableText: React.FC<ISelectableTextProps> = (props) => {
    const spanRef = useRef<HTMLSpanElement>(null)

    const clickHandler = useCallbackRef((e: React.MouseEvent<HTMLSpanElement>) => {
        if (!spanRef.current) {
            return
        }

        const documentSelection = document.getSelection()
        if (
            spanRef.current.contains(e.target as HTMLSpanElement) &&
            documentSelection &&
            documentSelection.toString().length > 0
        ) {
            e.stopPropagation()
        }
    })

    return (
        <span ref={spanRef} onClick={clickHandler}>
            {props.children}
        </span>
    )
}
