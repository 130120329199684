import classNames from "classnames"
import React, { useContext } from "react"
import { BoxEdges, Fade, Grid } from "../"
import { makeStyles } from "../utils/styles/makeStyles"
import { TabContext } from "./TabContainer"

interface ITabPaneProps {
    active?: boolean
    tabKey: string
    children: React.ReactNode
}

// Exported for testing
export const styles = {
    activeTabPane: {},
    tabPane: {
        display: "none",

        "&$activeTabPane": {
            display: "block",
        },
    },
}

const useStyles = makeStyles(styles, "TabPane")
export const TabPane: React.FC<ITabPaneProps> = (props) => {
    const { tabKey, children } = props
    const classes = useStyles()
    const { activeTab } = useContext(TabContext)

    const className = classNames(classes.tabPane, {
        [classes.activeTabPane]: activeTab === tabKey,
    })

    return (
        <BoxEdges px={12} className={className}>
            <Grid row>
                <Grid item>
                    <Fade show={activeTab === tabKey}>{children}</Fade>
                </Grid>
            </Grid>
        </BoxEdges>
    )
}
