import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { ColoredIcon } from "../types"

const CheckSquareColoredIcon: React.FC<ColoredIcon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        stroke = defaults.colors.primary[500],
        fill = defaults.colors.neutrals[100],
        backgroundColor = defaults.colors.accents.success[300],
        size = 24,
        ...otherProps
    } = props

    return (
        <svg
            width={size}
            height={size}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.38063 2.86979C6.97138 2.27905 7.83763 2.05819 8.6391 2.29397L21.9157 6.19967C23.578 6.68869 24.1109 8.77867 22.8856 10.0039L11.4214 21.4682C10.4142 22.4753 8.74082 22.3244 7.93016 21.1532L0.901674 10.9994C0.273788 10.0923 0.384407 8.86602 1.16449 8.08594L6.38063 2.86979Z"
                fill={backgroundColor}
            />
            <path
                d="M18 12V16.6667C18 17.0203 17.8595 17.3594 17.6095 17.6095C17.3594 17.8595 17.0203 18 16.6667 18H7.33333C6.97971 18 6.64057 17.8595 6.39052 17.6095C6.14048 17.3594 6 17.0203 6 16.6667V7.33333C6 6.97971 6.14048 6.64057 6.39052 6.39052C6.64057 6.14048 6.97971 6 7.33333 6H14.6667"
                fill={fill}
            />
            <path
                d="M18 12V16.6667C18 17.0203 17.8595 17.3594 17.6095 17.6095C17.3594 17.8595 17.0203 18 16.6667 18H7.33333C6.97971 18 6.64057 17.8595 6.39052 17.6095C6.14048 17.3594 6 17.0203 6 16.6667V7.33333C6 6.97971 6.14048 6.64057 6.39052 6.39052C6.64057 6.14048 6.97971 6 7.33333 6H14.6667"
                stroke={stroke}
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect x="6.66669" y="6.66669" width="11.1667" height="11" rx="0.666667" fill="white" />
            <path
                d="M18 12V16.6667C18 17.0203 17.8595 17.3594 17.6095 17.6095C17.3594 17.8595 17.0203 18 16.6667 18H7.33333C6.97971 18 6.64057 17.8595 6.39052 17.6095C6.14048 17.3594 6 17.0203 6 16.6667V7.33333C6 6.97971 6.14048 6.64057 6.39052 6.39052C6.64057 6.14048 6.97971 6 7.33333 6H14.6667"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 11.3334L12 13.3334L18.6667 6.66669"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export { CheckSquareColoredIcon }
