import React, { useEffect, useState } from "react"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

const Document = React.lazy(() =>
    import("react-pdf/dist/esm/entry.webpack").then(({ Document }) => ({ default: Document }))
)
const Page = React.lazy(() =>
    import("react-pdf/dist/esm/entry.webpack").then(({ Page }) => ({ default: Page }))
)

interface IFilePreviewPdf {
    src: string
    onDocumentLoaded?: () => void
    scale?: number
}

const useStyles = makeStyles(styles, "FilePreviewPdf", true)
export const FilePreviewPdf: React.FC<IFilePreviewPdf> = (props) => {
    const { src, scale = 1, onDocumentLoaded } = props
    const [numPages, setNumPages] = useState(0)
    const [pdfIsLoading, setPdfIsLoading] = useState(true)
    const [pages, setPages] = useState<JSX.Element[]>([])
    const classes = useStyles()

    useEffect(() => {
        if (!numPages) {
            return
        }
        const nextPages = []
        for (let i = 0; i < numPages; i++) {
            nextPages.push(
                <Page
                    key={`page_${i + 1}`}
                    pageNumber={i + 1}
                    scale={scale}
                    className={classes.pdfDocumentPage}
                />
            )
        }
        setPages(nextPages)
        setPdfIsLoading(false)
    }, [numPages, scale])

    useEffect(() => {
        if (!pdfIsLoading && onDocumentLoaded) {
            onDocumentLoaded()
        }
    }, [pdfIsLoading])

    const onDocumentLoadSuccess: (pdf: any) => void = ({ numPages }) => {
        setNumPages(numPages)
    }

    return (
        <React.Suspense fallback={() => null}>
            <div role="filepreview-pdf-container">
                <Document
                    file={src}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={classes.pdfDocument}
                >
                    {!pdfIsLoading && pages.length > 0 && pages}
                </Document>
            </div>
        </React.Suspense>
    )
}
