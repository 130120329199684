import classnames from "classnames"
import React, { useContext, useMemo } from "react"
import { Grid } from "../Grid"
import { CheckIcon, CrossIcon, FilledCircleIcon } from "../Icon"
import { Icon } from "../Icon/types"
import { ThemeContext } from "../ThemeProvider"
import { Tooltip } from "../Tooltip"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

export type StepCurrentStateType = "initial" | "complete" | "error" | "notice"
export type StepKindType = "icon" | "icon and text"

export interface IStep {
    label: string
    icon: React.ComponentType<Icon>
    id?: string
    active?: boolean
    currentState?: StepCurrentStateType
    kind?: StepKindType
    onClick?: (sectionId: string) => void
}

const useStyles = makeStyles(styles, "Step", true)
export const Step: React.FC<IStep> = (props) => {
    const {
        label,
        icon,
        active = false,
        currentState = "initial",
        onClick,
        id,
        kind = "icon and text",
    } = props
    const { defaults } = useContext(ThemeContext)
    const classes = useStyles()

    const containerClasses = classnames(classes.step, {
        [classes.active]: active,
        [classes.initial]: currentState === "initial",
    })

    const Icon = icon

    const stateIcon = useMemo(() => {
        switch (currentState) {
            case "complete":
                return <CheckIcon color={defaults.colors.accents.success[500]} size={16} />
            case "notice":
                return <FilledCircleIcon color={defaults.colors.accents.warning[500]} size={6} />
            case "error":
                return <CrossIcon color={defaults.colors.accents.error[500]} size={16} />
            default:
                return <Icon color={defaults.colors.neutrals[700]} size={16} />
        }
    }, [currentState])

    const isTextShown = kind === "icon and text"
    const iconElement = useMemo(() => {
        if (!isTextShown && !active) {
            return (
                <Tooltip text={label}>
                    <div className={classes.icon}>{stateIcon}</div>
                </Tooltip>
            )
        } else {
            return <div className={classes.icon}>{stateIcon}</div>
        }
    }, [isTextShown, active, stateIcon])

    return (
        <div
            className={containerClasses}
            onClick={() => {
                if (onClick && id) {
                    onClick(id)
                }
            }}
            id={id}
        >
            <Grid row noGutters alignItems="center" wrap="nowrap">
                <Grid item auto>
                    {iconElement}
                </Grid>
                {isTextShown && (
                    <Grid item>
                        <label className={classes.label}>{label}</label>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}
