import defaultsOrg from "../../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        display: "flex",
    },

    item: {
        flexGrow: 1,
        marginRight: defaults.spacing[8],

        "&:last-child": {
            marginRight: defaults.spacing[0],
        },
    },

    row: {
        flexDirection: "row",
    },

    column: {
        flexDirection: "column",

        "& $item": {
            marginBottom: defaults.spacing[8],
            marginRight: defaults.spacing[0],

            "&:last-child": {
                marginBottom: defaults.spacing[0],
            },
        },
    },
})

export default styles
