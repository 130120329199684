import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { ColoredIcon } from "../types"

const ActivityColoredIcon: React.FC<ColoredIcon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        stroke = defaults.colors.primary[500],
        fill = defaults.colors.neutrals[100],
        backgroundColor = defaults.colors.accents.success[300],
        size = 24,
        ...otherProps
    } = props

    return (
        <svg
            width={size}
            height={size}
            color={stroke}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.2405 15.962C20.0998 16.7855 19.5204 17.4663 18.7301 17.737L5.63775 22.2218C3.99851 22.7833 2.35587 21.3856 2.64777 19.6776L5.37901 3.69641C5.61896 2.29244 7.07189 1.44861 8.41026 1.93593L20.014 6.16103C21.0507 6.53848 21.6691 7.60323 21.4832 8.69066L20.2405 15.962Z"
                fill={backgroundColor}
            />
            <path
                d="M19.65 11.55H16.65L14.4 18.3L9.89996 4.80005L7.64996 11.55H4.64996"
                stroke={fill}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.5 12H16.5L14.25 18.75L9.75 5.25L7.5 12H4.5"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export { ActivityColoredIcon }
