import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const ApplicationNeedsInformationStepIcon: React.FC<Icon> = ({ size = 24, ...otherProps }) => {
    const { defaults } = useContext(ThemeContext)
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 49C38.2548 49 49 38.2548 49 25C49 11.7452 38.2548 1 25 1C11.7452 1 1 11.7452 1 25C1 38.2548 11.7452 49 25 49ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                fill="black"
            />
            <path
                d="M11.0513 16.7573C11.0513 15.1005 12.3944 13.7573 14.0513 13.7573H32.4979C34.1547 13.7573 35.4979 15.1005 35.4979 16.7573V17.4596H18.5762C16.9193 17.4596 15.5762 18.8027 15.5762 20.4596V32.5624H14.0513C12.3944 32.5624 11.0513 31.2193 11.0513 29.5624V16.7573Z"
                fill={defaults.colors.neutrals[600]}
            />
            <path
                d="M18.5762 17.4596C16.9193 17.4596 15.5762 18.8027 15.5762 20.4596V33.2647C15.5762 34.9215 16.9193 36.2647 18.5762 36.2647H26.7811L35.7469 41.0924L34.694 36.2647H37.0228C38.6796 36.2647 40.0228 34.9215 40.0228 33.2647V20.4596C40.0228 18.8027 38.6796 17.4596 37.0228 17.4596H18.5762Z"
                fill={defaults.colors.neutrals[100]}
            />
        </svg>
    )
}

export { ApplicationNeedsInformationStepIcon }
