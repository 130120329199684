import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const ApplicationUnderReviewStepIcon: React.FC<Icon> = (props) => {
    const { size = 24, ...otherProps } = props
    const { defaults } = useContext(ThemeContext)

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 47C37.1503 47 47 37.1503 47 25C47 12.8497 37.1503 3 25 3C12.8497 3 3 12.8497 3 25C3 37.1503 12.8497 47 25 47ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                fill={defaults.colors.neutrals[500]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 38.0924C25 37.5401 25.4477 37.0924 26 37.0924H37C37.5523 37.0924 38 37.5401 38 38.0924C38 38.6447 37.5523 39.0924 37 39.0924H26C25.4477 39.0924 25 38.6447 25 38.0924Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 42.0924C21 41.5401 21.4477 41.0924 22 41.0924L27 41.0924C27.5523 41.0924 28 41.5401 28 42.0924C28 42.6447 27.5523 43.0924 27 43.0924L22 43.0924C21.4477 43.0924 21 42.6447 21 42.0924Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 12.0924C11 11.5401 11.4477 11.0924 12 11.0924L19 11.0924C19.5523 11.0924 20 11.5401 20 12.0924C20 12.6447 19.5523 13.0924 19 13.0924L12 13.0924C11.4477 13.0924 11 12.6447 11 12.0924Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 12.0924C23 11.5401 23.4477 11.0924 24 11.0924L37 11.0924C37.5523 11.0924 38 11.5401 38 12.0924C38 12.6447 37.5523 13.0924 37 13.0924L24 13.0924C23.4477 13.0924 23 12.6447 23 12.0924Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 8.09241C18 7.54012 18.4477 7.09241 19 7.09241L31 7.09241C31.5523 7.09241 32 7.54012 32 8.09241C32 8.64469 31.5523 9.09241 31 9.09241L19 9.09241C18.4477 9.09241 18 8.64469 18 8.09241Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0323 34.1247C11.0439 34.1247 7 30.0808 7 25.0924C7 20.1041 11.0439 16.0602 16.0323 16.0602H34.5322L34.5468 16.0784C39.2654 16.377 43.0001 20.2986 43.0001 25.0924C43.0001 29.8869 39.2644 33.8089 34.545 34.1065L34.5322 34.1247H16.0323Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                d="M16.0323 32.1247C19.9161 32.1247 23.0645 28.9762 23.0645 25.0924C23.0645 21.2086 19.9161 18.0602 16.0323 18.0602C12.1484 18.0602 9 21.2086 9 25.0924C9 28.9762 12.1484 32.1247 16.0323 32.1247Z"
                fill={defaults.colors.neutrals[100]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.2255 22.2967L15.4677 28.7847L12.0186 26.0131L13.2713 24.4541L15.091 25.9163L18.6127 21.114L20.2255 22.2967Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                d="M33.4034 26.5068L35.5187 28.6221L36.9329 27.2079L34.8176 25.0926L36.9331 22.977L35.5189 21.5628L33.4034 23.6783L31.2877 21.5627L29.8735 22.977L31.9891 25.0926L29.8737 27.208L31.2879 28.6222L33.4034 26.5068Z"
                fill={defaults.colors.neutrals[100]}
            />
        </svg>
    )
}

export { ApplicationUnderReviewStepIcon }
