import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { ColoredIcon } from "../types"

const InboxColoredIcon: React.FC<ColoredIcon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        stroke = defaults.colors.primary[500],
        fill = defaults.colors.neutrals[100],
        backgroundColor = defaults.colors.accents.success[300],
        size = 24,
        ...otherProps
    } = props

    return (
        <svg
            width={size}
            height={size}
            color={stroke}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={backgroundColor}
                fillRule="evenodd"
                d="M5.32055 3.32774c.47919-.68435 1.29393-1.05228 2.12417-.95925L21.1978 3.9094c1.722.19293 2.6097 2.15862 1.6158 3.578l-9.2993 13.2808c-.817 1.1668-2.4912 1.3087-3.4929.2961l-8.68488-8.779c-.775857-.7843-.879865-2.0112-.2471-2.91488l4.23113-6.04268z"
                clipRule="evenodd"
            />
            <g
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.47945"
                clipPath="url(#clip1)"
            >
                <path
                    fill={fill}
                    d="M6.44305 5.71148L3.89099 10.8082v4.4383c0 .3924.15587.7687.43332 1.0462.27745.2774.65376.4333 1.04613.4333H17.2061c.3923 0 .7686-.1559 1.0461-.4333.2774-.2775.4333-.6538.4333-1.0462v-4.4383l-2.552-5.09672c-.1225-.24649-.3113-.45392-.5453-.59897-.2339-.14506-.5036-.22198-.7789-.22213H7.76716c-.27524.00015-.54499.07707-.7789.22213-.23392.14505-.42273.35248-.54521.59897v0z"
                />
                <path d="M18.6855 10.8082h-4.4383l-1.4795 2.2192H9.8088l-1.47945-2.2192H3.89099" />
            </g>
        </svg>
    )
}

export { InboxColoredIcon }
