import React from "react"

import { Icon } from "../types"

const ApplicationArchivedStepIcon: React.FC<Icon> = ({ size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.7961 24.3889V14.963C13.7961 13.8584 14.6916 12.963 15.7961 12.963H33.2776C34.3822 12.963 35.2776 13.8584 35.2776 14.963V24.3889C35.2776 25.4935 34.3822 26.3889 33.2776 26.3889H15.7961C14.6916 26.3889 13.7961 25.4935 13.7961 24.3889Z"
            fill="#E8EDF2"
        />
        <path
            d="M12.9014 26.6266V17.6482C12.9014 16.5436 13.7968 15.6482 14.9014 15.6482H34.6205C35.7251 15.6482 36.6205 16.5436 36.6205 17.6482V26.6266C36.6205 27.7312 35.7251 28.6266 34.6205 28.6266H14.9014C13.7968 28.6266 12.9014 27.7312 12.9014 26.6266Z"
            fill="#BAC6D9"
        />
        <path
            d="M11.1111 20.3334V33.3395C11.1111 34.4441 12.0065 35.3395 13.1111 35.3395H35.9629C37.0675 35.3395 37.9629 34.4441 37.9629 33.3395V20.3334C37.9629 19.2288 37.0675 18.3334 35.9629 18.3334H30.7515C30.2118 18.3334 29.695 18.5515 29.3185 18.9381L28.7061 19.5671C28.3296 19.9538 27.8128 20.1719 27.2732 20.1719H21.3533C20.8136 20.1719 20.2969 19.9538 19.9204 19.5671L19.308 18.9381C18.9315 18.5515 18.4147 18.3334 17.875 18.3334H13.1111C12.0065 18.3334 11.1111 19.2288 11.1111 20.3334Z"
            fill="#273346"
        />
        <circle cx="25" cy="25" r="23.5" stroke="#E8EDF2" strokeWidth="3" />
    </svg>
)

export { ApplicationArchivedStepIcon }
