import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { ColoredIcon } from "../types"

const AlertTriangleColoredIcon: React.FC<ColoredIcon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        stroke = defaults.colors.primary[500],
        fill = defaults.colors.neutrals[100],
        backgroundColor = defaults.colors.accents.success[300],
        size = 24,
        ...otherProps
    } = props

    return (
        <svg
            width={size}
            height={size}
            color={stroke}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path
                    fill={backgroundColor}
                    fillRule="evenodd"
                    d="M8.29776 2.83076c.72986-.40653 1.62348-.3819 2.32984.06421l11.7009 7.38993c1.465.9253 1.4056 3.0813-.1082 3.9244L8.05638 22.0986c-1.24433.6931-2.81254.0899-3.27176-1.2583L.803173 9.15065C.447493 8.10636.889488 6.95713 1.85327 6.42031l6.44449-3.58955z"
                    clipRule="evenodd"
                />
                <g
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.68"
                    clipPath="url(#clip1)"
                >
                    <path
                        fill={fill}
                        d="M9.04367 3.43102L1.92887 15.3086c-.14669.2541-.22431.5421-.22513.8354-.00082.2934.07518.5818.22045.8367.14527.2548.35473.4672.60756.616.25282.1487.54019.2287.83352.2319H17.5949c.2933-.0032.5807-.0832.8335-.2319.2528-.1488.4623-.3612.6076-.616.1452-.2549.2212-.5433.2204-.8367-.0008-.2933-.0784-.5813-.2251-.8354L11.9165 3.43102c-.1498-.24687-.3606-.45098-.6122-.59263-.2516-.14166-.5355-.21608-.8242-.21608-.2888 0-.57263.07442-.82423.21608-.25161.14165-.46245.34576-.6122.59263v0z"
                    />
                    <path d="M10.48 7.74872v3.35998M10.48 14.4687h.0091" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0">
                    <path fill={fill} d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="clip1">
                    <path
                        fill={fill}
                        d="M0 0h20.16v20.16H0z"
                        transform="translate(.399994 .188721)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export { AlertTriangleColoredIcon }
