export type breakpointValues = "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
export interface IBreakpointValues {
    [key: string]: number
}

export default function createBreakpoints(breakpoints: IBreakpointValues) {
    const unit = "px"
    const step = 5
    const keys = Object.keys(breakpoints)

    const min = (key: breakpointValues | number) => {
        const value = typeof breakpoints[key] === "number" ? breakpoints[key] : key
        return `@media (min-width:${value}${unit})`
    }

    const max = (key: breakpointValues | number) => {
        const endIndex = typeof key !== "number" ? keys.indexOf(key) + 1 : -1
        const upperbound = breakpoints[keys[endIndex]]

        if (endIndex === keys.length) {
            return min("xs")
        }

        const value = typeof upperbound === "number" && endIndex > 0 ? upperbound : (key as number)
        return `@media (max-width:${value - step / 100}${unit})`
    }

    const between = (start: breakpointValues | number, end: breakpointValues | number) => {
        const endIndex = typeof end !== "number" ? keys.indexOf(end) + 1 : -1

        if (endIndex === keys.length) {
            return min(start)
        }

        return (
            `@media (min-width:${breakpoints[start]}${unit}) and ` +
            `(max-width:${breakpoints[keys[endIndex]] - step / 100}${unit})`
        )
    }

    const only = (key: breakpointValues | number) => {
        return between(key, key)
    }

    return {
        between,
        keys,
        max,
        min,
        only,
        values: breakpoints,
    }
}
