import classnames from "classnames"
import React, { useMemo } from "react"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ILabelProps {
    margin?: "none" | "normal" | "extra"
    children: string | JSX.Element
    className?: string
    forInput?: string
    style?: React.CSSProperties
    size?: "xxxs" | "xxs" | "xs" | "m" | "l"
    uppercase?: boolean
    color?: string
    light?: boolean
    suffix?: React.ReactNode | string
}

const useStyles = makeStyles(styles, "Label", true)
export const Label: React.FC<ILabelProps> = (props) => {
    const {
        margin = "normal",
        className,
        light,
        forInput,
        size = "m",
        color,
        uppercase,
        children,
        suffix,
    } = props
    const classes = useStyles()

    const labelClasses = useMemo(
        () =>
            classnames({
                [classes.label]: true,
                [classes.uppercase]: uppercase,
                [classes.labelXs]: size === "xs",
                [classes.labelXxs]: size === "xxs",
                [classes.labelXxxs]: size === "xxxs",
                [classes.labelL]: size === "l",
                // @ts-ignore - @TODO
                [classes[`margin${margin}`]]: true,
                [classes.label]: true,
                [classes.light]: light,
                className,
            }),
        [classes, className]
    )

    const labelStyle = useMemo(() => {
        if (color) {
            return {
                color,
            }
        }

        return undefined
    }, [color])

    return (
        <label
            style={labelStyle}
            className={labelClasses}
            htmlFor={forInput ? forInput : undefined}
        >
            <span>{children}</span>
            {suffix && <span className={classes.suffix}>{suffix}</span>}
        </label>
    )
}
Label.displayName = "Label"
