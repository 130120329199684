const getTextWidth = (text: string, fontSize: number = 14, fontFamily: string) => {
    const containerElmId = "textWidthContainer"
    let containerElm = document.getElementById(containerElmId)
    if (!containerElm) {
        containerElm = document.createElement("div")
        containerElm.id = containerElmId
        containerElm.style.visibility = "hidden"
        containerElm.style.fontSize = `${fontSize}px`
        containerElm.style.fontFamily = fontFamily
        document.body.appendChild(containerElm)
    }

    const textElmId = "textElm"
    let textElm = document.getElementById(textElmId)
    if (!textElm) {
        textElm = document.createElement("div")
        textElm.id = textElmId
        textElm.style.display = "inline-block"
        containerElm.appendChild(textElm)
    }

    textElm.innerText = text

    return textElm.clientWidth
}

export default (text: string | string[], fontFamily: string, fontSize?: number) => {
    if (Array.isArray(text)) {
        let widest = 0

        text.forEach((t: string) => {
            const textWidth = getTextWidth(t, fontSize, fontFamily)

            if (widest < textWidth) {
                widest = textWidth
            }
        })

        return widest
    }

    return getTextWidth(text, fontSize, fontFamily)
}
