import orgDefaults from "../defaults"

export default (defaults: typeof orgDefaults) => ({
    sectionHeader: {
        zIndex: defaults.zIndex.SectionsHeader,
    },
    sectionNavigation: {
        borderBottom: `solid 1px ${defaults.colors.neutrals[500]}`,
        zIndex: defaults.zIndex.SectionsHeader,
    },
    sectionNavigationItem: {
        cursor: "pointer",
        fontWeight: defaults.fontWeights.medium,
        color: defaults.colors.neutrals[700],
        borderBottom: `3px solid transparent`,
        marginBottom: -2,
        transition: defaults.transitions.interactions.fast,
        lineHeight: 1,

        "& svg": {
            color: defaults.colors.neutrals[700],
        },

        "&:hover": {
            color: defaults.colors.neutrals[800],
            borderBottom: `3px solid ${defaults.colors.primary[400]}`,

            "& svg": {
                color: defaults.colors.neutrals[800],
            },
        },
    },

    sectionNavigationItemActive: {
        color: defaults.colors.neutrals[900],
        borderBottom: `3px solid ${defaults.colors.primary[500]}`,

        "& svg": {
            color: defaults.colors.neutrals[900],
        },
        "&:hover": {
            color: defaults.colors.neutrals[900],
            borderBottom: `3px solid ${defaults.colors.primary[500]}`,
        },
    },

    sectionNavigationItemContentContainer: {
        minHeight: `calc(1em + ${defaults.spacing[8] * 2}px)`,
    },
})
