import React from "react"
import { Grid } from "../Grid"
import { Separator } from "../Separator"
import { IStep } from "../Step"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface IStepsProps {
    activeStep: number
    direction?: "column" | "row"
    children: Array<React.ReactElement<IStep>>
}

const useStyles = makeStyles(styles, "Steps", true)
export const Steps: React.FC<IStepsProps> = (props) => {
    const { activeStep, direction = "column", children } = props
    const classes = useStyles()

    return (
        <Grid
            row
            direction={direction}
            alignItems="center"
            wrap="nowrap"
            noGutters
            className={classes.steps}
        >
            {React.Children.map<React.ReactElement<IStep>, React.ReactElement<IStep>>(
                children,
                (child, index) => {
                    const isLastItem = index === children.length - 1
                    const isActive = activeStep === index + 1

                    const element = isActive
                        ? React.cloneElement(child, {
                              active: true,
                          })
                        : child

                    const wrappedElement = element

                    if (direction === "row") {
                        if (isLastItem) {
                            return <Grid item>{wrappedElement}</Grid>
                        }

                        return (
                            <>
                                <Grid item>{wrappedElement}</Grid>
                                <Grid item>
                                    <Separator colorWeight={500} width={12} />
                                </Grid>
                            </>
                        )
                    }

                    return <Grid item>{wrappedElement}</Grid>
                }
            )}
        </Grid>
    )
}
