import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    arrow: {
        borderBottom: `8px solid transparent`,
        borderLeft: `8px solid ${defaults.colors.neutrals[100]}`,
        borderTop: `8px solid transparent`,
        content: '""',
        height: 0,
        position: "absolute",
        right: 0,
        top: 0,
        width: 0,
        margin: 0,
    },
    container: {
        zIndex: defaults.zIndex.Popover,

        "&[data-popper-placement=right]": {
            "& $arrow": {
                borderLeft: 0,
                borderRight: `8px solid ${defaults.colors.neutrals[100]}`,

                left: defaults.spacing[4],
                right: "auto",

                "&$dark": {
                    borderRightColor: defaults.colors.neutrals[900],
                },
            },
        },

        "&[data-popper-placement=bottom]": {
            "& $arrow": {
                borderBottom: `8px solid ${defaults.colors.neutrals[100]}`,
                borderLeft: `8px solid transparent`,
                borderRight: `8px solid transparent`,
                borderTop: 0,

                top: defaults.spacing[4],

                "&$dark": {
                    borderBottomColor: defaults.colors.neutrals[900],
                },
            },
        },

        "&[data-popper-placement=top]": {
            "& $arrow": {
                borderBottom: 0,
                borderLeft: `8px solid transparent`,
                borderRight: `8px solid transparent`,
                borderTop: `8px solid ${defaults.colors.neutrals[100]}`,

                bottom: defaults.spacing[4],
                top: "auto",

                "&$dark": {
                    borderTopColor: defaults.colors.neutrals[900],
                },
            },
        },
    },
    wrapper: {
        position: "relative",
        zIndex: defaults.zIndex.Popover,
    },

    dark: {},
    bottom: {},
    right: {},
    top: {},
})

export default styles
