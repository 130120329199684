import React, { useCallback, useEffect, useState } from "react"
import { Button, ButtonColors, Modal } from "../"

interface IConfirmDialogProps {
    title: string | JSX.Element
    icon: JSX.Element
    show: boolean
    onConfirm: () => void
    onAbort: () => void
    confirmLabel?: string
    confirmColor?: ButtonColors
    abortLabel?: string
    children: React.ReactNode
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = (props) => {
    const {
        show,
        title,
        icon,
        onAbort,
        onConfirm,
        confirmLabel = "Confirm",
        abortLabel = "Abort",
        confirmColor = "success",
    } = props

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (show && isLoading) {
            setIsLoading(false)
        }
    }, [show])

    const handleConfirmClick = useCallback(() => {
        setIsLoading(true)
        onConfirm()
    }, [onConfirm])

    const handleAbortClick = useCallback(() => {
        setIsLoading(true)
        onAbort()
    }, [onAbort])

    return (
        <Modal open={show} disableBackdropClose disableEscapeKeyClose removeClose>
            <Modal.Header prefix={icon}>{title}</Modal.Header>
            <Modal.Content>{props.children}</Modal.Content>
            <Modal.Footer>
                <Modal.Actions>
                    <Button
                        color="secondary"
                        light
                        onClick={handleAbortClick}
                        disabled={isLoading}
                        role="dialog-cancel"
                    >
                        {abortLabel}
                    </Button>
                    <Button
                        color={confirmColor}
                        onClick={handleConfirmClick}
                        disabled={isLoading}
                        role="dialog-ok"
                    >
                        {confirmLabel}
                    </Button>
                </Modal.Actions>
            </Modal.Footer>
        </Modal>
    )
}
