import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const VisaIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...otherProps } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.06913 3.9H8.82682C9.74279 3.93422 10.486 4.22799 10.7279 5.26546L12.1969 12.731C12.1968 12.7307 12.1967 12.7303 12.1965 12.7299C12.1966 12.7303 12.1967 12.7306 12.1968 12.731L12.6463 14.9775L16.7597 3.90067H21.201L14.5991 20.1274H10.1575L6.41366 6.01199C5.12208 5.25692 3.64804 4.64965 2 4.22822L2.06913 3.9Z"
            />
        </svg>
    )
}

export { VisaIcon }
