import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

export const ThickArrowIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...rest } = props
    return (
        <svg
            width={size}
            height={size}
            color={color}
            viewBox="0 0 22 17"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 0L19 6L11 12V7H0.5V5H11V0Z"
                fill={color}
            />
        </svg>
    )
}
