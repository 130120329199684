import defaultsOrg from "../../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        display: "flex",
    },

    item: {
        flexGrow: 1,
        marginRight: defaults.spacing[8],

        "&:last-child": {
            marginRight: defaults.spacing[0],
        },
    },

    column: {
        flexDirection: "column",

        "& >*": {
            marginBottom: defaults.spacing[8],
            marginRight: defaults.spacing[0],

            "&:last-child": {
                marginBottom: defaults.spacing[0],
            },
        },
    },

    disabled: {
        opacity: 0.5,
        pointerEvents: "none",
    },

    label: {
        display: "block",
        fontSize: 13,
        fontWeight: "bold",
        lineHeight: defaults.lineHeights.m,
        marginBottom: 10,
    },

    row: {
        flexDirection: "row",
    },

    simple: {
        "& >*": {
            width: "auto",
        },
    },
})

export default styles
