import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { ColoredIcon } from "../types"

const FileTextColoredIcon: React.FC<ColoredIcon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        stroke = defaults.colors.primary[500],
        fill = defaults.colors.neutrals[100],
        backgroundColor = defaults.colors.accents.success[300],
        size = 24,
        ...otherProps
    } = props

    return (
        <svg
            width={size}
            height={size}
            color={stroke}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.48233 2.78955C7.08209 2.20798 7.95164 2.00048 8.74939 2.24858L21.9643 6.35828C23.6188 6.87284 24.1195 8.97077 22.8755 10.177L11.2361 21.4633C10.2135 22.4549 8.54263 22.2782 7.7501 21.0947L0.878818 10.8338C0.264976 9.91719 0.394467 8.69273 1.18647 7.92475L6.48233 2.78955Z"
                fill={backgroundColor}
            />
            <path
                d="M13.5 4.5H7.5C7.10218 4.5 6.72064 4.65804 6.43934 4.93934C6.15804 5.22064 6 5.60218 6 6V18C6 18.3978 6.15804 18.7794 6.43934 19.0607C6.72064 19.342 7.10218 19.5 7.5 19.5H16.5C16.8978 19.5 17.2794 19.342 17.5607 19.0607C17.842 18.7794 18 18.3978 18 18V9L13.5 4.5Z"
                fill={fill}
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5 4.5V9H18"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 12.75H9"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 15.75H9"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 9.75H9.75H9"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export { FileTextColoredIcon }
