import classnames from "classnames"
import { shallowEqual } from "fast-equals"
import React, { useContext, useMemo } from "react"
import { CheckIcon } from "../../Icon"
import { ThemeContext } from "../../ThemeProvider"
import devMode from "../../utils/devMode"
import { makeStyles } from "../../utils/styles/makeStyles"
import styles from "./styles"
import { useCheckbox } from "./useCheckbox"

export interface ICheckboxProps {
    value?: string | boolean
    name?: string
    id?: string
    caption?: string
    style?: React.CSSProperties
    defaultChecked?: boolean
    simple?: boolean
    light?: boolean
    prefix?: JSX.Element
    row?: boolean
    disabled?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onFocus?: (e: React.FocusEvent) => void
    onBlur?: (e: React.FocusEvent) => void
    onMouseDown?: (e: React.MouseEvent) => void
    tight?: boolean
    checked?: boolean
    children?: React.ReactNode
}

const useStyles = makeStyles(styles, "Checkbox", true)
export const Checkbox: React.FC<ICheckboxProps> = React.memo((props) => {
    const {
        name,
        caption,
        value,
        id,
        style,
        simple,
        light,
        prefix,
        onChange,
        onFocus,
        onBlur,
        onMouseDown,
        row,
        defaultChecked,
        tight,
        children,
        disabled = false,
        checked: externalChecked,
    } = props
    const classes = useStyles()
    const { defaults } = useContext(ThemeContext)

    const {
        handleOnFocus,
        handleOnBlur,
        handleOnKeyPress,
        handleOnChange,
        focused,
        checked,
        inputRef,
    } = useCheckbox(defaultChecked, externalChecked, onChange, onFocus, onBlur)

    const containerClasses = useMemo(
        () =>
            classnames(classes.container, {
                [classes.containerChecked]: checked,
                [classes.simple]: simple,
                [classes.focused]: focused,
                [classes.light]: light,
                [classes.disabled]: disabled,
            }),
        [checked, simple, focused, light]
    )

    const checkboxClasses = useMemo(
        () =>
            classnames(classes.checkbox, {
                [classes.checked]: checked,
                [classes.simple]: simple,
                [classes.light]: light,
                [classes.row]: row,
                [classes.tight]: tight,
            }),
        [checked, simple, light, row, tight]
    )

    const captionClasses = useMemo(
        () =>
            classnames(classes.caption, {
                [classes.simple]: simple,
                [classes.light]: light,
                [classes.row]: row,
            }),
        [simple, light, row]
    )

    const prefixClasses = useMemo(
        () =>
            classnames(classes.prefix, {
                [classes.row]: row,
                [classes.simple]: simple,
            }),
        [row, simple]
    )

    return (
        <div className={containerClasses} style={style}>
            <label className={captionClasses}>
                <input
                    className={classes.input}
                    disabled={disabled}
                    name={name}
                    type="checkbox"
                    id={id}
                    ref={inputRef}
                    checked={checked}
                    value={value as string}
                    onChange={handleOnChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onKeyPress={handleOnKeyPress}
                    onMouseDown={onMouseDown}
                />
                <span className={checkboxClasses}>
                    {checked && (
                        <CheckIcon
                            size={14}
                            color={defaults.colors.neutrals[100]}
                            strokeWidth={2}
                        />
                    )}
                </span>
                {prefix && <span className={prefixClasses}>{prefix}</span>}
                {caption && <span>{caption}</span>}
                {children && <span>{children}</span>}
            </label>
        </div>
    )
}, shallowEqual)

if (devMode) {
    Checkbox.displayName = "Checkbox"
}
