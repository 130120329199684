import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"

interface Props extends React.SVGProps<SVGSVGElement> {
    backgroundColor?: string
    iconColor?: string
    pointerColor?: string
    size?: number
}

const MessageCircleClickIcon: React.FC<Props> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        backgroundColor = defaults.colors.primary[500],
        iconColor = defaults.colors.neutrals[100],
        pointerColor = defaults.colors.neutrals[100],
        size = 24,
        ...otherProps
    } = props

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <ellipse cx="9.41887" cy="9.56164" rx="9.41887" ry="9.56164" fill={backgroundColor} />
            <path
                d="M13.8565 8.96406C13.8581 9.57079 13.7184 10.1693 13.449 10.7109C13.1295 11.3599 12.6383 11.9057 12.0304 12.2873C11.4226 12.6689 10.7221 12.8712 10.0075 12.8715C9.40978 12.873 8.82018 12.7313 8.2867 12.4577L5.70557 13.3312L6.56594 10.7109C6.29648 10.1693 6.15684 9.57079 6.1584 8.96406C6.15867 8.23855 6.35792 7.52744 6.73382 6.91039C7.10971 6.29335 7.64742 5.79472 8.2867 5.47038C8.82018 5.19683 9.40978 5.05507 10.0075 5.05665H10.2339C11.1777 5.10951 12.0692 5.51394 12.7376 6.19249C13.4061 6.87105 13.8044 7.77605 13.8565 8.73421V8.96406Z"
                stroke={iconColor}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g clipPath="url(#clip0)">
                <path
                    d="M14.4905 14.3464L17.692 22.1474L18.8286 18.7503L22.175 17.5965L14.4905 14.3464Z"
                    stroke={pointerColor}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.0188 18.9434L21.7358 21.7015"
                    stroke={pointerColor}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <line
                x1="0.5"
                y1="-0.5"
                x2="2.13803"
                y2="-0.5"
                transform="matrix(-0.0785256 0.996912 0.996722 0.0809088 14.6985 9.39648)"
                stroke={pointerColor}
                strokeLinecap="round"
            />
            <line
                x1="0.5"
                y1="-0.5"
                x2="2.11553"
                y2="-0.5"
                transform="matrix(-0.756376 0.654137 0.64283 0.766009 17.6052 11.5039)"
                stroke={pointerColor}
                strokeLinecap="round"
            />
            <line
                x1="0.5"
                y1="-0.5"
                x2="2.12179"
                y2="-0.5"
                transform="matrix(0.64283 0.766009 -0.756376 0.654137 10.9373 10.3391)"
                stroke={pointerColor}
                strokeLinecap="round"
            />
            <line
                x1="0.5"
                y1="-0.5"
                x2="2.09914"
                y2="-0.5"
                transform="matrix(0.996722 0.0809088 -0.0785256 0.996912 9.75269 13.6741)"
                stroke={pointerColor}
                strokeLinecap="round"
            />
            <line
                x1="0.5"
                y1="-0.5"
                x2="2.11553"
                y2="-0.5"
                transform="matrix(0.756376 -0.654137 0.64283 0.766009 11.3403 16.9221)"
                stroke={pointerColor}
                strokeLinecap="round"
            />
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="10.8679"
                        height="11.0327"
                        fill="white"
                        transform="translate(13.1321 12.9673)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export { MessageCircleClickIcon }
