import React, { useContext } from "react"
import { BoxEdges } from "../BoxEdges"
import { ThemeContext } from "../ThemeProvider"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface SnackbarProps {
    children: React.ReactNode | React.ReactNode[]
    color?: string
    offset?: number
}

const useStyles = makeStyles(styles, "Snackbar", true)
const Snackbar: React.FC<SnackbarProps> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const classes = useStyles()
    const { color = defaults.colors.neutrals[100] } = props

    return (
        <article className={classes.snackbarContainer}>
            <BoxEdges
                shadowCustom={defaults.shadows.top}
                shadowTop
                style={{ backgroundColor: color, marginLeft: props.offset }}
                className={classes.snackbar}
            >
                {props.children}
            </BoxEdges>
        </article>
    )
}

export { Snackbar }
