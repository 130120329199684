import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Grid } from "../"

interface ITabContainerProps {
    activeTab?: string
    defaultTab?: string
    onSelect?: (tab: string) => void
    ref?: React.Ref<HTMLDivElement>
    children: React.ReactNode
}

interface ITabContext {
    activeTab: string
    onSelect: (tab: string) => void
}

export const TabContext = React.createContext<ITabContext>({
    activeTab: "",
    onSelect: () => {
        throw Error(`No TabContext set`)
    },
})

export const TabContainer: React.FC<ITabContainerProps> = (props) => {
    const { onSelect, defaultTab, children, ref } = props

    const [activeTab, setActiveTab] = useState(defaultTab ? defaultTab : "")

    const handleOnSelect = useCallback(
        (tab: string) => {
            if (tab !== activeTab) {
                setActiveTab(tab)
                if (onSelect) {
                    onSelect(tab)
                }
            }
        },
        [activeTab]
    )

    useEffect(() => {
        if (props.activeTab && props.activeTab !== activeTab) {
            setActiveTab(props.activeTab)
        }
    }, [props.activeTab])

    const contextValue = useMemo(
        () => ({
            activeTab,
            onSelect: handleOnSelect,
        }),
        [activeTab, handleOnSelect]
    )

    return (
        <TabContext.Provider value={contextValue}>
            <Grid container fluid ref={ref}>
                {children}
            </Grid>
        </TabContext.Provider>
    )
}
