import defaultsOrg from "../../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        alignItems: "center",
        backgroundColor: defaults.colors.neutrals[100],
        border: `1px solid ${defaults.colors.neutrals[500]}`,
        borderRadius: defaults.cornerRadius.normal,
        boxShadow: defaults.shadows.small.tight,
        display: "flex",
        height: "100%",
        justifyContent: "center",
        position: "relative",
        width: "100%",

        "&$simple": {
            backgroundColor: "transparent",
            border: 0,
            boxShadow: "none",

            "&$focused": {
                border: 0,
                boxShadow: "none",
                color: defaults.colors.primary[500],

                "& $radio": {
                    border: `1px solid ${defaults.colors.primary[500]}`,
                },
            },
        },

        "&$focused": {
            border: `1px solid ${defaults.colors.primary[400]}`,
            boxShadow: defaults.shadows.small.wide,
        },
    },

    containerChecked: {
        backgroundColor: defaults.colors.primary[100],
        border: `1px solid ${defaults.colors.primary[500]}`,
    },

    disabled: {
        opacity: 0.5,
        pointerEvents: "none",
    },

    input: {
        cursor: "pointer",
        height: 0,
        opacity: 0,
        position: "absolute",
        width: 0,
    },

    radio: {
        alignItems: "center",
        border: `1px solid ${defaults.colors.neutrals[600]}`,
        borderRadius: defaults.cornerRadius.rounded,
        boxSizing: "border-box",
        display: "flex",
        height: 20,
        justifyContent: "center",
        position: "absolute",
        right: 10,
        top: 10,
        width: 20,

        "&$row": {
            marginRight: defaults.spacing[16],
            position: "static",
        },

        "&$simple": {
            marginRight: defaults.spacing[16],
            position: "static",
        },
    },

    caption: {
        cursor: "pointer",
        fontSize: defaults.fontSizes[14],
        lineHeight: defaults.lineHeights.s,
        marginTop: defaults.spacing[6],
        padding: defaults.spacing[32],
        textAlign: "center",
        width: "100%",

        "&$row": {
            alignItems: "center",
            display: "flex",
            marginTop: defaults.spacing[0],
            padding: defaults.spacing[16],
        },

        "&$simple": {
            alignItems: "center",
            display: "flex",
            marginTop: defaults.spacing[0],
            padding: 0,
        },
    },

    checked: {
        alignItems: "center",
        backgroundColor: defaults.colors.primary[500],
        border: `1px solid ${defaults.colors.primary[500]}`,
        display: "flex",
        justifyContent: "center",

        // @ts-ignore
        "&:after": {
            backgroundColor: defaults.colors.neutrals[100],
            borderRadius: "50%",
            content: '""',
            display: "flex",
            height: 8,
            width: 8,
        },
    },

    prefix: {
        display: "flex",
        justifyContent: "center",
        marginBottom: defaults.spacing[8],

        "&$row": {
            marginBottom: defaults.spacing[0],
            marginRight: defaults.spacing[12],
        },

        "&$simple": {
            marginBottom: defaults.spacing[0],
            marginRight: defaults.spacing[12],
        },
    },

    focused: {},

    simple: {},

    row: {},
})

export default styles
