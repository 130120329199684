import React from "react"
import Flickity, { FlickityOptions } from "react-flickity-component"
import { BoxEdges } from "../BoxEdges"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ICarouselProps {
    className?: string
    style?: React.CSSProperties
    children: React.ReactNode
    options?: FlickityOptions
}

const useStyles = makeStyles(styles, "Carousel", true)
function Carousel(props: ICarouselProps) {
    const classes = useStyles()

    const defaultOptions: FlickityOptions = {
        pageDots: false,
    }

    const appliedOptions: FlickityOptions = { ...defaultOptions, ...props.options }

    return (
        <BoxEdges className={classes.carousel} style={props.style}>
            <Flickity className={classes.slider} options={appliedOptions}>
                {props.children}
            </Flickity>
        </BoxEdges>
    )
}

export { Carousel }
