import classnames from "classnames"
import React, { useMemo } from "react"
import { ColorNames, ColorWeights } from "../defaults"
import { Grid } from "../Grid"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface IBadgeProps {
    color?: ColorNames
    shade?: ColorWeights
    size?: number
    overlap?: "rectangular" | "circular"
    children?: React.ReactNode
}

const useStyles = makeStyles(styles, "Badge", true)
export const Badge: React.FC<IBadgeProps> = (props) => {
    const { children, color = "primary", shade = 500, size = 8, overlap } = props
    const classes = useStyles()

    const badgeClasses = useMemo(() => {
        return classnames(classes.badge, [classes[`color-${color}-${shade}`]], {
            [classes.overlap]: overlap,
            [classes.circular]: overlap === "circular",
            [classes.rectangular]: overlap === "rectangular",
        })
    }, [color, shade])

    const badgeStyle = useMemo(
        () => ({
            width: size,
            height: size,
        }),
        [size]
    )

    return (
        <Grid row noGutters className={classes.container}>
            <Grid item auto>
                <div>{children}</div>
                <div className={badgeClasses} style={badgeStyle} />
            </Grid>
        </Grid>
    )
}
