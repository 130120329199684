import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { ColoredIcon } from "../types"

const LayoutColoredIcon: React.FC<ColoredIcon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        stroke = defaults.colors.primary[500],
        fill = defaults.colors.neutrals[100],
        backgroundColor = defaults.colors.accents.success[300],
        size = 24,
        ...otherProps
    } = props

    return (
        <svg
            width={size}
            height={size}
            color={stroke}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <g clipPath="url(#clip1)">
                    <path
                        fill={backgroundColor}
                        fillRule="evenodd"
                        d="M8.29785 2.831c.72986-.40652 1.62348-.38189 2.32985.06422l11.7009 7.38988c1.465.9253 1.4056 3.0813-.1082 3.9245L8.05647 22.0988c-1.24433.6931-2.81254.09-3.27176-1.2583L.803265 9.15089C.447584 8.1066.889579 6.95737 1.85336 6.42055L8.29785 2.831z"
                        clipRule="evenodd"
                    />
                    <path
                        fill={fill}
                        stroke={stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.68"
                        d="M17.4 3.00006H5.63999c-.92784 0-1.68.75216-1.68 1.68V16.4401c0 .9278.75216 1.68 1.68 1.68H17.4c.9278 0 1.68-.7522 1.68-1.68V4.68006c0-.92784-.7522-1.68-1.68-1.68z"
                    />
                    <path
                        stroke={stroke}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.68"
                        d="M3.95999 8.04004H19.08M8.99997 18.12V8.04004"
                    />
                </g>
            </g>
        </svg>
    )
}

export { LayoutColoredIcon }
