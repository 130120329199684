import classNames from "classnames"
import React, {
    isValidElement,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { ChevronDownIcon } from "../../Icon"
import { Portal, PortalContext } from "../../Portal"
import { ThemeContext } from "../../ThemeProvider"
import devMode from "../../utils/devMode"
import { getDomPosition } from "../../utils/getDomPosition"
import getTextWidth from "../../utils/getTextWidth"
import { onlyText } from "../../utils/onlyText"
import scrollElementIntoView from "../../utils/scrollElementIntoView"
import { makeStyles } from "../../utils/styles/makeStyles"
import useCallbackRef from "../../utils/useCallbackRef"
import styles from "./styles"

interface ISelectProps {
    placeholder?: string
    value?: any
    name?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    onClick?: (event: React.MouseEvent) => void
    onFocus?: (e: React.FocusEvent) => void
    onBlur?: (e: React.FocusEvent) => void
    onMouseDown?: (e: React.MouseEvent) => void
    tight?: boolean
    hasError?: boolean
    notice?: boolean
    disabled?: boolean
    autoFocus?: boolean
    autoWidth?: boolean
    open?: boolean
    onOpen?: () => void
    onClose?: () => void
    listRef?: React.Ref<HTMLUListElement>
}

type Directions = "down" | "up"

const useStyles = makeStyles(styles, "Select", true)
export const Select = React.forwardRef<HTMLDivElement, React.PropsWithChildren<ISelectProps>>(
    (props, ref) => {
        const {
            placeholder,
            value,
            onChange,
            onClick,
            onFocus,
            onBlur,
            onMouseDown,
            tight = false,
            hasError = false,
            disabled = false,
            autoFocus = false,
            autoWidth = false,
            notice,
            children,
            open: externalOpen,
            onOpen,
            onClose,
            listRef: externalListRef,
        } = props

        // Refs
        const selectRef = useRef<HTMLDivElement>(null)
        const headerRef = useRef<HTMLDivElement>(null)
        const internalListRef = useRef<HTMLUListElement>()
        const listRef = useCallback(
            (ref: HTMLUListElement) => {
                if (typeof externalListRef === "function") {
                    externalListRef(ref)
                }
                internalListRef.current = ref
            },
            [externalListRef]
        )
        // useRef with 'React.ReactElement' is per default read-only, therefor the cast
        const foundMatch = useRef<React.ReactElement>(
            null
        ) as React.MutableRefObject<React.ReactElement>
        let { current: queryResetTimeout } = useRef<number>(-1)

        const { defaults } = useContext(ThemeContext)
        const classes = useStyles()

        const { rootContainer } = useContext(PortalContext)

        // Internal state
        const [listMaxHeight, setListMaxHeight] = useState(0)
        const [selectedValue, setSelectedValue] = useState<any>(value)
        const [listOpen, setListOpen] = useState(externalOpen !== undefined ? externalOpen : false)
        const prevListOpenRef = useRef(listOpen)
        const [focusedItem, setFocusedItem] = useState<React.ReactElement | null>(null)
        const [focusedItemIndex, setFocusedItemIndex] = useState(-1)
        const [query, setQuery] = useState("")
        const [popupDirection, setPopupDirection] = useState<Directions>("down")
        const [minWidth, setMinWidth] = useState(0)

        // Expensive states
        const mappedItems = useMemo(() => {
            const result: Array<{
                child: React.ReactElement
                textContent: string
                itemRef: React.RefObject<HTMLElement>
                value: any
            }> = []
            React.Children.forEach(children, (child, index) => {
                if (React.isValidElement(child)) {
                    result[index] = {
                        child,
                        itemRef: React.createRef(),
                        textContent: onlyText(child.props.children),
                        value: child.props.value,
                    }
                }
            })

            return result
        }, [children])

        useImperativeHandle(ref, () => {
            if (!selectRef.current) {
                return {} as HTMLDivElement
            }

            return {
                ...selectRef.current,
                focus: () => {
                    if (selectRef.current) {
                        selectRef.current.focus()
                    }
                },
            } as HTMLDivElement
        })

        useLayoutEffect(() => {
            if (autoFocus && headerRef.current) {
                headerRef.current.focus()
            }

            let minWidth = 0
            mappedItems.forEach((item) => {
                if (item.itemRef.current) {
                    const itemWidth = getTextWidth(item.textContent, defaults.fontFamilies.primary)
                    if (itemWidth > minWidth) {
                        minWidth = itemWidth
                    }
                }
            })
            if (minWidth > 0) {
                setMinWidth(minWidth)
            }
        }, [])

        useEffect(() => {
            if (value !== selectedValue) {
                setSelectedValue(value)
            }
        }, [value])

        useEffect(() => {
            if (value !== selectedValue && onChange) {
                onChange({
                    // @ts-ignore
                    target: {
                        value: selectedValue,
                    },
                })
            }
        }, [selectedValue])

        useEffect(() => {
            if (externalOpen === undefined) {
                return
            }

            setListOpen(externalOpen)
        }, [externalOpen])

        useEffect(() => {
            if (externalOpen !== undefined) {
                return
            }

            if (listOpen && listOpen !== prevListOpenRef.current) {
                if (selectedValue !== undefined) {
                    const itemIndex = mappedItems.findIndex((i) => {
                        return i.value === selectedValue
                    })
                    if (itemIndex > -1) {
                        setFocusedItemIndex(itemIndex)
                        setFocusedItem(mappedItems[itemIndex].child)
                        const itemRef = mappedItems[itemIndex].itemRef.current
                        if (itemRef) {
                            scrollElementIntoView(itemRef)
                        }
                    }
                }
                document.addEventListener("mousedown", handleClickOutside)

                if (onOpen) {
                    onOpen()
                }
            } else if (!listOpen && listOpen !== prevListOpenRef.current) {
                resetFocus()
                document.removeEventListener("mousedown", handleClickOutside)

                if (onClose) {
                    onClose()
                }
            }

            prevListOpenRef.current = listOpen
        }, [listOpen])

        useEffect(() => {
            if (focusedItemIndex > -1) {
                const itemElm = mappedItems[focusedItemIndex].itemRef.current
                if (itemElm) {
                    scrollElementIntoView(itemElm)
                }
            }
        }, [focusedItemIndex])

        // Handlers
        const handleItemClick = (item: React.ReactElement) => (e: React.MouseEvent) => {
            e.stopPropagation()

            const nextSelectedValue = item.props.value
            if (selectedValue !== nextSelectedValue) {
                setSelectedValue(item.props.value)
            }

            if (listOpen && externalOpen === undefined) {
                setListOpen(false)
            }
        }
        const handleKeyDown = useCallbackRef((e: React.KeyboardEvent<HTMLDivElement>) => {
            const keyPressed = e.key
            const keyCode = e.keyCode

            if (externalOpen !== undefined) {
                return
            }

            switch (keyPressed) {
                case " ":
                case "Enter": {
                    if (listOpen) {
                        if (focusedItem) {
                            setSelectedValue(focusedItem.props.value)
                        }
                    }
                    toggleList()
                    break
                }
                case "Escape": {
                    if (listOpen) {
                        e.stopPropagation()
                        toggleList()
                    }
                    break
                }
                case "ArrowUp": {
                    e.preventDefault()
                    if (!listOpen) {
                        toggleList()
                    }
                    updateFocus("up")
                    break
                }
                case "ArrowDown": {
                    e.preventDefault()
                    if (listOpen) {
                        updateFocus("down")
                    } else {
                        toggleList()
                    }
                    break
                }
                case "Tab": {
                    if (listOpen) {
                        toggleList()
                    }
                }
                default: {
                    if ((keyCode > 47 && keyCode < 58) || (keyCode > 64 && keyCode < 91)) {
                        e.preventDefault()
                        e.stopPropagation()
                        handleQuerying(keyPressed)
                    }
                    break
                }
            }
        })
        const handleQuerying = useCallbackRef((key: string) => {
            if (queryResetTimeout) {
                window.clearTimeout(queryResetTimeout)
            }

            const nextQuery = query + key
            let currentMatchIndexOf = -1
            let currentMatchIndex = -1
            mappedItems.forEach((currentItem, index) => {
                const indexOf = currentItem.textContent.toLowerCase().indexOf(nextQuery)
                if (indexOf === -1) {
                    return
                }

                if (currentMatchIndexOf === -1) {
                    currentMatchIndexOf = indexOf
                    currentMatchIndex = index
                } else if (indexOf < currentMatchIndexOf) {
                    currentMatchIndexOf = indexOf
                    currentMatchIndex = index
                }
            })

            if (currentMatchIndex > -1) {
                setFocusedItemIndex(currentMatchIndex)
                setFocusedItem(mappedItems[currentMatchIndex].child)
            }
            setQuery(nextQuery)
            queryResetTimeout = window.setTimeout(() => {
                setQuery("")
            }, 1500)
        })
        const handleClickOutside = (e: MouseEvent) => {
            if (
                internalListRef.current &&
                headerRef.current &&
                listOpen &&
                externalOpen === undefined
            ) {
                const targetElm = e.target as HTMLElement
                if (
                    internalListRef.current.contains(targetElm) ||
                    headerRef.current.contains(targetElm)
                ) {
                    return
                }

                setListOpen(false)
            }
        }
        const handleClickList = useCallbackRef((event: React.MouseEvent) => {
            if (onClick !== undefined) {
                onClick(event)
            }

            if (!listOpen) {
                toggleList()
            }
        })
        const handleItemMouseEnter =
            (item: React.ReactElement, index: number) => (e: React.MouseEvent) => {
                if (focusedItemIndex !== index) {
                    if (e.persist) {
                        e.persist()
                    }
                    setFocusedItem(item)
                    setFocusedItemIndex(index)
                }
            }

        const handleItemMouseLeave =
            (_: React.ReactElement, index: number) => (_: React.MouseEvent) => {
                if (focusedItemIndex === index) {
                    resetFocus()
                }
            }

        const handleOnFocus = useCallback(
            (e: React.FocusEvent) => {
                if (!listOpen) {
                    toggleList()
                }
                if (onFocus) {
                    if (e.persist) {
                        e.persist()
                    }
                    onFocus(e)
                }
            },
            [onFocus]
        )

        // Private methods
        const updateFocus = useCallbackRef((direction: Directions) => {
            let nextFocusIndex = -1
            if (direction === "up") {
                nextFocusIndex = focusedItemIndex - 1
            } else if (direction === "down") {
                nextFocusIndex = focusedItemIndex + 1
            }

            if (nextFocusIndex < mappedItems.length && nextFocusIndex > -1) {
                setFocusedItem(mappedItems[nextFocusIndex].child)
                setFocusedItemIndex(nextFocusIndex)
            }
        })
        const resetFocus = useCallbackRef(() => {
            if (selectedValue !== undefined || selectedValue !== null) {
                const selectedItemIndex = mappedItems.findIndex((i) => i.value === selectedValue)
                if (selectedItemIndex > -1) {
                    const selectedItem = mappedItems[selectedItemIndex]
                    setFocusedItem(selectedItem.child)
                    setFocusedItemIndex(selectedItemIndex)
                    return
                }
            }
            setFocusedItemIndex(-1)
            setFocusedItem(null)
        })
        const toggleList = useCallbackRef(() => {
            if (externalOpen !== undefined) {
                return
            }

            if (listOpen) {
                setListMaxHeight(0)
                setListOpen(false)
                resetFocus()

                return
            }

            const popDirection = getDirection()
            const maxedListHeight = getMaxedListHeight()
            const { top: containerTop } = getContainerRect()
            let nextListMaxHeight = 0
            switch (popDirection) {
                case "up":
                    nextListMaxHeight = containerTop - defaults.spacing[24]
                    break
                case "down":
                    const selectHeaderElement = headerRef.current!
                    const selectHeaderElementRect = selectHeaderElement.getBoundingClientRect()
                    const selectTop =
                        selectHeaderElementRect.height - window.pageXOffset - defaults.spacing["4"]
                    nextListMaxHeight =
                        window.innerHeight -
                        selectTop -
                        selectHeaderElementRect.top -
                        defaults.spacing[24]
                    break
            }

            if (nextListMaxHeight !== listMaxHeight) {
                setListMaxHeight(
                    nextListMaxHeight > maxedListHeight ? maxedListHeight : nextListMaxHeight
                )
            }

            setPopupDirection(popDirection)
            setListOpen(true)
        })

        const getDirection = useCallbackRef(() => {
            let result: Directions = "down"

            const listItemHeight = getListItemHeight()
            const maxedListHeight = getMaxedListHeight()

            if (
                !selectRef.current ||
                !internalListRef.current ||
                !listItemHeight ||
                !maxedListHeight
            ) {
                return result
            }

            const { height: containerHeight, top: containerTop } = getContainerRect()
            const currentViewport = window.innerHeight
            const spaceBelow = currentViewport - containerTop - containerHeight
            if (spaceBelow > maxedListHeight) {
                return "down"
            }

            const spaceAbove = currentViewport - spaceBelow - containerHeight
            if (spaceAbove > spaceBelow) {
                result = "up"
            }

            return result
        })

        const getContainerRect = useCallbackRef(() => {
            if (!selectRef.current) {
                return { height: 0, top: 0, bottom: 0 }
            }

            const { height: containerHeight, top: containerTopPosition } =
                selectRef.current.getBoundingClientRect()

            return {
                height: containerHeight,
                top: containerTopPosition,
            }
        })

        const getListItemHeight = useCallbackRef(() => {
            if (!internalListRef.current) {
                return null
            }

            const items = internalListRef.current.children
            const firstItem = items.item(0)
            if (!firstItem) {
                return null
            }

            const { height } = firstItem.getBoundingClientRect()

            return height
        })

        const getMaxedListHeight = useCallbackRef(() => {
            const listItemHeight = getListItemHeight()
            if (!listItemHeight) {
                return 0
            }

            if (items && items.length < 8) {
                return listItemHeight * items.length + 2
            }

            return listItemHeight * 8 + 2
        })

        // Create items, this is clones of the `SelectOption` components,
        // which has had their `value` moved to `data-value` and appended with `on{Method}`
        const items = React.Children.map(children, (child, index) => {
            if (!isValidElement(child)) {
                return null
            }

            const selected = selectedValue === child.props.value

            if (selected) {
                foundMatch.current = child
            }

            const hasFocus = index === focusedItemIndex

            return React.cloneElement(child as React.ReactElement, {
                "data-selected": selected ? "true" : undefined,
                "data-focused": hasFocus ? "true" : undefined,
                hasFocus,
                onClick: handleItemClick(child),
                onMouseEnter: handleItemMouseEnter(child, index),
                onMouseLeave: handleItemMouseLeave(child, index),
                ref: mappedItems[index].itemRef,
                role: "option",
                selected,
                tight,
                value: child.props.value,
            })
        })

        let selectedItem = null
        if (
            foundMatch.current &&
            selectedValue !== undefined &&
            foundMatch.current !== selectedValue
        ) {
            selectedItem = React.cloneElement(foundMatch.current, {
                value: undefined,
            })
        }

        const headerClasses = classNames(classes.header, {
            [classes.active]: listOpen,
            [classes.tight]: tight,
            [classes.hasError]: hasError,
            [classes.notice]: notice,
            [classes.disabled]: disabled,
        })

        const containerClasses = useMemo(
            () =>
                classNames(classes.container, {
                    [classes.disabled]: disabled,
                }),
            [disabled]
        )

        const listClasses = classNames(classes.list, {
            [classes.open]: listOpen,
            [classes.autoWidth]: autoWidth,
            [classes.hidden]: !items?.length,
        })

        const selectDomPosition = selectRef.current
            ? getDomPosition(selectRef.current, rootContainer)
            : undefined

        const listInlineStyle: React.CSSProperties = {
            top: selectDomPosition
                ? popupDirection !== "up"
                    ? selectDomPosition.top + selectRef.current!.clientHeight
                    : selectDomPosition.top - getMaxedListHeight()
                : undefined,
            left: selectDomPosition ? selectDomPosition.left : undefined,
            width: selectDomPosition ? selectRef.current!.clientWidth : undefined,
            maxHeight: listMaxHeight,
            position: "absolute",
        }

        return (
            <div className={containerClasses} ref={selectRef} role="listbox">
                <div
                    className={headerClasses}
                    tabIndex={0}
                    ref={headerRef}
                    onClick={handleClickList}
                    onKeyDown={handleKeyDown}
                    onMouseDown={onMouseDown}
                    onFocus={handleOnFocus}
                    onBlur={onBlur}
                    role="listboxtrigger"
                >
                    <div style={{ minWidth }}>
                        {selectedItem && <>{selectedItem}</>}
                        {!selectedItem && placeholder && (
                            <div className={classes.placeholder}>{placeholder}</div>
                        )}
                    </div>
                    <i className={classes.icon}>
                        <ChevronDownIcon
                            size={20}
                            color={disabled ? defaults.colors.neutrals[600] : undefined}
                        />
                    </i>
                </div>
                {!disabled && (
                    <Portal>
                        <ul className={listClasses} style={listInlineStyle} ref={listRef}>
                            {items}
                        </ul>
                    </Portal>
                )}
            </div>
        )
    }
)

if (devMode) {
    Select.displayName = "Select"
}
