import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const ApplicationLiveStepIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { size = 24, ...otherProps } = props

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z"
                fill={defaults.colors.accents.success[500]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.6717 19.2347L24.1283 34.7905L16 28.3357L18.3321 25.399L23.426 29.4442L32.6603 17L35.6717 19.2347Z"
                fill={defaults.colors.neutrals[100]}
            />
        </svg>
    )
}

export { ApplicationLiveStepIcon }
