import classnames from "classnames"
import React, { Ref, useMemo } from "react"
import { BoxEdges } from "../BoxEdges"
import defaults, { ColorNames, ColorWeights } from "../defaults"
import { Grid } from "../Grid"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

export type CornerRadius = keyof typeof defaults.cornerRadius

export interface ICardProps {
    className?: string
    style?: React.CSSProperties
    noSpacing?: boolean
    wideSpacing?: boolean
    children?: React.ReactNode
    frame?: boolean
    frameKind?: ColorNames
    frameShade?: ColorWeights
    kind?: ColorNames
    shade?: ColorWeights
    flatten?: boolean
    gradient?: boolean
    cornerRadius?: CornerRadius
    innerRef?: Ref<HTMLDivElement>
}

const useStyles = makeStyles(styles, "Card", true)
function Card(props: ICardProps) {
    const classes = useStyles()
    const {
        className,
        style,
        noSpacing,
        wideSpacing,
        children,
        frame,
        frameKind = "neutrals",
        frameShade = 400,
        kind = "neutrals",
        shade = 100,
        flatten,
        gradient,
        cornerRadius = "large",
        innerRef,
        ...passThroughProps
    } = props

    const cornerRadiusValue = useMemo(() => {
        if (!cornerRadius) {
            return
        }

        return defaults.cornerRadius[cornerRadius]
    }, [cornerRadius])

    const cardClasses = classnames(className, {
        [classes.card]: true,
        [classes.frame]: frame,
        [classes.flatten]: flatten,
        [classes[`kind-${kind}-${shade}`]]: kind,
        [classes[`frame-${frameKind}-${frameShade}`]]: frameKind,
    })

    return (
        <BoxEdges
            cornerRadius={cornerRadiusValue}
            className={cardClasses}
            style={style}
            ref={innerRef}
            {...passThroughProps}
        >
            {children}
        </BoxEdges>
    )
}

interface IHeaderProps extends ICardProps {
    style?: React.CSSProperties
}
function Header(props: IHeaderProps) {
    return (
        <Grid row>
            <Grid item>
                <BoxEdges p={props.noSpacing ? 0 : props.wideSpacing ? 32 : 24} pb={0}>
                    {props.children}
                </BoxEdges>
            </Grid>
        </Grid>
    )
}

interface IContentProps extends ICardProps {
    style?: React.CSSProperties
}
function Content(props: IContentProps) {
    return (
        <Grid row>
            <Grid item style={props.style}>
                <BoxEdges p={props.noSpacing ? 0 : props.wideSpacing ? 32 : 24}>
                    {props.children}
                </BoxEdges>
            </Grid>
        </Grid>
    )
}

interface IFooterProps extends ICardProps {
    style?: React.CSSProperties
}
function Footer(props: IFooterProps) {
    return (
        <Grid row>
            <Grid item style={props.style}>
                <BoxEdges p={props.noSpacing ? 0 : props.wideSpacing ? 32 : 24} pt={0}>
                    {props.children}
                </BoxEdges>
            </Grid>
        </Grid>
    )
}

interface IActionsProps extends ICardProps {
    style?: React.CSSProperties
}
function Actions(props: IActionsProps) {
    return (
        <Grid row>
            <Grid item style={props.style}>
                <BoxEdges p={props.noSpacing ? 0 : 16}>{props.children}</BoxEdges>
            </Grid>
        </Grid>
    )
}

Card.Header = React.memo(Header)
Card.Header.displayName = "Card.Header"

Card.Content = React.memo(Content)
Card.Content.displayName = "Card.Content"

Card.Footer = React.memo(Footer)
Card.Footer.displayName = "Card.Footer"

Card.Actions = React.memo(Actions)
Card.Actions.displayName = "Card.Actions"

Card.displayName = "Card"

export { Card }
