import React, { useCallback, useEffect, useRef, useState } from "react"
import { Observable, Subscription } from "rxjs"
import { distinctUntilChanged, map } from "rxjs/operators"
import { reach, Schema, ValidationError } from "yup"
import { Checkbox, DatePicker, IMark, Input, RadioGroup, Select, Slider, Textarea } from "../"
import { Label } from "../../"
import devMode from "../../utils/devMode"
import { CommonInputProps } from "../types"
import { ValidationInputError } from "./ValidationInputError"

export type ErrorMessageType =
    | string
    | React.ReactElement
    | { [key: string]: string | React.ReactElement }

interface ValidationInputProps extends CommonInputProps<string | boolean | null> {
    label?: string
    validationSchema: Schema<any>
    pathToValidation?: string
    instantValidation?: boolean
    instantValidationOnBlur?: boolean
    validationDelay?: number
    validateOnMount?: boolean
    errorMessage?: string | JSX.Element
    errorMessageParser?: (errorMessage: string) => ErrorMessageType
    onValid?: (value: string | boolean | null) => void
    onInvalid?: (validationError: ValidationError) => void
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        v: string | boolean | null
    ) => void
    kind?: "input" | "textarea" | "date" | "checkbox" | "select" | "radiogroup" | "slider"
    dateFormat?: string // Visual dateFormat, the returned value will always be yyyy-MM-dd!
    checkedValue?: string | boolean
    prefix?: JSX.Element
    suffix?: JSX.Element
    simple?: boolean
    autoFocus?: boolean
    autoComplete?: boolean
    datePickerMonths?: string[]
    datePickerWeekdaysShort?: string[]
    datePickerFirstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    disabled?: boolean
    direction?: "row" | "column"
    transformEmptyToNull?: boolean
    hasError?: boolean | Observable<string[] | boolean>
    // Slider props
    initialValue?: number
    min?: number
    max?: number
    step?: number
    marks?: IMark[]
    snapToMark?: boolean
    track?: boolean
    onChangeCommitted?: (event: React.ChangeEvent<HTMLInputElement>) => void
    children?: React.ReactNode
}

const transformInputToDate = (input: string) => {
    const inputParts = input.split("-")
    if (inputParts.length !== 3) {
        // Invalid date format
        return undefined
    }

    const year = parseInt(inputParts[0])
    const month = parseInt(inputParts[1])
    const day = parseInt(inputParts[2])

    return new Date(year, month - 1, day)
}

const transformDateToInput = (date: Date | undefined) => {
    if (!date) {
        return ""
    }

    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const day = date.getDate().toString().padStart(2, "0")

    return `${year}-${month}-${day}`
}

export const ValidationInput: React.FC<ValidationInputProps> = (props) => {
    const {
        label,
        value,
        onBlur,
        onChange,
        onValid,
        onInvalid,
        onFocus,
        validationSchema,
        validateOnMount = false,
        pathToValidation,
        instantValidation = false,
        instantValidationOnBlur,
        validationDelay = 50,
        kind = "input",
        dateFormat = "dd/mm/yyyy",
        checkedValue = "true",
        defaultValue,
        prefix,
        suffix,
        simple,
        autoFocus,
        autoComplete,
        datePickerMonths,
        datePickerFirstDayOfWeek,
        datePickerWeekdaysShort,
        disabled = false,
        hasError: externalHasError,
        errorMessage: externalErrorMessage,
        errorMessageParser,
        children,
        direction = "column",
        transformEmptyToNull = false,
        ...passToInputProps
    } = props

    const [currentValue, setCurrentValue] = useState<string | boolean | null>(() => {
        if (defaultValue !== undefined) {
            return defaultValue ? defaultValue : kind === "checkbox" ? false : ""
        } else if (value !== undefined) {
            return value ? value : kind === "checkbox" ? false : ""
        } else {
            return kind === "checkbox" ? false : ""
        }
    })
    const [hasError, setHasError] = useState<boolean>(
        externalHasError !== undefined
            ? typeof externalHasError === "boolean"
                ? externalHasError
                : false
            : false
    )
    const [errorMessage, setErrorMessage] = useState<ErrorMessageType | undefined>(
        externalErrorMessage
    )
    const lastValidatedValue = useRef<typeof currentValue>(!validateOnMount ? currentValue : null)

    const externalHasErrorSubscriptionRef = useRef<Subscription>()
    useEffect(() => {
        if (
            externalHasError !== undefined &&
            typeof externalHasError === "boolean" &&
            externalHasError !== hasError
        ) {
            setHasError(externalHasError)
        } else if (externalHasError instanceof Observable && pathToValidation) {
            const externalHasErrorSubscription = externalHasErrorSubscriptionRef.current
            if (!externalHasErrorSubscription || externalHasErrorSubscription.closed) {
                externalHasErrorSubscriptionRef.current = externalHasError
                    .pipe(
                        map((v) => {
                            if (typeof v === "boolean") {
                                return v
                            }
                            return !!(v && v.includes(pathToValidation))
                        }),
                        distinctUntilChanged()
                    )
                    .subscribe((v) => {
                        setHasError(v)
                    })
            }
        }
    }, [externalHasError])

    const [selectedDate, setSelectedDate] = useState(() => {
        if (kind !== "date") {
            return new Date()
        }

        return transformInputToDate(currentValue as string)
    })

    const [hasFocus, setHasFocus] = useState(false)
    const handleOnFocus = useCallback(
        (e: React.FocusEvent) => {
            setHasFocus(true)
            if (onFocus) {
                if (e.persist) {
                    e.persist()
                }
                onFocus(e)
            }
        },
        [setHasFocus, onFocus]
    )

    // The reason for using useRef and not useState here, is that updating a Ref does not trigger a component update
    const delayTimer = useRef<number>(-1)
    let { current: prevInputValue } = useRef(currentValue)

    let validationForPath: Schema<string | number>
    try {
        validationForPath = pathToValidation
            ? reach<string | number>(validationSchema, pathToValidation)
            : validationSchema
    } catch (e) {
        if (e instanceof Error) {
            throw new Error(`'${pathToValidation}' path not found in 'validationSchema'`)
        }
    }

    // If external props value updates and the field does not have focus,
    // update the internal value, as it most likely reflects a backend update
    useEffect(() => {
        if (value === undefined && transformEmptyToNull) {
            setCurrentValue(null)
        } else if (value !== undefined && currentValue !== value && !hasFocus) {
            setCurrentValue(value)
        }
    }, [value])

    useEffect(() => {
        if (currentValue === value) {
            return // inputValue didn't change from external value, do not validate. When boolean always update.
        }

        prevInputValue = currentValue

        if (
            instantValidation ||
            kind === "select" ||
            kind === "checkbox" ||
            kind === "radiogroup"
        ) {
            validate()
        } else {
            if (delayTimer.current) {
                window.clearTimeout(delayTimer.current)
            }

            delayTimer.current = window.setTimeout(validate, validationDelay)
        }
    }, [currentValue])

    useEffect(() => {
        if (validateOnMount) {
            validate()
        }
    }, [validateOnMount])

    useEffect(() => {
        if (externalErrorMessage && errorMessageParser && devMode) {
            // tslint:disable-next-line:no-console
            console.error(
                new Error(`'errorMessage' and 'errorMessageParser' cannot be used together!`)
            )
        }
        return () => {
            const externalHasErrorSubscription = externalHasErrorSubscriptionRef.current
            if (externalHasErrorSubscription && !externalHasErrorSubscription.closed) {
                externalHasErrorSubscription.unsubscribe()
            }
        }
    }, [])

    // force re-render of RadioGroup, when items change, but chanking key
    const [radioGroupKey, setRadioGroupKey] = useState(0)
    useEffect(() => {
        if (kind === "radiogroup") {
            setRadioGroupKey(radioGroupKey + 1)
        }
    }, [children])

    const validate = () => {
        if (lastValidatedValue.current === currentValue) {
            return // Do not validate if last validation was the same
        }
        if (prevInputValue === "" && currentValue === "" && !validateOnMount) {
            return // Do not validate just because we tabbed through the form
        }

        if (delayTimer.current) {
            window.clearTimeout(delayTimer.current)
        }

        try {
            validationForPath.validateSync(currentValue)
            if (hasError) {
                setHasError(false)
            }
            if (lastValidatedValue.current !== currentValue) {
                if (onValid) {
                    onValid(currentValue)
                }
                lastValidatedValue.current = currentValue
            }
        } catch (e) {
            if (e instanceof ValidationError) {
                setHasError(true)
                if (errorMessageParser) {
                    const nextErrorMessage = errorMessageParser(e.message)
                    if (errorMessage !== nextErrorMessage) {
                        setErrorMessage(nextErrorMessage)
                    }
                }
                if (onInvalid) {
                    onInvalid(e)
                }
            } else {
                throw e // Unknown error, let a parent deal with it
            }
        }
    }

    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, v?: string | boolean) => {
            let nextValue: typeof currentValue
            if (kind === "checkbox" && v !== undefined) {
                nextValue = v
            } else {
                nextValue = v ? v : e.target.value
            }

            if (transformEmptyToNull && !nextValue) {
                nextValue = null
            }

            if (kind === "checkbox") {
                if (e.target instanceof HTMLInputElement && e.target.checked) {
                    setCurrentValue(checkedValue)
                } else {
                    nextValue = false
                    setCurrentValue(false)
                }
            } else {
                setCurrentValue(nextValue)
            }

            if (onChange) {
                onChange(e, nextValue)
            }
        },
        [onChange, setCurrentValue]
    )

    const handleDateOnChange = useCallback(
        (date: Date | undefined, e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedDate(date)
            const transformedInputValue = transformDateToInput(date)
            setCurrentValue(transformedInputValue)

            if (onChange) {
                onChange(e, transformedInputValue)
            }
        },
        [onChange, setSelectedDate, setCurrentValue]
    )

    const handleOnBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (instantValidationOnBlur) {
                validate()
            }

            if (onBlur) {
                if (e.persist) {
                    e.persist()
                }

                onBlur(e)
            }

            setHasFocus(false)
        },
        [instantValidationOnBlur, onBlur, validate]
    )

    return (
        <>
            {label && <Label>{label}</Label>}
            {kind === "input" && (
                <Input
                    value={(currentValue as string) || ""}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    hasError={hasError}
                    defaultValue={defaultValue as string}
                    suffix={suffix}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    {...passToInputProps}
                />
            )}
            {kind === "textarea" && (
                <Textarea
                    value={(currentValue as string) || ""}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    hasError={hasError}
                    defaultValue={defaultValue as string}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    {...passToInputProps}
                />
            )}
            {kind === "date" && (
                <DatePicker
                    onBlur={handleOnBlur}
                    selected={selectedDate}
                    hasError={hasError}
                    onChange={handleDateOnChange}
                    onInputChange={handleOnChange}
                    months={datePickerMonths}
                    weekdaysShort={datePickerWeekdaysShort}
                    firstDayOfWeek={datePickerFirstDayOfWeek}
                    {...passToInputProps}
                />
            )}
            {kind === "checkbox" && (
                <Checkbox
                    row
                    caption={passToInputProps.placeholder}
                    value={checkedValue}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    defaultChecked={currentValue === checkedValue}
                    prefix={prefix}
                    simple={simple}
                    disabled={disabled}
                    {...passToInputProps}
                />
            )}
            {kind === "select" && (
                <Select
                    value={currentValue}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    disabled={disabled}
                    hasError={hasError}
                    autoFocus={autoFocus}
                    {...passToInputProps}
                >
                    {children}
                </Select>
            )}
            {kind === "radiogroup" && (
                <RadioGroup
                    onChange={handleOnChange}
                    defaultCheckedValue={currentValue as string}
                    key={radioGroupKey}
                    direction={direction}
                    disabled={disabled}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    {...passToInputProps}
                >
                    {children}
                </RadioGroup>
            )}
            {kind === "slider" && (
                <Slider
                    initialValue={currentValue as unknown as number}
                    onChangeCommitted={handleOnChange}
                    hasError={hasError}
                    disabled={disabled}
                    {...passToInputProps}
                />
            )}
            <ValidationInputError show={hasError} errorMessage={errorMessage} />
        </>
    )
}

ValidationInput.displayName = "ValidationInput"
