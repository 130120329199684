import { defaults as defaultsOrg } from "../../"

export default (defaults: typeof defaultsOrg) => ({
    "@global": {
        ".DayPicker-Day--outside": {
            color: defaults.colors.neutrals[700],
        },

        ".DayPicker-Day--today": {
            color: defaults.colors.accents.notice[700],
            fontWeight: "700",
        },

        ".DayPicker-Day--selected, .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
            backgroundColor: defaults.colors.primary[500],
            color: defaults.colors.neutrals[100],
        },

        ".DayPicker-Day, .DayPicker-Weekday": {
            height: "2em",
            lineHeight: "2em",
            padding: 0,
            textAlign: "center",
            width: "2em",

            "&:hover:not(role=[columnheader])": {
                backgroundColor: defaults.colors.primary[300],
            },
        },

        ".DayPicker": {
            fontSize: defaults.fontSizes[16],
        },

        ".DayPicker-Week": {
            height: "2em",
        },

        ".DayPicker-Weekday": {
            fontSize: "0.875em",
        },

        ".DayPicker-Caption": {
            padding: 0,
        },

        ".DayPicker-Month": {
            margin: defaults.grid.gutterWidth / 2,
        },
    },

    inline: {
        "& .DayPicker-Month, & .DayPicker-wrapper": {
            margin: 0,
            padding: 0,
        },
    },

    selectRange: {
        "& .DayPicker-Day--selected, & .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
            backgroundColor: defaults.colors.primary[400],
            borderRadius: 0,
            color: defaults.colors.neutrals[100],
        },

        "& .DayPicker-Day--start": {
            borderRadius: 0,
            borderTopLeftRadius: `${defaults.cornerRadius.rounded}px !important`,
            borderBottomLeftRadius: `${defaults.cornerRadius.rounded}px !important`,
            backgroundColor: `${defaults.colors.primary[500]} !important`,
            color: `${defaults.colors.neutrals[100]} !important`,
        },

        "& .DayPicker-Day--end": {
            borderRadius: 0,
            borderTopRightRadius: `${defaults.cornerRadius.rounded}px !important`,
            borderBottomRightRadius: `${defaults.cornerRadius.rounded}px !important`,
            backgroundColor: `${defaults.colors.primary[500]} !important`,
            color: `${defaults.colors.neutrals[100]} !important`,
        },
    },
})
