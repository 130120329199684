import { Children, ReactElement, ReactNode } from "react"
import { hasChildren } from "./hasChildren"

// Return only the text from the given children
// Eg. <Comp>Foo<strong>Bar</strong></Comp> would return 'FooBar'
export const onlyText = (children: React.ReactNode): string => {
    return Children.toArray(children)
        .reduce<string[]>((result: string[], child: ReactNode): string[] => {
            if (!child) {
                return result
            }

            return [
                ...result,
                hasChildren(child)
                    ? onlyText((child as ReactElement).props.children)
                    : child.toString(),
            ]
        }, [])
        .join("")
}
