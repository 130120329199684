import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => {
    const shared = {
        fontFamily: defaults.fontFamilies.secondary,
        fontWeight: defaults.fontWeights.normal,
        lineHeight: defaults.lineHeights.s,
    }
    return {
        center: {
            textAlign: "center",
        },
        h1: {
            ...shared,
            fontSize: defaults.fontSizes[36],
            lineHeight: "37px",

            "&$margin": {
                marginBottom: defaults.spacing[24],
            },
        },

        h2: {
            ...shared,
            fontSize: defaults.fontSizes[30],
            lineHeight: "31px",

            "&$margin": {
                marginBottom: defaults.spacing[24],
            },
        },

        h3: {
            ...shared,
            fontSize: defaults.fontSizes[24],
            letterSpacing: "-0.43px",
            lineHeight: "25px",

            "&$margin": {
                marginBottom: defaults.spacing[16],
            },
        },

        h4: {
            ...shared,
            fontSize: defaults.fontSizes[20],
            letterSpacing: "-0.24px",
            lineHeight: "21px",

            "&$margin": {
                marginBottom: defaults.spacing[12],
            },
        },

        h5: {
            ...shared,
            fontSize: defaults.fontSizes[16],
            letterSpacing: "-0.3px",
            lineHeight: "17px",

            "&$margin": {
                marginBottom: defaults.spacing[12],
            },
        },

        h6: {
            ...shared,
            fontSize: defaults.fontSizes[13],
            letterSpacing: "0.5px",
            lineHeight: "14px",

            "&$margin": {
                marginBottom: defaults.spacing[8],
            },
        },
        heading: {
            margin: 0,
        },
        margin: {},
    }
}

export default styles
