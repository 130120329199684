import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Grid } from "../Grid"

interface ISectionContainerProps {
    activeSection?: string
    defaultSection?: string
    onSelect?: (section: string) => void
    onIntersection?: (section: string) => void
    ref?: React.Ref<HTMLDivElement>
    children: React.ReactNode
}

interface ISectionContext {
    activeSection: string
    onSelect: (section: string) => void
    onIntersection: (section: string) => void
}

export const SectionContext = React.createContext<ISectionContext>({
    activeSection: "",
    onSelect: () => {
        throw Error("No SectionContext set")
    },
    onIntersection: () => {
        throw Error("No SectionContext set")
    },
})

export const SectionContainer: React.FC<ISectionContainerProps> = (props) => {
    const { onSelect, onIntersection, defaultSection, children, ref } = props

    const [activeSection, setActiveSection] = useState(defaultSection ? defaultSection : "")

    const handleOnSelect = useCallback(
        (section: string) => {
            if (section !== activeSection) {
                setActiveSection(section)

                if (onSelect) {
                    onSelect(section)
                }
            }
        },
        [onSelect, activeSection]
    )

    const handleOnIntersection = useCallback(
        (section: string) => {
            if (section !== activeSection) {
                setActiveSection(section)

                if (onIntersection) {
                    onIntersection(section)
                }
            }
        },
        [onIntersection, activeSection]
    )

    useEffect(() => {
        if (props.activeSection && props.activeSection !== activeSection) {
            setActiveSection(props.activeSection)
        }
    }, [props.activeSection])

    const contextValue = useMemo(
        () => ({ activeSection, onSelect: handleOnSelect, onIntersection: handleOnIntersection }),
        [activeSection, handleOnSelect, handleOnIntersection]
    )

    return (
        <SectionContext.Provider value={contextValue}>
            <Grid container fluid ref={ref}>
                {children}
            </Grid>
        </SectionContext.Provider>
    )
}
