import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        borderRadius: defaults.cornerRadius.rounded,
        width: "2.5em",
        height: "2.5em",
        lineHeight: "2.45em",
        textAlign: "center",
    },

    initials: {
        color: defaults.colors.neutrals[100],
        fontWeight: defaults.fontWeights.medium,
        textTransform: "uppercase",
    },
})

export default styles
