import classNames from "classnames"
import { Property } from "csstype"
import React, { useMemo } from "react"
import defaults, { ColorNames, ColorWeights } from "../defaults"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

export type Spacing = keyof typeof defaults.spacing
export type SpacingMargin = Spacing | "auto"

export interface BoxEdgesProps {
    children: React.ReactNode | React.ReactNode[]
    className?: string
    shadow?: ColorNames
    shadowWeight?: ColorWeights
    shadowCustom?: Property.BoxShadow
    shadowTop?: boolean
    style?: React.CSSProperties
    fullWidth?: boolean
    cornerRadius?: number
    minWidth?: number
    maxWidth?: number
    m?: SpacingMargin
    mt?: SpacingMargin
    mr?: SpacingMargin
    mb?: SpacingMargin
    ml?: SpacingMargin
    mx?: SpacingMargin
    my?: SpacingMargin
    p?: Spacing
    pt?: Spacing
    pr?: Spacing
    pb?: Spacing
    pl?: Spacing
    px?: Spacing
    py?: Spacing
    role?: string
}

const useStyles = makeStyles(styles, "BoxEdges", true)
export const BoxEdges = React.forwardRef<HTMLDivElement, React.PropsWithChildren<BoxEdgesProps>>(
    (props, ref) => {
        const classes = useStyles()
        const { m, mt, mr, mb, ml, mx, my, p, pt, pr, pb, pl, px, py } = props
        const { style, shadow, shadowCustom, shadowTop, cornerRadius, role, maxWidth } = props
        const { fullWidth } = props
        let { shadowWeight, minWidth } = props

        if (!minWidth) {
            minWidth = 0
        }

        if (!shadowWeight) {
            shadowWeight = 500
        }

        const finalClasses = classNames(
            {
                // @ts-ignore - @TODO
                [classes.fullWidth]: fullWidth,
                // @ts-ignore - @TODO
                [classes[`m${m}`]]: !!m,
                // @ts-ignore - @TODO
                [classes[`mt${mt}`]]: !!mt,
                // @ts-ignore - @TODO
                [classes[`mr${mr}`]]: !!mr,
                // @ts-ignore - @TODO
                [classes[`mb${mb}`]]: !!mb,
                // @ts-ignore - @TODO
                [classes[`ml${ml}`]]: !!ml,
                // @ts-ignore - @TODO
                [classes[`mx${mx}`]]: !!mx,
                // @ts-ignore - @TODO
                [classes[`my${my}`]]: !!my,
                // @ts-ignore - @TODO
                [classes[`p${p}`]]: !!p,
                // @ts-ignore - @TODO
                [classes[`pt${pt}`]]: !!pt,
                // @ts-ignore - @TODO
                [classes[`pr${pr}`]]: !!pr,
                // @ts-ignore - @TODO
                [classes[`pb${pb}`]]: !!pb,
                // @ts-ignore - @TODO
                [classes[`pl${pl}`]]: !!pl,
                // @ts-ignore - @TODO
                [classes[`px${px}`]]: !!px,
                // @ts-ignore - @TODO
                [classes[`py${py}`]]: !!py,
            },
            {
                // @ts-ignore - @TODO
                [classes[`shadow-${shadow}-${shadowWeight}`]]: shadow && shadowWeight && !shadowTop,
                // @ts-ignore - @TODO
                [classes[`shadow-top-${shadow}-${shadowWeight}`]]:
                    shadow && shadowWeight && shadowTop,
            },
            props.className
        )

        const boxEdgesStyle = useMemo(
            () =>
                shadowCustom
                    ? {
                          boxShadow: shadowCustom,
                          borderRadius: cornerRadius,
                          minWidth,
                          maxWidth,
                          ...style,
                      }
                    : { borderRadius: cornerRadius, minWidth, maxWidth, ...style },
            [style, shadowCustom, cornerRadius, minWidth, maxWidth]
        )

        return (
            <div className={finalClasses} style={boxEdgesStyle} role={role} ref={ref}>
                {props.children}
            </div>
        )
    }
)
BoxEdges.displayName = "BoxEdges"
