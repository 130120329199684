import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const ChromeIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...otherProps } = props
    return (
        <svg
            width={size}
            height={size}
            color={color}
            {...otherProps}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            stroke="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m11.958 0c.039 0 .085-.001.131-.001 2.179 0 4.219.597 5.965 1.637l-.054-.03c2.032 1.171 3.645 2.87 4.679 4.911l.031.067-9.935-.52c-.128-.009-.278-.014-.43-.014-1.301 0-2.513.378-3.534 1.03l.026-.016c-1.152.703-2.024 1.767-2.468 3.032l-.012.04-3.696-5.68c1.112-1.384 2.505-2.494 4.097-3.256l.07-.03c1.5-.733 3.263-1.165 5.126-1.172h.002zm-10.003 5.423 4.513 8.88c.624 1.251 1.596 2.25 2.791 2.888l.035.017c.806.449 1.768.713 2.792.713.399 0 .789-.04 1.165-.117l-.037.006-3.08 6.039c-3.857-.613-7.052-2.997-8.77-6.277l-.031-.066c-.839-1.579-1.332-3.452-1.332-5.441 0-.024 0-.049 0-.073v.004c0-.021 0-.045 0-.069 0-2.423.73-4.674 1.982-6.548l-.027.043zm21.237 2.263c.494 1.232.787 2.66.8 4.155v.005c.001.051.001.112.001.172 0 1.405-.238 2.755-.677 4.011l.026-.085c-.495 1.399-1.19 2.607-2.065 3.662l.016-.02c-.916 1.118-2.006 2.052-3.237 2.772l-.056.031c-1.708 1.015-3.765 1.615-5.962 1.615-.248 0-.495-.008-.739-.023l.034.002 5.423-8.342c.696-1.01 1.111-2.26 1.111-3.607 0-.099-.002-.198-.007-.296l.001.014c-.029-1.427-.564-2.724-1.433-3.723l.006.007zm-11.192.268c2.233.001 4.043 1.811 4.044 4.044-.001 2.233-1.811 4.043-4.044 4.044-2.233-.001-4.043-1.811-4.044-4.044.001-2.233 1.811-4.043 4.044-4.044z" />
        </svg>
    )
}

export { ChromeIcon }
