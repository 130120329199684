import React from "react"
import ReactMarkdown from "react-markdown"
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown"

interface IMarkdownProps extends ReactMarkdownOptions {
    children: string
}

const Markdown: React.FC<IMarkdownProps> = (props) => {
    const { children } = props

    return <ReactMarkdown children={children} />
}

export { Markdown }
