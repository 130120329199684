import hexToRgba from "hex-to-rgba"
import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => {
    const sizeStyles = {
        fullWidth: {
            width: "100%",
        },
    }

    const getStylesForKey = (key: string, cssProps: string[]) => {
        const values: Array<string | number> = Object.values(defaults.spacing)

        if (key.includes("m")) {
            values.push("auto")
        }

        const valuesArray = values.map((value) => {
            let cssPropsObject = {}
            cssProps.forEach((cssProp) => {
                cssPropsObject = { ...cssPropsObject, ...{ [`${cssProp}`]: value } }
            })

            return {
                [`${key}${value}`]: {
                    ...cssPropsObject,
                },
            }
        })

        return Object.assign({}, ...valuesArray)
    }

    const getShadowClassNamesAndHex = (): Array<{
        path: string
        value: string
        weight: number
    }> => {
        const classObjs = []
        const nodes = [
            {
                obj: defaults.colors as object,
                path: [],
                value: "",
            },
        ]
        while (nodes.length > 0) {
            const node = nodes.pop()
            if (!node) {
                break
            }
            for (const _property in node.obj) {
                if (node.obj.hasOwnProperty(_property)) {
                    const property = _property as keyof typeof node.obj
                    const path = node.path.concat(property)
                    if (typeof node.obj[property] === "object") {
                        nodes.unshift({
                            obj: node.obj[property],
                            path,
                            value: "",
                        })
                    } else {
                        classObjs.push({
                            path: path.join("-"),
                            value: node.obj[property],
                            weight: parseInt(property),
                        })
                    }
                }
            }
        }

        return classObjs
    }

    const getShadowStyles = () => {
        const weights = [100, 200, 300, 400, 500, 600, 700, 800, 900]
        const classObjs = getShadowClassNamesAndHex()
        const boxShadows = {}
        for (const classObj of classObjs) {
            const weightIndex = weights.indexOf(classObj.weight) + 1
            if (weightIndex === 0) {
                continue
            }
            Object.assign(boxShadows, {
                ...boxShadows,
                [`shadow-${classObj.path}`]: {
                    boxShadow: `0 ${weightIndex}px ${weightIndex * 1.5}px ${hexToRgba(
                        classObj.value,
                        classObj.weight / 2000
                    )}`,
                },
                [`shadow-top-${classObj.path}`]: {
                    boxShadow: `0 -${weightIndex}px ${weightIndex * 1.5}px ${hexToRgba(
                        classObj.value,
                        classObj.weight / 2000
                    )}`,
                },
            })
        }

        return boxShadows
    }

    const mStyling = getStylesForKey("m", ["margin"])
    const mtStyling = getStylesForKey("mt", ["marginTop"])
    const mrStyling = getStylesForKey("mr", ["marginRight"])
    const mbStyling = getStylesForKey("mb", ["marginBottom"])
    const mlStyling = getStylesForKey("ml", ["marginLeft"])
    const mxStyling = getStylesForKey("mx", ["marginLeft", "marginRight"])
    const myStyling = getStylesForKey("my", ["marginTop", "marginBottom"])
    const pStyling = getStylesForKey("p", ["padding"])
    const ptStyling = getStylesForKey("pt", ["paddingTop"])
    const prStyling = getStylesForKey("pr", ["paddingRight"])
    const pbStyling = getStylesForKey("pb", ["paddingBottom"])
    const plStyling = getStylesForKey("pl", ["paddingLeft"])
    const pxStyling = getStylesForKey("px", ["paddingLeft", "paddingRight"])
    const pyStyling = getStylesForKey("py", ["paddingTop", "paddingBottom"])

    const boxShadowStyling = getShadowStyles()

    return {
        ...mStyling,
        ...mtStyling,
        ...mrStyling,
        ...mbStyling,
        ...mlStyling,
        ...mxStyling,
        ...myStyling,
        ...pStyling,
        ...ptStyling,
        ...prStyling,
        ...pbStyling,
        ...plStyling,
        ...pxStyling,
        ...pyStyling,
        ...boxShadowStyling,
        ...sizeStyles,
    }
}

export default styles
