import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

export const GoogleLogoIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { size = 24, color = defaults.colors.neutrals[900], ...otherProps } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                d="M3.77548 8.41631C3.77154 8.41454 3.7676 8.41271 3.76367 8.41082C3.80887 8.30972 3.85611 8.20922 3.9054 8.10938L3.86919 8.42354C3.863 8.4462 3.86201 8.44597 3.86103 8.44573L3.85553 8.44435L3.84441 8.44135C3.83695 8.43924 3.82941 8.43693 3.82179 8.43438C3.80653 8.42928 3.79105 8.4233 3.77548 8.41631ZM4.42784 15.806C3.23294 13.522 3.16734 10.6981 4.37565 8.28665L4.42613 8.18812C6.88774 3.38314 13.3471 1.96621 17.6018 5.37561C17.0965 5.87355 16.2143 6.73622 15.7316 7.20808C14.3312 6.10803 12.5513 5.80457 10.9695 6.13981C8.8388 6.58714 7.09322 8.21138 6.52237 10.0376C6.29874 10.5653 6.19895 11.2255 6.19895 11.8826C6.19895 12.4388 6.27041 13.0203 6.41767 13.5555L6.3923 13.5741L6.51401 13.923C7.20138 15.8935 8.89421 17.4473 11.0391 17.8737C12.2923 18.1254 13.9394 17.9738 15.2899 17.2243L15.2998 17.234L15.5206 17.0885C15.5527 17.0685 15.5846 17.0482 15.6163 17.0274C15.6382 17.0142 15.657 17.0006 15.6727 16.9879C16.7494 16.2588 17.4754 14.8904 17.6856 13.9058L17.8147 13.3014H17.1966H12.6869C12.6895 12.5271 12.69 11.4761 12.6901 10.7036H20.4057C20.8319 14.0897 19.77 16.7006 18.1226 18.2937L17.941 18.4579C15.78 20.4125 12.515 20.8865 9.93414 20.2097L9.93243 20.2093C7.66371 19.6229 5.7574 18.1387 4.62456 16.1891L4.62723 16.1871L4.42784 15.806Z"
                fill="currentColor"
                stroke="currentColor"
            />
        </svg>
    )
}
