import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        width: "100vw",
    },
    scrollWrapper: {
        bottom: 0,
        left: 0,
        overflowX: "auto",
        position: "absolute",
        right: 0,
        top: 0,
    },
    wrapper: {
        height: `calc(100vh - ${defaults.spacing[32]}px)`,
        margin: defaults.spacing[32] / 2,
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
        width: `calc(100vw - ${defaults.spacing[32]}px)`,

        "& img": {
            display: "inline-block",
            height: "auto",
            margin: "0 auto",
            maxHeight: "100%",
            maxWidth: "100%",
            verticalAlign: "middle",
            width: "auto",
        },
    },

    pdfDocumentPage: {
        marginBottom: defaults.spacing[32],
    },

    pdfDocument: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    csvTable: {
        backgroundColor: defaults.colors.neutrals[100],
    },
})

export default styles
