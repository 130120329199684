import React from "react"

interface IGooglePayLogoProps {
    height: number
}

export const GooglePayLogo: React.FC<IGooglePayLogoProps> = (props) => {
    const { height = 24 } = props

    return (
        <svg height={height} viewBox="0 0 61 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M28.585 11.7226V18.7577H26.3531V1.38398H32.2724C32.9789 1.36922 33.6814 1.49453 34.3393 1.75268C34.9971 2.01083 35.5974 2.39671 36.1053 2.88805C36.6184 3.34956 37.0268 3.91555 37.3029 4.54798C37.5791 5.18041 37.7167 5.86464 37.7064 6.55466C37.7211 7.24838 37.5857 7.93707 37.3093 8.57353C37.0329 9.20999 36.6222 9.77917 36.1053 10.2421C35.0703 11.2291 33.7926 11.7221 32.2724 11.7212H28.585V11.7226ZM28.585 3.52295V9.58776H32.3278C32.7381 9.59992 33.1465 9.52631 33.5267 9.37163C33.907 9.21695 34.2508 8.98461 34.5361 8.68947C34.8199 8.41352 35.0456 8.08346 35.1996 7.71881C35.3537 7.35415 35.4331 6.96231 35.4331 6.56644C35.4331 6.17058 35.3537 5.77873 35.1996 5.41408C35.0456 5.04942 34.8199 4.71936 34.5361 4.44341C34.2544 4.14206 33.9118 3.90402 33.5311 3.7451C33.1504 3.58617 32.7402 3.50997 32.3278 3.52156H28.585V3.52295Z"
                    fill="#5F6368"
                />
                <path
                    d="M42.8491 6.48282C44.4988 6.48282 45.8009 6.92364 46.7556 7.80529C47.7102 8.68694 48.1871 9.89575 48.1862 11.4317V18.758H46.0513V17.1084H45.9543C45.0301 18.4669 43.801 19.1461 42.2669 19.1461C40.9574 19.1461 39.8618 18.758 38.9801 17.9817C38.557 17.6254 38.2187 17.1792 37.99 16.6756C37.7612 16.172 37.6476 15.6236 37.6577 15.0706C37.6577 13.8405 38.1225 12.8623 39.0522 12.1359C39.9819 11.4095 41.2231 11.0454 42.7757 11.0436C44.1009 11.0436 45.1923 11.2861 46.05 11.7713V11.2612C46.0525 10.8842 45.9711 10.5113 45.8116 10.1696C45.6522 9.82795 45.4187 9.52604 45.1281 9.2858C44.5379 8.75331 43.7687 8.46277 42.9739 8.47208C41.7272 8.47208 40.7407 8.99792 40.0143 10.0496L38.0486 8.8117C39.1298 7.25911 40.73 6.48282 42.8491 6.48282ZM39.9616 15.1191C39.9601 15.4031 40.0265 15.6834 40.1553 15.9366C40.284 16.1898 40.4714 16.4086 40.7018 16.5747C41.1954 16.9629 41.8081 17.1686 42.436 17.1569C43.3778 17.1553 44.2806 16.7804 44.9465 16.1144C45.6858 15.4185 46.0555 14.602 46.0555 13.6649C45.3596 13.1104 44.3892 12.8332 43.1444 12.8332C42.2378 12.8332 41.4818 13.0518 40.8765 13.4889C40.2652 13.9325 39.9616 14.4717 39.9616 15.1191V15.1191Z"
                    fill="#5F6368"
                />
                <path
                    d="M60.4396 6.87186L52.9872 23.9989H50.6832L53.4488 18.0061L48.5484 6.87186H50.9744L54.5162 15.4111H54.5647L58.0095 6.87186H60.4396Z"
                    fill="#5F6368"
                />
                <path
                    d="M19.5665 10.2091C19.5673 9.52899 19.5098 8.85001 19.3946 8.17969H9.98203V12.0237H15.3731C15.2628 12.6377 15.0293 13.2229 14.6867 13.7442C14.3441 14.2655 13.8995 14.712 13.3797 15.0568V17.5521H16.5972C18.4811 15.8151 19.5665 13.2464 19.5665 10.2091Z"
                    fill="#4285F4"
                />
                <path
                    d="M9.98178 19.9629C12.6752 19.9629 14.9431 19.0785 16.5969 17.5536L13.3795 15.0584C12.4839 15.6655 11.3306 16.0121 9.98178 16.0121C7.37842 16.0121 5.16875 14.2571 4.37859 11.8922H1.06409V14.4637C1.89482 16.1167 3.16865 17.5064 4.74337 18.4775C6.31809 19.4485 8.13172 19.9628 9.98178 19.9629V19.9629Z"
                    fill="#34A853"
                />
                <path
                    d="M4.3773 11.8904C3.95957 10.6512 3.95957 9.30916 4.3773 8.06992V5.49844H1.06279C0.363955 6.88906 0 8.42383 0 9.98016C0 11.5365 0.363955 13.0713 1.06279 14.4619L4.3773 11.8904Z"
                    fill="#FBBC04"
                />
                <path
                    d="M9.98178 3.95144C11.4052 3.92819 12.7805 4.46598 13.8106 5.44858L16.6593 2.59986C14.8529 0.903203 12.4599 -0.0282886 9.98178 0.000654843C8.13172 0.000739161 6.31809 0.515026 4.74337 1.4861C3.16865 2.45717 1.89482 3.84681 1.06409 5.49987L4.37859 8.07135C5.16875 5.70642 7.37842 3.95144 9.98178 3.95144Z"
                    fill="#EA4335"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="60.436" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
