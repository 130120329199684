import classNames from "classnames"
import * as React from "react"
import { Popover, SelectableText } from "../"
import { BoxEdges } from "../BoxEdges"
import { Paragraph } from "../Paragraph"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ITooltipProps {
    /**
     * Text to show in the tooltip
     */
    text: string | JSX.Element
    noWrap?: boolean
    underline?: "none" | "dashed"
    type?: "component" | "paragraph"
    children: React.ReactNode
}

const useStyles = makeStyles(styles, "Tooltip", true)
const Tooltip: React.FC<ITooltipProps> = (props) => {
    const { children, text, noWrap, underline = "dashed", type = "paragraph" } = props
    const classes = useStyles()

    return (
        <Popover showOnMouseEnter placement={"top"}>
            <Popover.Reference>
                <div className={classNames({ [classes[`underline-${underline}`]]: underline })}>
                    {children}
                </div>
            </Popover.Reference>
            <Popover.Content dark>
                <BoxEdges
                    py={4}
                    px={8}
                    className={classNames(classes.content, { [classes.noWrap]: noWrap })}
                >
                    {type === "paragraph" && (
                        <Paragraph size="xs" lineHeight="m">
                            <SelectableText>{text}</SelectableText>
                        </Paragraph>
                    )}
                    {type === "component" && text}
                </BoxEdges>
            </Popover.Content>
        </Popover>
    )
}

export { Tooltip }
