import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { makeStyles } from "../../utils/styles/makeStyles"
import { CommonInputProps } from "../types"
import styles from "./styles"

export interface ITextareaProps extends CommonInputProps {
    rows?: number
    hasError?: boolean
    kind?: "light" | "dark"
}

const useStyles = makeStyles(styles, "Textarea", true)
export const Textarea: React.FC<ITextareaProps> = (props) => {
    const {
        name,
        placeholder,
        rows = 3,
        hasError,
        autoFocus,
        defaultValue,
        value,
        onChange,
        onBlur,
        onKeyPress,
        onKeyDown,
        onFocus,
        notice,
        disabled = false,
        autoComplete = true,
        kind,
    } = props
    const classes = useStyles()

    // states
    const [focused, setFocused] = useState<boolean>(false)
    const [currentValue, setCurrentValue] = useState(defaultValue)
    const [prevValue, setPrevValue] = useState<string>()

    // effects
    useEffect(() => {
        if (value !== undefined) {
            setCurrentValue(value)
        }
    }, [value])

    // handlers
    if (value !== prevValue) {
        setCurrentValue(value)
        setPrevValue(value)
    }

    const callOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>, value: string) => {
        if (onChange) {
            onChange(event, value)
        }
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (event.persist) {
            event.persist()
        }

        if (value !== undefined) {
            callOnChange(event, event.target.value)
        } else {
            setCurrentValue(event.target.value)
            callOnChange(event, event.target.value)
        }
    }

    const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setFocused(false)

        if (onBlur) {
            if (event.persist) {
                event.persist()
            }
            onBlur(event)
        }
    }

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (onKeyPress) {
            if (event.persist) {
                event.persist()
            }
            onKeyPress(event)
        }
    }

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (onKeyDown) {
            if (event.persist) {
                event.persist()
            }
            onKeyDown(event)
        }
    }

    const handleOnFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setFocused(true)
        if (onFocus) {
            if (event.persist) {
                event.persist()
            }
            onFocus(event)
        }
    }

    return (
        <div className={classes.container}>
            <div
                className={classNames(classes.textareaContainer, {
                    [classes.disabled]: disabled,
                    [classes.focused]: focused,
                    [classes.hasError]: hasError,
                    [classes.notice]: notice,
                    [classes["kind-dark"]]: kind === "dark",
                })}
            >
                <textarea
                    className={classes.textarea}
                    name={name}
                    placeholder={placeholder}
                    onChange={handleOnChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onKeyPress={handleOnKeyPress}
                    onKeyDown={handleOnKeyDown}
                    value={currentValue}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete ? "on" : "off"}
                    rows={rows}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}
