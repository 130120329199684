import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

export const VippsLogoIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { size = 24, color = defaults.colors.neutrals[900], ...otherProps } = props

    return (
        <svg
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.6627 15.5909C15.3559 15.5909 16.8845 14.2273 18.3403 12.2576C19.141 11.197 20.1601 10.9697 20.888 11.5758C21.6159 12.1818 21.6887 13.3182 20.888 14.3788C18.7771 17.2576 16.0838 19 12.6627 19C8.95041 19 5.67486 16.8788 3.41836 13.1667C2.76325 12.1818 2.90883 11.1212 3.63673 10.5909C4.36464 10.0606 5.45649 10.2879 6.1116 11.3485C7.71298 13.8485 9.89668 15.5909 12.6627 15.5909ZM17.6852 6.27273C17.6852 7.63636 16.6662 8.54545 15.5015 8.54545C14.3369 8.54545 13.3178 7.63636 13.3178 6.27273C13.3178 4.90909 14.3369 4 15.5015 4C16.6662 4 17.6852 4.98485 17.6852 6.27273Z"
                fill="currentColor"
            />
        </svg>
    )
}
