const styles = {
    content: {
        alignItems: "flex-end",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    main: {
        display: "flex",
        justifyContent: "flex-end",
    },
    sidebar: {
        position: "fixed",
    },

    wrapper: {
        display: "flex",
        justifyContent: "center",
    },

    container: {
        maxWidth: 940,
        width: "90vw",
    },
}

export default styles
