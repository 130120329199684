import React from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const ApplicationOverviewIcon: React.FC<Icon> = (props) => {
    const { size = 24, ...otherProps } = props
    const { defaults } = React.useContext(ThemeContext)
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 49C38.2548 49 49 38.2548 49 25C49 11.7452 38.2548 1 25 1C11.7452 1 1 11.7452 1 25C1 38.2548 11.7452 49 25 49ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                fill="black"
            />
            <rect x="11" y="11" width="20" height="20" rx="2" fill="#CDD4E1" />
            <rect x="15" y="15" width="20" height="20" rx="2" fill="#9CA9C3" />
            <rect x="19" y="19" width="20" height="20" rx="2" fill="white" />
            <rect x="21" y="22" width="16" height="2" rx="1" fill="#273346" />
            <rect x="21" y="26" width="13" height="2" rx="1" fill="#273346" />
            <rect x="21" y="30" width="7" height="2" rx="1" fill="#273346" />
        </svg>
    )
}

export { ApplicationOverviewIcon }
