import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    line: {
        position: "relative",
        height: 20,
        marginRight: 15,
        width: "100%",

        "&:before": {
            content: "''",
            display: "block",
            height: 20,
            backgroundPosition: "center",
            backgroundRepeat: "repeat-x",
            backgroundImage:
                'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxIiB2aWV3Qm94PSIwIDAgNiAxIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy4zMzMzMyAwLjI1SDIuNjY2NjdDMi41ODMzMyAwLjI1IDIuNSAwLjM1IDIuNSAwLjVDMi41IDAuNjUgMi41ODMzMyAwLjc1IDIuNjY2NjcgMC43NUgzLjI5MTY3QzMuNDE2NjcgMC43NSAzLjUgMC42NSAzLjUgMC41QzMuNSAwLjM1IDMuNDE2NjcgMC4yNSAzLjMzMzMzIDAuMjVaIiBmaWxsPSIjQjdDNkQ3Ii8+Cjwvc3ZnPgo=")',
            color: defaults.colors.neutrals[600],
            backgroundSize: 10,
            minWidth: 35,
        },

        "&:after": {
            top: 0,
            position: "absolute",
            height: 20,
            width: 1,
            background: defaults.colors.neutrals[600],
            right: 0,
        },
    },
    start: {
        marginRight: 0,
        "&:after": {
            content: "''",
            left: -15,
        },
    },
    end: {
        "&:after": {
            content: "''",
            right: -15,
        },
    },
})

export default styles
