import React, { useContext } from "react"
import { BoxEdges } from "../BoxEdges"
import { Grid } from "../Grid"
import { Paragraph } from "../Paragraph"
import { ThemeContext } from "../ThemeProvider"

const containerStyle = { height: "100%" }

interface IFilePreviewErrorProps {
    title: string
    description: string
}

export const FilePreviewError: React.FC<IFilePreviewErrorProps> = (props) => {
    const { title, description } = props

    const { defaults } = useContext(ThemeContext)

    return (
        <Grid row grow={1} alignItems="center" justify="center" style={containerStyle}>
            <Grid item auto>
                <BoxEdges mb={24} maxWidth={260}>
                    <Paragraph
                        weight="medium"
                        align="center"
                        lineHeight="xl"
                        noMargin
                        color={defaults.colors.neutrals[100]}
                    >
                        {title}
                    </Paragraph>
                    <Paragraph align="center" lineHeight="m" color={defaults.colors.neutrals[500]}>
                        {description}
                    </Paragraph>
                </BoxEdges>
            </Grid>
        </Grid>
    )
}
