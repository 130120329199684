import useEventListener from "@use-it/event-listener"
import classNames from "classnames"
import React, {
    createContext,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react"
import { BoxEdges } from "../BoxEdges"
import { Grid } from "../Grid"
import devMode from "../utils/devMode"
import { makeStyles } from "../utils/styles/makeStyles"
import useMeasure from "../utils/useMeasure"
import styles from "./styles"

interface INavigationProps {
    fixedTop?: boolean
    fixedBottom?: boolean
    fluid?: boolean
    shadowAlwaysShown?: boolean
    noPadding?: boolean
    noZIndex?: boolean
    color?: "light" | "dark" | "transparent"
    heightOnMount?: (height: number) => void
}

interface INavigationContext {
    classes: Record<any, string>
}
const NavigationContext = createContext<INavigationContext | null>(null)

const useStyles = makeStyles(styles, "Navigation", true)
const Navigation = (props: React.PropsWithChildren<INavigationProps>) => {
    const classes = useStyles()
    const [shadow, setShadow] = useState(false)
    const [measureRef, { height }] = useMeasure()
    const { color = "light", fluid = false, noPadding = false } = props

    useEffect(() => {
        if (props.shadowAlwaysShown) {
            setShadow(true)
        }
    }, [])

    useLayoutEffect(() => {
        if (props.heightOnMount && height) {
            props.heightOnMount(height)
        }
    }, [height])

    const navigationContextValue = useMemo(
        () => ({
            classes,
        }),
        [classes]
    )

    const handleScroll = () => {
        if (!document.scrollingElement) {
            return
        }

        if (props.shadowAlwaysShown) {
            return
        }

        const scrollPosition = document.scrollingElement.scrollTop

        if (scrollPosition === 0 && shadow) {
            setShadow(false)
        } else if (scrollPosition > 0 && !shadow) {
            setShadow(true)
        }
    }

    useEventListener("scroll", handleScroll)

    const otherChildren: React.ReactElement[] = []
    const announcementChildren: React.ReactElement[] = []

    React.Children.map(props.children, (child: React.ReactElement) => {
        if (
            child &&
            typeof child.type !== "string" &&
            (child.type as React.FC).displayName === Announcement.displayName
        ) {
            announcementChildren.push(child)
            return
        }

        otherChildren.push(child)
    })

    return (
        <NavigationContext.Provider value={navigationContextValue}>
            <header
                ref={measureRef}
                className={classNames(classes.navigationContainer, {
                    [classes.navigationFixed]: props.fixedTop,
                    [classes.navigationFixedBottom]: props.fixedBottom,
                    [classes.noZIndex]: props.noZIndex,
                    [classes.navigationLight]: shadow && color === "light",
                    [classes.navigationDark]: shadow && color === "dark",
                    [classes.navigationTransparent]: color === "transparent",
                })}
            >
                <BoxEdges
                    shadow={shadow && color !== "transparent" ? "neutrals" : undefined}
                    shadowWeight={
                        shadow && color !== "transparent"
                            ? color === "dark"
                                ? 900
                                : 600
                            : undefined
                    }
                    shadowTop={props.fixedBottom}
                >
                    {announcementChildren}
                    <Grid container fluid={fluid}>
                        <BoxEdges py={noPadding ? 0 : 12}>
                            <Grid row alignItems="center" noGutters>
                                {otherChildren}
                            </Grid>
                        </BoxEdges>
                    </Grid>
                </BoxEdges>
            </header>
        </NavigationContext.Provider>
    )
}

const useNavigationContextContext = () => {
    const context = useContext(NavigationContext)

    if (!context) {
        throw new Error(
            `Navigation compound components cannot be rendered outside the <Navigation /> component`
        )
    }
    return context
}

const Announcement: React.FC = (props: any) => {
    useNavigationContextContext()

    return props.children
}

Announcement.displayName = "Announcement"
Navigation.Announcement = Announcement

interface IBrandProps {
    children?: React.ReactNode
}
const Brand: React.FC<IBrandProps> = (props) => {
    const { classes } = useNavigationContextContext()
    const { children } = props
    if (!children) {
        return null
    }

    return (
        <Grid item className={classNames(classes.navigationBrand)} auto>
            {children}
        </Grid>
    )
}

Navigation.Brand = Brand

const NavigationNavContext = createContext<INavigationContext | null>(null)

interface INavigationNavProps {
    align?: "left" | "right"
    fill?: boolean
    noPadding?: boolean
}

const Nav = React.forwardRef<HTMLUListElement, React.PropsWithChildren<INavigationNavProps>>(
    (props, ref) => {
        const { classes } = useNavigationContextContext()

        const containerClasses = classNames(classes.navigationNavItems, classes[`${props.align}`], {
            [classes.noPadding]: props.noPadding,
        })
        const navigationClasses = classNames(classes.navigationNavUl, classes[`${props.align}`])

        const navigationNavContextValue = useMemo(() => ({ classes }), [classes])

        return (
            <NavigationNavContext.Provider value={navigationNavContextValue}>
                <Grid item className={containerClasses} auto={!props.fill}>
                    <ul className={navigationClasses} ref={ref}>
                        {props.children}
                    </ul>
                </Grid>
            </NavigationNavContext.Provider>
        )
    }
)
if (devMode) {
    Nav.displayName = "Navigation.Nav"
}

Navigation.Nav = Nav

const useNavigationNavContextContext = () => {
    const context = useContext(NavigationNavContext)

    if (!context) {
        throw new Error(
            `Navigation.Nav compound components cannot be rendered outside a <Navigation.Nav /> component`
        )
    }
    return context
}

interface INavigationNavItemProps {
    active?: boolean
    className?: string
    onClick?: () => void
    children: React.ReactNode
}
const NavItem: React.FC<INavigationNavItemProps> = (props) => {
    const { classes } = useNavigationNavContextContext()

    const { onClick, active, className } = props

    const myClassNames = useMemo(() => {
        return classNames(
            classes.navigationNavItem,
            {
                [classes.navigationNavItemActive]: props.active,
            },
            className
        )
    }, [active, className])

    return (
        <li className={myClassNames} onClick={onClick}>
            {props.children}
        </li>
    )
}

Navigation.NavItem = NavItem

export default Navigation
export { Navigation }
