import React from "react"
import defaultsOrg from "../defaults"
import { makeStyles } from "../utils/styles/makeStyles"

const styles = (defaults: typeof defaultsOrg) => ({
    "@global": {
        a: {
            color: defaults.colors.primary[500],
            cursor: "pointer",
            fontSize: defaults.fontSizes[16],
            fontWeight: defaults.fontWeights.medium,
            textDecoration: "underline",
            transition: "all",
            transitionDuration: ".2s",

            "&:active": {
                color: defaults.colors.primary[600],
            },
            "&:focus": {
                color: defaults.colors.primary[600],
                outline: 0,
            },
            "&:hover": {
                color: defaults.colors.primary[600],
            },
        },
        body: {
            backgroundColor: defaults.colors.neutrals[200],
        },
        "html, body": {
            "-webkit-font-smoothing": "antialiased",
            "-webkit-tap-highlight-color": "rgba(255, 255, 255, 0)",
            fontFamily: "'Calibre', Arial, sans-serif",
            fontSize: "100%",
            minHeight: 0,
            minWidth: 0,
            textRendering: "optimizeLegibility",
            position: "relative",
        },
        h1: {
            fontFamily: defaults.fontFamilies.secondary,
            fontWeight: defaults.fontWeights.normal,
            fontSize: defaults.fontSizes[24],
            lineHeight: defaults.lineHeights.s,
        },
        h2: {
            fontFamily: defaults.fontFamilies.secondary,
            fontWeight: defaults.fontWeights.normal,
            fontSize: defaults.fontSizes[24],
            lineHeight: defaults.lineHeights.s,
        },
        h3: {
            fontFamily: defaults.fontFamilies.secondary,
            fontWeight: defaults.fontWeights.normal,
            fontSize: defaults.fontSizes[20],
            lineHeight: defaults.lineHeights.s,
        },
        h4: {
            fontFamily: defaults.fontFamilies.secondary,
            fontWeight: defaults.fontWeights.normal,
            fontSize: defaults.fontSizes[16],
            lineHeight: defaults.lineHeights.s,
        },
        h5: {
            fontFamily: defaults.fontFamilies.secondary,
            fontWeight: defaults.fontWeights.normal,
            fontSize: defaults.fontSizes[13],
            lineHeight: defaults.lineHeights.s,
        },
        h6: {
            fontFamily: defaults.fontFamilies.secondary,
            fontWeight: defaults.fontWeights.normal,
            fontSize: defaults.fontSizes[12],
            lineHeight: defaults.lineHeights.s,
        },
        p: {
            fontSize: defaults.fontSizes[16],
            lineHeight: defaults.lineHeights.l,
            margin: 0,
            "& + p": {
                marginTop: "1em",
            }
        },
        strong: {
            fontWeight: defaults.fontWeights.medium,
        },
        b: {
            fontWeight: defaults.fontWeights.medium,
        },
        li: {
            fontSize: defaults.fontSizes[16],
            lineHeight: defaults.lineHeights.l,
        },
        blockquote: {
            borderLeft: `${defaults.spacing[4]}px solid ${defaults.colors.neutrals[500]}`,
            marginLeft: defaults.spacing[0],

            "& p": {
                marginLeft: defaults.spacing[16],
                fontSize: defaults.fontSizes[16],
                fontStyle: "italic",
            },
        },
        code: {
            fontSize: defaults.fontSizes[13],
            backgroundColor: defaults.colors.neutrals[400],
            padding: `${defaults.spacing[4]}px ${defaults.spacing[8]}px`,
            borderRadius: defaults.cornerRadius.large,
        },
        kbd: {
            backgroundColor: defaults.colors.primary[200],
            borderRadius: defaults.cornerRadius.normal,
            boxShadow: `inset 0 -1px 0 ${defaults.colors.neutrals[600]}`,
            fontFamily: defaults.fontFamilies.primary,
            fontSize: defaults.fontSizes[11],
            fontWeight: defaults.fontWeights.medium,
            marginLeft: defaults.spacing[2],
            marginRight: defaults.spacing[2],
            padding: `${defaults.spacing[2]}px 3px ${defaults.spacing[2]}px 3px`,
            textTransform: "uppercase",
        },
        svg: {
            "&:only-child": {
                display: "block",
            },
        },
        pre: {
            whiteSpace: "pre-wrap",
            fontSize: defaults.fontSizes[13],
            margin: 0,
            lineHeight: defaults.lineHeights.xl,
        },
        ":focus-visible": {
            outline: "none",
        },
    },
})

const useStyles = makeStyles(styles, "GlobalStyles", true)
export const GlobalStyles: React.FC = () => {
    useStyles()

    return null
}
