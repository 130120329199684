import { shallowEqual } from "fast-equals"
import React, { useCallback, useEffect, useRef, useState } from "react"
import devMode from "../../utils/devMode"
import scrollElementIntoView from "../../utils/scrollElementIntoView"
import useCallbackRef from "../../utils/useCallbackRef"

export type AutocompleteOption = {
    title: string
    inputValue: string
}

const defaultFiltering = (options: Array<string | AutocompleteOption>, inputValue: string) => {
    return options.filter((option) =>
        typeof option === "string"
            ? option.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
            : option.inputValue.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    )
}

export const useCombobox = (
    value: string | AutocompleteOption,
    onChange: (value: string | AutocompleteOption, newValue?: string) => void,
    options: Array<string | AutocompleteOption>,
    filterOptions?: (
        options: Array<string | AutocompleteOption>,
        inputValue: string
    ) => Array<string | AutocompleteOption>,
    onBlur?: (e: React.FocusEvent) => void
) => {
    const filterOptionsFn = filterOptions ? filterOptions : defaultFiltering

    // refs
    const inputRef = useRef<HTMLInputElement>(null)
    const optionsRef = useRef<{ [itemIndex: string]: HTMLLIElement }>({})
    const tabbedRef = useRef(false)

    // internal states
    const [open, setOpen] = useState(false)
    const [inputValue, setInputValue] = useState(() => {
        const option = options.find((opt) => opt === value)
        if (option) {
            return typeof option === "string" ? option : option.inputValue
        }

        return ""
    })
    const [focusedItemIndex, setFocusedItemIndex] = useState(-1)
    const [filteredOptions, setFilteredOptions] = useState(options)

    const resetCombobox = useCallbackRef(() => {
        setFocusedItemIndex(-1)
        setInputValue(typeof value === "string" ? value : value.inputValue)
        setFilteredOptions(options)
    })

    useEffect(() => {
        resetCombobox()
    }, [options, resetCombobox])

    useEffect(() => {
        setInputValue(typeof value === "string" ? value : value.inputValue)
    }, [value])

    useEffect(() => {
        if (open) {
            const itemIndex = options.findIndex((opt) =>
                typeof opt === "string" ? opt === inputValue : opt.inputValue === inputValue
            )

            window.setTimeout(() => {
                if (itemIndex > -1) {
                    const itemRef = optionsRef.current[itemIndex]
                    if (itemRef) {
                        scrollElementIntoView(itemRef, true, "up")
                    }
                }
                setFocusedItemIndex(itemIndex)
            })
        } else if (!tabbedRef.current) {
            resetCombobox()
        }
    }, [open])

    const setOptionRef = useCallback((elm: HTMLLIElement) => {
        if (!elm) {
            return
        }

        const elmIndex = elm.dataset.index ? parseInt(elm.dataset.index) : -1
        if (elmIndex !== -1) {
            optionsRef.current[elmIndex] = elm
        }
    }, [])

    const handleOnInputChange = useCallbackRef((e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)

        const nextFilteredOptions = filterOptionsFn(options, e.target.value)
        if (!shallowEqual(nextFilteredOptions, filteredOptions)) {
            setFilteredOptions(nextFilteredOptions)
            if (nextFilteredOptions.length === 1) {
                setFocusedItemIndex(0)
            } else {
                setFocusedItemIndex(-1)
            }
        }
    })

    const handleOnClick = useCallbackRef(() => {
        inputRef.current?.focus()

        if (!open) {
            setOpen(true)
        }
    })

    const handleInputOnBlur = useCallbackRef((e: React.FocusEvent) => {
        if (open) {
            setOpen(false)
        }
        if (onBlur) {
            if (e.persist) {
                e.persist()
            }
            onBlur(e)
        }
    })

    const updateFocus = (direction: "next" | "prev") => {
        const lastHasFocus = focusedItemIndex === filteredOptions.length - 1
        const firstHasFocus = focusedItemIndex <= 0
        let nextFocusIndex = focusedItemIndex
        if (direction === "next" && !lastHasFocus) {
            nextFocusIndex++
            scrollElementIntoView(optionsRef.current[nextFocusIndex], true, "down")
        } else if (direction === "prev" && !firstHasFocus) {
            nextFocusIndex--
            scrollElementIntoView(optionsRef.current[nextFocusIndex], true, "up")
        }

        if (nextFocusIndex !== focusedItemIndex) {
            setFocusedItemIndex(nextFocusIndex)
        }
    }

    const handleInputKeyDown = useCallbackRef((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!inputRef.current) {
            return
        }

        const keyPressed = e.key

        switch (keyPressed) {
            case "Tab":
                tabbedRef.current = true
            // eslint-disable-next-line no-fallthrough
            case "Enter": {
                if (focusedItemIndex !== -1) {
                    const value = filteredOptions[focusedItemIndex]
                    const changeEvent = e as unknown as React.ChangeEvent<HTMLInputElement>
                    changeEvent.target = inputRef.current

                    if (options.indexOf(value) === -1) {
                        onChange("", typeof value === "string" ? value : value.inputValue)
                    } else if (typeof value === "string") {
                        onChange(value)
                    } else {
                        onChange(value)
                    }
                } else {
                    resetCombobox()
                }

                setOpen(false)
                break
            }
            case "ArrowUp": {
                e.preventDefault()
                if (open) {
                    updateFocus("prev")
                }
                break
            }
            case "ArrowDown": {
                e.preventDefault()
                if (open) {
                    updateFocus("next")
                }
                break
            }
            case "Escape": {
                e.stopPropagation()
                setInputValue(typeof value === "string" ? value : value.inputValue)
                setOpen(false)
                break
            }
            default: {
                if (!open) {
                    setOpen(true)
                }
            }
        }
    })

    const handleItemMouseEnter = useCallbackRef((e: React.MouseEvent<HTMLLIElement>) => {
        const itemIndex = e.currentTarget.dataset.index
        setFocusedItemIndex(itemIndex ? parseInt(itemIndex) ?? -1 : -1)
    })

    const handleItemMouseDown = useCallbackRef((e: React.MouseEvent<HTMLLIElement>) => {
        const itemIndex = e.currentTarget.dataset.index
        if (itemIndex) {
            const value = filteredOptions[parseInt(itemIndex)]
            if (options.indexOf(value) === -1) {
                onChange("", typeof value === "string" ? value : value.inputValue)
            } else if (typeof value === "string") {
                onChange(value)
            } else {
                onChange(value)
            }
        } else if (devMode) {
            // tslint:disable-next-line:no-console
            console.warn(`Out of index value!`)
        }

        setOpen(false)
    })

    return {
        setOptionRef,
        handleOnInputChange,
        handleOnClick,
        handleInputOnBlur,
        handleInputKeyDown,
        handleItemMouseEnter,
        handleItemMouseDown,
        inputValue,
        inputRef,
        open,
        filteredOptions,
        focusedItemIndex,
    }
}
