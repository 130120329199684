import classNames from "classnames"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import stickybits, { StickyBits } from "stickybits"
import { Grid, Spacer } from ".."
import { Navigation } from "../Navigation"
import { makeStyles } from "../utils/styles/makeStyles"
import useMeasure from "../utils/useMeasure"
import { Section } from "./Section"
import { SectionContext } from "./SectionContainer"
import styles from "./styles"

interface ISectionHeaderProps {
    header?: JSX.Element
    stickyOffset: number
    backgroundColor: string
    onHeaderHeightChange: (height: number) => void
    children: React.ReactNode
}

const useStyles = makeStyles(styles, "SectionHeader", true)
const renderNavigation = (child: React.ReactComponentElement<typeof Section>) => {
    const classes = useStyles()
    const { activeSection, onSelect } = useContext(SectionContext)

    const handleOnClick = useCallback(() => {
        if (child) {
            onSelect(child.props.sectionKey)
        }
    }, [onSelect])

    const navigationItemClasses = useMemo(() => {
        if (child) {
            return classNames(classes.sectionNavigationItem, {
                [classes.sectionNavigationItemActive]: activeSection === child.props.sectionKey,
            })
        } else {
            return undefined
        }
    }, [activeSection])

    if (!child) {
        return null
    }

    return (
        <Navigation.NavItem className={navigationItemClasses} onClick={handleOnClick}>
            <Grid container fluid>
                <Grid
                    row
                    alignItems="center"
                    className={classes.sectionNavigationItemContentContainer}
                    noGutters
                >
                    {child.props.icon && (
                        <>
                            <Grid item auto>
                                {child.props.icon}
                            </Grid>
                            <Grid item auto>
                                <Spacer size={8} />
                            </Grid>
                        </>
                    )}
                    <Grid item auto>
                        {child.props.title}
                    </Grid>
                </Grid>
            </Grid>
        </Navigation.NavItem>
    )
}

export const SectionHeader: React.FC<ISectionHeaderProps> = (props) => {
    const { children, backgroundColor } = props
    const classes = useStyles()
    const [measureRef, { height }] = useMeasure()
    const [headerRef, setHeaderRef] = useState<HTMLDivElement | null>(null)
    const stickyBitsRef = useRef<null | StickyBits>(null)
    const headerRefCallback = useCallback((ref: HTMLDivElement) => {
        setHeaderRef(ref)
    }, [])

    const headerStyle = useMemo(
        () => ({
            backgroundColor,
        }),
        []
    )

    useEffect(() => {
        if (props.onHeaderHeightChange && height) {
            props.onHeaderHeightChange(height)
        }
    }, [height])

    useEffect(() => {
        if (headerRef) {
            stickyBitsRef.current = stickybits(headerRef, {
                stickyBitStickyOffset: props.stickyOffset,
            })
        }

        return () => {
            if (stickyBitsRef.current) {
                stickyBitsRef.current.cleanup()
            }
        }
    }, [headerRef, props.stickyOffset])

    return (
        <Grid ref={headerRefCallback} row className={classes.sectionHeader} style={headerStyle}>
            <Grid item ref={measureRef}>
                <Grid row>
                    <Grid item>{props.header}</Grid>
                </Grid>

                <Grid row className={classes.sectionNavigation} noGutters>
                    <Navigation color="transparent" noPadding noZIndex>
                        <Navigation.Nav noPadding>
                            {React.Children.map(children, renderNavigation)}
                        </Navigation.Nav>
                    </Navigation>
                </Grid>
            </Grid>
        </Grid>
    )
}
