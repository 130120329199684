import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    carousel: {},
    slider: {
        paddingLeft: defaults.spacing[64],
        paddingRight: defaults.spacing[64],
        position: "relative",
        userSelect: "none",
        overflow: "hidden",

        "&:before": {
            content: "''",
            position: "absolute",
            left: 0,
            bottom: 0,
            top: 0,
            width: defaults.spacing[64],
            background: `linear-gradient(90deg, ${defaults.colors.neutrals[200]}, rgba(255,255,255,0))`,
            zIndex: 1,
        },
        "&:after": {
            content: "''",
            position: "absolute",
            right: 0,
            bottom: 0,
            top: 0,
            width: defaults.spacing[64],
            background: `linear-gradient(-90deg, ${defaults.colors.neutrals[200]}, rgba(255,255,255,0))`,
            zIndex: 1,
        },

        "&:focus": {
            outline: "none",
        },

        "& .flickity-viewport": {
            position: "relative",
            height: "100%",
        },

        "& .flickity-slider": {
            position: "absolute",
            width: "100%",
            height: "100%",
        },

        "& .flickity-button": {
            position: "absolute",
            background: defaults.colors.primary[500],
            border: "none",
            color: "white",
            cursor: "pointer",
            zIndex: 2,
            padding: 7,

            "&:hover": {
                opacity: "0.9",
            },

            "&:focus": {
                outline: "none",
            },

            "&:active": {
                opacity: "0.6",
            },

            "&:disabled": {
                display: "none",
            },
        },

        "& .flickity-button-icon": {
            fill: "currentColor",
        },

        "& .flickity-prev-next-button": {
            top: "50%",
            width: 24,
            height: 24,
            borderRadius: "50%",
            transform: "translateY(-50%)",

            "&.previous": {
                left: 0,
            },

            "&.next": {
                right: 0,
            },
        },
    },
})

export default styles
