export default (el: Element | HTMLElement, fully?: boolean, direction: "down" | "up" = "up") => {
    const { top, bottom } = el.getBoundingClientRect()
    const height = bottom - top
    const parentEl = el.parentElement
    if (!parentEl) {
        return
    }

    const { top: parentTop, bottom: parentBottom } = parentEl.getBoundingClientRect()
    const parentViewport = {
        height: parentEl.clientHeight,
        width: parentEl.clientWidth,
    }

    const isInView = top >= parentTop && top <= parentTop + parentViewport.height
    const isFullyInView =
        top + height >= parentTop && top + height <= parentTop + parentViewport.height
    if (!isInView || (fully && !isFullyInView)) {
        if (direction === "up") {
            parentEl.scrollTop = top + parentEl.scrollTop - parentTop
        } else {
            parentEl.scrollTop = bottom + parentEl.scrollTop - parentBottom
        }
    }
}
