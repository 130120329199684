import React, { isValidElement, useCallback, useState } from "react"
import { defaults } from "../"
import devMode from "../utils/devMode"
import { Section, SectionWithRef } from "./Section"
import { SectionContainer } from "./SectionContainer"
import { SectionContent } from "./SectionContent"
import { SectionHeader } from "./SectionHeader"
import { SectionPane } from "./SectionPane"

if ("IntersectionObserver" in window === false) {
    // tslint:disable-next-line:no-var-requires
    require("intersection-observer") // polyfill
}

interface ISectionsProps {
    header?: JSX.Element
    activeSection?: string
    defaultSection?: string
    stickyOffset?: number
    backgroundColor?: string
    onSelect?: (section: string) => void
    onIntersection?: (section: string) => void
    children?: React.ReactNode
}

interface ISectionsComponent
    extends React.ForwardRefExoticComponent<
        React.PropsWithChildren<ISectionsProps> & React.RefAttributes<HTMLDivElement>
    > {
    Section: React.Component
}

interface ISectionRef {
    key: string
    ref: React.RefObject<HTMLDivElement>
}

const scrollToSection = (element: HTMLElement, offset: number) => {
    const position = window.pageYOffset + element.getBoundingClientRect().top - offset

    window.scrollTo({
        top: position,
        behavior: "smooth",
    })
}

const setSectionProps = (
    child: React.ReactComponentElement<typeof Section>
): ISectionRef | null => {
    if (!child) {
        return null
    }

    if (!child.props.sectionKey) {
        // tslint:disable-next-line: no-console
        console.warn(`There must be a 'sectionKey'`)
        return null
    }

    return { key: child.props.sectionKey, ref: React.createRef() }
}

const getSectionProps = (sections: ISectionRef[] | null | undefined, section: string) => {
    if (!sections) {
        return null
    }
    return sections.find((sectionObject) => sectionObject.key === section)
}

const renderSections = (child: React.ReactComponentElement<typeof SectionWithRef>) => {
    if (!child) {
        return null
    }

    const childProps = { ...child.props }

    // @ts-ignore - title is only optional in this context
    delete childProps.title
    delete childProps.icon
    return <SectionPane {...childProps}>{childProps.children}</SectionPane>
}

export const Sections = React.forwardRef<HTMLDivElement, ISectionsProps>((props, ref) => {
    const {
        backgroundColor = defaults.colors.neutrals[200],
        stickyOffset = 0,
        activeSection,
        header,
        children,
        onSelect,
        onIntersection,
    } = props
    let { defaultSection } = props
    const [headerHeight, setHeaderHeight] = useState(0)

    const sectionObjects = React.Children.map(children, setSectionProps)

    const handleOnSelect = useCallback(
        (section: string) => {
            const sectionRef = getSectionProps(sectionObjects, section)
            if (sectionRef && sectionRef.ref.current) {
                scrollToSection(sectionRef.ref.current, stickyOffset + headerHeight)
            }

            if (onSelect) {
                onSelect(section)
            }
        },
        [onSelect, sectionObjects, stickyOffset, headerHeight]
    )

    const handleHeaderHeightChange = useCallback(
        (height: number) => {
            setHeaderHeight(height)
        },
        [setHeaderHeight]
    )

    const handleOnIntersection = useCallback(
        (section: string) => {
            if (onIntersection) {
                onIntersection(section)
            }
        },
        [onIntersection]
    )

    // Remove undefined or null from children
    const childrenFiltered = React.Children.toArray(children).filter((o) => o)

    // Add ref to each of the filtered children
    const sectionsWithRef = React.Children.map(childrenFiltered, (child, index) => {
        if (!sectionObjects) {
            return null
        }

        if (!isValidElement(child)) {
            return null
        }

        return React.cloneElement(child as React.ReactElement, {
            sectionRef: sectionObjects[index].ref,
        })
    })

    if (!defaultSection && sectionObjects && sectionObjects.length > 0) {
        defaultSection = sectionObjects[0].key
    }

    return (
        <SectionContainer
            ref={ref}
            activeSection={activeSection}
            defaultSection={defaultSection}
            onSelect={handleOnSelect}
            onIntersection={handleOnIntersection}
        >
            <SectionHeader
                header={header}
                backgroundColor={backgroundColor}
                stickyOffset={stickyOffset}
                onHeaderHeightChange={handleHeaderHeightChange}
            >
                {children}
            </SectionHeader>
            <SectionContent>{React.Children.map(sectionsWithRef, renderSections)}</SectionContent>
        </SectionContainer>
    )
}) as ISectionsComponent

if (devMode) {
    Sections.displayName = "Sections"
}
