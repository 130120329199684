import React, { useRef, useState } from "react"

interface IFilePreviewImageProps {
    src: string
    scale?: number
    onImageLoaded?: () => void
}

export const FilePreviewImage: React.FC<IFilePreviewImageProps> = (props) => {
    // tslint:disable-next-line: no-empty
    const { src, scale = 1, onImageLoaded = () => {} } = props

    const dragging = useRef(false)
    const initialOffset = useRef({ x: 0, y: 0 })
    const [offset, setOffset] = useState({ x: 0, y: 0 })

    return (
        <img
            role="filepreview-image"
            src={src}
            onLoad={onImageLoaded}
            style={{
                cursor: "move",
                transform: `scale(${scale}) translate(${offset.x}px, ${offset.y}px)`,
            }}
            draggable={false}
            onMouseDown={(e) => {
                initialOffset.current = {
                    x: e.clientX,
                    y: e.clientY,
                }
                dragging.current = true
            }}
            onMouseUp={() => {
                dragging.current = false
            }}
            onMouseMove={(e) => {
                if (!dragging.current) {
                    return
                }

                const nextOffset = {
                    x: e.clientX - initialOffset.current.x + offset.x,
                    y: e.clientY - initialOffset.current.y + offset.y,
                }

                initialOffset.current.x = e.clientX
                initialOffset.current.y = e.clientY

                setOffset(nextOffset)
            }}
        />
    )
}
