import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => {
    const colors = [
        "primary",
        "neutrals",
        "accents-success",
        "accents-warning",
        "accents-error",
        "accents-notice",
    ]
    const shades = [100, 200, 300, 400, 500, 600, 700, 800, 900]

    const getColorStyles = (prefix: string, property: string) => {
        const ArrayOfStyles = colors
            .map((color) => {
                return shades.map((shade) => {
                    let colorProp

                    if (color.includes("-")) {
                        const [accents, accentColor] = color.split("-")

                        // @ts-ignore
                        colorProp = defaults.colors[accents][accentColor]
                    } else {
                        // @ts-ignore
                        colorProp = defaults.colors[color]
                    }

                    if (!colorProp[shade]) {
                        return
                    }

                    return {
                        [`${prefix}-${color}-${shade}`]: {
                            [property]: colorProp[shade],
                        },
                    }
                })
            })
            .flat()

        const returnObject = Object.assign({}, ...ArrayOfStyles)

        return returnObject
    }

    const kindStyles = getColorStyles("kind", "backgroundColor")
    const frameStyles = getColorStyles("frame", "borderColor")

    return {
        card: {
            backgroundColor: defaults.colors.neutrals[100],
            borderRadius: defaults.cornerRadius.normal,
            boxShadow: defaults.shadows.bottom,
        },
        frame: {
            border: `1px solid`,
        },

        flatten: {
            boxShadow: "none",
        },
        ...kindStyles,
        ...frameStyles,
    } as Record<any, string>
}

export default styles
