import useEventListener from "@use-it/event-listener"
import classNames from "classnames"
import { useAnimation } from "framer-motion"
import React, { useMemo } from "react"
import { useContext, useEffect, useRef, useState } from "react"
import { BoxEdges } from "../BoxEdges"
import { Button } from "../Button"
import { GridBreakpoints } from "../defaults"
import { Grid } from "../Grid"
import { Heading } from "../Heading"
import { CrossIcon } from "../Icon"
import Backdrop from "../Modal/Backdrop"
import { Portal } from "../Portal"
import { Separator } from "../Separator"
import { Snackbar } from "../Snackbar"
import { ThemeContext } from "../ThemeProvider"
import { makeStyles } from "../utils/styles/makeStyles"
import useCallbackRef from "../utils/useCallbackRef"
import { useIsMounted } from "../utils/useIsMounted"
import styles from "./styles"

interface IModalProps {
    /**
     * Triggers open of the modal
     */
    open: boolean
    /**
     * Called before trying to close the modal, if the callback returns false, it'll cancel closing the modal
     */
    onClose?: () => void | boolean
    /**
     * Called after the modal has closed and animations has completed
     */
    onClosed?: () => void
    /**
     * Called after the modal has opened and animations has completed
     */
    onOpened?: () => void
    children: React.ReactNode
    /**
     * Disable close trigger when clicking outside of the modal (The Backdrop)
     */
    disableBackdropClose?: boolean
    /**
     * Disable escape key close
     */
    disableEscapeKeyClose?: boolean
    /**
     * Do not show a close button (X)
     */
    removeClose?: boolean
    /**
     * Trigger a fullscreen modal
     */
    fullscreen?: boolean
    /**
     *
     */
    transparent?: boolean
    /**
     * Size of the modal
     * @default sm
     */
    size?: GridBreakpoints
}

interface IModalHeaderProps {
    prefix?: JSX.Element
    noSpacing?: boolean
    children: React.ReactNode
}

const ModalHeader: React.FC<IModalHeaderProps> = (props) => {
    const { defaults } = useContext(ThemeContext)
    return (
        <BoxEdges
            shadowCustom={defaults.shadows.bottom}
            px={props.noSpacing ? undefined : 32}
            py={props.noSpacing ? undefined : 24}
        >
            <Grid row noGutters alignItems="center">
                {props.prefix && (
                    <Grid item auto>
                        <BoxEdges mr={12}>{props.prefix}</BoxEdges>
                    </Grid>
                )}

                <Grid item>
                    <Heading level={4}>
                        <>{props.children}</>
                    </Heading>
                </Grid>
            </Grid>
        </BoxEdges>
    )
}

interface IModalContentProps {
    noPadding?: boolean
    children: React.ReactNode
}

const ModalContent: React.FC<IModalContentProps> = (props) => {
    const { noPadding = false } = props

    if (noPadding && props.children) {
        return <>{props.children}</>
    }

    return <BoxEdges p={32}>{props.children}</BoxEdges>
}

interface IModalFooterProps {
    children?: React.ReactNode
}

const ModalFooter: React.FC<IModalFooterProps> = (props) => {
    return (
        <>
            <Separator />
            <BoxEdges p={16}>{props.children}</BoxEdges>
        </>
    )
}

interface IModalActionsProps {
    children?: React.ReactNode
}

const ModalActions: React.FC<IModalActionsProps> = (props) => {
    return (
        <Grid row alignItems={"center"} justify="flex-end" noGutters>
            {React.Children.map(props.children, (c) => (
                <Grid item auto>
                    <BoxEdges pl={16}>{c}</BoxEdges>
                </Grid>
            ))}
        </Grid>
    )
}

interface IModalSnackbarProps {
    color?: string
    children?: React.ReactNode
}

const ModalSnackbar: React.FC<IModalSnackbarProps> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.primary[500] } = props

    return <Snackbar color={color}>{props.children}</Snackbar>
}

const useStyles = makeStyles(styles, "Modal", true)
const Modal = (props: IModalProps) => {
    const { onClose, onClosed, onOpened, size = "sm", open } = props

    const { defaults } = useContext(ThemeContext)
    const classes = useStyles()
    const [isOpen, setIsOpen] = useState(false)
    const [isBackdropOpen, setIsBackdropOpen] = useState(false)
    const [isGoingToClose, setIsGoingToClose] = useState(false)
    const prevIsOpen = useRef(isOpen)
    const activeElement = useRef<HTMLElement | null>(null)
    const animationControls = useAnimation()
    const isMounted = useIsMounted()
    const innerContentRef = useRef<HTMLDivElement>(null)

    const closeIcon = useMemo(() => <CrossIcon color={defaults.colors.neutrals[100]} />, [defaults])

    useEffect(() => {
        if (!isOpen && open) {
            activeElement.current = document.activeElement as HTMLElement
            setIsOpen(true)
        } else if (isOpen && !open) {
            setIsGoingToClose(true)
        }
    }, [open])

    useEffect(() => {
        if (!isGoingToClose) {
            return
        }

        if (isOpen) {
            if (!onClose) {
                setIsOpen(false)
            } else {
                const onCloseResult = onClose()
                if (onCloseResult) {
                    setIsOpen(false)
                }
            }
        }

        setIsGoingToClose(false)
    }, [isGoingToClose])

    const handleEscapeKeyDown = useCallbackRef((e: KeyboardEvent) => {
        if (!props.disableEscapeKeyClose && isOpen) {
            if (e.key === "Escape") {
                setIsGoingToClose(true)
            }
        }
    })
    useEventListener("keydown", handleEscapeKeyDown)

    const handleBackdropClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (!props.disableBackdropClose && !props.fullscreen) {
            if (innerContentRef.current) {
                const targetElm = e.target as HTMLElement
                if (innerContentRef.current.contains(targetElm)) {
                    return
                }
            }

            setIsGoingToClose(true)
        }
    }

    const handleCloseClick = () => {
        setIsGoingToClose(true)
    }

    useEffect(() => {
        if (isOpen === prevIsOpen.current) {
            return
        }

        if (isOpen) {
            setIsBackdropOpen(true)
        }

        prevIsOpen.current = isOpen
    }, [isOpen])

    useEffect(() => {
        if (isBackdropOpen && isOpen) {
            animationControls.start("open").finally(() => {
                if (onOpened) {
                    onOpened()
                }
            })
        } else if (isBackdropOpen && !isOpen) {
            animationControls.start("close").finally(() => {
                if (onClosed) {
                    onClosed()
                }

                if (activeElement && activeElement.current) {
                    activeElement.current.focus()
                }

                if (isMounted.current) {
                    setIsBackdropOpen(false)
                }
            })
        }
    }, [isBackdropOpen, isOpen])

    const modalClasses = classNames([classes.modal], {
        [classes.fullscreen]: props.fullscreen,
        [classes.transparent]: props.transparent,
        // @ts-ignore : @TODO
        [classes[`size-${size}`]]: !props.fullscreen ? size : false,
    })

    return (
        <Portal>
            <Backdrop
                onClick={handleBackdropClick}
                open={isBackdropOpen}
                animationControls={animationControls}
            >
                <Grid row alignItems="center" justify="center" noGutters>
                    <div className={modalClasses}>
                        {!props.removeClose && (
                            <Button
                                color="primary"
                                tight
                                light={!props.fullscreen}
                                className={classNames(classes.close)}
                                onClick={handleCloseClick}
                                size={"large"}
                                prefix={closeIcon}
                                role="closeModal"
                            />
                        )}
                        {!props.fullscreen && (
                            <BoxEdges shadow={"neutrals"} shadowWeight={800} ref={innerContentRef}>
                                {props.children}
                            </BoxEdges>
                        )}
                        {props.fullscreen && props.children}
                    </div>
                </Grid>
            </Backdrop>
        </Portal>
    )
}

Modal.Header = ModalHeader
Modal.Content = ModalContent
Modal.Actions = ModalActions
Modal.Snackbar = ModalSnackbar
Modal.Footer = ModalFooter

export { Modal }
export default Modal
