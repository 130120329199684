import defaultsOrg from "../defaults"

export default (defaults: typeof defaultsOrg) => ({
    container: {
        "& $dismis": {
            position: "absolute",
            right: defaults.spacing[24],
            top: defaults.spacing[12],
            zIndex: 1,
        },
    },

    primary: {
        "& $button": {
            color: defaults.colors.primary[700],
        },
    },

    neutrals: {
        "& $button": {
            color: defaults.colors.neutrals[700],
        },
    },

    "accents-success": {
        "& $button": {
            color: defaults.colors.accents.success[700],
        },
    },

    "accents-warning": {
        "& $button": {
            color: defaults.colors.accents.warning[700],
        },
    },

    "accents-error": {
        "& $button": {
            color: defaults.colors.accents.error[700],
        },
    },

    "accents-notice": {
        "& $button": {
            color: defaults.colors.accents.notice[700],
        },
    },

    dismis: {
        "& $innerContainer": {
            paddingRight: defaults.spacing["32"],
        },
    },

    innerContainer: {},

    button: {},
})
