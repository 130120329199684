import classNames from "classnames"
import React, { useMemo } from "react"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ISidebarProps {
    open: boolean
    children: React.ReactNode
}

const useStyles = makeStyles(styles, "Sidebar", true)
export const Sidebar: React.FC<ISidebarProps> = (props) => {
    const classes = useStyles()

    const containerClasses = useMemo(() => {
        return classNames(classes.container, { [classes.open]: props.open })
    }, [props.open])

    return <aside className={containerClasses}>{props.children}</aside>
}
