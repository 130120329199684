import defaultsOrg from "../defaults"
const styles = (defaults: typeof defaultsOrg) => ({
    content: {
        maxWidth: 350,
        width: "max-content",
    },

    noWrap: {
        whiteSpace: "nowrap",
    },

    "underline-none": {
        textDecoration: "none",
    },

    "underline-dashed": {
        textDecoration: "underline",
        textUnderlineOffset: `${defaults.spacing[4]}px`,
        textDecorationStyle: "dashed",
        textDecorationColor: defaults.colors.neutrals[500],
    },
})

export default styles
