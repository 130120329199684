import React from "react"
import { BoxEdges } from "../"

interface ITabContent {
    children: React.ReactNode
}
export const TabContent: React.FC<ITabContent> = (props) => {
    const { children } = props

    return (
        <BoxEdges py={24} role="TabContent">
            {children}
        </BoxEdges>
    )
}
