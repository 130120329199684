import React from "react"
import { Icon } from "../types"

const VisaColoredIcon: React.FC<Icon> = (props) => {
    const { size = 24, ...otherProps } = props
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                d="M16.7597 3.90067L12.6464 14.9775L12.1969 12.731C11.4364 10.1388 9.05138 7.32246 6.38983 5.92209L10.1575 20.1274H14.5991L21.2011 3.90067H16.7597V3.90067Z"
                fill="#1A1F71"
            />
            <path
                d="M8.82682 3.89999H2.06913L2 4.22821C7.27142 5.57621 10.7626 8.82551 12.1969 12.731L10.7279 5.26545C10.486 4.22798 9.74279 3.93422 8.82682 3.89999Z"
                fill="#F7B600"
            />
        </svg>
    )
}

export { VisaColoredIcon }
