import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const MastercardIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...otherProps } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
        >
            <circle cx="16.515" cy="11.995" r="7.415" fill="currentColor" opacity="0.75" />
            <circle cx="7.415" cy="11.995" r="7.415" fill="currentColor" />
        </svg>
    )
}

export { MastercardIcon }
