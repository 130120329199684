import hexToRgba from "hex-to-rgba"
import React, { useContext } from "react"
import { ThemeContext } from "../ThemeProvider"

export interface BrandmarkProps {
    size?: string | number
    color?: string
}

const Brandmark: React.FC<BrandmarkProps> = ({ size = 32, color }) => {
    const { defaults } = useContext(ThemeContext)

    let colors

    if (!color) {
        colors = {
            primary: defaults.colors.neutrals[900],
            secondary: defaults.colors.neutrals[500],
        }
    } else {
        colors = {
            primary: color,
            secondary: hexToRgba(color, 0.23),
        }
    }
    return (
        <svg viewBox="0 0 96 90" style={{ width: size, height: size }}>
            <path d="M66 90V0L0 36V78L66 90Z" fill={colors.primary} />
            <path d="M72 87V6L96 36V78L72 87Z" fill={colors.secondary} />
        </svg>
    )
}

export { Brandmark }
