import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

export const ZoomInIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { size = 24, color = defaults.colors.neutrals[900], ...otherProps } = props
    return (
        <svg
            width={size}
            height={size}
            color={color}
            {...otherProps}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
            <line x1="11" y1="8" x2="11" y2="14" />
            <line x1="8" y1="11" x2="14" y2="11" />
        </svg>
    )
}
