import React, { useState } from "react"
import devMode from "../utils/devMode"
import { Tab } from "./Tab"
import { TabContainer } from "./TabContainer"
import { TabContent } from "./TabContent"
import { TabNavigation } from "./TabNavigation"
import { TabPane } from "./TabPane"

interface ITabsProps {
    activeTab?: string
    defaultTab?: string
    onSelect?: (tab: string) => void
    children: React.ReactNode
}

interface ITabsComponent
    extends React.ForwardRefExoticComponent<
        React.PropsWithChildren<ITabsProps> & React.RefAttributes<HTMLDivElement>
    > {
    Tab: React.Component
}

const renderTabPane = (child: React.ReactComponentElement<typeof Tab>) => {
    if (!child) {
        return null
    }

    const childProps = { ...child.props }

    // @ts-ignore - title is only optional in this context
    delete childProps.title
    delete childProps.icon

    return <TabPane {...childProps}>{childProps.children}</TabPane>
}

const getTabKey = (child: React.ReactComponentElement<typeof Tab>) => {
    if (!child) {
        return null
    }

    if (!child.props.tabKey) {
        // tslint:disable-next-line: no-console
        console.warn(`There must be a 'tabKey'`)
        return null
    }

    return child.props.tabKey
}

export const Tabs = React.forwardRef<HTMLDivElement, ITabsProps>((props, ref) => {
    const { activeTab, children, onSelect } = props
    let { defaultTab } = props

    const [tabKeys] = useState(() => {
        return React.Children.map(children, getTabKey)
    })

    if (!defaultTab && tabKeys && tabKeys.length > 0) {
        defaultTab = tabKeys[0]
    }

    return (
        <TabContainer ref={ref} activeTab={activeTab} defaultTab={defaultTab} onSelect={onSelect}>
            <TabNavigation>{children}</TabNavigation>
            <TabContent>{React.Children.map(children, renderTabPane)}</TabContent>
        </TabContainer>
    )
}) as ITabsComponent

if (devMode) {
    Tabs.displayName = "Tabs"
}
