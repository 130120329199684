import React, { useContext, useMemo } from "react"
import { BoxEdges, ThemeContext } from "../../"
import { makeStyles } from "../../utils/styles/makeStyles"
import { Icon } from "../types"

interface IRoundedIconProps {
    icon: React.FunctionComponent<Icon>
    backgroundColor?: string
    color?: string
    size?: number
}

const styles = () => ({
    container: {
        display: "flex",
        overflow: "hidden",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",

        "& svg": {
            height: "0.6em",
            width: "0.6em",
        },
    },
})

const useStyles = makeStyles(styles, "RoundedIcon")
export const RoundedIcon: React.FC<IRoundedIconProps> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const classes = useStyles()
    const { color, backgroundColor, icon, size = defaults.fontSizes[18] } = props

    const Icon = useMemo(() => {
        const Icon = icon

        return <Icon color={color} />
    }, [icon, color])

    const boxEdgesStyle = useMemo(() => {
        return {
            fontSize: size,
            height: size,
            lineHeight: size,
            width: size,
            backgroundColor,
        }
    }, [size, backgroundColor])

    return (
        <BoxEdges
            className={classes.container}
            cornerRadius={defaults.cornerRadius.rounded}
            style={boxEdgesStyle}
        >
            {Icon}
        </BoxEdges>
    )
}
