import React, { useContext, useEffect, useState } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { ISO3166_1_alpha2 } from "../types"

interface IFlagProps {
    countryCode: ISO3166_1_alpha2 | "international-black" | "international-white" | "international"
    color?: string
    size?: string | number
    height?: number
    width?: number
}

export const FlagIcon: React.FC<IFlagProps> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { countryCode, size = defaults.fontSizes[14], ...rest } = props

    const [rawFlag, setRawFlag] = useState<any>(null)

    useEffect(() => {
        const importPromise = import(`./flags/${countryCode.toLowerCase()}.svg`)
        importPromise.then((res) => {
            setRawFlag(res.default)
        })
    }, [])

    if (!rawFlag) {
        return null
    }

    const Flag = rawFlag

    if (typeof Flag === "string") {
        return <img src={Flag} height={size} width={size} {...rest} />
    } else {
        return <Flag height={size} width={size} {...rest} />
    }
}
