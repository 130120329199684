import React from "react"

interface ITabProps {
    title: string | JSX.Element
    icon?: JSX.Element
    tabKey: string
    children: React.ReactNode
}

export const Tab: React.FC<ITabProps> = () => {
    return null
}
