import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    snackbarContainer: {
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        left: 0,
        pointerEvents: "none",
        position: "fixed",
        width: "100%",
        zIndex: defaults.zIndex.Snackbar,
    },

    snackbar: {
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        display: "inline-flex",
        maxWidth: "60vw",
        padding: `${defaults.spacing[12]}px ${defaults.spacing[24]}px`,
        pointerEvents: "auto",
    },
})

export default styles
