import classnames from "classnames"
import React from "react"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"
interface HeadingProps {
    level: 1 | 2 | 3 | 4 | 5 | 6
    margin?: boolean
    children?: string | string[] | JSX.Element
    style?: React.CSSProperties
    center?: boolean
    color?: string
}

const useStyles = makeStyles(styles, "Heading", true)
const Heading: React.FC<HeadingProps> = (props) => {
    const classes = useStyles()
    const { level, children, margin = false, style, center = false, color } = props

    const Tag = (`h${level}` as unknown) as React.ComponentType<
        React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
    >

    // @ts-ignore - @TODO
    const className = classnames(classes.heading, classes[`h${level}`], {
        [classes.margin]: margin,
        [classes.center]: center,
    })

    return (
        <Tag className={className} style={{ color, ...style }}>
            {children}
        </Tag>
    )
}
export { Heading }
