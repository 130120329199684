import React, { createContext, useContext } from "react"
import { Label } from "../Label"
import { Paragraph } from "../Paragraph"

import classNames from "classnames"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

const DescriptionListContext = createContext<{} | null>(null)

interface DescriptionListProps {
    children?: React.ReactNode
}

const useStyles = makeStyles(styles, "DescriptionList", true)
function DescriptionList(props: DescriptionListProps) {
    const classes = useStyles()

    return (
        <DescriptionListContext.Provider value={{}}>
            <dl className={classes.descriptionList}>{props.children}</dl>
        </DescriptionListContext.Provider>
    )
}

const useDescriptionListContext = () => {
    const context = useContext(DescriptionListContext)

    if (!context) {
        throw new Error(
            `DescriptionList compound components cannot be rendered outside the <DescriptionList /> component`
        )
    }
    return context
}

interface ItemProps extends DescriptionListProps {
    label: string
    value: JSX.Element | string
    labelSuffix?: JSX.Element | string
}

const Item: React.FC<ItemProps> = (props) => {
    const { label, value, labelSuffix } = props
    useDescriptionListContext()
    const classes = useStyles()
    const termClasses = classNames(classes.term)

    return (
        <React.Fragment>
            <dt className={termClasses}>
                <Label light>{label}</Label>
                {labelSuffix && <span className={classes.labelSuffix}>{labelSuffix}</span>}
            </dt>
            <dd className={classes.description}>
                {typeof value === "string" ? <Paragraph weight={"bold"}>{value}</Paragraph> : value}
            </dd>
        </React.Fragment>
    )
}

DescriptionList.Item = Item

export { DescriptionList }
