import classnames from "classnames"
import { shallowEqual } from "fast-equals"
import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { makeStyles } from "../../utils/styles/makeStyles"
import { ICheckboxProps } from "../Checkbox"
import styles from "./styles"

interface ICheckboxGroupProps {
    direction?: "column" | "row"
    children?: React.ReactNode
    onChange?: (values: SelectedValueType[]) => void
    selected?: SelectedValueType[]
    name?: string
}

type SelectedValueType = string | number | boolean

const selectedValuesStaticCache: { [key: string]: SelectedValueType[] } = {}

const useStyles = makeStyles(styles, "CheckboxGroup", true)
const CheckboxGroup: React.FC<ICheckboxGroupProps> = React.memo((props) => {
    const classes = useStyles()
    const { direction = "row", onChange, selected, name } = props

    const groupName = useMemo(() => (name ? name : nanoid()), [])

    const [selectedValues, setSelectedValues] = useState(selected ? selected : [])

    const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValues = selectedValuesStaticCache[groupName]

        let nextSelectedValues = [...selectedValues]
        const isChecked = e.target.checked
        if (isChecked) {
            nextSelectedValues.push(e.target.value)
        } else {
            nextSelectedValues = nextSelectedValues.filter((v) => v !== e.target.value)
        }

        if (!shallowEqual(nextSelectedValues, selectedValues)) {
            setSelectedValues(nextSelectedValues)
        }
    }, [])

    useEffect(() => {
        if (selected && selected !== selectedValues) {
            setSelectedValues(selected)
        }
    }, [selected])

    useEffect(() => {
        if (onChange && selected !== selectedValues) {
            onChange(selectedValues)
        }

        selectedValuesStaticCache[groupName] = selectedValues
    }, [selectedValues])

    const containerClasses = useMemo(
        () =>
            classnames(classes.container, {
                // @ts-ignore
                [classes[`${direction}`]]: direction,
            }),
        []
    )

    return (
        <div className={containerClasses}>
            {React.Children.map(props.children, (child, index) => {
                const childElement = child as React.ReactElement<ICheckboxProps>
                const finalChild = React.cloneElement(childElement, {
                    checked:
                        childElement.props.value !== undefined
                            ? selectedValues.includes(childElement.props.value)
                            : false,
                    onChange: handleOnChange,
                    key: `${groupName}-${index}`,
                })
                return <div className={classes.item}>{finalChild}</div>
            })}
        </div>
    )
})

export { CheckboxGroup }
