import classNames from "classnames"
import React from "react"
import devMode from "../utils/devMode"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

export type ButtonColors =
    | "primary"
    | "secondary"
    | "tertiary"
    | "notice"
    | "error"
    | "warning"
    | "success"

export type ButtonKind = "rounded" | "squared" | "plain"
export type ButtonSize = "small" | "normal" | "medium" | "large"

export interface IButtonProps {
    kind?: ButtonKind
    light?: boolean
    color?: ButtonColors
    size?: ButtonSize
    href?: string
    target?: "blank" | "self" | "parent" | "top"
    fullWidth?: boolean
    tight?: boolean
    round?: boolean
    style?: React.CSSProperties
    className?: string
    reference?: string
    disabled?: boolean
    prefix?: JSX.Element
    suffix?: JSX.Element
    onClick?: (e: React.MouseEvent<HTMLElement>, ref?: string) => void
    role?: string
}

const useStyles = makeStyles(styles, "Button", true)
const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<IButtonProps>>(
    (props, ref) => {
        const classes = useStyles()
        const {
            className,
            color = "primary",
            fullWidth,
            kind,
            light,
            prefix,
            suffix,
            round,
            size = "medium",
            tight,
            children,
            disabled,
            href,
            target,
            ...rest
        } = props

        function handleOnKeyPress(e: React.KeyboardEvent) {
            e.stopPropagation()
        }

        const buttonClassNames = classNames(
            classes.button,
            {
                [classes[color]]: color !== undefined,
                [classes.fullWidth]: fullWidth !== undefined && fullWidth,
                // @ts-ignore
                [classes[kind]]: kind !== undefined,
                [classes.light]: light !== undefined && light,
                [classes[`prefixed`]]: prefix !== undefined,
                [classes[`suffixed`]]: suffix !== undefined,
                [classes.round]: round !== undefined && round,
                // @ts-ignore
                [classes[`size-${size}`]]: size !== undefined,
                [classes.tight]: tight !== undefined && tight,
                [classes.disabled]: disabled !== undefined && disabled,
            },
            className
        )

        const inner = (
            <>
                {prefix && <span className={classes.prefix}>{prefix}</span>}
                {children && typeof children === "string" && (
                    <div className={classes.text}>{children}</div>
                )}
                {children && typeof children !== "string" && <span>{children}</span>}
                {suffix && <span className={classes.suffix}>{suffix}</span>}
            </>
        )

        return (
            <>
                {href && !disabled && (
                    <a
                        {...rest}
                        onKeyPress={handleOnKeyPress}
                        href={href}
                        target={`_${target}`}
                        className={buttonClassNames}
                        tabIndex={0}
                    >
                        {inner}
                    </a>
                )}
                {!href && (
                    <button
                        {...rest}
                        onKeyPress={handleOnKeyPress}
                        className={buttonClassNames}
                        tabIndex={0}
                        disabled={disabled}
                        ref={ref}
                    >
                        {inner}
                    </button>
                )}
            </>
        )
    }
)

if (devMode) {
    Button.displayName = "Button"
}

export { Button }
