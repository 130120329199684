import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        bottom: 0,
        left: 0,
        overflowY: "auto",
        position: "fixed",
        right: 0,
        top: 0,
        zIndex: defaults.zIndex.Modal,
    },
    fullscreen: {},
    modal: {
        backgroundColor: defaults.colors.neutrals[100],
        borderRadius: defaults.cornerRadius.normal,
        margin: defaults.spacing[48],
        position: "relative",
        zIndex: defaults.zIndex.Modal,

        "& $close": {
            position: "absolute",
            right: defaults.spacing[24],
            top: 23,
            zIndex: 1,
        },

        "&$fullscreen": {
            borderRadius: 0,
            margin: 0,
            minHeight: "100vh",
            width: "100vw",

            "& $close": {
                position: "fixed",
                right: defaults.spacing[16],
                top: defaults.spacing[16],
            },
        },
    },
    transparent: {
        backgroundColor: "transparent",
    },

    "size-xs": {
        maxWidth: defaults.grid.breakpoints.xs > 0 ? defaults.grid.breakpoints.xs : undefined,
    },

    "size-sm": {
        maxWidth: defaults.grid.breakpoints.sm,
        minWidth: defaults.grid.breakpoints.xs,
    },

    "size-md": {
        maxWidth: defaults.grid.breakpoints.md,
        minWidth: defaults.grid.breakpoints.sm,
    },

    "size-lg": {
        maxWidth: defaults.grid.breakpoints.lg,
        minWidth: defaults.grid.breakpoints.md,
    },

    "size-xl": {
        maxWidth: defaults.grid.breakpoints.xl,
        minWidth: defaults.grid.breakpoints.lg,
    },

    close: {},
})

export default styles
