import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

export const MotoLogoIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { size = 24, color = defaults.colors.neutrals[900], ...otherProps } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                d="M14 2.5H6C5.46957 2.5 4.96086 2.70018 4.58579 3.0565C4.21071 3.41282 4 3.89609 4 4.4V19.6C4 20.1039 4.21071 20.5872 4.58579 20.9435C4.96086 21.2998 5.46957 21.5 6 21.5H18C18.5304 21.5 19.0391 21.2998 19.4142 20.9435C19.7893 20.5872 20 20.1039 20 19.6V8.2L14 2.5Z"
                stroke="currentColor"
                stroke-width="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0488 9.39062L11.875 14.5469L13.6914 9.39062H15.6152V16.5H14.1455V14.5566L14.292 11.2021L12.373 16.5H11.3672L9.45312 11.207L9.59961 14.5566V16.5H8.13477V9.39062H10.0488Z"
                fill="currentColor"
                strokeWidth="0.25"
            />
        </svg>
    )
}
