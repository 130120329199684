import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    active: {
        "&$initial": {
            "& svg": {
                color: defaults.colors.primary[500],
                transition: defaults.transitions.interactions.fast,
            },
        },

        "& $label": {
            color: defaults.colors.primary[500],
        },
    },
    icon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 16,
        height: 16,
        backgroundColor: defaults.colors.neutrals[300],
        border: `1px solid ${defaults.colors.neutrals[400]}`,
        padding: defaults.spacing[4],
        borderRadius: defaults.cornerRadius.large,
    },
    initial: {},
    label: {
        color: defaults.colors.neutrals[700],
        cursor: "pointer",
        fontSize: defaults.fontSizes[16],
        fontWeight: defaults.fontWeights.medium,
        marginLeft: defaults.spacing[16],
        marginBottom: defaults.spacing[2],
        transition: defaults.transitions.interactions.fast,
        userSelect: "none",
        whiteSpace: "nowrap",
        width: "100%",
    },
    step: {
        "&:active": {
            backgroundColor: defaults.colors.primary[500],
        },
        "&:hover": {
            backgroundColor: defaults.colors.primary[100],
        },
        "&:hover:active": {
            backgroundColor: defaults.colors.primary[200],
        },
        alignItems: "center",
        borderRadius: defaults.cornerRadius.normal,
        cursor: "pointer",
        display: "flex",
        lineHeight: defaults.lineHeights.m,
        padding: `${defaults.spacing[8]}px ${defaults.spacing[12]}px`,
    },
})

export default styles
