import classnames from "classnames"
import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useRef, useState } from "react"
import devMode from "../../utils/devMode"
import { makeStyles } from "../../utils/styles/makeStyles"
import { IRadioProps, Radio } from "../Radio"
import styles from "./styles"

export interface IRadioGroupProps {
    defaultCheckedValue?: string | null
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    children: React.ReactNode[] | React.ReactNode
    direction?: "column" | "row"
    allowDeselect?: boolean
    name?: string
    disabled?: boolean
    onFocus?: (e: React.FocusEvent) => void
    onBlur?: (e: React.FocusEvent) => void
    onMouseDown?: (e: React.MouseEvent) => void
}

const useStyles = makeStyles(styles, "RadioGroup", true)
export const RadioGroup: React.FC<IRadioGroupProps> = (props) => {
    const {
        children,
        direction = "row",
        onChange,
        defaultCheckedValue,
        allowDeselect,
        disabled = false,
        name: externalName,
        onMouseDown,
        onFocus,
        onBlur,
    } = props
    const classes = useStyles()

    const radioGroupRef = useRef<HTMLDivElement>(null)
    const [name] = useState(() => {
        return externalName || nanoid()
    })

    // states
    const [checkedValue, setCheckedValue] = useState(defaultCheckedValue)
    const [hasFocus, setHasFocus] = useState(false)

    // effects
    useEffect(() => {
        setCheckedValue(defaultCheckedValue)
    }, [defaultCheckedValue])

    // handlers
    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            let nextCheckedValue
            if (allowDeselect && !event.target.checked) {
                nextCheckedValue = ""
            } else {
                nextCheckedValue = event.target.value
            }
            setCheckedValue(nextCheckedValue)

            if (onChange) {
                if (event.persist) {
                    event.persist()
                }

                onChange(event)
            }
        },
        [onChange]
    )

    const handleOnFocus = useCallback(
        (e: React.FocusEvent) => {
            if (hasFocus) {
                return
            }

            setHasFocus(true)
            if (onFocus) {
                if (e.persist) {
                    e.persist()
                }
                onFocus(e)
            }
        },
        [hasFocus, onFocus]
    )

    const handleOnBlur = useCallback(
        (e: React.FocusEvent) => {
            if (!radioGroupRef.current) {
                return
            }

            const insideRadioGroup = radioGroupRef.current.contains(e.relatedTarget)
            if (!insideRadioGroup) {
                setHasFocus(false)

                if (onBlur) {
                    if (e.persist) {
                        e.persist()
                    }
                    onBlur(e)
                }
            }
        },
        [onBlur]
    )

    const containerClasses = classnames(classes.container, {
        // @ts-ignore
        [classes[`${direction}`]]: direction,
        [classes.disabled]: disabled,
    })

    const childClasses: string = classes.item
    return (
        <div className={containerClasses} onMouseDown={onMouseDown} ref={radioGroupRef}>
            {React.Children.map(children, (child, index) => {
                const childElement = child as React.ReactElement<IRadioProps>

                if (
                    (childElement.type as React.ComponentClass<IRadioProps>).displayName !==
                    Radio.displayName
                ) {
                    if (devMode) {
                        // tslint:disable-next-line:no-console
                        console.warn("Child is not of type Radio - ignored")
                    }
                    return null
                } else {
                    return React.cloneElement(childElement, {
                        checked: childElement.props.value === checkedValue,
                        className: childClasses,
                        onChange: handleOnChange,
                        onFocus: handleOnFocus,
                        onBlur: handleOnBlur,
                        allowDeselect,
                        name,
                        key: `${name}-${index}`,
                        tabIndex: index,
                    })
                }
            })}
        </div>
    )
}
