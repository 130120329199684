import { ThemeContext } from "@clearhaus/design-system"
import useEventListener from "@use-it/event-listener"
import classNames from "classnames"
import React, {
    createContext,
    MutableRefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { usePopper } from "react-popper"
import { BoxEdges } from "../BoxEdges"
import { Card } from "../Card"
import { Grid } from "../Grid"
import { Portal } from "../Portal"
import { Fade } from "../Transitions"
import { makeStyles } from "../utils/styles/makeStyles"
import useCallbackRef from "../utils/useCallbackRef"
import styles from "./styles"

interface IPopoverContext {
    classes: Record<any, string>
    referenceElement: null | HTMLElement
    setReferenceElement: (ref: HTMLElement | null) => void
    popperElement: null | HTMLElement
    setPopperElement: (ref: HTMLElement | null) => void
    arrowElement: null | HTMLElement
    setArrowElement: (ref: HTMLElement | null) => void
    contentElement: null | HTMLDivElement
    setContentElement: (ref: HTMLDivElement | null) => void
    popperStyles: ReturnType<typeof usePopper>["styles"]
    popperAttributes: ReturnType<typeof usePopper>["attributes"]
    placement: "left" | "right" | "top" | "bottom"
    preventClickOutsideRef?: MutableRefObject<boolean>
    isGoingToClose: boolean
    setIsGoingToClose: (goingToClose: boolean) => void
    show: boolean
    setShow: (show: boolean) => void
    pointerWithInContent: (x: number, y: number) => boolean
    mouseTriggerTimerRef: MutableRefObject<number>
    handleOnMouseLeave: (e: React.MouseEvent) => void
    forceUpdate: (() => void) | null
    showOnMouseEnter?: boolean
}
const PopoverContext = createContext<IPopoverContext | null>(null)

interface IPopoverProps {
    children?: React.ReactNode
    placement?: "left" | "right" | "top" | "bottom"
    preventClickOutsideRef?: MutableRefObject<boolean>
    showOnMouseEnter?: boolean
    contentElementRef?: MutableRefObject<HTMLDivElement | null>
    show?: boolean
}

const useStyles = makeStyles(styles, "Popover", true)
const Popover = (props: IPopoverProps) => {
    const classes = useStyles()
    const {
        placement = "left",
        preventClickOutsideRef,
        showOnMouseEnter,
        contentElementRef,
    } = props
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
    const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)
    const [contentElement, setContentElement] = useState<HTMLDivElement | null>(null)
    const [isGoingToClose, setIsGoingToClose] = useState(false)
    const [show, setShow] = useState(!!props.show)
    const mouseTriggerTimerRef = useRef(0)
    const {
        styles: popperStyles,
        attributes: popperAttributes,
        forceUpdate,
    } = usePopper(referenceElement, popperElement, {
        modifiers: [{ name: "arrow", options: { element: arrowElement } }],
        placement,
    })

    const handleMouseMove = useCallback((e: MouseEvent) => {
        const withInContent = pointerWithInContent(e.clientX, e.clientY)
        if (withInContent && mouseTriggerTimerRef.current > 0) {
            window.clearTimeout(mouseTriggerTimerRef.current)
            mouseTriggerTimerRef.current = -1
        }
    }, [])

    useEffect(() => {
        if (!props.show && show) {
            setIsGoingToClose(true)
        } else if (props.show && !show) {
            setShow(props.show)
        }
    }, [props.show])

    useEffect(() => {
        if (!show) {
            setIsGoingToClose(false)
            document.removeEventListener("mousemove", handleMouseMove)
        } else {
            document.addEventListener("mousemove", handleMouseMove)
        }
    }, [show])

    useEffect(() => {
        if (contentElementRef) {
            contentElementRef.current = contentElement
        }
    }, [contentElement])

    const pointerWithInContent = useCallback(
        (clientX: number, clientY: number) => {
            if (!contentElement) {
                return false
            }

            const {
                bottom: contentBottom,
                top: contentTop,
                left: contentLeft,
                right: contentRight,
            } = contentElement.getBoundingClientRect()

            let withInContent = false
            if (
                clientY > contentTop &&
                clientY < contentBottom &&
                clientX > contentLeft &&
                clientX < contentRight
            ) {
                withInContent = true
            }

            return withInContent
        },
        [contentElement]
    )

    const handleOnMouseLeave = useCallback(
        (e: React.MouseEvent) => {
            if (!showOnMouseEnter) {
                return
            }

            if (!pointerWithInContent(e.clientX, e.clientY)) {
                if (mouseTriggerTimerRef.current > 0) {
                    window.clearTimeout(mouseTriggerTimerRef.current)
                }

                mouseTriggerTimerRef.current = window.setTimeout(() => {
                    setIsGoingToClose(true)
                }, 150)
            }
        },
        [contentElement]
    )

    const context = useMemo(() => {
        return {
            classes,
            referenceElement,
            setReferenceElement,
            popperElement,
            setPopperElement,
            arrowElement,
            setArrowElement,
            contentElement,
            setContentElement,
            popperStyles,
            popperAttributes,
            placement,
            preventClickOutsideRef,
            isGoingToClose,
            setIsGoingToClose,
            show,
            setShow,
            pointerWithInContent,
            handleOnMouseLeave,
            mouseTriggerTimerRef,
            showOnMouseEnter,
            forceUpdate,
        }
    }, [
        classes,
        referenceElement,
        popperElement,
        arrowElement,
        contentElement,
        popperStyles,
        popperAttributes,
        placement,
        preventClickOutsideRef,
        isGoingToClose,
        setIsGoingToClose,
        show,
        setShow,
        pointerWithInContent,
        handleOnMouseLeave,
        mouseTriggerTimerRef,
        showOnMouseEnter,
        forceUpdate,
    ])

    return <PopoverContext.Provider value={context}>{props.children}</PopoverContext.Provider>
}

const usePopoverContext = () => {
    const context = useContext(PopoverContext)
    if (!context) {
        throw new Error(
            `Popover compound components cannot be rendered outside the <Popover /> component`
        )
    }
    return context
}

type PopoverReferenceProps = {
    children: React.ReactNode
}
const Reference = (props: PopoverReferenceProps) => {
    const {
        setReferenceElement,
        show,
        setShow,
        isGoingToClose,
        setIsGoingToClose,
        showOnMouseEnter,
        handleOnMouseLeave,
    } = usePopoverContext()

    const handleOnMouseEnter = useCallbackRef(() => {
        if (show && isGoingToClose) {
            setIsGoingToClose(false)
            setShow(true)
        } else if (!show) {
            setShow(true)
        }
    })

    return (
        <div
            ref={setReferenceElement}
            onMouseEnter={showOnMouseEnter ? handleOnMouseEnter : undefined}
            onMouseLeave={showOnMouseEnter ? handleOnMouseLeave : undefined}
        >
            {props.children}
        </div>
    )
}

Popover.Reference = Reference

interface IPopoverContentProps {
    onTransitionStart?: () => void
    onShowEnd?: () => void
    onHideEnd?: () => void
    dark?: boolean
    children: React.ReactNode
}

const Content = (props: IPopoverContentProps) => {
    const {
        classes,
        setPopperElement,
        contentElement,
        setContentElement,
        popperAttributes,
        popperStyles,
        preventClickOutsideRef,
        referenceElement,
        isGoingToClose,
        setIsGoingToClose,
        show,
        setShow,
        showOnMouseEnter,
        handleOnMouseLeave,
        forceUpdate,
    } = usePopoverContext()

    const handleClickOutside = useCallback(
        (e: MouseEvent) => {
            if (preventClickOutsideRef?.current) {
                return
            }

            if (contentElement && referenceElement) {
                const targetElm = e.target as HTMLElement
                if (contentElement.contains(targetElm) || referenceElement.contains(targetElm)) {
                    return
                }

                setIsGoingToClose(true)
            }
        },
        [contentElement, referenceElement]
    )
    useEventListener("click", handleClickOutside, document.body)

    const handleOnHideEnd = useCallbackRef(() => {
        setShow(false)
        setIsGoingToClose(false)

        if (props.onHideEnd) {
            props.onHideEnd()
        }
    })

    const handleOnShowEnd = useCallbackRef(() => {
        if (forceUpdate) {
            forceUpdate()
        }

        if (props.onShowEnd) {
            props.onShowEnd()
        }
    })

    const handleOnShowStart = useCallbackRef(() => {
        if (props.onTransitionStart) {
            props.onTransitionStart()
        }
    })

    const containerClasses = classNames(classes.container)

    return (
        <Portal>
            {show && (
                <div
                    className={classes.wrapper}
                    onMouseLeave={showOnMouseEnter ? handleOnMouseLeave : undefined}
                >
                    <Fade
                        show={!isGoingToClose}
                        onShowStart={handleOnShowStart}
                        onShowEnd={handleOnShowEnd}
                        onHideEnd={handleOnHideEnd}
                    >
                        <div
                            ref={setPopperElement}
                            style={popperStyles.popper}
                            className={containerClasses}
                            {...popperAttributes.popper}
                        >
                            <div ref={setContentElement}>
                                <PopoverCard dark={props.dark}>{props.children}</PopoverCard>
                            </div>
                        </div>
                    </Fade>
                </div>
            )}
        </Portal>
    )
}

interface IPopoverCardProps {
    children: React.ReactNode
    dark?: boolean
}

const PopoverCard = (props: IPopoverCardProps) => {
    const { children, dark } = props
    const { defaults } = useContext(ThemeContext)
    const { classes, setArrowElement, popperStyles } = usePopoverContext()

    const classnames = classNames(classes.arrow, {
        [classes.dark]: dark,
    })

    const wrapperStyle = useMemo(
        () => ({
            color: dark ? defaults.colors.neutrals[100] : undefined,
        }),
        [dark]
    )

    return (
        <BoxEdges p={12}>
            <Grid row noGutters>
                <Grid item auto>
                    <Card shade={dark ? 800 : 100} noSpacing>
                        <Card.Content noSpacing>
                            <div style={wrapperStyle}>{children}</div>
                        </Card.Content>
                    </Card>
                </Grid>
            </Grid>
            <div ref={setArrowElement} className={classnames} style={popperStyles.arrow} />
        </BoxEdges>
    )
}

Popover.Content = Content

export { Popover }
