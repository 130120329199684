import classNames from "classnames"
import { shallowEqual } from "fast-equals"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Grid } from "../../Grid"
import devMode from "../../utils/devMode"
import { makeStyles } from "../../utils/styles/makeStyles"
import { CommonInputProps } from "../types"
import styles from "./styles"

export interface IInputProps extends CommonInputProps {
    inputRef?: React.RefObject<HTMLInputElement>
    prefix?: JSX.Element
    tight?: boolean
    hasError?: boolean
    paddingSize?: "s" | "m" | "l"
}

const useStyles = makeStyles(styles, "Input", true)
export const Input = React.memo(
    React.forwardRef<HTMLDivElement, IInputProps>((props, ref) => {
        const {
            className,
            name,
            placeholder,
            suffix,
            hasError,
            paddingSize,
            inputRef,
            prefix,
            tight = false,
            value,
            defaultValue,
            onChange,
            onBlur,
            onKeyPress,
            onKeyDown,
            onFocus,
            onMouseDown,
            role,
            disabled = false,
            notice = false,
            autoComplete = true,
        } = props
        const classes = useStyles()

        // internal refs
        const internalInputRef = useRef<HTMLInputElement>(null)

        // states
        const [focused, setFocused] = useState<boolean>(false)
        const [currentValue, setCurrentValue] = useState<string | undefined>(defaultValue)
        const [prevValue, setPrevValue] = useState<string | undefined>()
        const [hadFocus, setHadFocus] = useState(false)

        useEffect(() => {
            if (disabled) {
                if (focused) {
                    setHadFocus(true)
                } else {
                    if (hadFocus) {
                        setHadFocus(false)
                    }
                }
            } else if (!disabled && hadFocus) {
                const ref = inputRef || internalInputRef
                if (ref.current) {
                    ref.current.focus()
                }
            }
        }, [disabled])

        useEffect(() => {
            if (value !== undefined) {
                const nextValue = !value ? "" : value
                if (nextValue !== prevValue) {
                    setCurrentValue(nextValue)
                    setPrevValue(nextValue)
                }
            }
        }, [value])

        const handleOnChange = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.persist) {
                    event.persist()
                }

                setCurrentValue(event.target.value)

                if (value !== undefined) {
                    if (onChange) {
                        onChange(event, event.target.value)
                    }
                } else {
                    if (onChange) {
                        onChange(event, event.target.value)
                    }
                }
            },
            [onChange, value]
        )

        const handleOnBlur = useCallback(
            (event: React.FocusEvent<HTMLInputElement>) => {
                setFocused(false)

                if (onBlur) {
                    if (event.persist) {
                        event.persist()
                    }
                    onBlur(event)
                }
            },
            [onBlur]
        )

        const handleOnKeyPress = useCallback(
            (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (onKeyPress) {
                    if (event.persist) {
                        event.persist()
                    }
                    onKeyPress(event)
                }
            },
            [onKeyPress]
        )

        const handleOnKeyDown = useCallback(
            (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (onKeyDown) {
                    if (event.persist) {
                        event.persist()
                    }
                    onKeyDown(event)
                }
            },
            [onKeyDown]
        )

        const handleOnFocus = useCallback(
            (event: React.FocusEvent<HTMLInputElement>) => {
                setFocused(true)
                if (onFocus) {
                    if (event.persist) {
                        event.persist()
                    }
                    onFocus(event)
                }
            },
            [onFocus]
        )

        const handleOnMouseDown = useCallback(
            (event: React.MouseEvent<HTMLInputElement>) => {
                if (onMouseDown) {
                    if (event.persist) {
                        event.persist()
                    }
                    onMouseDown(event)
                }
            },
            [onMouseDown]
        )

        const containerClasses = useMemo(
            () =>
                classNames(classes.container, className, {
                    [classes.tight]: tight,
                    // @ts-ignore
                    [classes[`${paddingSize}`]]: paddingSize,
                }),
            [tight]
        )

        const gridClasses = useMemo(
            () =>
                classNames(classes.inputContainer, {
                    [classes.focused]: focused,
                    [classes.hasError]: hasError,
                    [classes.disabled]: disabled,
                    [classes.notice]: notice,
                }),
            [focused, hasError, disabled, notice]
        )

        return (
            <div className={containerClasses} ref={ref}>
                <Grid row noGutters alignItems="center" className={gridClasses}>
                    {prefix && (
                        <Grid item auto>
                            <div className={classes.prefix}>{prefix}</div>
                        </Grid>
                    )}
                    <Grid item>
                        <input
                            className={classes.input}
                            name={name}
                            placeholder={placeholder}
                            type="text"
                            value={currentValue}
                            onChange={handleOnChange}
                            onFocus={handleOnFocus}
                            onBlur={handleOnBlur}
                            onKeyPress={handleOnKeyPress}
                            onKeyDown={handleOnKeyDown}
                            onMouseDown={handleOnMouseDown}
                            ref={inputRef || internalInputRef}
                            autoFocus={props.autoFocus}
                            autoComplete={autoComplete ? "on" : "off"}
                            role={role}
                            disabled={disabled}
                        />
                    </Grid>

                    {suffix && (
                        <Grid item auto>
                            <div className={classes.suffix}>{suffix}</div>
                        </Grid>
                    )}
                </Grid>
            </div>
        )
    }),
    shallowEqual
)

if (devMode) {
    Input.displayName = "Input"
}
