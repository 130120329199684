import { nanoid } from "nanoid"
import React, { useEffect, useMemo, useState } from "react"
import { LocaleUtils } from "react-day-picker"
import { Button, Grid } from "../../"
import { BoxEdges } from "../../BoxEdges"
import { Select, SelectOption } from "../Select"

interface IDatePickerHeader {
    date: Date
    localeUtils: LocaleUtils
    onChange: (date: Date) => void
    fromMonth: Date
    toMonth: Date
    onSelectOpened: () => void
    onSelectClosed: () => void
    monthRef: React.Ref<HTMLUListElement>
    yearRef: React.Ref<HTMLUListElement>
}

export const DatePickerHeader: React.FC<IDatePickerHeader> = (props) => {
    const [uniqueId] = useState(nanoid())
    const {
        date,
        fromMonth,
        toMonth,
        localeUtils,
        onChange,
        onSelectOpened,
        onSelectClosed,
        monthRef,
        yearRef,
    } = props

    const [month, setMonth] = useState(() => date.getMonth())
    const [year, setYear] = useState(() => date.getFullYear())

    useEffect(() => {
        const nextDate = new Date(year, month)
        if (
            nextDate.getFullYear() !== date.getFullYear() ||
            nextDate.getMonth() !== date.getMonth()
        ) {
            onChange(nextDate)
        }
    }, [month, year])

    useEffect(() => {
        const nextMonth = date.getMonth()
        if (nextMonth !== month) {
            setMonth(nextMonth)
        }

        const nextYear = date.getFullYear()
        if (nextYear !== year) {
            setYear(nextYear)
        }
    }, [date])

    const years = useMemo(() => {
        const years = []
        for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i++) {
            years.push(i)
        }
        return years
    }, [fromMonth, toMonth])
    const months = localeUtils.getMonths()

    const handleOnMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            setMonth(months.indexOf(e.target.value))
        }
    }

    const handleOnYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            setYear(parseInt(e.target.value))
        }
    }

    const handleNextMonth = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()

        let nextMonth = month + 1
        let nextYear = year
        if (nextMonth === 12) {
            nextMonth = 0
            nextYear = nextYear + 1
        }

        if (nextYear <= toMonth.getFullYear()) {
            setMonth(nextMonth)
            setYear(nextYear)
        }
    }

    const handlePrevMonth = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()

        let nextMonth = month - 1
        let nextYear = year
        if (nextMonth === -1) {
            nextMonth = 11
            nextYear = nextYear - 1
        }

        if (nextYear >= fromMonth.getFullYear()) {
            setMonth(nextMonth)
            setYear(nextYear)
        }
    }

    return (
        <div className="DayPicker-Caption">
            <Grid row noGutters wrap="nowrap">
                <Grid item alignSelf="center">
                    <BoxEdges pr={4}>
                        <Select
                            value={months[month]}
                            onChange={handleOnMonthChange}
                            tight
                            onOpen={onSelectOpened}
                            onClose={onSelectClosed}
                            listRef={monthRef}
                        >
                            {months.map((m) => (
                                <SelectOption value={m} key={`${uniqueId}-${m}`}>
                                    {m}
                                </SelectOption>
                            ))}
                        </Select>
                    </BoxEdges>
                </Grid>
                <Grid item alignSelf="center">
                    <BoxEdges px={4}>
                        <Select
                            value={year}
                            onChange={handleOnYearChange}
                            tight
                            onOpen={onSelectOpened}
                            onClose={onSelectClosed}
                            listRef={yearRef}
                        >
                            {years.map((y) => (
                                <SelectOption value={y} key={`${uniqueId}-${y}`}>
                                    {y}
                                </SelectOption>
                            ))}
                        </Select>
                    </BoxEdges>
                </Grid>
                <Grid item auto alignSelf="center" onClick={handlePrevMonth}>
                    <BoxEdges pr={2}>
                        <Button size={"large"} kind={"plain"} tight>
                            {"<"}
                        </Button>
                    </BoxEdges>
                </Grid>
                <Grid item auto alignSelf="center" onClick={handleNextMonth}>
                    <BoxEdges pl={2}>
                        <Button size={"large"} kind={"plain"} tight>
                            {">"}
                        </Button>
                    </BoxEdges>
                </Grid>
            </Grid>
        </div>
    )
}
