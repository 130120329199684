import { murmur3 } from "murmurhash-js"
import React, { useState } from "react"
import { usePopper } from "react-popper"
import { Input, SelectOption } from ".."
import { Paragraph } from "../.."
import { Card, Portal } from "../.."
import { makeStyles } from "../../utils/styles/makeStyles"
import styles from "./Combobox.styles"
import { AutocompleteOption, useCombobox } from "./useCombobox"

interface IComboboxProps {
    value: string | AutocompleteOption
    onChange: (value: string | AutocompleteOption, newValue?: string) => void
    options: Array<string | AutocompleteOption>
    emptyOptions?: string
    filterOptions?: (
        options: Array<string | AutocompleteOption>,
        inputValue: string
    ) => Array<string | AutocompleteOption>
    onFocus?: (e: React.FocusEvent) => void
    onBlur?: (e: React.FocusEvent) => void
    onMouseDown?: (e: React.MouseEvent) => void
}

const useStyles = makeStyles(styles, "Combobox", true)
export const Combobox: React.FC<IComboboxProps> = (props) => {
    const { filterOptions, options, onChange, value, emptyOptions, onFocus, onBlur, onMouseDown } =
        props
    const classes = useStyles()

    const {
        handleOnInputChange,
        handleInputOnBlur,
        handleInputKeyDown,
        handleItemMouseDown,
        handleItemMouseEnter,
        handleOnClick,
        setOptionRef,
        inputValue,
        inputRef,
        open,
        filteredOptions,
        focusedItemIndex,
    } = useCombobox(value, onChange, options, filterOptions, onBlur)

    // state refs
    const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null)
    const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null)

    const { styles, attributes } = usePopper(anchorRef, popperRef, {
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 12],
                },
            },
        ],
    })

    return (
        <div onClick={handleOnClick}>
            <Input
                value={inputValue}
                onChange={handleOnInputChange}
                onBlur={handleInputOnBlur}
                onKeyDown={handleInputKeyDown}
                onFocus={onFocus}
                onMouseDown={onMouseDown}
                inputRef={inputRef}
                ref={setAnchorRef}
            />
            {open && anchorRef && (
                <Portal>
                    <Card
                        className={classes.listContainer}
                        innerRef={setPopperRef}
                        style={{ ...styles.popper, width: anchorRef.clientWidth ?? undefined }}
                        {...attributes.popper}
                    >
                        <Card.Content noSpacing>
                            <ul className={classes.list}>
                                {filteredOptions.length === 0 && emptyOptions && (
                                    <SelectOption value={-1} notSelectable>
                                        <Paragraph noMargin light italic>
                                            {emptyOptions}
                                        </Paragraph>
                                    </SelectOption>
                                )}
                                {filteredOptions.map((o, i) => (
                                    <SelectOption
                                        key={typeof o === "string" ? o : murmur3(JSON.stringify(o))}
                                        value={0}
                                        onMouseEnter={handleItemMouseEnter}
                                        data-index={i}
                                        hasFocus={i === focusedItemIndex}
                                        onMouseDown={handleItemMouseDown}
                                        ref={setOptionRef}
                                    >
                                        {typeof o === "string" ? o : o.title}
                                    </SelectOption>
                                ))}
                            </ul>
                        </Card.Content>
                    </Card>
                </Portal>
            )}
        </div>
    )
}
Combobox.displayName = "Combobox"
