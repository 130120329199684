import classNames from "classnames"
import React, { useContext, useMemo } from "react"
import defaults from "../defaults"
import { ParagraphLineHeights } from "../Paragraph"
import { ThemeContext } from "../ThemeProvider"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface IMonospaceProps {
    size?: keyof typeof defaults.fontSizes
    weight?: "normal" | "medium" | "semibold"
    color?: string
    lineHeight?: ParagraphLineHeights
    children: React.ReactNode
}

const useStyles = makeStyles(styles, "Monospace", true)
export const Monospace: React.FC<IMonospaceProps> = (props) => {
    const { children, size = 13, weight = "normal", color, lineHeight } = props
    const classes = useStyles()
    const { defaults } = useContext(ThemeContext)

    const spanClasses = useMemo(() => {
        return classNames(classes.monospace, {
            [classes.normal]: weight === "normal",
            [classes.medium]: weight === "medium",
            [classes.semibold]: weight === "semibold",
        })
    }, [weight])

    const spanStyle = useMemo(() => {
        return {
            fontSize: size,
            ...(color && { color }),
            ...(lineHeight && { lineHeight: defaults.lineHeights[lineHeight] }),
        }
    }, [size])

    return (
        <span className={spanClasses} style={spanStyle}>
            {children}
        </span>
    )
}
