import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const UserCircleIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...otherProps } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 17.25V16c0-.663-.2634-1.2989-.7322-1.7678-.4689-.4688-1.1048-.7322-1.7678-.7322h-5c-.66304 0-1.29893.2634-1.76777.7322C7.26339 14.7011 7 15.337 7 16v1.25M12 11c1.3807 0 2.5-1.11929 2.5-2.5S13.3807 6 12 6 9.5 7.11929 9.5 8.5 10.6193 11 12 11z"
            />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 22c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10C6.47715 2 2 6.47715 2 12c0 5.5228 4.47715 10 10 10z"
            />
        </svg>
    )
}

export { UserCircleIcon }
