import FileSaver from "file-saver"
import React from "react"
import { Button } from "../Button"
import { Card } from "../Card"
import { Grid } from "../Grid"
import { DownloadIcon } from "../Icon"
import { Paragraph } from "../Paragraph"
import { Spacer } from "../Spacer"

interface IFilePreviewUnsupportedProps {
    src: string
    fileName: string
    caption?: string
    downloadCaption?: string
}

export const FilePreviewUnsupported: React.FC<IFilePreviewUnsupportedProps> = (props) => {
    const { src, fileName, caption = "Unsupported file type", downloadCaption = "Download" } = props

    const triggerDownload = () => {
        FileSaver.saveAs(src, fileName)
    }

    return (
        <Grid
            row
            justify="center"
            alignItems="center"
            style={{ height: "100%" }}
            role="filepreview-unsupported"
        >
            <Grid item sm={6} md={3}>
                <Card>
                    <Card.Content>
                        <Paragraph center>{caption}</Paragraph>
                        <Spacer size={32} />
                        <Grid row justify="center">
                            <Button kind="squared" onClick={triggerDownload}>
                                {downloadCaption}
                                <Spacer size={8} />
                                <DownloadIcon />
                            </Button>
                        </Grid>
                    </Card.Content>
                </Card>
            </Grid>
        </Grid>
    )
}
