import useEventListener from "@use-it/event-listener"
import { shallowEqual } from "fast-equals"
import * as React from "react"
import { useEffect, useMemo, useRef, useState } from "react"
import DayPicker, { DateUtils, LocaleUtils } from "react-day-picker"
import "react-day-picker/lib/style.css"
import { Modifier, RangeModifier } from "react-day-picker/types/Modifiers"
import XRegExp from "xregexp"
import { Input } from "../"
import { Popover } from "../../"
import { makeStyles } from "../../utils/styles/makeStyles"
import useCallbackRef from "../../utils/useCallbackRef"
import { DatePickerHeader } from "./DatePickerHeader"
import styles from "./styles"

interface IDatePickerProps {
    /**
     * How should the date be visually show in the input field
     * @default dd/mm/yyyy
     */
    dateFormat?: string
    /**
     * Callback called once a date has been changed
     */
    onChange?: (date?: Date | RangeModifier, e?: React.ChangeEvent) => void
    /**
     * Callback called on input change, eg. typing.
     * NB: Will not be called if it matches the currently selected data
     */
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: (e?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    hasError?: boolean
    notice?: boolean
    placeholder?: string
    /**
     * Initially selected date
     */
    selected?: Date | RangeModifier
    /**
     * How far back should it be possible to select
     */
    fromMonth?: Date
    /**
     * How far ahead should it be possible to select
     */
    toMonth?: Date
    /**
     * Array of month names
     * @default ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
     */
    months?: string[]
    /**
     * Array of weekdays, long
     * @default ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
     */
    weekdaysLong?: string[]
    /**
     * Array of weekdays, short
     * @default ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
     */
    weekdaysShort?: string[]
    /**
     * Should it should previous / next months dates?
     * @default false
     */
    hideOutsideDays?: boolean
    /**
     * Disable the possibility for clicking on dates outside of current month
     * @default false
     */
    disableOutsideDaysClick?: boolean
    firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    tight?: boolean
    /**
     * Always show 6 weeks
     * @default true
     */
    fixedWeeks?: boolean
    /**
     * Display inline
     * @default false
     */
    displayInline?: boolean
    /**
     * Number of months to render
     * @default 1
     */
    numberOfMonths?: number
    /**
     * Select range
     * @default false
     */
    selectRange?: boolean
}

const currentDate = new Date()

const navbarElement = () => null

const useStyles = makeStyles(styles, "DatePicker", true)
export const DatePicker: React.FC<IDatePickerProps> = React.memo((props) => {
    const {
        dateFormat = "dd/mm/yyyy",
        onBlur,
        onChange,
        onInputChange,
        hasError = false,
        placeholder,
        selected,
        months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        weekdaysLong = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ],
        weekdaysShort = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        fromMonth = new Date(currentDate.getFullYear() - 100, currentDate.getMonth()),
        toMonth = new Date(currentDate.getFullYear(), currentDate.getMonth()),
        hideOutsideDays = false,
        disableOutsideDaysClick = false,
        firstDayOfWeek = 1,
        tight = false,
        fixedWeeks = true,
        notice,
        displayInline = false,
        numberOfMonths = 1,
        selectRange = false,
    } = props
    const classes = useStyles()
    const preventPopoverCloseRef = useRef<boolean>(false)
    const [value, setValue] = useState("")
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(
        selected instanceof Date ? selected : undefined
    )
    const [selectedRange, setSelectedRange] = useState<RangeModifier | undefined>(() =>
        selectedDate ? { from: selectedDate, to: selectedDate } : undefined
    )
    const [month, setMonth] = useState<Date>(() => {
        return selectedDate ? selectedDate : toMonth
    })
    const [open, setOpen] = useState(false)
    const myRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const popoverContentRef = useRef<HTMLDivElement | null>(null)
    const monthRef = useRef<HTMLUListElement>(null)
    const yearRef = useRef<HTMLUListElement>(null)

    if (months.length !== 12) {
        throw new Error(`'months' prop must contain 12 months`)
    }

    if (weekdaysLong.length !== 7 || weekdaysShort.length !== 7) {
        throw new Error(`'weekdaysLong' and/or 'weekdaysShort' props must contain 7 days`)
    }

    const handleClickOutside = (e: MouseEvent) => {
        if (
            myRef.current &&
            inputRef.current &&
            popoverContentRef.current &&
            monthRef.current &&
            yearRef.current &&
            open
        ) {
            const targetElm: HTMLElement | null = e.target as HTMLElement
            if (
                myRef.current.contains(targetElm) ||
                inputRef.current.contains(targetElm) ||
                monthRef.current.contains(targetElm) ||
                yearRef.current.contains(targetElm) ||
                popoverContentRef.current?.contains(targetElm)
            ) {
                return
            }

            setOpen(false)
        }
    }
    useEventListener("click", handleClickOutside, document.body)

    useEffect(() => {
        if (selectedDate) {
            const fullYear = selectedDate.getFullYear().toString()
            const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0") // getMonth() is zero indexed
            const day = selectedDate.getDate().toString().padStart(2, "0")

            const nextValue = dateFormat
                .replace("yyyy", fullYear)
                .replace("dd", day)
                .replace("mm", month)
            if (nextValue !== value) {
                setValue(nextValue)
            }

            if (onChange) {
                onChange(selectedDate)
            }
        }
    }, [selectedDate])

    useEffect(() => {
        if (selectRange && onChange) {
            onChange(selectedRange)
        }
    }, [selectedRange])

    useEffect(() => {
        if (selectRange) {
            if (!shallowEqual(selected, selectedRange) && !(selected instanceof Date)) {
                setSelectedRange(selected)
            }
        } else {
            if (
                (selected instanceof Date &&
                    selectedDate &&
                    selected.getTime() !== selectedDate.getTime()) ||
                selected === undefined
            ) {
                setSelectedDate(selected)
            }
        }
    }, [selected])

    const handleDatePickerHeaderSelectOpened = useCallbackRef(() => {
        preventPopoverCloseRef.current = true
    })

    const handleDatePickerHeaderSelectClosed = useCallbackRef(() => {
        preventPopoverCloseRef.current = false
    })

    const captionElement = useMemo(() => {
        interface ICaptionElementProps {
            date: Date
            localeUtils: LocaleUtils
        }

        return (props: ICaptionElementProps) => {
            const { date, localeUtils } = props
            return (
                <DatePickerHeader
                    date={date}
                    localeUtils={localeUtils}
                    fromMonth={fromMonth}
                    toMonth={toMonth}
                    onChange={handleOnMonthYearChange}
                    onSelectOpened={handleDatePickerHeaderSelectOpened}
                    onSelectClosed={handleDatePickerHeaderSelectClosed}
                    monthRef={monthRef}
                    yearRef={yearRef}
                />
            )
        }
    }, [fromMonth, toMonth])

    const handleOnMonthYearChange = useCallbackRef((date: Date) => {
        setMonth(date)
    })

    const handleOnChange = useCallbackRef((date: Date) => {
        if (selectRange) {
            // @ts-ignore
            const nextRange = DateUtils.addDayToRange(date, selectedRange)
            if (!shallowEqual(nextRange, selectedRange)) {
                setSelectedRange(nextRange)
            }
        } else {
            setSelectedDate(date)
        }
        if (!displayInline) {
            setOpen(false)
        }
    })

    const modifiers = useMemo(() => {
        if (selectedRange) {
            return {
                start: selectedRange.from,
                end: selectedRange.to,
            } as { [other: string]: Modifier }
        }

        return undefined
    }, [selectedRange])

    const handleInputOnChange = useCallbackRef((e: React.ChangeEvent<HTMLInputElement>) => {
        if (onInputChange) {
            e.persist()
            onInputChange(e)
        }

        setValue(e.target.value)
    })

    const handleInputOnFocus = useCallbackRef(() => {
        setOpen(true)
    })

    const handleInputOnBlur = useCallbackRef((e: React.ChangeEvent<HTMLInputElement>) => {
        // noinspection RegExpRedundantEscape
        let cleanedDateFormat = dateFormat.replace(/[\-\/\\\.]/g, "")
        cleanedDateFormat = cleanedDateFormat.replace("yyyy", "(?<year> [\\d+]{4})[-/\\.]?")
        cleanedDateFormat = cleanedDateFormat.replace("dd", "(?<dd> [\\d+]{2})[-/\\.]?")
        cleanedDateFormat = cleanedDateFormat.replace("mm", "(?<mm> [\\d+]{2})[-/\\.]?")

        const dateRegExp = XRegExp(cleanedDateFormat, "x")
        const parts = XRegExp.exec(e.target.value, dateRegExp) as any

        if (parts && parts.groups && parts.groups.dd <= 31 && parts.groups.mm <= 12) {
            setSelectedDate(
                new Date(
                    parseInt(parts.groups.year),
                    parseInt(parts.groups.mm) - 1,
                    parseInt(parts.groups.dd)
                )
            )
        }

        if (onBlur) {
            onBlur()
        }
    })

    const handleKeyDown = useCallbackRef((e: React.KeyboardEvent) => {
        if (e.key === "Escape" && open) {
            e.stopPropagation()
            setOpen(false)
        }
        if (e.key === "Tab" && open) {
            setOpen(false)
        }
    })

    const dayPicker = useMemo(() => {
        return (
            <div
                ref={myRef}
                role="datepicker"
                className={displayInline ? classes.inline : undefined}
            >
                <DayPicker
                    showOutsideDays={!hideOutsideDays}
                    enableOutsideDaysClick={!disableOutsideDaysClick}
                    selectedDays={!selectRange ? selectedDate : selectedRange}
                    onDayClick={handleOnChange}
                    month={month}
                    months={months}
                    weekdaysLong={weekdaysLong}
                    weekdaysShort={weekdaysShort}
                    captionElement={captionElement}
                    navbarElement={navbarElement}
                    firstDayOfWeek={firstDayOfWeek}
                    fixedWeeks={fixedWeeks}
                    toMonth={toMonth}
                    numberOfMonths={numberOfMonths}
                    className={selectedRange ? classes.selectRange : undefined}
                    modifiers={modifiers}
                />
            </div>
        )
    }, [
        hideOutsideDays,
        disableOutsideDaysClick,
        selectedDate,
        handleOnChange,
        month,
        months,
        weekdaysLong,
        weekdaysShort,
        captionElement,
        navbarElement,
        firstDayOfWeek,
        fixedWeeks,
        toMonth,
        numberOfMonths,
        selectRange,
        selectedRange,
    ])

    if (displayInline) {
        return dayPicker
    }

    return (
        <Popover
            placement="bottom"
            preventClickOutsideRef={preventPopoverCloseRef}
            show={open}
            contentElementRef={popoverContentRef}
        >
            <Popover.Reference>
                <Input
                    value={value}
                    onBlur={handleInputOnBlur}
                    onChange={handleInputOnChange}
                    onFocus={handleInputOnFocus}
                    onKeyDown={handleKeyDown}
                    inputRef={inputRef}
                    hasError={hasError}
                    notice={notice}
                    placeholder={placeholder}
                    tight={tight}
                    role="datepicker-input"
                />
            </Popover.Reference>
            <Popover.Content>{dayPicker}</Popover.Content>
        </Popover>
    )
}, shallowEqual)
DatePicker.displayName = "DatePicker"
