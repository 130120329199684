import classNames from "classnames"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { makeStyles } from "../../utils/styles/makeStyles"
import styles from "./styles"

export interface IRadioProps {
    value?: string
    name?: string
    id?: string
    caption?: string | JSX.Element
    checked?: boolean
    prefix?: JSX.Element
    simple?: boolean
    className?: string
    row?: boolean
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    allowDeselect?: boolean
    onFocus?: (e: React.FocusEvent) => void
    onBlur?: (e: React.FocusEvent) => void
    tabIndex?: number
}

const useStyles = makeStyles(styles, "Radio", true)
export const Radio: React.FC<IRadioProps> = (props) => {
    const {
        name,
        caption,
        value,
        id,
        prefix,
        simple,
        checked,
        className,
        onChange,
        onBlur,
        onFocus,
        row,
        disabled = false,
        allowDeselect = false,
    } = props
    const classes = useStyles()
    const _inputRef = useRef<HTMLInputElement>(null)

    // states
    const [isChecked, setIsChecked] = useState<boolean>(!!checked)
    const [focused, setFocused] = useState<boolean>(false)

    // effects
    useEffect(() => {
        setIsChecked(checked ? checked : false)
    }, [checked])

    // handlets
    const handleOnClick = (event: React.MouseEvent<HTMLInputElement>) => {
        let nextIsChecked

        if (allowDeselect && isChecked) {
            nextIsChecked = false
        } else {
            nextIsChecked = true
        }

        event.currentTarget.checked = nextIsChecked

        if (isChecked !== nextIsChecked) {
            setIsChecked(nextIsChecked)
        }
    }

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsChecked(event.target.checked)

            if (onChange) {
                if (event.persist) {
                    event.persist()
                }

                onChange(event)
            }
        },
        [onChange]
    )

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.persist) {
            event.persist()
        }

        if ((event.key === "Enter" || event.key === " ") && _inputRef.current) {
            _inputRef.current.click()
        }
    }

    const handleOnFocus = (e: React.FocusEvent) => {
        setFocused(true)

        if (onFocus) {
            if (e.persist) {
                e.persist()
            }
            onFocus(e)
        }
    }

    const handleOnBlur = (e: React.FocusEvent) => {
        setFocused(false)

        if (onBlur) {
            if (e.persist) {
                e.persist()
            }
            onBlur(e)
        }
    }

    const radioClasses = classNames(classes.radio, {
        [classes.checked]: isChecked,
        [classes.simple]: simple,
        [classes.row]: row,
    })

    const containerClasses = classNames(className, {
        [classes.containerChecked]: isChecked,
        [classes.container]: true,
        [classes.simple]: simple,
        [classes.disabled]: disabled,
        [classes.focused]: focused,
    })

    const captionClasses = classNames(classes.caption, {
        [classes.simple]: simple,
        [classes.row]: row,
    })

    const prefixClasses = classNames(classes.prefix, {
        [classes.row]: row,
        [classes.simple]: simple,
    })

    return (
        <div className={containerClasses}>
            <label className={captionClasses}>
                <input
                    className={classes.input}
                    name={name}
                    type="checkbox"
                    id={id}
                    ref={_inputRef}
                    value={value}
                    checked={isChecked}
                    onChange={handleOnChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onKeyPress={handleOnKeyPress}
                    onClick={handleOnClick}
                    disabled={disabled}
                />
                <span className={radioClasses} />
                {prefix && <span className={prefixClasses}>{prefix}</span>}
                {caption && <span>{caption}</span>}
            </label>
        </div>
    )
}

Radio.displayName = "Radio"
