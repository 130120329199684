import React from "react"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ILayoutContext {
    setSidebarWidth?: React.Dispatch<number>
    sidebarWidth?: number
}
const LayoutContext = createContext<ILayoutContext>({})

interface ILayoutProps {
    children?: React.ReactChild | React.ReactChild[]
}

const useStyles = makeStyles(styles, "Layout", true)
function Layout(props: ILayoutProps) {
    const classes = useStyles()
    const [sidebarWidth, setSidebarWidth] = useState<number>()

    return (
        <LayoutContext.Provider value={{ sidebarWidth, setSidebarWidth }}>
            <div className={classes.wrapper}>
                <div className={classes.container}>{props.children}</div>
            </div>
        </LayoutContext.Provider>
    )
}

const useLayoutContext = (): any => {
    const { sidebarWidth, setSidebarWidth } = useContext(LayoutContext)
    const classes = useStyles()

    if (!setSidebarWidth) {
        throw new Error(
            "Layout compound component cannot be rendered outside the <Layout /> component"
        )
    }
    return { sidebarWidth, setSidebarWidth, classes }
}

interface ISidebarProps extends ILayoutProps {}
function Sidebar(props: ISidebarProps) {
    const { classes, sidebarWidth, setSidebarWidth } = useLayoutContext()
    const sidebarRef = useRef<HTMLElement>(null)

    useEffect(() => {
        const nextSidebarWidth = sidebarRef && sidebarRef.current && sidebarRef.current.offsetWidth
        if (nextSidebarWidth !== sidebarWidth) {
            setSidebarWidth(nextSidebarWidth)
        }
    }, [sidebarRef.current])

    return (
        <aside className={classes.sidebar} ref={sidebarRef}>
            {props.children}
        </aside>
    )
}

interface IMainProps extends ILayoutProps {}
function Main(props: IMainProps) {
    const { classes, sidebarWidth } = useLayoutContext()

    return (
        <main className={classes.main}>
            <article className={classes.content} style={{ marginLeft: `${sidebarWidth}px` }}>
                {props.children}
            </article>
        </main>
    )
}

Layout.Main = Main
Layout.Sidebar = Sidebar

export { Layout }
