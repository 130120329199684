import classNames from "classnames"
import React, { useEffect, useMemo, useRef, useState } from "react"
import devMode from "../../utils/devMode"
import { makeStyles } from "../../utils/styles/makeStyles"
import useCallbackRef from "../../utils/useCallbackRef"
import {
    findClosest,
    getCoordinates,
    percentageToMarkValue,
    percentageToValue,
    roundValueToStep,
    valueToMarkPosition,
    valueToPercentage,
} from "./helpers"
import styles from "./styles"

export interface IMark {
    value: number
    railPosition?: number
    label?: string | JSX.Element
}

interface ISliderProps {
    /**
     * The initial/default value of the Slider, must be numerical
     * NB: Changing this post-mount will not update it
     * @default 0
     */
    initialValue?: number | string
    /**
     * Minimum value
     * @default 0
     */
    min?: number
    /**
     * Maximum value
     * @default 100
     */
    max?: number
    /**
     * Sets in which increments the value should change
     * @default 1
     */
    step?: number
    /**
     * Should the Slider have predefined marks
     * Signature <code>Array<{ value: number, railPosition?: number, label?: string }></code>
     * @default undefined
     */
    marks?: IMark[]
    /**
     * Snap to closest mark
     * @default false
     */
    snapToMark?: boolean
    /**
     * Should the track be shown
     * @default true
     */
    track?: boolean
    /**
     * Will be called on any change of the slider
     */
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    /**
     * Will be called when a change has been commited, usually last change
     */
    onChangeCommitted?: (event: React.ChangeEvent<HTMLInputElement>) => void
    /**
     * Does it have an error?
     */
    hasError?: boolean
    /**
     * Is it disabled?
     * @default false
     */
    disabled?: boolean
}

const useStyles = makeStyles(styles, "Slider", true)
export const Slider: React.FC<ISliderProps> = (props) => {
    const {
        initialValue,
        marks = [],
        max = 100,
        min = 0,
        step = 1,
        snapToMark = false,
        track = true,
        onChange,
        onChangeCommitted,
        hasError,
        disabled = false,
    } = props

    const sliderRef = useRef<HTMLDivElement>(null)
    const sliderInputRef = useRef<HTMLInputElement>(null)
    const touchId = useRef(0)
    const [hasMarkRailPositions] = useState(() => {
        const hasInvalidMark = marks.some(
            (m) => m.railPosition && (m.railPosition < 0 || m.railPosition > 100)
        )
        if (hasInvalidMark && devMode) {
            throw Error(
                `One or more marks has an invalid 'railPosition', must be between 0 and 100`
            )
        }

        return marks.some((m) => m.railPosition !== undefined)
    })
    const [hasMarkLabels] = useState(() => {
        return marks.some((m) => m.label !== undefined)
    })

    const marksValues = useMemo(() => marks.map((m) => m.value), [marks])

    const [currentValue, setCurrentValue] = useState(() => {
        return initialValue
            ? typeof initialValue === "string"
                ? parseInt(initialValue)
                : initialValue
            : 0
    })
    const [valuePosition, setValuePosition] = useState(() => {
        if (initialValue) {
            const parsedInitialValue = initialValue
                ? typeof initialValue === "string"
                    ? parseInt(initialValue)
                    : initialValue
                : 0
            let percentage = hasMarkRailPositions
                ? valueToMarkPosition(
                      marksValues[findClosest(marksValues, parsedInitialValue)],
                      marks
                  )
                : valueToPercentage(parsedInitialValue, min, max)

            if (percentage > 100) {
                percentage = 100
            } else if (percentage < 0) {
                percentage = 0
            }

            return percentage
        } else {
            return 0
        }
    })

    const trackStyle = useMemo(() => {
        return {
            width: `${valuePosition}%`,
        }
    }, [valuePosition])

    const pointerStyle = useMemo(() => {
        return {
            left: `${valuePosition}%`,
        }
    }, [valuePosition])

    const classes = useStyles()

    useEffect(() => {
        const { current: sliderInput } = sliderInputRef
        if (onChange && sliderInput) {
            let newValue = hasMarkRailPositions
                ? percentageToMarkValue(valuePosition, marks)
                : percentageToValue(valuePosition, min, max)

            if (step) {
                newValue = roundValueToStep(newValue, step, min)
            }

            const fakeEvent = {
                target: {
                    value: newValue.toString(),
                } as HTMLInputElement,
                bubbles: false,
                cancelable: false,
                currentTarget: sliderInput,
            }

            onChange(fakeEvent as React.ChangeEvent<HTMLInputElement>)
        }
    }, [valuePosition])

    useEffect(() => {
        const { current: slider } = sliderRef
        if (slider) {
            slider.addEventListener("mousedown", handleMouseDown, { passive: true })
            slider.addEventListener("touchstart", handleMouseDown)
        }
    }, [sliderRef])

    // Handlers
    const getNewValue = useCallbackRef((event: MouseEvent | TouchEvent) => {
        const { current: slider } = sliderRef
        if (!slider) {
            return 0
        }

        const { width, left } = slider.getBoundingClientRect()
        const coords = getCoordinates(event, touchId.current)
        const percentage = ((coords.x - left) / width) * 100
        let newValue = hasMarkRailPositions
            ? percentageToMarkValue(percentage, marks)
            : percentageToValue(percentage, min, max)

        if (snapToMark && !hasMarkRailPositions) {
            newValue = marksValues[findClosest(marksValues, newValue)]
        }

        if (step) {
            newValue = roundValueToStep(newValue, step, min)
        }

        return newValue
    })

    const handleMouseUp = useCallbackRef((event: MouseEvent | TouchEvent) => {
        let newValue = getNewValue(event)

        setCurrentValue(newValue)

        let nextPosition = hasMarkRailPositions
            ? valueToMarkPosition(newValue, marks)
            : valueToPercentage(newValue, min, max)

        if (nextPosition > 100) {
            nextPosition = 100
            newValue = percentageToValue(100, min, max)
        } else if (nextPosition < 0) {
            nextPosition = 0
            newValue = percentageToValue(0, min, max)
        }

        if (nextPosition !== valuePosition) {
            setValuePosition(nextPosition)
        }

        const { current: slider } = sliderRef
        if (slider) {
            // Mouse Events
            document.removeEventListener("mousemove", handleMouseMove)
            document.removeEventListener("mouseup", handleMouseUp)

            // Touch Events
            document.removeEventListener("touchmove", handleMouseMove)
            document.removeEventListener("touchend", handleMouseUp)

            // Reenable animation
            slider.classList.add(classes.animate)
        }

        const { current: sliderInput } = sliderInputRef
        if (onChangeCommitted && sliderInput) {
            const fakeEvent = {
                target: {
                    value: newValue.toString(),
                } as HTMLInputElement,
                bubbles: false,
                cancelable: false,
                currentTarget: sliderInput,
            }

            onChangeCommitted(fakeEvent as React.ChangeEvent<HTMLInputElement>)
        }
    })

    const handleMouseMove = useCallbackRef((event: MouseEvent | TouchEvent) => {
        const { current: slider } = sliderRef
        if (!slider) {
            return
        }

        const { width, left } = slider.getBoundingClientRect()
        const coords = getCoordinates(event, touchId.current)
        let percentage = ((coords.x - left) / width) * 100
        if (percentage > 100) {
            percentage = 100
        } else if (percentage < 0) {
            percentage = 0
        }

        setValuePosition(percentage)
    })

    const handleMouseDown = useCallbackRef((event: MouseEvent | TouchEvent) => {
        const { current: slider } = sliderRef
        if (!slider) {
            return
        }

        // Set touchId, if TouchEvent
        if (window.TouchEvent && event instanceof TouchEvent) {
            touchId.current = event.changedTouches[0].identifier
        }

        const { width, left } = slider.getBoundingClientRect()
        // Disable animation
        slider.classList.remove(classes.animate)

        const coords = getCoordinates(event, touchId.current)
        const percentage = ((coords.x - left) / width) * 100

        setValuePosition(percentage)

        // Mouse Events
        document.addEventListener("mousemove", handleMouseMove)
        document.addEventListener("mouseup", handleMouseUp)

        // Touch Events
        document.addEventListener("touchmove", handleMouseMove)
        document.addEventListener("touchend", handleMouseUp)
    })

    const handleKeyDown = useCallbackRef((event: React.KeyboardEvent<HTMLSpanElement>) => {
        const marksValues = marks.map((m) => m.value)
        const markIndex = marksValues.indexOf(currentValue)

        let newValue
        switch (event.key) {
            case "Home":
                newValue = min
                break
            case "End":
                newValue = max
                break
            case "ArrowRight":
            case "ArrowUp":
                if (step && !snapToMark) {
                    newValue = currentValue + step
                } else {
                    newValue = marksValues[markIndex + 1] || marksValues[marksValues.length - 1]
                }
                break
            case "ArrowLeft":
            case "ArrowDown":
                if (step && !snapToMark) {
                    newValue = currentValue - step
                } else {
                    newValue = marksValues[markIndex - 1] || marksValues[0]
                }
                break
            default:
                return
        }

        // Prevent scroll
        event.preventDefault()

        if (step) {
            newValue = roundValueToStep(newValue, step, min)
        }

        setCurrentValue(newValue)

        const nextPosition = hasMarkRailPositions
            ? valueToMarkPosition(newValue, marks)
            : valueToPercentage(newValue, min, max)
        if (nextPosition !== valuePosition) {
            setValuePosition(nextPosition)
        }

        const { current: sliderInput } = sliderInputRef
        if (onChangeCommitted && sliderInput) {
            const fakeEvent = {
                target: {
                    value: newValue.toString(),
                } as HTMLInputElement,
                bubbles: false,
                cancelable: false,
                currentTarget: sliderInput,
            }

            onChangeCommitted(fakeEvent as React.ChangeEvent<HTMLInputElement>)
        }
    })

    const containerClasses = useMemo(() => {
        return classNames(classes.container, {
            [classes.hasMarkLabels]: hasMarkLabels,
            [classes.hasError]: hasError,
            [classes.disabled]: disabled,
        })
    }, [hasError, disabled])

    return (
        <div className={containerClasses} ref={sliderRef}>
            <span className={classes.rail} />
            {track && <span className={classes.track} style={trackStyle} />}
            <span
                className={classes.pointer}
                style={pointerStyle}
                tabIndex={0}
                role="slider"
                onKeyDown={handleKeyDown}
            />
            {marks.map((mark) => {
                if (!snapToMark && mark.railPosition && devMode) {
                    // tslint:disable-next-line:no-console
                    console.warn(
                        `'railPosition' on marks cannot be used without 'snapToMark' set to true`
                    )
                }

                const percent =
                    snapToMark && mark.railPosition !== undefined
                        ? mark.railPosition
                        : valueToPercentage(mark.value, min, max)
                const style = {
                    left: `${percent}%`,
                }

                // If last mark (100%), let CSS deal with position
                if (percent === 100) {
                    // @ts-ignore
                    delete style.left
                }

                return (
                    <React.Fragment key={mark.value}>
                        <span style={style} className={classes.mark} data-position={percent} />
                        {mark.label && (
                            <label
                                style={style}
                                className={classes.markLabel}
                                data-position={percent}
                            >
                                {mark.label}
                            </label>
                        )}
                    </React.Fragment>
                )
            })}
            <input type="hidden" value={currentValue} ref={sliderInputRef} />
        </div>
    )
}
