import hexToRgba from "hex-to-rgba"
import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => {
    const disabled = {
        backgroundColor: defaults.colors.neutrals[400],
        color: defaults.colors.neutrals[100],
        cursor: "default",

        "&:active": {
            backgroundColor: defaults.colors.neutrals[400],
        },
        "&:focus": {
            backgroundColor: defaults.colors.neutrals[400],
        },
        "&:hover": {
            backgroundColor: defaults.colors.neutrals[400],
        },
    }

    return {
        button: {
            alignItems: "center",
            border: 0,
            borderRadius: defaults.cornerRadius.rounded,
            color: defaults.colors.neutrals[100],
            cursor: "pointer",
            display: "flex",
            fontSize: defaults.fontSizes[13],
            fontWeight: defaults.fontWeights.bold,
            justifyContent: "space-between",
            lineHeight: defaults.lineHeights.s,
            minHeight: "1em",
            minWidth: "1em",
            outline: 0,
            padding: `${defaults.spacing[16]}px calc(1em * 1.5)`,
            position: "relative",
            textDecoration: "none",
            textTransform: "uppercase",
            transition: defaults.transitions.interactions.fast,
            userSelect: "none",

            "& svg": {
                color: "inherit",
                flexShrink: 0,
                fontSize: defaults.fontSizes[16],
                height: "1em",
                width: "1em",
            },

            "&:only-child": {
                "&$prefixed": {
                    display: "flex",
                },
            },
        },

        prefix: {
            marginRight: "calc(1em / 1.5)",
            marginTop: "-0.25em",
            marginBottom: "-0.25em",

            "&:only-child": {
                marginRight: 0,
                marginTop: 0,
                marginBottom: 0,
                height: "auto",
            },

            "& + $text": {
                lineHeight: 0.9,
            },
        },

        suffix: {
            marginLeft: "calc(1em / 1.5)",

            "&:only-child": {
                marginRight: 0,
            },

            "& + $text": {
                lineHeight: 0.9,
            },
        },

        text: {
            flexGrow: 1,
            height: "1em",
            lineHeight: 1,
            whiteSpace: "nowrap",
        },

        round: {
            height: "auto",
            padding: defaults.spacing[8],
        },

        primary: {
            backgroundColor: defaults.colors.primary[500],

            "&:hover": {
                backgroundColor: defaults.colors.primary[600],
                color: defaults.colors.neutrals[100],
            },

            "&:active": {
                backgroundColor: defaults.colors.primary[600],
            },

            "&:focus": {
                backgroundColor: defaults.colors.primary[600],
                boxShadow: defaults.shadows.small.wide,
            },

            "&:hover:active": {
                backgroundColor: defaults.colors.primary[500],
            },

            "&$disabled": {
                ...disabled,
            },
        },

        secondary: {
            backgroundColor: defaults.colors.neutrals[800],

            "&:hover": {
                backgroundColor: defaults.colors.neutrals[900],
            },

            "&:active": {
                backgroundColor: defaults.colors.neutrals[900],
            },

            "&:focus": {
                backgroundColor: defaults.colors.neutrals[900],
                boxShadow: defaults.shadows.small.wide,
            },

            "&:hover:active": {
                backgroundColor: defaults.colors.neutrals[800],
            },

            "&$disabled": {
                ...disabled,
            },
        },

        tertiary: {
            backgroundColor: defaults.colors.neutrals[100],
            color: defaults.colors.neutrals[900],

            "&:hover": {
                backgroundColor: defaults.colors.neutrals[200],
            },

            "&:active": {
                backgroundColor: defaults.colors.neutrals[200],
            },

            "&:focus": {
                backgroundColor: defaults.colors.neutrals[200],
                boxShadow: defaults.shadows.small.wide,
            },

            "&:hover:active": {
                backgroundColor: defaults.colors.neutrals[100],
            },

            "&$disabled": {
                "&$disabled": {
                    backgroundColor: hexToRgba(defaults.colors.neutrals[100], 0.05),
                    color: hexToRgba(defaults.colors.neutrals[100], 0.5),
                    cursor: "default",

                    "&:active": {
                        backgroundColor: hexToRgba(defaults.colors.neutrals[100], 0.1),
                        color: hexToRgba(defaults.colors.neutrals[100], 0.5),
                    },
                    "&:focus": {
                        backgroundColor: hexToRgba(defaults.colors.neutrals[100], 0.1),
                        color: hexToRgba(defaults.colors.neutrals[100], 0.5),
                    },
                    "&:hover": {
                        backgroundColor: hexToRgba(defaults.colors.neutrals[100], 0.1),
                        color: hexToRgba(defaults.colors.neutrals[100], 0.5),
                    },
                },
            },
        },

        notice: {
            backgroundColor: defaults.colors.accents.notice[500],

            "&:hover": {
                backgroundColor: defaults.colors.accents.notice[600],
            },

            "&:active": {
                backgroundColor: defaults.colors.accents.notice[600],
            },

            "&:focus": {
                backgroundColor: defaults.colors.accents.notice[600],
                boxShadow: defaults.shadows.small.wide,
            },

            "&:hover:active": {
                backgroundColor: defaults.colors.accents.notice[500],
            },

            "&$light": {
                backgroundColor: defaults.colors.accents.notice[300],
                color: defaults.colors.accents.notice[900],

                "&:hover": {
                    backgroundColor: defaults.colors.accents.notice[400],
                },

                "&:active": {
                    backgroundColor: defaults.colors.accents.notice[400],
                },

                "&:focus": {
                    backgroundColor: defaults.colors.accents.notice[400],
                    boxShadow: defaults.shadows.small.wide,
                },

                "&:hover:active": {
                    backgroundColor: defaults.colors.accents.notice[400],
                },

                "&$disabled": {
                    "&:hover": {
                        backgroundColor: defaults.colors.accents.notice[200],
                    },

                    "&:active": {
                        backgroundColor: defaults.colors.accents.notice[200],
                    },

                    "&:focus": {
                        backgroundColor: defaults.colors.accents.notice[200],
                        boxShadow: defaults.shadows.small.wide,
                    },

                    "&:hover:active": {
                        backgroundColor: defaults.colors.accents.notice[200],
                    },
                },
            },

            "&$disabled": {
                color: defaults.colors.accents.notice[400],
                backgroundColor: defaults.colors.accents.notice[200],
                cursor: "not-allowed",

                "&:hover": {
                    backgroundColor: defaults.colors.accents.notice[200],
                },

                "&:active": {
                    backgroundColor: defaults.colors.accents.notice[200],
                },

                "&:focus": {
                    backgroundColor: defaults.colors.accents.notice[200],
                    boxShadow: defaults.shadows.small.wide,
                },

                "&:hover:active": {
                    backgroundColor: defaults.colors.accents.notice[200],
                },
            },
        },

        error: {
            backgroundColor: defaults.colors.accents.error[500],

            "&:hover": {
                backgroundColor: defaults.colors.accents.error[600],
            },

            "&:active": {
                backgroundColor: defaults.colors.accents.error[600],
            },

            "&:focus": {
                backgroundColor: defaults.colors.accents.error[600],
                boxShadow: defaults.shadows.small.wide,
            },

            "&:hover:active": {
                backgroundColor: defaults.colors.accents.error[500],
            },

            "&$light": {
                backgroundColor: defaults.colors.accents.error[200],
                color: defaults.colors.accents.error[900],

                "&:hover": {
                    backgroundColor: defaults.colors.accents.error[300],
                },

                "&:active": {
                    backgroundColor: defaults.colors.accents.error[300],
                },

                "&:focus": {
                    backgroundColor: defaults.colors.accents.error[300],
                    boxShadow: defaults.shadows.small.wide,
                },

                "&:hover:active": {
                    backgroundColor: defaults.colors.accents.error[300],
                },
            },

            "&$plain": {
                color: defaults.colors.accents.error[500],

                "&:hover": {
                    color: defaults.colors.accents.error[700],
                },

                "&:active": {
                    color: defaults.colors.accents.error[500],
                },

                "&:focus": {
                    boxShadow: "none",
                    color: defaults.colors.accents.error[700],
                },

                "&:hover:active": {
                    color: defaults.colors.accents.error[500],
                },
            },

            "&$disabled": {
                ...disabled,
            },
        },

        warning: {
            backgroundColor: defaults.colors.accents.warning[500],

            "&:hover": {
                backgroundColor: defaults.colors.accents.warning[700],
            },

            "&:active": {
                backgroundColor: defaults.colors.accents.warning[700],
            },

            "&:focus": {
                backgroundColor: defaults.colors.accents.warning[700],
                boxShadow: defaults.shadows.small.wide,
            },

            "&:hover:active": {
                backgroundColor: defaults.colors.accents.warning[700],
            },

            "&$light": {
                backgroundColor: defaults.colors.accents.warning[300],
                color: defaults.colors.accents.warning[900],

                "&:hover": {
                    backgroundColor: defaults.colors.accents.warning[500],
                },

                "&:active": {
                    backgroundColor: defaults.colors.accents.warning[500],
                },

                "&:focus": {
                    backgroundColor: defaults.colors.accents.warning[500],
                    boxShadow: defaults.shadows.small.wide,
                },

                "&:hover:active": {
                    backgroundColor: defaults.colors.accents.warning[500],
                },
            },

            "&$plain": {
                color: defaults.colors.accents.warning[500],

                "&:hover": {
                    color: defaults.colors.accents.warning[700],
                },

                "&:active": {
                    color: defaults.colors.accents.warning[500],
                },

                "&:focus": {
                    boxShadow: "none",
                    color: defaults.colors.accents.warning[700],
                },

                "&:hover:active": {
                    color: defaults.colors.accents.warning[500],
                },
            },

            "&$disabled": {
                ...disabled,
            },
        },

        success: {
            backgroundColor: defaults.colors.accents.success[500],

            "&:hover": {
                backgroundColor: defaults.colors.accents.success[700],
            },

            "&:active": {
                backgroundColor: defaults.colors.accents.success[700],
            },

            "&:focus": {
                backgroundColor: defaults.colors.accents.success[700],
                boxShadow: defaults.shadows.small.wide,
            },

            "&:hover:active": {
                backgroundColor: defaults.colors.accents.success[700],
            },

            "&$light": {
                backgroundColor: defaults.colors.accents.success[200],
                color: defaults.colors.accents.success[900],

                "&:hover": {
                    backgroundColor: defaults.colors.accents.success[300],
                },

                "&:active": {
                    backgroundColor: defaults.colors.accents.success[300],
                },

                "&:focus": {
                    backgroundColor: defaults.colors.accents.success[300],
                    boxShadow: defaults.shadows.small.wide,
                },

                "&:hover:active": {
                    backgroundColor: defaults.colors.accents.success[300],
                },
            },

            "&$plain": {
                color: defaults.colors.accents.success[500],

                "&:hover": {
                    color: defaults.colors.accents.success[700],
                },

                "&:active": {
                    color: defaults.colors.accents.success[500],
                },

                "&:focus": {
                    backgroundColor: defaults.colors.accents.success[700],
                    boxShadow: "none",
                },

                "&:hover:active": {
                    color: defaults.colors.accents.success[500],
                },
            },

            "&$disabled": {
                ...disabled,
            },
        },

        squared: {
            borderRadius: defaults.cornerRadius.normal,
        },

        plain: {
            backgroundColor: "transparent",
            color: defaults.colors.primary[500],
            fontSize: defaults.fontSizes[13],
            height: "auto",
            lineHeight: defaults.lineHeights.s,
            padding: 0,

            "& *": {
                cursor: "pointer",
            },

            "&$prefixed": {
                paddingLeft: 0,
            },

            "&$suffixed": {
                paddingRight: 0,
            },

            "&:hover": {
                backgroundColor: "transparent",
                color: defaults.colors.primary[600],
            },

            "&:active": {
                backgroundColor: "transparent",
                color: defaults.colors.primary[600],
            },

            "&:focus": {
                backgroundColor: "transparent",
                boxShadow: "none",
                color: defaults.colors.primary[600],
            },

            "&:hover:active": {
                backgroundColor: "transparent",
                color: defaults.colors.primary[500],
            },

            "&$disabled": {
                ...disabled,
                backgroundColor: "transparent",
                color: defaults.colors.neutrals[500],

                "&:active": {
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[500],
                },
                "&:focus": {
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[500],
                },
                "&:hover": {
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[500],
                },
            },

            "&$secondary": {
                color: defaults.colors.neutrals[900],

                "&:hover": {
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[800],
                },

                "&:active": {
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[800],
                },

                "&:focus": {
                    backgroundColor: "transparent",
                    boxShadow: defaults.shadows.small.wide,
                },

                "&:hover:active": {
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[800],
                },

                "&$disabled": {
                    ...disabled,
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[500],

                    "&:active": {
                        backgroundColor: "transparent",
                        color: defaults.colors.neutrals[500],
                    },
                    "&:focus": {
                        backgroundColor: "transparent",
                        boxShadow: defaults.shadows.small.wide,
                    },
                    "&:hover": {
                        backgroundColor: "transparent",
                        color: defaults.colors.neutrals[500],
                    },
                },
            },
            "&$tertiary": {
                color: defaults.colors.neutrals[100],

                "&:hover": {
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[200],
                },

                "&:active": {
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[200],
                },

                "&:focus": {
                    backgroundColor: "transparent",
                    boxShadow: defaults.shadows.small.wide,
                },

                "&:hover:active": {
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[100],
                },

                "&$disabled": {
                    ...disabled,
                    backgroundColor: "transparent",
                    color: defaults.colors.neutrals[500],

                    "&:active": {
                        backgroundColor: "transparent",
                        color: defaults.colors.neutrals[500],
                    },
                    "&:focus": {
                        backgroundColor: "transparent",
                        color: defaults.colors.neutrals[500],
                    },
                    "&:hover": {
                        backgroundColor: "transparent",
                        color: defaults.colors.neutrals[500],
                    },
                },
            },
        },

        light: {
            "&$primary": {
                backgroundColor: defaults.colors.primary[200],
                color: defaults.colors.primary[500],

                "&:hover": {
                    backgroundColor: defaults.colors.primary[500],
                    color: defaults.colors.neutrals[100],
                },

                "&:active": {
                    backgroundColor: defaults.colors.primary[500],
                    color: defaults.colors.neutrals[100],
                },

                "&:focus": {
                    backgroundColor: defaults.colors.primary[500],
                    boxShadow: defaults.shadows.small.wide,
                    color: defaults.colors.neutrals[100],
                },

                "&:hover:active": {
                    backgroundColor: defaults.colors.primary[500],
                    color: defaults.colors.neutrals[100],
                },

                "&$disabled": {
                    ...disabled,
                },
            },

            "&$secondary": {
                backgroundColor: hexToRgba(defaults.colors.neutrals[900], 0.04),
                color: defaults.colors.neutrals[900],

                "&:hover": {
                    backgroundColor: hexToRgba(defaults.colors.neutrals[900], 0.1),
                    color: defaults.colors.neutrals[900],
                },

                "&:active": {
                    backgroundColor: hexToRgba(defaults.colors.neutrals[900], 0.1),
                    color: defaults.colors.neutrals[900],
                },

                "&:focus": {
                    backgroundColor: hexToRgba(defaults.colors.neutrals[900], 0.1),
                    boxShadow: defaults.shadows.small.wide,
                },

                "&:hover:active": {
                    backgroundColor: hexToRgba(defaults.colors.neutrals[900], 0.1),
                    color: defaults.colors.neutrals[900],
                },

                "&$disabled": {
                    ...disabled,
                },
            },

            "&$tertiary": {
                backgroundColor: hexToRgba(defaults.colors.neutrals[100], 0.1),
                color: defaults.colors.neutrals[100],

                "&:hover": {
                    backgroundColor: hexToRgba(defaults.colors.neutrals[100], 0.2),
                    color: defaults.colors.neutrals[100],
                },

                "&:active": {
                    backgroundColor: hexToRgba(defaults.colors.neutrals[100], 0.2),
                    color: defaults.colors.neutrals[800],
                },

                "&:focus": {
                    backgroundColor: hexToRgba(defaults.colors.neutrals[100], 0.2),
                    boxShadow: defaults.shadows.small.wide,
                },

                "&:hover:active": {
                    backgroundColor: hexToRgba(defaults.colors.neutrals[100], 0.2),
                    color: defaults.colors.neutrals[900],
                },

                "&$disabled": {
                    ...disabled,
                },
            },
        },

        "size-small": {
            fontSize: defaults.fontSizes[11],
            padding: `1em calc(1em * 1.15)`,

            "&$plain": {
                padding: 0,
            },
            "&$tight": {
                padding: defaults.spacing[2],
            },

            "& svg": {
                fontSize: defaults.fontSizes[13],
            },
        },

        "size-normal": {
            fontSize: defaults.fontSizes[13],
            height: 36,
        },

        "size-large": {
            fontSize: defaults.fontSizes[20],
        },

        fullWidth: {
            width: "100%",
        },

        tight: {
            height: "auto",
            padding: `${defaults.spacing[6]}px ${defaults.spacing[10]}px`,
            fontSize: defaults.fontSizes[11],
        },

        prefixed: {},

        suffixed: {},

        disabled: {
            ...disabled,
        },
    }
}

export default styles
