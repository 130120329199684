import React, { useCallback, useEffect, useRef, useState } from "react"
import devMode from "../../utils/devMode"

export const useCheckbox = (
    defaultChecked?: boolean,
    externalChecked?: boolean,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onFocus?: (e: React.FocusEvent) => void,
    onBlur?: (e: React.FocusEvent) => void
) => {
    const firstMount = useRef<boolean>(true)
    const inputRef = useRef<HTMLInputElement>(null)

    // states
    const [checked, setChecked] = useState<boolean>(() => {
        if (externalChecked !== undefined) {
            return externalChecked
        } else if (defaultChecked !== undefined) {
            return defaultChecked
        }

        return false
    })
    const [focused, setFocused] = useState<boolean>(false)

    // handlers
    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setChecked(event.target.checked)
            if (onChange) {
                if (event.persist) {
                    event.persist()
                }

                onChange(event)
            }
        },
        [onChange]
    )

    const handleOnKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.persist) {
                event.persist()
            }

            if ((event.key === "Enter" || event.key === " ") && inputRef.current) {
                inputRef.current.click()
            }
        },
        [inputRef.current]
    )

    const handleOnFocus = useCallback(
        (e: React.FocusEvent) => {
            setFocused(true)

            if (onFocus) {
                if (e.persist) {
                    e.persist()
                }
                onFocus(e)
            }
        },
        [onFocus]
    )

    const handleOnBlur = useCallback(
        (e: React.FocusEvent) => {
            setFocused(false)

            if (onBlur) {
                if (e.persist) {
                    e.persist()
                }
                onBlur(e)
            }
        },
        [onBlur]
    )

    // effects
    useEffect(() => {
        if (typeof externalChecked === "boolean" && externalChecked !== checked) {
            setChecked(externalChecked)
        }
    }, [externalChecked])

    useEffect(() => {
        if (firstMount.current === false && devMode) {
            // tslint:disable-next-line:no-console
            console.error(
                `'defaultChecked' cannot be altered after mount, use 'externalChecked' option!`
            )
        }

        firstMount.current = false
    }, [defaultChecked])

    return {
        inputRef,
        checked,
        focused,
        handleOnBlur,
        handleOnFocus,
        handleOnKeyPress,
        handleOnChange,
    }
}
