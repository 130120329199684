import defaultsOrg from "../../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    listContainer: {
        border: `1px solid ${defaults.colors.neutrals[500]}`,
        borderRadius: defaults.cornerRadius.normal,
        boxShadow: defaults.shadows.small.tight,
        boxSizing: "border-box",
    },
    list: {
        listStyleType: "none",
        transition: defaults.transitions.interactions.fast,
        zIndex: defaults.zIndex.Select,
        padding: 0,
        margin: 0,
        maxHeight: "40vh",
        overflow: "auto",
    },
})

export default styles
