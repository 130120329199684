import { ParseResult } from "papaparse"
import * as Papa from "papaparse"
import React, { useEffect, useState } from "react"
import { Table } from "../Table"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface IFilePreviewCsvProps {
    src: string
    onCsvLoaded?: () => void
}

const useStyles = makeStyles(styles, "FilePreviewCsv", true)
export const FilePreviewCsv: React.FC<IFilePreviewCsvProps> = (props) => {
    const { src, onCsvLoaded } = props
    const [csvRawContent, setRawCsvContent] = useState<string | null>(null)
    const [parsedCsvContent, setParsedCsvContent] = useState<ParseResult<any> | null>(null)
    const [headers, setHeaders] = useState<string[] | null>(null)
    const [isCsvLoading, setIsCsvLoading] = useState(true)
    const classes = useStyles()

    // First read the url into raw text
    useEffect(() => {
        const readUrlIntoText = async () => {
            const req = new Request(src)
            const res = await fetch(req)
            if (res.ok) {
                const text = await res.text()
                setRawCsvContent(text)
            }
        }

        readUrlIntoText()
    }, [])

    // Parse the csv
    useEffect(() => {
        if (!csvRawContent) {
            return
        }

        const internalParseCsv = async () => {
            const parsedCsv = Papa.parse(csvRawContent, { header: true })
            setParsedCsvContent(parsedCsv)
        }

        internalParseCsv()
    }, [csvRawContent])

    // Set headers
    useEffect(() => {
        if (parsedCsvContent && parsedCsvContent.data) {
            const justOneItem = parsedCsvContent.data[0]
            const headers = Object.keys(justOneItem)
            setHeaders(headers)
        }
    }, [parsedCsvContent])

    // Set loading state to false
    useEffect(() => {
        if (parsedCsvContent) {
            setIsCsvLoading(false)
        }
    }, [parsedCsvContent])

    // Call loaded callback, if provided
    useEffect(() => {
        if (!isCsvLoading && onCsvLoaded) {
            onCsvLoaded()
        }
    }, [parsedCsvContent])

    return (
        <Table className={classes.csvTable} role="filepreview-csv">
            <Table.Head>
                <Table.Row>
                    {headers &&
                        headers.map((header) => <Table.Header key={header}>{header}</Table.Header>)}
                </Table.Row>
            </Table.Head>
            <Table.Body>
                {parsedCsvContent &&
                    parsedCsvContent.data.map((rowData, index) => {
                        const cols = []
                        for (const rowProperty in rowData) {
                            if (rowData[rowProperty]) {
                                cols.push(
                                    <Table.Cell key={`row-${index}-cell-${rowProperty}`}>
                                        {rowData[rowProperty]}
                                    </Table.Cell>
                                )
                            }
                        }

                        return <Table.Row key={`row-${index}`}>{cols}</Table.Row>
                    })}
            </Table.Body>
        </Table>
    )
}
