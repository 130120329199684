import hexToRgba from "hex-to-rgba"
import defaultsOrg from "../../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    input: {
        backgroundColor: "transparent",
        border: `none`,
        color: defaults.colors.neutrals[800],
        flexGrow: 1,
        fontSize: defaults.fontSizes[14],
        letterSpacing: 0,
        outline: 0,
        padding: defaults.spacing[16],
        width: "100%",
        boxSizing: "border-box",

        "&::placeholder": {
            color: hexToRgba(defaults.colors.neutrals[800], 0.5),
        },
    },
    inputContainer: {
        backgroundColor: defaults.colors.neutrals[100],
        border: `1px solid ${defaults.colors.neutrals[500]}`,
        borderRadius: defaults.cornerRadius.normal,
        boxShadow: defaults.shadows.small.tight,
        outline: 0,
        position: "relative",
        transition: defaults.transitions.interactions.fast,
        width: "100%",
    },

    disabled: {
        opacity: 0.5,
        pointerEvents: "none",
    },

    focused: {
        border: `1px solid ${defaults.colors.primary[400]}`,
        boxShadow: defaults.shadows.small.wide,
    },

    hasError: {
        borderColor: defaults.colors.accents.error[400],
    },

    notice: {
        borderColor: defaults.colors.accents.notice["500"],
    },

    tight: {
        "& $input": {
            padding: defaults.spacing[8],
        },
    },

    prefix: {
        lineHeight: 1,
        maxHeight: "1em",
        paddingLeft: defaults.spacing[8],

        "& svg": {
            height: "1em",
            width: "1em",
        },
    },
    suffix: {
        paddingRight: defaults.spacing[8],
    },
    s: {
        "& $input": {
            padding: defaults.spacing[10],
        },
    },
    m: {
        "& $input": {
            padding: defaults.spacing[12],
        },
    },
    l: {
        "& $input": {
            padding: defaults.spacing[16],
        },
    },
})

export default styles
