import { GenerateId } from "jss"

export function createGenerateClassName(): GenerateId {
    let ruleCounter = 0

    const getNextRuleCounter = () => {
        ruleCounter += 1
        return ruleCounter
    }

    return (_rule, styleSheet) => {
        const name = styleSheet?.options?.meta

        return name ? `clrhs-${name}-${getNextRuleCounter()}` : `clrhs-${getNextRuleCounter()}`
    }
}
