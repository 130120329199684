import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const HomeIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...otherProps } = props
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            width={size}
            height={size}
            color={color}
            {...otherProps}
        >
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
            <polyline points="9 22 9 12 15 12 15 22" />
        </svg>
    )
}

export { HomeIcon }
