import classNames from "classnames"
import React, { useMemo } from "react"

import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface IProgressBarProps {
    value: number
    size?: "xxs" | "xs" | "s" | "tiny"
    style?: React.CSSProperties
    kind?: "tiny"
    className?: string
}

interface IProgressBarInnerProps extends IProgressBarProps {}

const useStyles = makeStyles(styles, "ProgressBar", true)
export const ProgressBarInner: React.FC<IProgressBarInnerProps> = (props) => {
    const { value, className } = props
    const classes = useStyles()
    const currentValue = value > 100 ? 100 : value

    const allClasses = useMemo(() => {
        return classNames(classes.inner, className)
    }, [classes, className])

    return (
        <div
            className={allClasses}
            style={{ width: `${currentValue}%` }}
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin={0}
            aria-valuemax={100}
        />
    )
}

export const ProgressBar: React.FC<IProgressBarProps> = (props) => {
    const { value, style, size } = props
    const _classes = useStyles()

    const classes = classNames(
        [
            {
                [_classes.containerXxs]: size === "xxs",
                [_classes.containerXs]: size === "xs",
                [_classes.containerTiny]: size === "tiny",
            },
        ],
        _classes.container
    )

    return (
        <div className={classes} style={style}>
            <ProgressBarInner value={value} />
        </div>
    )
}
