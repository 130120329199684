import { StyleSheetFactoryOptions } from "jss"
import { useContext, useRef } from "react"
import defaults from "../../defaults"
import { ThemeContext } from "../../ThemeProvider"
import { multiKeyStore } from "../../utils/styles/multiKeyStore"
import { useSynchronousEffect } from "../../utils/useSynchronousEffect"

type StylesFn<C extends string> = (settings: typeof defaults) => Record<C, any>
export type Styles<C extends string> = Record<C, any> | StylesFn<C>

let indexCounter = -1e9

function increment() {
    indexCounter += 1
    return indexCounter
}

export function makeStyles<C extends string>(
    styles: Styles<C>,
    name: string = "",
    noDetach: boolean = false
) {
    const defaultJssOptions = {
        meta: name,
        index: increment(),
    }

    const useStyles = () => {
        const theme = useContext(ThemeContext)

        const generatedStylesRef = useRef<Record<C, any>>()
        const classesRef = useRef()

        const jssOptions: StyleSheetFactoryOptions = {
            ...defaultJssOptions,
            generateId: theme.generateClassName,
        }

        useSynchronousEffect(() => {
            let sheetManager = multiKeyStore.get(theme.sheetsManager, styles, theme)
            if (!sheetManager) {
                sheetManager = {
                    refs: 0,
                    staticSheet: null,
                    noDetach,
                }
                multiKeyStore.set(theme.sheetsManager, styles, theme, sheetManager)
            }

            if (sheetManager.refs === 0) {
                generatedStylesRef.current =
                    typeof styles === "function" ? styles(theme.defaults) : styles
                sheetManager.staticSheet = theme.jss.createStyleSheet(generatedStylesRef.current, {
                    link: false,
                    ...jssOptions,
                })
                sheetManager.staticSheet.attach()
            }

            classesRef.current = sheetManager.staticSheet.classes

            sheetManager.refs += 1

            return () => {
                const sheetManager = multiKeyStore.get(theme.sheetsManager, styles, theme)
                sheetManager.refs -= 1

                if (sheetManager.noDetach && sheetManager.refs === 0) {
                    // Do not allow refs to become zero when 'noDetach' is set
                    sheetManager.refs = 1
                }

                if (sheetManager.refs === 0) {
                    multiKeyStore.delete(theme.sheetsManager, styles, theme)
                    if (sheetManager.staticSheet) {
                        theme.jss.removeStyleSheet(sheetManager.staticSheet)
                    }
                }
            }
        }, [theme, styles])

        return (classesRef.current ? classesRef.current : {}) as Record<C, string>
    }

    return useStyles
}
