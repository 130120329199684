import React from "react"

interface ISamsungPayLogoProps {
    height: number
}

export const SamsungPayLogo: React.FC<ISamsungPayLogoProps> = (props) => {
    const { height = 24 } = props

    return (
        <svg height={height} viewBox="0 0 161 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M119.25 1.6425H124.721C128.025 1.6425 130.475 4.18063 130.475 7.33236C130.475 10.5046 128.025 13.0428 124.699 13.0428H122.226V17.6166H119.25V1.6425ZM122.226 4.39988V10.2862H124.502C126.275 10.2862 127.478 8.97293 127.478 7.33236C127.478 5.71234 126.275 4.39988 124.502 4.39988H122.226Z"
                fill="black"
            />
            <path
                d="M131.045 11.227C131.045 6.98203 134.153 4.55277 137.391 4.55277C139.077 4.55277 140.585 5.25315 141.504 6.34712V4.81541H144.459V17.6164H141.504V15.953C140.564 17.1346 139.032 17.8791 137.347 17.8791C134.262 17.8791 131.045 15.4064 131.045 11.227ZM141.658 11.1836C141.658 9.01695 140.016 7.24392 137.829 7.24392C135.641 7.24392 133.955 8.9728 133.955 11.1836C133.955 13.4149 135.641 15.1659 137.829 15.1659C140.016 15.1659 141.658 13.3936 141.658 11.1836"
                fill="black"
            />
            <path
                d="M150.674 16.9816L145.422 4.8155H148.573L152.14 13.4371L155.51 4.8155H158.617L150.98 23.5249H147.983L150.674 16.9816Z"
                fill="black"
            />
            <path
                d="M88.1453 2.09122L88.3486 13.8188H88.2656L84.8322 2.09122H79.2878V16.8769H82.9609L82.7554 4.74506H82.8369L86.5253 16.8769H91.8451V2.09122H88.1453Z"
                fill="black"
            />
            <path
                d="M17.8171 2.09122L15.0475 17.0307H19.0862L21.1295 3.47447L21.2124 3.47371L23.204 17.0307H27.2182L24.4631 2.09122H17.8171Z"
                fill="black"
            />
            <path
                d="M40.391 2.09122L38.5479 13.5143H38.4642L36.6241 2.09122H30.53L30.1996 17.0307H33.9391L34.0327 3.60009H34.1165L36.6112 17.0307H40.4032L42.8987 3.60161L42.9809 3.60009L43.0745 17.0307H46.8124L46.482 2.09122H40.391Z"
                fill="black"
            />
            <path
                d="M8.75973 12.8161C8.90589 13.1777 8.85945 13.6405 8.78942 13.9214C8.66761 14.4193 8.3296 14.9263 7.33383 14.9263C6.39974 14.9263 5.83258 14.3858 5.83258 13.572L5.82953 12.124H1.82287L1.82211 13.2759C1.82211 16.608 4.44399 17.6152 7.25238 17.6152C9.95419 17.6152 12.1771 16.6933 12.5319 14.1993C12.7139 12.9097 12.5814 12.0647 12.5167 11.7503C11.8856 8.62213 6.21779 7.68879 5.79451 5.94012C5.72371 5.6356 5.74046 5.32119 5.77852 5.15447C5.8851 4.67486 6.20941 4.15109 7.14351 4.15109C8.01823 4.15109 8.53134 4.69085 8.53134 5.50466V6.43038H12.2601V5.37752C12.2601 2.12379 9.33602 1.61525 7.22116 1.61525C4.56655 1.61525 2.39536 2.4953 1.99873 4.93141C1.89139 5.59754 1.8754 6.19362 2.03375 6.94654C2.68161 10.0001 7.99006 10.8824 8.75973 12.8161Z"
                fill="black"
            />
            <path
                d="M57.3326 12.7878C57.475 13.1471 57.4316 13.6024 57.3615 13.8818C57.2405 14.3736 56.9063 14.8753 55.9204 14.8753C54.9954 14.8753 54.4328 14.3416 54.4328 13.5339L54.4298 12.1027H50.4642L50.4627 13.2423C50.4627 16.5425 53.0602 17.5375 55.8389 17.5375C58.5156 17.5375 60.7158 16.6262 61.0675 14.1558C61.2471 12.8799 61.1154 12.044 61.0515 11.7311C60.425 8.63271 54.8142 7.70927 54.3978 5.9781C54.3247 5.67892 54.343 5.36755 54.3788 5.20235C54.4846 4.72731 54.8066 4.20811 55.7301 4.20811C56.5987 4.20811 57.1057 4.74101 57.1057 5.54874V6.46304H60.7972V5.42313C60.7972 2.20136 57.902 1.69891 55.8085 1.69891C53.1813 1.69891 51.0306 2.56754 50.6393 4.98082C50.5312 5.64085 50.5168 6.23085 50.6736 6.97463C51.3138 9.99998 56.5705 10.8724 57.3326 12.7878Z"
                fill="black"
            />
            <path
                d="M69.8745 14.7944C70.9136 14.7944 71.2326 14.0788 71.3072 13.7111C71.3384 13.5505 71.3437 13.3328 71.3422 13.1409V2.08855H75.1243V12.8006C75.1281 13.0754 75.0999 13.6373 75.0862 13.7842C74.8251 16.5713 72.6204 17.4757 69.8745 17.4757C67.127 17.4757 64.9223 16.5713 64.6612 13.7842C64.6482 13.6373 64.6193 13.0754 64.6269 12.8006V2.08855H68.4059V13.1409C68.4014 13.3328 68.409 13.5505 68.441 13.7111C68.514 14.0788 68.8345 14.7944 69.8745 14.7944Z"
                fill="black"
            />
            <path
                d="M101.035 14.639C102.117 14.639 102.494 13.9546 102.562 13.555C102.593 13.3867 102.6 13.1774 102.598 12.9886V10.8181H101.066V8.6401H106.357V12.6452C106.356 12.9261 106.349 13.1317 106.304 13.6288C106.057 16.3496 103.698 17.321 101.05 17.321C98.4011 17.321 96.0434 16.3496 95.7945 13.6288C95.7526 13.1317 95.7443 12.9261 95.7435 12.6452L95.7443 6.36005C95.7443 6.09436 95.7762 5.62617 95.8074 5.37646C96.1401 2.58407 98.4011 1.68422 101.051 1.68422C103.7 1.68422 106.02 2.57493 106.295 5.37646C106.344 5.85227 106.327 6.36005 106.33 6.36005V6.85793H102.562V6.02051C102.564 6.02203 102.56 5.66575 102.515 5.45107C102.447 5.12219 102.163 4.367 101.021 4.367C99.9351 4.367 99.6161 5.08261 99.5324 5.45107C99.4905 5.64824 99.473 5.91469 99.473 6.1583V12.9886C99.4715 13.1774 99.4784 13.3867 99.5073 13.555C99.5773 13.9546 99.9549 14.639 101.035 14.639Z"
                fill="black"
            />
        </svg>
    )
}
