import classNames from "classnames"
import React, { useMemo } from "react"
import { BoxEdges, BoxEdgesProps } from "../BoxEdges"
import defaults, { ColorWeights } from "../defaults"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ISeparatorProps extends Omit<BoxEdgesProps, "children"> {
    direction?: "horizontal" | "vertical"
    colorWeight?: ColorWeights
    customColor?: string
    height?: number
    width?: number
    dashed?: boolean
}

const useStyles = makeStyles(styles, "Separator", true)
export const Separator: React.FC<ISeparatorProps> = (props) => {
    const classes = useStyles()
    const {
        direction = "horizontal",
        colorWeight = 300,
        customColor,
        height,
        width,
        dashed,
        ...boxEdgesProps
    } = props

    // @ts-ignore - @TODO
    const separatorClasses = classNames(classes[`${direction}`], classes.separator)

    const separatorStyle = useMemo(() => {
        const color = customColor ? customColor : defaults.colors.neutrals[colorWeight]
        const dashedGradiantDegree = direction === "horizontal" ? 90 : 0
        const dashedBackgroundSize = direction === "horizontal" ? "6px 1px" : "1px 6px"

        return {
            height,
            width,
            backgroundColor: !dashed ? color : "transparent",
            backgroundImage: dashed
                ? `linear-gradient(${dashedGradiantDegree}deg, ${color}, ${color} 50%, transparent 50%, transparent 100%)`
                : "none",
            backgroundSize: dashed ? dashedBackgroundSize : "auto",
        }
    }, [customColor, colorWeight, height])

    return (
        <BoxEdges {...boxEdgesProps} className={separatorClasses}>
            <div className={separatorClasses} style={separatorStyle} />
        </BoxEdges>
    )
}
