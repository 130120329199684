import hexToRgba from "hex-to-rgba"
import defaultsOrg, { GridColumns } from "../defaults"
import createBreakpoints from "../Grid/createBreakpoints"

export default (defaults: typeof defaultsOrg) => {
    const styles = {
        container: {
            position: "relative",
        },

        head: {
            boxShadow: defaults.shadows.large.tight,

            "&$noHeadShadow": {
                boxShadow: "none",
            },

            "&$border": {
                borderTop: `1px solid ${defaults.colors.neutrals[300]}`,
                borderBottom: `1px solid ${defaults.colors.neutrals[300]}`,
            },
        },

        header: {
            color: defaults.colors.neutrals[700],
            fontSize: defaults.fontSizes[13],
            fontWeight: defaults.fontWeights.medium,
            padding: `${defaults.spacing[16]}px`,
            textAlign: "left",

            "&$tight": {
                padding: `${defaults.spacing[10]}px`,
            },
            "&:first-child": {
                paddingLeft: `${defaults.spacing[32]}px`,

                "&$tight": {
                    paddingLeft: `${defaults.spacing[16]}px`,
                },
            },
            "&:last-child": {
                paddingRight: `${defaults.spacing[32]}px`,

                "&$tight": {
                    paddingRight: `${defaults.spacing[16]}px`,
                },
            },
        },

        table: {
            borderCollapse: "collapse",
            width: "100%",

            "&$fixedLayout": {
                tableLayout: "fixed",
            },

            "&$noStripes $row": {
                "&:nth-child(even)": {
                    backgroundColor: "transparent",
                },
            },
        },

        noStripes: {},

        noHeadShadow: {},

        border: {},

        row: {
            borderBottom: `1px solid ${defaults.colors.neutrals[300]}`,

            "&$clickable": {
                cursor: "pointer",

                "&:hover": {
                    backgroundColor: defaults.colors.primary[100],
                },
            },

            "&:nth-child(even)": {
                backgroundColor: hexToRgba(defaults.colors.primary[200], 0.25),
            },

            "&:last-of-type": {
                borderBottom: 0,
            },
        },

        cell: {
            padding: `${defaults.spacing[24]}px ${defaults.spacing[16]}px`,

            "&$highlighted": {
                fontWeight: defaults.fontWeights.medium,
            },
            "&$tight": {
                padding: `${defaults.spacing[10]}px`,
            },
            "&:first-child": {
                paddingLeft: `${defaults.spacing[32]}px`,

                "&$tight": {
                    paddingLeft: `${defaults.spacing[16]}px`,
                },
            },
            "&:last-child": {
                paddingRight: `${defaults.spacing[32]}px`,

                "&$tight": {
                    paddingRight: `${defaults.spacing[16]}px`,
                },
            },
        },
        noWrap: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        textAlign: {
            "&$center": {
                textAlign: "center",
            },
            "&$left": {
                textAlign: "left",
            },
            "&$right": {
                textAlign: "right",
            },
        },
        textIndent: {
            textIndent: defaults.spacing[16],
        },
        horizontalScroll: {
            overflowX: "auto",
        },

        center: {},
        clickable: {},
        fixedLayout: {},
        highlighted: {},
        left: {},
        right: {},
        tight: {},
    }

    const breakpoints = createBreakpoints(defaults.grid.breakpoints)
    breakpoints.keys.forEach((breakpointKey) => {
        const currentBreakpointStyles = {}

        GridColumns.forEach((size) => {
            const key = `table-cell-${breakpointKey}-${size}`

            // Only keep 6 significant numbers.
            const width = `${Math.round(((size as number) / GridColumns.length) * 10e6) / 10e4}%`

            // @ts-ignore - @TODO
            currentBreakpointStyles[key] = {
                maxWidth: width,
                width,
            }

            const emptyObj = {
                [key]: {},
            }
            Object.assign(styles, emptyObj)
        })

        if (breakpointKey === "xs") {
            Object.assign(styles, currentBreakpointStyles)
        } else {
            // @ts-ignore - @TODO
            styles[breakpoints.min(breakpointKey as breakpointValues)] = currentBreakpointStyles
        }
    })

    return styles
}
