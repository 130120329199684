import classNames from "classnames"
import React, { createContext, useCallback, useContext, useMemo, useState } from "react"
import { Button, CrossIcon } from "../"
import { BoxEdges } from "../BoxEdges"
import { Card } from "../Card"
import { ColorNames } from "../defaults"
import { Grid } from "../Grid"
import { Heading } from "../Heading"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

const NoticeBoxContext = createContext<{} | null>(null)

interface INoticeBoxProps {
    /**
     * Is this notice dismissable?
     * @default false
     */
    dismissable?: boolean
    /**
     * Called once a notice has been dismissed
     */
    onDismissed?: () => void
    /**
     * Make the NoticeBox's width fill to the parent width
     * @default true
     */
    fill?: boolean
    /**
     * Tighten up the NoticeBox's paddings
     * @default false
     */
    tight?: boolean
    /**
     * Remove paddings from the inner container of the NoticeBox
     * @default false
     */
    noPadding?: boolean
    children?: React.ReactNode
    /** One of the color names from defaults
     * @default accents-notice
     */
    kind?: ColorNames
    /** Remove shadow
     * @default false
     */
    flatten?: boolean
}

const useStyles = makeStyles(styles, "NoticeBox", true)
const NoticeBox = (props: INoticeBoxProps) => {
    const {
        fill = true,
        tight = false,
        noPadding = false,
        kind = "accents-notice",
        dismissable = false,
        onDismissed,
    } = props
    const classes = useStyles()

    const [dismissed, setDismissed] = useState(false)

    const contextValue = useMemo(() => ({}), [])

    const classnames = classNames(classes.container, {
        // @ts-ignore - @TODO
        [classes[kind]]: true,
        [classes.dismis]: dismissable,
    })

    const headerChild = React.Children.map(props.children, (c) => {
        // @ts-ignore : Non-standard approach
        if (c.type && c.type.displayName === "Header") {
            return c
        }
        return null
    })
    const restChildren = useMemo(
        () =>
            React.Children.map<React.ReactNode, React.ReactNode>(props.children, (c) => {
                // @ts-ignore : Non-standard approach
                if (c.type && c.type.displayName !== "Header") {
                    return c
                }
                return null
            }),
        [props.children]
    )

    const cardStyle = useMemo(() => {
        return {
            width: !fill ? "auto" : undefined,
        }
    }, [fill])

    const shade = useMemo(() => {
        if (kind === "neutrals") {
            return
        }

        return 200
    }, [kind])

    const frameShade = useMemo(() => {
        if (kind === "neutrals") {
            return
        }

        return 300
    }, [kind])

    const handleOnDismisClick = useCallback(() => {
        setDismissed(true)
        if (onDismissed) {
            onDismissed()
        }
    }, [onDismissed])

    if (dismissed) {
        return null
    }

    return (
        <NoticeBoxContext.Provider value={contextValue}>
            <Card
                style={cardStyle}
                kind={kind}
                shade={shade}
                frame
                frameKind={kind}
                frameShade={frameShade}
                className={classnames}
            >
                <Card.Content noSpacing={tight || noPadding}>
                    {dismissable && (
                        <div className={classes.dismis}>
                            <Button
                                kind={"plain"}
                                tight
                                className={classes.button}
                                onClick={handleOnDismisClick}
                            >
                                <CrossIcon />
                            </Button>
                        </div>
                    )}
                    <BoxEdges
                        py={tight ? 16 : noPadding ? 0 : 24}
                        px={tight ? 16 : noPadding ? 0 : 32}
                        className={classes.innerContainer}
                    >
                        {headerChild && <Grid row>{headerChild}</Grid>}
                        {restChildren && (
                            <Grid row alignItems="center">
                                {restChildren}
                            </Grid>
                        )}
                    </BoxEdges>
                </Card.Content>
            </Card>
        </NoticeBoxContext.Provider>
    )
}

const useNoticeBoxContext = () => {
    const context = useContext(NoticeBoxContext)
    if (!context) {
        throw new Error(
            `NoticeBox compound components cannot be rendered outside the <NoticeBox /> component`
        )
    }
    return context
}

const NoticeBoxHeaderContext = createContext<{} | null>(null)

const Header = (props: INoticeBoxProps) => {
    useNoticeBoxContext()

    const contextValue = useMemo(() => ({}), [])

    return (
        <NoticeBoxHeaderContext.Provider value={contextValue}>
            <Grid item>
                <Grid row alignItems="center" noGutters>
                    {props.children}
                </Grid>
            </Grid>
        </NoticeBoxHeaderContext.Provider>
    )
}

Header.displayName = "Header"
NoticeBox.Header = Header

const useNoticeBoxHeaderContext = () => {
    const context = useContext(NoticeBoxHeaderContext)
    if (!context) {
        throw new Error(
            `NoticeBox.Header compound components cannot be rendered outside the <NoticeBox.Header /> component`
        )
    }
    return context
}

const HeaderIcon = (props: INoticeBoxProps) => {
    useNoticeBoxHeaderContext()

    return (
        <Grid item auto>
            <BoxEdges mt={2} mr={12}>
                {props.children}
            </BoxEdges>
        </Grid>
    )
}

NoticeBox.HeaderIcon = HeaderIcon

interface INoticeBoxHeaderTitleProps {
    color?: string
    children?: JSX.Element | string
}

const HeaderTitle = (props: INoticeBoxHeaderTitleProps) => {
    useNoticeBoxHeaderContext()

    return (
        <Grid item>
            <Heading level={4} color={props.color}>
                {props.children}
            </Heading>
        </Grid>
    )
}

NoticeBox.HeaderTitle = HeaderTitle

const Content = (props: INoticeBoxProps) => {
    useNoticeBoxContext()

    return <Grid item>{props.children}</Grid>
}

NoticeBox.Content = Content

export { NoticeBox }
