import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"

interface Props extends React.SVGProps<SVGSVGElement> {
    backgroundColor?: string
    iconColor?: string
    pointerColor?: string
    size?: number
}

const SendClickIcon: React.FC<Props> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        backgroundColor = defaults.colors.primary[500],
        iconColor = defaults.colors.neutrals[100],
        pointerColor = defaults.colors.neutrals[100],
        size = 24,
        ...otherProps
    } = props
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                d="M0.585449 10.2198C0.585449 5.8182 4.06663 2.25 8.36087 2.25H11.1883C15.4825 2.25 18.9637 5.8182 18.9637 10.2198V10.2198C18.9637 14.6214 15.4825 18.1896 11.1883 18.1896H8.36088C4.06663 18.1896 0.585449 14.6214 0.585449 10.2198V10.2198Z"
                fill={backgroundColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.947 6.24361C13.1375 6.43888 13.1375 6.75546 12.947 6.95072L9.0593 10.9356C8.8688 11.1309 8.55994 11.1309 8.36944 10.9356C8.17894 10.7404 8.17894 10.4238 8.36944 10.2285L12.2571 6.24361C12.4476 6.04835 12.7565 6.04835 12.947 6.24361Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.947 6.24363C13.0795 6.37945 13.1243 6.58106 13.0624 6.76236L10.5884 14.0076C10.5224 14.2011 10.3478 14.3337 10.1479 14.342C9.94807 14.3504 9.7635 14.2329 9.68227 14.0455L8.34475 10.9609L5.33535 9.58994C5.15257 9.50667 5.03791 9.31749 5.04606 9.11265C5.05422 8.90781 5.18353 8.72883 5.37231 8.6611L12.4409 6.12525C12.6178 6.0618 12.8145 6.10781 12.947 6.24363ZM6.85594 9.18833L8.91243 10.1252C9.02279 10.1755 9.11103 10.2659 9.16008 10.379L10.0741 12.4869L11.8069 7.41217L6.85594 9.18833Z"
                fill={iconColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2914 13.042C14.4661 12.8629 14.729 12.8096 14.9571 12.907L22.4542 16.1085C22.6883 16.2085 22.8376 16.4466 22.8291 16.7064C22.8205 16.9663 22.6559 17.1936 22.4158 17.2772L19.4356 18.3147L18.4234 21.3694C18.3418 21.6156 18.12 21.7843 17.8665 21.7931C17.613 21.8018 17.3807 21.6488 17.2831 21.4088L14.1597 13.7243C14.0647 13.4905 14.1167 13.221 14.2914 13.042ZM15.8549 14.6445L17.7879 19.4004L18.3775 17.621C18.4384 17.4371 18.5793 17.2927 18.7588 17.2302L20.4947 16.6259L15.8549 14.6445Z"
                fill={pointerColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7094 17.5703C18.9475 17.3262 19.3336 17.3262 19.5717 17.5703L22.2224 20.2872C22.4605 20.5313 22.4605 20.9271 22.2224 21.1711C21.9843 21.4152 21.5982 21.4152 21.3601 21.1711L18.7094 18.4541C18.4712 18.2101 18.4712 17.8143 18.7094 17.5703Z"
                fill={pointerColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4391 8.56817C14.1706 8.54616 13.9355 8.75147 13.914 9.02673L13.7897 10.6205C13.7682 10.8958 13.9685 11.1368 14.237 11.1588C14.5056 11.1808 14.7407 10.9755 14.7622 10.7002L14.8865 9.10644C14.908 8.83117 14.7077 8.59019 14.4391 8.56817Z"
                fill={pointerColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.445 10.3035C17.2703 10.0933 16.9624 10.068 16.7573 10.2471L15.5699 11.284C15.3648 11.463 15.3402 11.7786 15.5149 11.9888C15.6896 12.199 15.9975 12.2243 16.2026 12.0452L17.39 11.0083C17.5951 10.8292 17.6197 10.5137 17.445 10.3035Z"
                fill={pointerColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6272 9.21243C11.8323 9.03335 12.1402 9.05859 12.3149 9.26879L13.3265 10.4859C13.5012 10.6961 13.4765 11.0117 13.2715 11.1908C13.0664 11.3698 12.7585 11.3446 12.5838 11.1344L11.5722 9.91729C11.3975 9.70708 11.4221 9.39151 11.6272 9.21243Z"
                fill={pointerColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.1391 12.3234C10.1606 12.0481 10.3957 11.8428 10.6642 11.8648L12.2192 11.9923C12.4877 12.0143 12.688 12.2552 12.6665 12.5305C12.6451 12.8058 12.4099 13.0111 12.1414 12.9891L10.5865 12.8616C10.3179 12.8396 10.1176 12.5986 10.1391 12.3234Z"
                fill={pointerColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3329 15.6406C11.1582 15.4304 11.1829 15.1148 11.3879 14.9358L12.5753 13.8989C12.7804 13.7198 13.0883 13.7451 13.263 13.9553C13.4377 14.1655 13.4131 14.481 13.208 14.6601L12.0206 15.697C11.8155 15.8761 11.5076 15.8508 11.3329 15.6406Z"
                fill={pointerColor}
            />
        </svg>
    )
}

export { SendClickIcon }
