import { DependencyList, EffectCallback, useEffect, useMemo, useRef } from "react"

export function useSynchronousEffect(effect: EffectCallback, deps: DependencyList = []) {
    const key = useRef({})
    let output: ReturnType<EffectCallback>

    const currentKey = useMemo(() => ({}), deps)

    if (key.current !== currentKey) {
        key.current = currentKey
        output = effect()
    }

    useEffect(
        () => () => {
            if (output) {
                output()
            }
        },
        [currentKey]
    )
}
