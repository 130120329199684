import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { ColoredIcon } from "../types"

const UserColoredIcon: React.FC<ColoredIcon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const {
        stroke = defaults.colors.primary[500],
        fill = defaults.colors.neutrals[100],
        backgroundColor = defaults.colors.accents.success[300],
        size = 24,
        ...otherProps
    } = props

    return (
        <svg
            width={size}
            height={size}
            color={stroke}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#a)">
                <g clipPath="url(#b)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.298 2.831a2.283 2.283 0 0 1 2.33.064l11.7 7.39c1.466.925 1.406 3.081-.108 3.925L8.056 22.099a2.283 2.283 0 0 1-3.271-1.259L.803 9.15a2.283 2.283 0 0 1 1.05-2.73l6.445-3.589Z"
                        fill={backgroundColor}
                    />
                    <path
                        d="m8.825 5.4 2.438-.975h1.462l1.95.975v1.462l-.488 1.95-1.462.975h-1.95l-1.95-.975-.488-1.95.488-1.462ZM5.9 16.125v2.925h12.675v-2.925L17.6 14.37l-1.463-1.17H8.337l-1.462 1.17-.975 1.755Z"
                        fill={fill}
                    />
                    <path
                        d="M5.413 18.563h12.674"
                        stroke={stroke}
                        strokeWidth="1.628"
                        strokeLinecap="round"
                    />
                    <path
                        d="M18.25 18.563v-1.625a3.25 3.25 0 0 0-3.25-3.25H8.5a3.25 3.25 0 0 0-3.25 3.25v1.625"
                        stroke={stroke}
                        strokeWidth="1.625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18.25 18.563v-1.625a3.25 3.25 0 0 0-3.25-3.25H8.5a3.25 3.25 0 0 0-3.25 3.25v1.625M11.75 10.438a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
                        stroke={stroke}
                        strokeWidth="1.625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill={fill} d="M0 0h24v24H0z" />
                </clipPath>
                <clipPath id="b">
                    <path fill={fill} d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export { UserColoredIcon }
