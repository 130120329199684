import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const EdgeIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...otherProps } = props
    return (
        <svg
            width={size}
            height={size}
            color={color}
            {...otherProps}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            stroke="none"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m0 10.659h.014c.154-1.208.43-2.302.82-3.34l-.033.101c.414-1.11.933-2.071 1.565-2.945l-.025.036c.645-.902 1.386-1.68 2.222-2.342l.024-.018c.855-.663 1.85-1.199 2.927-1.555l.07-.02c1.079-.366 2.323-.577 3.615-.577h.097-.005c.052-.001.114-.001.175-.001 1.976 0 3.829.525 5.428 1.444l-.053-.028c1.667.985 3.006 2.365 3.914 4.015l.027.054c.881 1.628 1.398 3.565 1.398 5.622 0 .107-.001.214-.004.32v-.016 2.521h-15.083v.068c0 .931.268 1.8.732 2.533l-.012-.019c.449.706 1.07 1.266 1.804 1.631l.026.012c.738.36 1.593.628 2.494.758l.047.006c.473.075 1.019.118 1.574.118.452 0 .897-.028 1.334-.084l-.052.005c1.043-.127 1.986-.346 2.889-.653l-.1.03c.886-.289 1.655-.674 2.354-1.155l-.03.02v5.054c-.886.515-1.91.937-2.992 1.215l-.084.018c-1.225.325-2.632.512-4.083.512-.038 0-.076 0-.113 0h.006c-.071.002-.155.002-.24.002-1.436 0-2.811-.261-4.08-.739l.08.026c-1.733-.686-3.157-1.84-4.157-3.307l-.02-.031c-1.014-1.384-1.635-3.112-1.668-4.983v-.008c-.006-.122-.009-.266-.009-.41 0-1.898.558-3.665 1.519-5.147l-.022.037c1.092-1.568 2.568-2.798 4.294-3.568l.066-.026c-.396.487-.745 1.039-1.025 1.631l-.021.049c-.277.619-.492 1.338-.61 2.089l-.006.049h8.514c.024-.191.038-.413.038-.638 0-.44-.053-.867-.153-1.276l.008.037c-.119-.52-.338-.977-.637-1.369l.006.009c-.274-.34-.585-.634-.931-.882l-.014-.01c-.31-.226-.667-.413-1.052-.542l-.028-.008q-.55-.174-1.006-.275c-.217-.054-.474-.095-.737-.113l-.014-.001-.295-.014c-1.257.044-2.448.259-3.572.623l.092-.026c-1.142.358-2.135.834-3.043 1.429l.046-.028c-.885.58-1.657 1.2-2.364 1.887l.004-.003c-.671.658-1.278 1.376-1.816 2.145l-.032.049z" />
        </svg>
    )
}

export { EdgeIcon }
