import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const PlusIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...otherProps } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
    )
}

export { PlusIcon }
