import React from "react"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface IMediumProps {
    children: React.ReactNode
}

const useStyles = makeStyles(styles, "Medium", true)
export const Medium: React.FC<IMediumProps> = (props) => {
    const classes = useStyles()

    return <span className={classes.medium}>{props.children}</span>
}
