import hexToRgba from "hex-to-rgba"
import defaultsOrg from "../../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        backgroundColor: defaults.colors.neutrals[100],
        border: `1px solid ${defaults.colors.neutrals[500]}`,
        borderRadius: defaults.cornerRadius.normal,
        boxShadow: defaults.shadows.small.tight,
        display: "flex",
        height: "100%",
        justifyContent: "center",
        position: "relative",

        "&$light": {
            backgroundColor: defaults.colors.neutrals[800],
        },

        "&$simple": {
            backgroundColor: "transparent",
            border: 0,
            boxShadow: "none",

            "&$focused": {
                border: 0,
                boxShadow: "none",
                color: defaults.colors.primary[500],

                "& $checkbox": {
                    border: `1px solid ${defaults.colors.primary[500]}`,
                },

                "&$light": {
                    "& $checkbox": {
                        border: `1px solid ${hexToRgba(defaults.colors.neutrals[100], 0.5)}`,
                    },
                },
            },
        },

        "&$focused": {
            border: `1px solid ${defaults.colors.primary[400]}`,
            boxShadow: defaults.shadows.small.wide,
        },
    },

    disabled: {
        opacity: 0.5,
        pointerEvents: "none",
    },

    containerChecked: {
        backgroundColor: defaults.colors.primary[100],
        border: `1px solid ${defaults.colors.primary[500]}`,
    },

    input: {
        cursor: "pointer",
        height: 0,
        opacity: 0,
        position: "absolute",
        width: 0,
    },

    caption: {
        cursor: "pointer",
        fontSize: defaults.fontSizes[14],
        lineHeight: defaults.lineHeights.m,
        marginTop: defaults.spacing[6],
        padding: defaults.spacing[32],
        textAlign: "center",
        width: "100%",

        "&$row": {
            alignItems: "center",
            display: "flex",
            lineHeight: defaults.lineHeights.s,
            marginTop: defaults.spacing[0],
            padding: defaults.spacing[16],
        },

        "&$simple": {
            alignItems: "center",
            display: "flex",
            marginTop: defaults.spacing[0],
            padding: 0,
        },

        "&$light": {
            color: defaults.colors.neutrals[100],
        },
    },

    checkbox: {
        alignItems: "center",
        border: `1px solid ${defaults.colors.neutrals[600]}`,
        borderRadius: defaults.cornerRadius.normal,
        boxSizing: "border-box",
        display: "flex",
        height: 20,
        justifyContent: "center",
        position: "absolute",
        right: 10,
        top: 10,
        width: 20,

        "&$row": {
            marginRight: defaults.spacing[16],
            position: "static",
        },

        "&$simple": {
            marginRight: defaults.spacing[16],
            position: "static",
        },

        "&$tight": {
            marginRight: defaults.spacing[8],
        },

        "&$light": {
            borderColor: hexToRgba(defaults.colors.neutrals[100], 0.5),
        },
    },

    checked: {
        backgroundColor: defaults.colors.primary[500],
        borderColor: defaults.colors.primary[500],
        padding: defaults.spacing[2],

        "&>svg": {
            height: "100%",
            width: "100%",
        },
    },

    prefix: {
        display: "flex",
        justifyContent: "center",
        marginBottom: defaults.spacing[8],

        "&$row": {
            marginBottom: defaults.spacing[0],
            marginRight: defaults.spacing[12],
        },

        "&$simple": {
            marginBottom: defaults.spacing[0],
            marginRight: defaults.spacing[12],
        },
    },

    focused: {},

    simple: {},

    light: {},

    row: {},

    tight: {},
})

export default styles
