import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    weightBold: {
        fontWeight: defaults.fontWeights.semibold,
    },

    weightMedium: {
        fontWeight: defaults.fontWeights.medium,
    },

    weightSemibold: {
        fontWeight: defaults.fontWeights.semibold,
    },

    weightNormal: {
        fontWeight: defaults.fontWeights.normal,
    },

    paragraph: {
        margin: 0,

        "& + $xxxs": {
            marginTop: `calc(1em * ${defaults.lineHeights.s})`,
        },
        "& + $xxs": {
            marginTop: `calc(1em * ${defaults.lineHeights.s})`,
        },
        "& + $xs": {
            marginTop: `calc(1em * ${defaults.lineHeights.s})`,
        },
        "& + $s": {
            marginTop: `calc(1em * ${defaults.lineHeights.s})`,
        },
        "& + $m": {
            marginTop: `calc(1em * ${defaults.lineHeights.m})`,
        },
        "& + $l": {
            marginTop: `calc(1em * ${defaults.lineHeights.m})`,
        },
        "& + $xl": {
            marginTop: `calc(1em * ${defaults.lineHeights.m})`,
        },
        "& + $xxl": {
            marginTop: `calc(1em * ${defaults.lineHeights.m})`,
        },
        "& + $xxxl": {
            marginTop: `calc(1em * ${defaults.lineHeights.l})`,
        },

        "&$noMargin": {
            marginTop: 0,

            "& + $xxxs": {
                marginTop: 0,
            },
            "& + $xxs": {
                marginTop: 0,
            },
            "& + $xs": {
                marginTop: 0,
            },
            "& + $s": {
                marginTop: 0,
            },
            "& + $m": {
                marginTop: 0,
            },
            "& + $l": {
                marginTop: 0,
            },
            "& + $xl": {
                marginTop: 0,
            },
            "& + $xxl": {
                marginTop: 0,
            },
            "& + $xxxl": {
                marginTop: 0,
            },
        },
    },

    xxxs: {
        fontSize: defaults.fontSizes[11],
        lineHeight: defaults.lineHeights.m,
    },
    xxs: {
        fontSize: defaults.fontSizes[12],
        lineHeight: defaults.lineHeights.m,
    },
    xs: {
        fontSize: defaults.fontSizes[13],
        lineHeight: defaults.lineHeights.m,
    },
    s: {
        fontSize: defaults.fontSizes[14],
        lineHeight: defaults.lineHeights.m,
    },
    m: {
        fontSize: defaults.fontSizes[15],
        lineHeight: defaults.lineHeights.m,
    },
    l: {
        fontSize: defaults.fontSizes[16],
        lineHeight: defaults.lineHeights.l,
    },
    xl: {
        fontSize: defaults.fontSizes[18],
        lineHeight: defaults.lineHeights.l,
    },
    xxl: {
        fontSize: defaults.fontSizes[20],
        lineHeight: defaults.lineHeights.l,
    },
    xxxl: {
        fontSize: defaults.fontSizes[24],
        lineHeight: defaults.lineHeights.l,
    },

    left: {
        textAlign: "left",
    },

    right: {
        textAlign: "right",
    },

    center: {
        textAlign: "center",
    },

    justify: {
        textAlign: "justify",
    },

    light: {
        color: defaults.colors.neutrals[700],
    },

    noMargin: {},
})

export default styles
