import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        backgroundColor: defaults.colors.neutrals[100],
        borderTopLeftRadius: defaults.cornerRadius.normal,
        borderTopRightRadius: defaults.cornerRadius.normal,
        top: 0,
        width: "100%",
    },
})

export default styles
