import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        listStyle: "none",
        margin: 0,
        padding: 0,
    },
    page: {
        display: "inline-flex",
    },
    pageLink: {
        borderRadius: defaults.cornerRadius.normal,
        boxSizing: "border-box",
        color: defaults.colors.neutrals[700],
        lineHeight: defaults.lineHeights.s,
        padding: defaults.spacing[4],
        textAlign: "center",
        textDecoration: "none",
        userSelect: "none",

        height: `calc(1em + ${defaults.spacing[4] * 2}px)`,
        width: `calc(1em + ${defaults.spacing[4] * 2}px)`,

        "&$breakerLink": {
            cursor: "default",

            "&:hover": {
                backgroundColor: "inherit",
                color: defaults.colors.neutrals[700],
            },
        },
        "&$selectedPage": {
            backgroundColor: defaults.colors.neutrals[400],
            color: defaults.colors.neutrals[800],
        },

        "& > svg": {
            color: defaults.colors.neutrals[700],
            display: "block",
            height: "1em",
            width: "1em",
        },
        "&:focus": {
            outline: "none",
        },
        "&:hover": {
            backgroundColor: defaults.colors.neutrals[100],
            color: defaults.colors.neutrals[800],
        },
    },

    breakerLink: {},
    selectedPage: {},
})

export default styles
