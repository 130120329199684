import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "../Icon"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface IPaginationProps {
    pageCount: number
    pageRangeDisplayed: number
    marginPagesDisplayed: number
    initialPage?: number
    onPageChange?: (selectedPage: number) => void
}

const useStyles = makeStyles(styles, "Pagination", true)
export const Pagination: React.FC<IPaginationProps> = (props) => {
    const classes = useStyles()
    const { pageCount, pageRangeDisplayed, marginPagesDisplayed, initialPage = 1 } = props
    const [currentPage, setCurrentPage] = useState(initialPage)
    const [initialLoad, setInitialLoad] = useState(true)

    useEffect(() => {
        if (props.onPageChange && !initialLoad) {
            props.onPageChange(currentPage)
        }
    }, [currentPage])

    useEffect(() => {
        setInitialLoad(false)
    }, [])

    const preferedNumberOfLinks = pageRangeDisplayed + marginPagesDisplayed * 2 + 2 // last +2 is number of breakers

    const generateItems = () => {
        const result = []
        let firstBreakerLocationStart = -1
        let firstBreakerLocationEnd = -1
        let lastBreakerLocationStart = -1
        let lastBreakerLocationEnd = -1
        const side = Math.floor(pageRangeDisplayed / 2)
        const minPageBeforeBreaker = marginPagesDisplayed + 1 + side + 1
        const maxPageBeforeBreaker = pageCount - marginPagesDisplayed - 1 - side

        if (pageCount > preferedNumberOfLinks) {
            if (currentPage > marginPagesDisplayed + side + 1 + 1) {
                firstBreakerLocationStart = marginPagesDisplayed + 1
                firstBreakerLocationEnd =
                    currentPage > maxPageBeforeBreaker
                        ? maxPageBeforeBreaker - side - 1
                        : currentPage - side - 1
            }
            lastBreakerLocationStart =
                currentPage < minPageBeforeBreaker
                    ? minPageBeforeBreaker + side + 1
                    : currentPage + side + 1
            lastBreakerLocationEnd = pageCount - marginPagesDisplayed
        }

        for (let i = 1; i <= pageCount; i++) {
            const pageLinkClasses = classNames(classes.pageLink, {
                [classes.selectedPage]: currentPage === i,
            })
            if (pageCount <= preferedNumberOfLinks) {
                result.push(
                    <li className={classes.page} key={`paginationPage-${i}`}>
                        <a
                            className={pageLinkClasses}
                            onClick={() => setCurrentPage(i)}
                            role="goToPage"
                        >
                            {i}
                        </a>
                    </li>
                )
            } else {
                if (firstBreakerLocationStart === i) {
                    result.push(
                        <li className={classes.page} key={`paginationPage-first-breaker-${i}`}>
                            <a className={`${pageLinkClasses} ${classes.breakerLink}`}>...</a>
                        </li>
                    )
                    continue
                }
                if (i > firstBreakerLocationStart && i <= firstBreakerLocationEnd) {
                    continue
                }
                if (
                    lastBreakerLocationStart === i &&
                    currentPage < pageCount - marginPagesDisplayed - 1 - side
                ) {
                    result.push(
                        <li className={classes.page} key={`paginationPage-last-breaker-${i}`}>
                            <a className={`${pageLinkClasses} ${classes.breakerLink}`}>...</a>
                        </li>
                    )
                    continue
                }
                if (i > lastBreakerLocationStart && i <= lastBreakerLocationEnd) {
                    continue
                }

                result.push(
                    <li className={classes.page} key={`paginationPage-${i}`}>
                        <a
                            className={pageLinkClasses}
                            onClick={() => setCurrentPage(i)}
                            role="goToPage"
                        >
                            {i}
                        </a>
                    </li>
                )
            }
        }

        return result
    }

    const items = generateItems()

    const nextPage = () => {
        if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1)
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    items.unshift(
        <li className={classes.page} key={`paginationPage-prev`}>
            <a className={classes.pageLink} onClick={() => prevPage()} role="goToPrevious">
                <ChevronLeftIcon />
            </a>
        </li>
    )
    items.push(
        <li className={classes.page} key={`paginationPage-next`}>
            <a className={classes.pageLink} onClick={() => nextPage()} role="goToNext">
                <ChevronRightIcon />
            </a>
        </li>
    )

    return <ul className={classes.container}>{items}</ul>
}
