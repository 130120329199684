export const getDomPosition = (elm: HTMLElement, relativeElm?: HTMLElement | Element | null) => {
    const relativeElmPos = relativeElm
        ? relativeElm.getBoundingClientRect()
        : document.documentElement.getBoundingClientRect()
    const elmPos = elm.getBoundingClientRect()

    return {
        top: elmPos.top - relativeElmPos.top,
        left: elmPos.left - relativeElmPos.left,
        height: elm.clientHeight,
    }
}
