export const multiKeyStore = {
    set: (cache: Map<any, Map<any, any>>, key1: any, key2: any, value: any) => {
        let subCache = cache.get(key1)

        if (!subCache) {
            subCache = new Map()
            cache.set(key1, subCache)
        }

        subCache.set(key2, value)
    },
    get: (cache: Map<any, Map<any, any>>, key1: any, key2: any) => {
        const subCache = cache.get(key1)
        return subCache ? subCache.get(key2) : undefined
    },
    delete: (cache: Map<any, Map<any, any>>, key1: any, key2: any) => {
        const subCache = cache.get(key1)
        if (subCache) {
            subCache.delete(key2)
        }
    },
}
