import { ResizeObserver } from "@juggle/resize-observer"
import reactUseMeasure, { RectReadOnly } from "react-use-measure"

export default function useMeasure(): [
    (element: HTMLOrSVGElement | null) => void,
    RectReadOnly,
    () => void
] {
    return reactUseMeasure({ polyfill: ResizeObserver })
}
