import classNames from "classnames"
import React, { useContext } from "react"
import defaults from "../defaults"
import { ThemeContext } from "../ThemeProvider"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

export type ParagraphSizes = "xxxs" | "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl"
export type ParagraphAligns = "left" | "right" | "center" | "justify"
export type ParagraphLineHeights = keyof typeof defaults.lineHeights

interface IParagraphProps {
    size?: ParagraphSizes
    weight?: "bold" | "semibold" | "medium" | "normal" | null
    light?: boolean
    color?: string
    align?: ParagraphAligns
    style?: React.CSSProperties
    className?: string
    noMargin?: boolean
    center?: boolean
    width?: number
    lineHeight?: ParagraphLineHeights
    textTransform?: "capitalize" | "uppercase" | "lowercase"
    italic?: boolean
    children: React.ReactNode
}

const useStyles = makeStyles(styles, "Paragraph", true)
export const Paragraph: React.FC<IParagraphProps> = (props) => {
    const {
        size = "l",
        children,
        center,
        color,
        width = "auto",
        light = false,
        align = "left",
        style,
        weight,
        className,
        noMargin,
        lineHeight,
        textTransform,
        italic,
    } = props

    const { defaults } = useContext(ThemeContext)
    const classes = useStyles()

    const extraClasses = classNames(
        classes.paragraph,
        // @ts-ignore - @TODO
        classes[`${align}`],
        // @ts-ignore - @TODO
        classes[`${size}`],
        {
            [classes.weightNormal]: weight === "normal",
            [classes.weightBold]: weight === "bold",
            [classes.weightSemibold]: weight === "semibold",
            [classes.weightMedium]: weight === "medium",
            [classes.light]: light,
            [classes.noMargin]: noMargin,
        },
        className
    )

    return (
        <p
            className={extraClasses}
            style={{
                color,
                lineHeight: lineHeight && defaults.lineHeights[lineHeight],
                maxWidth: width,
                textAlign: center ? "center" : undefined,
                textTransform,
                fontStyle: italic ? "italic" : undefined,
                ...style,
            }}
        >
            {children}
        </p>
    )
}
