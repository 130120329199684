import classNames from "classnames"
import React from "react"
import { Grid } from "../"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ILoaderProps {
    size: number
    className?: string
    fillToParent?: boolean
    noBackdrop?: boolean
    light?: boolean
}

const useStyles = makeStyles(styles, "Loader", true)
export const Loader: React.FC<ILoaderProps> = (props) => {
    const { size, className, fillToParent = false, noBackdrop = false } = props
    const classes = useStyles()
    const changeStyleSize = 32
    const barClasses = classNames(classes.bar, {
        [classes.small]: size <= changeStyleSize,
        [classes.light]: props.light,
    })
    const containerClasses = classNames({
        [classes.fillToParent]: fillToParent,
        [classes.noBackdrop]: noBackdrop,
    })

    return (
        <Grid row justify="center" alignItems="center" className={containerClasses} noGutters>
            <div
                className={classNames(classes.holder, className)}
                style={{ width: size, height: size }}
            >
                {size > changeStyleSize && <div className={barClasses} />}
                <div className={barClasses} />
                <div className={barClasses} />
                <div className={barClasses} />
                {size > changeStyleSize && <div className={barClasses} />}
            </div>
        </Grid>
    )
}
