import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    monospace: {
        fontFamily: defaults.fontFamilies.mono,
    },
    semibold: {
        fontWeight: defaults.fontWeights.semibold,
    },
    medium: {
        fontWeight: defaults.fontWeights.medium,
    },
    normal: {
        fontWeight: defaults.fontWeights.normal,
    },
})

export default styles
