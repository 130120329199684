import FileSaver from "file-saver"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { Button } from "../Button"
import { Grid } from "../Grid"
import { DownloadIcon, ZoomInIcon, ZoomOutIcon } from "../Icon"
import { LoadingScreen } from "../LoadingScreen"
import { Modal } from "../Modal"
import { Paragraph } from "../Paragraph"
import { Separator } from "../Separator"
import { ThemeContext } from "../ThemeProvider"
import { makeStyles } from "../utils/styles/makeStyles"
import { FilePreviewCsv } from "./FilePreviewCsv"
import { FilePreviewError } from "./FilePreviewError"
import { FilePreviewImage } from "./FilePreviewImage"
import { FilePreviewPdf } from "./FilePreviewPdf"
import { FilePreviewUnsupported } from "./FilePreviewUnsupported"
import styles from "./styles"

const zoomInIcon = <ZoomInIcon />
const zoomOutIcon = <ZoomOutIcon />
const downloadIcon = <DownloadIcon />

interface IFilePreviewProps {
    caption?: string
    src?: string
    mimetype?: string
    downloadHandler?: () => Promise<[string, string]>
    onClosed?: () => void
    unsupportedCaption?: string
    errorTitle: string
    errorDescription: string
    showDownload?: boolean
    downloadFileName?: string
}

const useStyles = makeStyles(styles, "FilePreview", true)
export const FilePreview: React.FC<IFilePreviewProps> = (props) => {
    const scaleStep = 0.5

    const {
        src: externalSrc,
        mimetype: externalMimetype = "",
        downloadHandler,
        caption,
        onClosed,
        unsupportedCaption,
        errorTitle,
        errorDescription,
        showDownload = true,
        downloadFileName = caption,
    } = props

    const { defaults } = useContext(ThemeContext)

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [downloading, setDownloading] = useState<Promise<[string, string]> | null>(null)
    const [src, setSrc] = useState<string | null>(null)
    const [mimetype, setMimetype] = useState(externalMimetype)
    const classes = useStyles()
    const [scale, setScale] = useState(1)
    const [showScaling, setShowScaling] = useState(false)
    const [unsupported, setUnsupported] = useState<boolean>(false)

    useEffect(() => {
        if (!downloading) {
            return
        }

        const internalDownloadHandler = async () => {
            try {
                const [urlFromDownloadHandler, mimetypeFromDownloadHandler] = await downloading
                setMimetype(mimetypeFromDownloadHandler)
                setSrc(urlFromDownloadHandler)
                setDownloading(null)
            } catch {
                setIsError(true)
                setIsLoading(false)
            }
        }

        internalDownloadHandler()
    }, [downloading])

    useEffect(() => {
        if (!mimetype) {
            return
        }

        const unsupported =
            !mimetype.startsWith("image/") &&
            mimetype !== "application/pdf" &&
            mimetype !== "text/csv"
        setUnsupported(unsupported)
        setIsLoading(false)

        setShowScaling(!unsupported && mimetype !== "text/csv")
    }, [mimetype])

    if (!externalSrc && !downloadHandler) {
        throw new Error(`'src' or 'downloadHandler' one of them must be set`)
    }

    if (externalSrc && !src) {
        setSrc(externalSrc)
    }

    if (downloadHandler && !isLoading && !downloading && !src) {
        // If there is a download handler present, set isLoading and call it
        setIsLoading(true)
    }

    const modalOpened = () => {
        setIsError(false)

        if (downloadHandler && !downloading && !src) {
            setDownloading(downloadHandler())
        }
    }

    const onLoadEndHandler = () => {
        if (!downloading && src) {
            setIsLoading(false)
        }
    }

    const onCloseHandler = () => {
        if (onClosed) {
            onClosed()
        }
    }

    const handleOnZoomInClick = useCallback(() => {
        const nextScale = scale + scaleStep
        setScale(nextScale)
    }, [scale, scaleStep])

    const handleOnZoomOutClick = useCallback(() => {
        const nextScale = scale - scaleStep
        setScale(nextScale > scaleStep ? nextScale : scaleStep)
    }, [scale, scaleStep])

    const handleOnDownloadClick = useCallback(() => {
        if (src) {
            FileSaver.saveAs(src, downloadFileName ? downloadFileName : "download")
        }
    }, [src, downloadFileName])

    return (
        <Modal open={true} fullscreen transparent onClosed={onCloseHandler} onOpened={modalOpened}>
            <Modal.Content noPadding>
                <div className={classes.container} role="filepreview-container">
                    <div className={classes.wrapper}>
                        {isLoading && <LoadingScreen noBackground />}
                        {isError && (
                            <FilePreviewError title={errorTitle} description={errorDescription} />
                        )}
                        {src && mimetype.startsWith("image/") && (
                            <FilePreviewImage
                                src={src}
                                scale={scale}
                                onImageLoaded={onLoadEndHandler}
                            />
                        )}
                        {src && mimetype === "application/pdf" && (
                            <div className={classes.scrollWrapper}>
                                <FilePreviewPdf
                                    src={src}
                                    scale={scale}
                                    onDocumentLoaded={onLoadEndHandler}
                                />
                            </div>
                        )}
                        {src && mimetype === "text/csv" && (
                            <div className={classes.scrollWrapper}>
                                <FilePreviewCsv src={src} onCsvLoaded={onLoadEndHandler} />
                            </div>
                        )}
                        {src && unsupported && (
                            <FilePreviewUnsupported
                                src={src}
                                fileName={caption ? caption : ""}
                                caption={unsupportedCaption}
                            />
                        )}
                    </div>
                </div>
            </Modal.Content>
            {caption && (
                <Modal.Snackbar>
                    <Grid row>
                        <Grid item>
                            <Paragraph center color={defaults.colors.neutrals[300]}>
                                {caption}
                            </Paragraph>
                        </Grid>
                        {showScaling && (
                            <>
                                <Separator
                                    direction={"vertical"}
                                    mx={12}
                                    customColor={defaults.colors.primary[600]}
                                />
                                <Grid item auto>
                                    <Grid row>
                                        <Grid item auto>
                                            <Button
                                                kind={"plain"}
                                                color={"tertiary"}
                                                prefix={zoomInIcon}
                                                tight
                                                onClick={handleOnZoomInClick}
                                            />
                                        </Grid>
                                        <Grid item auto>
                                            <Button
                                                kind={"plain"}
                                                color={"tertiary"}
                                                prefix={zoomOutIcon}
                                                tight
                                                onClick={handleOnZoomOutClick}
                                            />
                                        </Grid>
                                        {showDownload && (
                                            <Grid item auto>
                                                <Button
                                                    kind="plain"
                                                    color="tertiary"
                                                    prefix={downloadIcon}
                                                    onClick={handleOnDownloadClick}
                                                    tight
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Modal.Snackbar>
            )}
        </Modal>
    )
}
