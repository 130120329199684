import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    label: {
        alignItems: "center",
        color: defaults.colors.neutrals[900],
        display: "flex",
        fontSize: defaults.fontSizes[14],
        fontWeight: defaults.fontWeights.medium,
        lineHeight: defaults.lineHeights.m,
    },

    labelL: {
        fontSize: defaults.fontSizes[14],
    },

    labelXs: {
        fontSize: defaults.fontSizes[12],
    },
    labelXxs: {
        fontSize: defaults.fontSizes[11],
        letterSpacing: 0.3,
    },
    labelXxxs: {
        fontSize: defaults.fontSizes[8],
        letterSpacing: 0.3,
    },

    light: {
        color: defaults.colors.neutrals[600],
        fontWeight: defaults.fontWeights.normal,
    },

    marginnormal: {
        marginBottom: defaults.spacing[12],
    },

    marginnone: {
        marginBottom: 0,
    },

    marginextra: {
        marginBottom: defaults.spacing[24],
    },
    uppercase: {
        textTransform: "uppercase",
    },

    suffix: {
        display: "flex",
        marginLeft: defaults.spacing[4],
    },
})

export default styles
