import { shallowEqual } from "fast-equals"
import { Classes } from "jss"
import { useContext } from "react"
import { createUseStyles } from "react-jss"
import { ThemeContext } from "../ThemeProvider"
import { Styles } from "./types"

const stylesCache: Array<Styles<any>> = []
const generatedStylesCache: any[] = []

// @deprecated Use makeStyles instead
export const useJss = <C extends string>(styles: Styles<C>) => {
    const { defaults } = useContext(ThemeContext)

    let stylesCacheIndex = stylesCache.findIndex((s: Styles<C>) => shallowEqual(s, styles))
    let generatedStyles = generatedStylesCache[stylesCacheIndex] as Record<C, any>
    if (stylesCacheIndex === -1) {
        stylesCacheIndex = stylesCache.push(styles) - 1
        generatedStyles = typeof styles === "function" ? styles(defaults) : styles
        generatedStylesCache[stylesCacheIndex] = createUseStyles(generatedStyles)
    }

    const classes = generatedStylesCache[stylesCacheIndex]() as Classes<
        keyof typeof generatedStyles
    >

    return { classes }
}
