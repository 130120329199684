import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"

interface IPortalContext {
    rootContainer: Element | null
}

export const PortalContext = React.createContext<IPortalContext>({
    rootContainer: ReactDOM.findDOMNode(document.body) as Element | null,
})

interface PortalContainerProps {
    children?: React.ReactNode
}
export const PortalContainer: React.FC<PortalContainerProps> = (props) => {
    const portalContainerRef = useRef<HTMLDivElement>(null)
    const [rootContainer, setRootContainer] = useState<HTMLDivElement | null>(null)

    useEffect(() => {
        if (portalContainerRef.current !== rootContainer) {
            setRootContainer(portalContainerRef.current)
        }
    }, [portalContainerRef.current])

    const portalContainerStyle = useMemo(
        () => ({ position: "relative" } as React.CSSProperties),
        []
    )
    const rootContainerContextValue = useMemo(() => ({ rootContainer }), [rootContainer])

    return (
        <div ref={portalContainerRef} style={portalContainerStyle}>
            <PortalContext.Provider value={rootContainerContextValue}>
                {props.children}
            </PortalContext.Provider>
        </div>
    )
}

interface PortalProps {
    children?: React.ReactNode
}
export const Portal: React.FC<PortalProps> = (props) => {
    const { children } = props
    const { rootContainer } = useContext(PortalContext)

    if (!rootContainer || rootContainer instanceof Text) {
        // tslint:disable-next-line:no-console
        console.debug(
            `Could not find the provided rootContainer`,
            `This happens when using a '<Portal />' with a '<PortalContainer />' that has not yet been rendered by React.`,
            `Please ensure that the '<PortalContainer />' has been fully rendered before rendering the '<Portal />'`
        )
        return null
    }

    return ReactDOM.createPortal(children, rootContainer)
}
