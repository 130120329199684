import React, { useContext } from "react"
import {
    BoxEdges,
    ErrorMessageType,
    Fade,
    NoticeBox,
    Paragraph,
    Spacer,
    ThemeContext,
} from "../../"
import devMode from "../../utils/devMode"

interface IValidationInputErrorProps {
    errorMessage?: ErrorMessageType
    show?: boolean
}

export const ValidationInputError: React.FC<IValidationInputErrorProps> = React.memo((props) => {
    const { errorMessage, show } = props
    const { defaults } = useContext(ThemeContext)

    if (show === undefined || errorMessage === undefined) {
        return null
    }

    return (
        <Fade show={show}>
            <Spacer size={8} />
            <NoticeBox kind="accents-error" noPadding>
                <NoticeBox.Content>
                    <BoxEdges p={8}>
                        <Paragraph
                            weight="medium"
                            size="xxs"
                            noMargin
                            color={defaults.colors.accents.error[700]}
                        >
                            {errorMessage as React.ReactElement}
                        </Paragraph>
                    </BoxEdges>
                </NoticeBox.Content>
            </NoticeBox>
        </Fade>
    )
})

if (devMode) {
    ValidationInputError.displayName = "ValidationInputError"
}
