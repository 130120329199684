import classNames from "classnames"
import React from "react"
import devMode from "../../utils/devMode"
import { makeStyles } from "../../utils/styles/makeStyles"
import styles from "./styles"

interface ISelectOption {
    value: any
    selected?: boolean
    prefix?: React.ReactNode
    hasFocus?: boolean
    tight?: boolean
    onClick?: (e: React.MouseEvent) => void
    onMouseEnter?: (e: React.MouseEvent) => void
    onMouseLeave?: (e: React.MouseEvent) => void
    onMouseDown?: (e: React.MouseEvent) => void
    className?: string
    notSelectable?: boolean
}

const useStyles = makeStyles(styles, "Select", true)
export const SelectOption = React.forwardRef<HTMLLIElement, React.PropsWithChildren<ISelectOption>>(
    (props, ref) => {
        const {
            value,
            selected = false,
            prefix,
            hasFocus,
            tight = false,
            children,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onMouseDown,
            className,
            notSelectable,
            ...other
        } = props
        const classes = useStyles()

        const itemClasses = classNames(className, classes.listItem, {
            [classes.focused]: hasFocus,
            [classes.tight]: tight,
            [classes.highlighted]: selected,
            [classes.notSelectable]: notSelectable,
        })

        return (
            <li
                className={itemClasses}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                ref={ref}
                {...other}
            >
                {prefix && <i className={classes.prefix}>{prefix}</i>}
                {children}
            </li>
        )
    }
)

if (devMode) {
    SelectOption.displayName = "SelectOption"
}
