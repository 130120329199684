import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const ApplicationUnsubmittedStepIcon: React.FC<Icon> = (props) => {
    const { size = 24, ...otherProps } = props
    const { defaults } = useContext(ThemeContext)
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                d="M39.8145 14.5242L34.8833 31.1886C28.8955 31.915 29.3357 31.1886 24.5367 30.1204C19.7376 29.0521 17.3161 28.753 13.9699 29.6931C11.293 30.4451 9.71385 30.7471 9.2589 30.804L13.9699 14.5242C19.6936 12.7295 19.8697 12.174 28.3231 14.5242C35.0858 16.4042 38.8018 15.3075 39.8145 14.5242Z"
                fill={defaults.colors.neutrals[600]}
            />
            <path
                d="M36.0563 34.1645C33.2122 34.4091 32.3132 34.1583 30.5708 33.6722C29.8498 33.4711 28.9845 33.2297 27.7772 32.9664C23.1236 31.9516 20.7754 31.6674 17.5307 32.5605C14.9349 33.2749 13.4036 33.5618 12.9624 33.6159L17.5307 18.15C18.0275 17.9974 18.4812 17.8542 18.9014 17.7216C23.1758 16.3728 23.9854 16.1172 31.4489 18.15C35.6502 19.2943 38.6389 19.2778 40.5026 18.9226L40.5154 18.8787C40.7474 18.0834 41.5801 17.6268 42.3754 17.8587C43.1707 18.0907 43.6273 18.9235 43.3953 19.7188L35.1938 47.8339C34.0712 48.3359 32.9048 48.7575 31.7019 49.0914L36.0563 34.1645Z"
                fill={defaults.colors.neutrals[100]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 49C38.2548 49 49 38.2548 49 25C49 11.7452 38.2548 1 25 1C11.7452 1 1 11.7452 1 25C1 38.2548 11.7452 49 25 49ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                fill="black"
            />
        </svg>
    )
}

export { ApplicationUnsubmittedStepIcon }
