import defaultsOrg from "../defaults"

export const styles = (defaults: typeof defaultsOrg) => ({
    description: {
        margin: 0,

        "& + $term": {
            marginTop: defaults.spacing[12],
        },
    },
    descriptionList: {
        display: "flex",
        flexDirection: "column",
        margin: 0,
    },
    labelSuffix: {
        marginLeft: defaults.spacing[2],
    },
    term: {
        alignItems: "center",
        display: "flex",
    },
})

export default styles
