import defaultsOrg, { ColorNames } from "../defaults"

const colors: ColorNames[] = [
    "primary",
    "neutrals",
    "accents-success",
    "accents-warning",
    "accents-error",
    "accents-notice",
]

const shades = [100, 200, 300, 400, 500, 600, 700, 800, 900]

export const styles = (defaults: typeof defaultsOrg) => {
    const getColorStyles = (prefix: string, property: string) => {
        const ArrayOfStyles = colors
            .map((color) => {
                return shades.map((shade) => {
                    let colorProp

                    if (color.includes("-")) {
                        const [accents, accentColor] = color.split("-")

                        // @ts-ignore
                        colorProp = defaults.colors[accents][accentColor]
                    } else {
                        // @ts-ignore
                        colorProp = defaults.colors[color]
                    }

                    if (!colorProp[shade]) {
                        return
                    }

                    return {
                        [`${prefix}-${color}-${shade}`]: {
                            [property]: colorProp[shade],
                        },
                    }
                })
            })
            .flat()

        const returnObject = Object.assign({}, ...ArrayOfStyles)

        return returnObject
    }

    const colorStyles = getColorStyles("color", "backgroundColor")

    return {
        badge: {
            position: "absolute",
            borderRadius: defaults.cornerRadius.rounded,
            top: 0,
            right: 0,
            transform: "translate(50%, -50%)",
        },
        overlap: {
            "&rectangular": {
                top: 0,
                right: 0,
            },
            "&$circular": {
                top: "12%",
                right: "12%",
            },
        },
        regtangular: {},
        circular: {},
        ...colorStyles,
    } as Record<any, string>
}

export default styles
