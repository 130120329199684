import defaultsOrg from "../defaults"

export const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        borderRadius: defaults.cornerRadius.rounded,
        backgroundColor: defaults.colors.neutrals[500],
        height: defaults.fontSizes[13],
        width: "100%",
    },
    containerTiny: {
        height: 3,
    },
    containerXs: {
        height: defaults.fontSizes[12],
    },
    containerXxs: {
        height: defaults.fontSizes[11],
    },
    inner: {
        backgroundColor: defaults.colors.primary[500],
        borderRadius: defaults.cornerRadius.rounded,
        height: "100%",
        transition: "all",
        transitionDuration: ".5s",
    },
    tiny: {
        backgroundColor: defaults.colors.primary[500],
        height: 3,
        marginTop: 10,
        overflow: "hidden",
        padding: 0,
    },
})

export default styles
