import React, { useContext, useEffect } from "react"
import { BoxEdges, Grid } from "../"
import { useIntersectionObserver } from "../utils/useIntersectionObserver"
import { SectionContext } from "./SectionContainer"

interface ISectionPaneProps {
    active?: boolean
    sectionKey: string
    sectionRef: React.RefObject<HTMLDivElement>
    children: React.ReactNode
}

export const SectionPane: React.FC<ISectionPaneProps> = (props) => {
    const { children } = props
    const { onIntersection } = useContext(SectionContext)

    const isIntersecting = useIntersectionObserver(props.sectionRef, { rootMargin: "-45% 0%" })

    useEffect(() => {
        if (isIntersecting) {
            onIntersection(props.sectionKey)
        }
    }, [isIntersecting])

    return (
        <BoxEdges px={12}>
            <Grid row ref={props.sectionRef}>
                <Grid item>{children}</Grid>
            </Grid>
        </BoxEdges>
    )
}
