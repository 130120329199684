import React, { createContext, useContext, useMemo } from "react"
import { BoxEdges, SpacingMargin } from "../BoxEdges"
import { ColorWeights } from "../defaults"
import { Grid } from "../Grid"
import { FilledCircleIcon } from "../Icon"
import { Separator as DSSeparator } from "../Separator"
import { ThemeContext } from "../ThemeProvider"

const flexStyle = { display: "flex" }

interface ITimelineProps {
    children?: React.ReactNode
}

const Timeline = (props: ITimelineProps) => {
    return (
        <Grid row noGutters>
            {props.children}
        </Grid>
    )
}

type ItemDotType = "dot" | "line" | undefined
export type ItemDotsType = { left?: ItemDotType; right?: ItemDotType }
const ItemContext = createContext<{ margins: { [key: string]: SpacingMargin } } | null>(null)

interface IItemProps {
    auto?: boolean
    dots?: boolean | ItemDotType | ItemDotsType
    connector?: boolean | "solid" | "dashed"
    connectorWeight?: ColorWeights
    margin?: "left" | "right" | "both" | "none"
    children?: React.ReactNode
}

const Item: React.FC<IItemProps> = (props) => {
    const { margin = "both", dots = "dot", connector = "solid" } = props

    const margins: { margins: { [key: string]: SpacingMargin } } = useMemo(
        () => ({
            margins: {
                ml: margin === "left" ? 16 : 0,
                mr: margin === "right" ? 16 : 0,
                mx: margin === "both" ? 16 : 0,
            },
        }),
        [margin]
    )

    const internalDots: ItemDotsType | null = useMemo(() => {
        if (typeof dots === "boolean") {
            if (!dots) {
                return null
            } else {
                return { left: "dot", right: "dot" }
            }
        }

        if (typeof dots === "string") {
            return { left: dots, right: dots }
        }

        return dots
    }, [dots])

    return (
        <ItemContext.Provider value={margins}>
            <Grid item auto={props.auto} direction="column" grow={1} style={flexStyle}>
                <Grid row noGutters grow={1}>
                    <Grid item direction="column" grow={1} style={flexStyle}>
                        {props.children && <Content>{props.children}</Content>}
                        <Separator>
                            {internalDots && internalDots.left && <Dot type={internalDots.left} />}
                            {connector && (
                                <Connector
                                    dashed={connector === "dashed"}
                                    colorWeight={props.connectorWeight}
                                />
                            )}
                            {internalDots && internalDots.right && (
                                <Dot type={internalDots.right} />
                            )}
                        </Separator>
                    </Grid>
                </Grid>
            </Grid>
        </ItemContext.Provider>
    )
}

interface ISeparatorProps {
    children?: React.ReactNode
}
const Separator: React.FC<ISeparatorProps> = (props) => {
    return (
        <Grid row grow={1} alignItems="flex-end">
            <Grid item>
                <Grid row alignItems="center" noGutters>
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    )
}

interface IDotProps {
    type?: "line" | "dot"
}

const Dot: React.FC<IDotProps> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const context = useContext(ItemContext)
    const { type = "dot" } = props

    if (!context) {
        return null
    }

    return (
        <Grid item auto>
            <BoxEdges ml={context.margins.ml} mr={context.margins.mr} mx={context.margins.mx}>
                {type === "dot" ? (
                    <FilledCircleIcon size={6} color={defaults.colors.neutrals[500]} />
                ) : (
                    <div style={{ height: 8 }}>
                        <DSSeparator direction="vertical" colorWeight={600} />
                    </div>
                )}
            </BoxEdges>
        </Grid>
    )
}

interface IConnectorProps {
    colorWeight?: ColorWeights
    dashed?: boolean
}

const Connector: React.FC<IConnectorProps> = (props) => {
    const { colorWeight = 500 } = props

    return (
        <Grid item>
            <DSSeparator colorWeight={colorWeight} dashed={props.dashed} />
        </Grid>
    )
}

interface IContentProps {
    children: React.ReactNode
}
const Content: React.FC<IContentProps> = (props) => {
    const context = useContext(ItemContext)

    if (!context) {
        return null
    }

    return (
        <Grid row noGutters>
            <Grid item>
                <BoxEdges
                    mb={12}
                    ml={context.margins.ml}
                    mr={context.margins.mr}
                    mx={context.margins.mx}
                >
                    {props.children}
                </BoxEdges>
            </Grid>
        </Grid>
    )
}

Timeline.Item = Item

export { Timeline }
