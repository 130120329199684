import classNames from "classnames"
import React from "react"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

interface ILineProps {
    start?: boolean
    end?: boolean
}

const useStyles = makeStyles(styles, "Line", true)
export const Line: React.FC<ILineProps> = (props) => {
    const classes = useStyles()

    const lineClasses = classNames({
        [classes.line]: true,
        [classes.start]: props.start,
        [classes.end]: props.end,
    })

    return <div className={lineClasses} />
}
