import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    tag: {
        alignItems: "center",
        backgroundColor: defaults.colors.primary[500],
        borderRadius: defaults.cornerRadius.normal,
        color: defaults.colors.neutrals[100],
        display: "inline-flex",
        fontSize: defaults.fontSizes[14],
        fontWeight: defaults.fontWeights.medium,
        padding: `${defaults.spacing[8]}px ${defaults.spacing[12]}px`,
        verticalAlign: "middle",
        whiteSpace: "nowrap",

        "& span": {
            lineHeight: 1,
        },

        "& + $tag": {
            marginLeft: `${defaults.spacing[12]}px`,
        },
    },

    clickable: {
        cursor: "pointer",
    },

    small: {
        fontSize: defaults.fontSizes[13],
        padding: `${defaults.spacing[4]}px ${defaults.spacing[6]}px`,
    },

    xsmall: {
        fontSize: defaults.fontSizes[12],
        padding: `${defaults.spacing[2]}px ${defaults.spacing[3]}px`,
    },

    xxsmall: {
        fontSize: defaults.fontSizes[11],
        padding: `${defaults.spacing[1]}px ${defaults.spacing[3]}px`,
    },

    light: {
        backgroundColor: defaults.colors.primary[300],
        color: defaults.colors.primary[700],
    },

    tight: {
        padding: `${defaults.spacing[4]}px ${defaults.spacing[8]}px`,
    },

    "shade-lightest": {
        backgroundColor: defaults.colors.primary[200],
        color: defaults.colors.primary[900],
    },

    "shade-light": {
        backgroundColor: defaults.colors.primary[300],
        color: defaults.colors.primary[900],
    },

    "shade-normal": {
        backgroundColor: defaults.colors.primary[500],
        color: defaults.colors.primary[100],
    },

    "shade-dark": {
        backgroundColor: defaults.colors.primary[600],
        color: defaults.colors.neutrals[100],
    },

    "shade-darkest": {
        backgroundColor: defaults.colors.primary[900],
        color: defaults.colors.neutrals[100],
    },

    "color-neutral": {
        backgroundColor: defaults.colors.neutrals[700],
        color: defaults.colors.neutrals[100],

        "&$light": {
            backgroundColor: defaults.colors.neutrals[400],
            color: defaults.colors.neutrals[700],
        },

        "&$shade-lightest": {
            backgroundColor: defaults.colors.neutrals[200],
            color: defaults.colors.neutrals[900],
        },

        "&$shade-light": {
            backgroundColor: defaults.colors.neutrals[400],
            color: defaults.colors.neutrals[900],
        },

        "&$shade-normal": {
            backgroundColor: defaults.colors.neutrals[700],
            color: defaults.colors.neutrals[100],
        },

        "shade-dark": {
            backgroundColor: defaults.colors.neutrals[700],
            color: defaults.colors.neutrals[100],
        },

        "shade-darkest": {
            backgroundColor: defaults.colors.neutrals[900],
            color: defaults.colors.neutrals[100],
        },
    },

    "color-error": {
        backgroundColor: defaults.colors.accents.error[500],
        color: defaults.colors.neutrals[100],

        "&$light": {
            backgroundColor: defaults.colors.accents.error[300],
            color: defaults.colors.accents.error[900],
        },

        "&$shade-lightest": {
            backgroundColor: defaults.colors.accents.error[200],
            color: defaults.colors.accents.error[900],
        },

        "&$shade-light": {
            backgroundColor: defaults.colors.accents.error[300],
            color: defaults.colors.accents.error[900],
        },

        "&$shade-normal": {
            backgroundColor: defaults.colors.accents.error[500],
            color: defaults.colors.neutrals[100],
        },

        "shade-dark": {
            backgroundColor: defaults.colors.accents.error[700],
            color: defaults.colors.neutrals[100],
        },

        "shade-darkest": {
            backgroundColor: defaults.colors.accents.error[900],
            color: defaults.colors.neutrals[100],
        },
    },
    "color-notice": {
        backgroundColor: defaults.colors.accents.notice[500],
        color: defaults.colors.neutrals[100],

        "&$light": {
            backgroundColor: defaults.colors.accents.notice[100],
            color: defaults.colors.accents.notice[900],
        },

        "&$shade-lightest": {
            backgroundColor: defaults.colors.accents.notice[200],
            color: defaults.colors.accents.notice[900],
        },

        "&$shade-light": {
            backgroundColor: defaults.colors.accents.notice[300],
            color: defaults.colors.accents.notice[900],
        },

        "&$shade-normal": {
            backgroundColor: defaults.colors.accents.notice[500],
            color: defaults.colors.neutrals[100],
        },

        "shade-dark": {
            backgroundColor: defaults.colors.accents.notice[700],
            color: defaults.colors.neutrals[100],
        },

        "shade-darkest": {
            backgroundColor: defaults.colors.accents.notice[900],
            color: defaults.colors.neutrals[100],
        },
    },
    "color-success": {
        backgroundColor: defaults.colors.accents.success[500],
        color: defaults.colors.neutrals[100],

        "&$light": {
            backgroundColor: defaults.colors.accents.success[200],
            color: defaults.colors.accents.success[900],
        },

        "&$shade-lightest": {
            backgroundColor: defaults.colors.accents.success[200],
            color: defaults.colors.accents.success[900],
        },

        "&$shade-light": {
            backgroundColor: defaults.colors.accents.success[300],
            color: defaults.colors.accents.success[900],
        },

        "&$shade-normal": {
            backgroundColor: defaults.colors.accents.success[500],
            color: defaults.colors.neutrals[100],
        },

        "shade-dark": {
            backgroundColor: defaults.colors.accents.success[700],
            color: defaults.colors.neutrals[100],
        },

        "shade-darkest": {
            backgroundColor: defaults.colors.accents.success[900],
            color: defaults.colors.neutrals[100],
        },
    },
    "color-warning": {
        backgroundColor: defaults.colors.accents.warning[500],
        color: defaults.colors.neutrals[100],

        "&$light": {
            backgroundColor: defaults.colors.accents.warning[400],
            color: defaults.colors.accents.warning[900],
        },

        "&$shade-lightest": {
            backgroundColor: defaults.colors.accents.warning[200],
            color: defaults.colors.accents.warning[900],
        },

        "&$shade-light": {
            backgroundColor: defaults.colors.accents.warning[300],
            color: defaults.colors.accents.warning[900],
        },

        "&$shade-normal": {
            backgroundColor: defaults.colors.accents.warning[500],
            color: defaults.colors.neutrals[100],
        },

        "shade-dark": {
            backgroundColor: defaults.colors.accents.warning[700],
            color: defaults.colors.neutrals[100],
        },

        "shade-darkest": {
            backgroundColor: defaults.colors.accents.warning[900],
            color: defaults.colors.neutrals[100],
        },
    },
})

export default styles
