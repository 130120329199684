import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

export const SamsungPayIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { size = 24, color = defaults.colors.neutrals[900], ...otherProps } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            color={color}
            {...otherProps}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                d="M13.7255 15.597C13.8901 16.0035 13.8397 16.5277 13.7624 16.8436C13.6213 17.4013 13.2416 17.9726 12.1261 17.9726C11.071 17.9726 10.4326 17.3677 10.4326 16.4471V14.8208H5.92334L5.91998 16.1211C5.91998 19.8676 8.87014 21 12.032 21C15.0729 21 17.5761 19.9617 17.9726 17.1594C18.1776 15.7079 18.023 14.757 17.9558 14.3974C17.2468 10.8794 10.866 9.8277 10.3923 7.86205C10.3116 7.52604 10.3351 7.16651 10.3755 6.97499C10.4931 6.44073 10.8593 5.84936 11.911 5.84936C12.8922 5.84936 13.4735 6.45753 13.4735 7.37484C13.4735 7.68396 13.4735 8.41311 13.4735 8.41311H17.6635V7.23371C17.6635 3.57122 14.3773 3 11.9984 3C9.00791 3 6.56512 3.98787 6.11823 6.72298C5.99727 7.479 5.98046 8.15102 6.15519 8.9944C6.89105 12.4251 12.8586 13.4196 13.7255 15.597Z"
                fill="currentColor"
            />
        </svg>
    )
}
