import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    // tslint:disable:object-literal-sort-keys - The order make logical sense here
    xxsmall: {
        height: defaults.spacing[4],
        width: defaults.spacing[4],
    },
    xsmall: {
        height: defaults.spacing[12],
        width: defaults.spacing[12],
    },
    small: {
        height: defaults.spacing[16],
        width: defaults.spacing[16],
    },
    medium: {
        height: defaults.spacing[24],
        width: defaults.spacing[24],
    },
    large: {
        height: defaults.spacing[48],
        width: defaults.spacing[48],
    },
    xlarge: {
        height: defaults.spacing[96],
        width: defaults.spacing[96],
    },
    xxlarge: {
        height: defaults.spacing[128],
        width: defaults.spacing[128],
    },
})

export default styles
