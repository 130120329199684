import { useEffect, useState } from "react"

export function useIntersectionObserver(
    ref: React.RefObject<Element>,
    options: IntersectionObserverInit = {},
    onIntersecting?: (ref: React.RefObject<Element>) => void
) {
    const { rootMargin = "0px 0px 0px 0px", threshold = 0.0 } = options

    const [isIntersecting, setIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                const intersecting = entry.isIntersecting
                setIntersecting(intersecting)
            },
            {
                rootMargin,
                threshold,
            }
        )
        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
    }, [])

    useEffect(() => {
        if (isIntersecting && onIntersecting) {
            onIntersecting(ref)
        }
    }, [isIntersecting])

    return isIntersecting
}
