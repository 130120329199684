import React from "react"

interface IVisaLogoProps {
    height: number
}

export const VisaLogo: React.FC<IVisaLogoProps> = (props) => {
    const { height = 24 } = props

    return (
        <svg height={height} viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M35.6507 0.425986L30.8391 23.6378H25.0216L29.8335 0.425986H35.6507ZM60.1277 15.4138L63.1907 6.69726L64.9533 15.4138H60.1277ZM66.6187 23.6378H72L67.3043 0.425986H62.3367C61.221 0.425986 60.279 1.09631 59.8603 2.12911L51.132 23.6378H57.2407L58.454 20.1717H65.918L66.6187 23.6378ZM51.4353 16.0594C51.46 9.93268 43.226 9.59535 43.2833 6.85854C43.3003 6.02532 44.0693 5.14035 45.7503 4.91363C46.5853 4.80131 48.881 4.71489 51.4877 5.95276L52.5083 1.03073C51.1087 0.505803 49.3067 0.00130726 47.0663 0.00130726C41.3163 0.00130726 37.2683 3.15691 37.2343 7.67508C37.198 11.0165 40.1233 12.8804 42.3283 13.9918C44.5943 15.1297 45.356 15.8583 45.3463 16.8758C45.3313 18.4341 43.538 19.1205 41.8653 19.1478C38.9397 19.1955 37.2423 18.3327 35.889 17.682L34.8347 22.7677C36.194 23.4125 38.7027 23.9722 41.3057 24.0011C47.4167 24.0011 51.4153 20.8842 51.4353 16.0594ZM27.3372 0.425986L17.9115 23.6378H11.7607L7.12253 5.11294C6.8409 3.97183 6.59583 3.55455 5.73907 3.074C4.34233 2.29104 2.03242 1.55608 0 1.10037L0.139193 0.425986H10.0382C11.2996 0.425986 12.4348 1.29265 12.7211 2.7923L15.1711 16.2253L21.2253 0.425986H27.3372"
                    fill="#1D337A"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="72" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
