import React, { useContext } from "react"
import { ThemeContext } from "../ThemeProvider"
import { makeStyles } from "../utils/styles/makeStyles"

export const Fonts = React.memo(
    () => {
        const { defaults } = useContext(ThemeContext)

        const fontsStyles = {
            "@font-face": [],
        }
        defaults.fonts.forEach((font) => {
            // @ts-ignore
            fontsStyles["@font-face"] = [
                // @ts-ignore
                ...fontsStyles["@font-face"], {
                    // @ts-ignore
                    fontFamily: font.family,
                    // @ts-ignore
                    fontStyle: font.style,
                    // @ts-ignore
                    fontWeight: font.weight,
                    // @ts-ignore
                    src: `url(${font.path}) format('${font.format}')`,
                },
            ]
        })
        const useStyles = makeStyles(fontsStyles, "Fonts", true)
        useStyles()

        return null
    },
    () => true
)
Fonts.displayName = "Fonts"
