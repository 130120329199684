import classNames from "classnames"
import React, { useCallback, useContext, useMemo } from "react"
import { Grid, Navigation } from "../"
import orgDefaults from "../defaults"
import { Spacer } from "../Spacer"
import { makeStyles } from "../utils/styles/makeStyles"
import { Tab } from "./Tab"
import { TabContext } from "./TabContainer"

const styles = (defaults: typeof orgDefaults) => ({
    tabNavigation: {
        borderBottom: `solid 1px ${defaults.colors.neutrals[500]}`,
    },
    tabNavigationItem: {
        cursor: "pointer",
        fontWeight: defaults.fontWeights.medium,
        color: defaults.colors.neutrals[700],
        borderBottom: `3px solid transparent`,
        marginBottom: -2,
        transition: defaults.transitions.interactions.fast,
        lineHeight: 1,

        "& svg": {
            color: defaults.colors.neutrals[700],
        },

        "&:hover": {
            color: defaults.colors.neutrals[800],
            borderBottom: `3px solid ${defaults.colors.primary[400]}`,

            "& svg": {
                color: defaults.colors.neutrals[800],
            },
        },
    },
    tabNavigationItemContentContainer: {
        minHeight: `calc(1em + ${defaults.spacing[8] * 2}px)`,
    },
    tabNavigationItemActive: {
        color: defaults.colors.neutrals[900],
        borderBottom: `3px solid ${defaults.colors.primary[500]}`,

        "& svg": {
            color: defaults.colors.neutrals[900],
        },
        "&:hover": {
            color: defaults.colors.neutrals[900],
            borderBottom: `3px solid ${defaults.colors.primary[500]}`,
        },
    },
})

const useStyles = makeStyles(styles, "TabNavigation", true)
const renderNavigation = (child: React.ReactComponentElement<typeof Tab>) => {
    const classes = useStyles()

    const { activeTab, onSelect } = useContext(TabContext)

    const handleOnClick = useCallback(() => {
        if (child) {
            onSelect(child.props.tabKey)
        }
    }, [onSelect])

    const myClassNames = useMemo(() => {
        if (child) {
            return classNames(classes.tabNavigationItem, {
                [classes.tabNavigationItemActive]: activeTab === child.props.tabKey,
            })
        } else {
            return undefined
        }
    }, [activeTab])

    if (!child) {
        return null
    }

    return (
        <Navigation.NavItem className={myClassNames} onClick={handleOnClick}>
            <Grid container fluid>
                <Grid
                    row
                    alignItems="center"
                    className={classes.tabNavigationItemContentContainer}
                    noGutters
                >
                    {child.props.icon && (
                        <>
                            <Grid item auto>
                                {child.props.icon}
                            </Grid>
                            <Grid item auto>
                                <Spacer size={8} />
                            </Grid>
                        </>
                    )}
                    <Grid item auto>
                        {child.props.title}
                    </Grid>
                </Grid>
            </Grid>
        </Navigation.NavItem>
    )
}

interface ITabNavigationProps {
    children: React.ReactNode
}
export const TabNavigation: React.FC<ITabNavigationProps> = (props) => {
    const { children } = props
    const classes = useStyles()

    return (
        <Grid row className={classes.tabNavigation} noGutters>
            <Navigation color="transparent" noPadding noZIndex>
                <Navigation.Nav noPadding>
                    {React.Children.map(children, renderNavigation)}
                </Navigation.Nav>
            </Navigation>
        </Grid>
    )
}
