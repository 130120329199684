import classNames from "classnames"
import * as React from "react"
import { GridBreakpoints, GridSizes } from "../defaults"
import devMode from "../utils/devMode"
import { makeStyles } from "../utils/styles/makeStyles"
import GridStyles from "./GridStyles"

type GridSizesType = boolean | "auto" | GridSizes
type GrowValues = 0 | GridSizes | false
type ShrinkValues = 0 | GridSizes | false
type GridContentAlignment =
    | "flex-start"
    | "center"
    | "flex-end"
    | "stretch"
    | "space-between"
    | "space-around"
type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
    /**
     * alignContent
     * @default stretch
     */
    alignContent: "stretch" as GridContentAlignment,
    /**
     * alignItems
     * @default stretch
     */
    alignItems: "stretch" as "flex-start" | "center" | "flex-end" | "stretch" | "baseline",
    /**
     * alignSelf
     * @default auto
     */
    alignSelf: "auto" as "flex-start" | "center" | "flex-end" | "stretch" | "baseline" | "auto",
    /** Should width be determined by content?
     * @default false
     */
    auto: false as boolean,
    component: "div" as string,
    /**
     * Is this a parent to other Grid items?
     * @default false
     */
    container: false as boolean,
    /**
     * Container size, sets container width to a fixed breakpoint size
     * @default false
     */
    containerSize: false as false | GridBreakpoints,
    /**
     * Is this a column or a row?
     * @default row
     */
    direction: "row" as "row" | "row-reverse" | "column" | "column-reverse",
    /**
     * Should this expand to the edges of it's parent, or should it follow the breakpoints
     * @default false
     */
    fluid: false as boolean,
    /**
     * See flex-grow https://developer.mozilla.org/en-US/docs/Web/CSS/flex-grow
     * @default false
     */
    grow: false as GrowValues,
    /**
     * Is this an item
     * @default false
     */
    item: false as boolean,
    /**
     * See https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content
     * @default flex-start
     */
    justify: "flex-start" as
        | "flex-start"
        | "center"
        | "flex-end"
        | "space-between"
        | "space-around"
        | "space-evenly",
    /**
     * Should this have no gutters
     * @default false
     */
    noGutters: false as boolean,
    /**
     * Creates a "row"
     * NB: The direction of the "row" is determined by the parent
     * @default false
     */
    row: false as boolean,
    /**
     * See https://developer.mozilla.org/en-US/docs/Web/CSS/flex-shrink
     * @default false
     */
    shrink: false as ShrinkValues,
    /**
     * Should items wrap
     * @default wrap
     */
    wrap: "wrap" as "nowrap" | "wrap" | "wrap-reverse",

    // tslint:disable:object-literal-sort-keys
    /**
     * A number between 1 - number of grid columns defined by defaults
     * Or "auto", to set auto width on a specific breakpoint
     */
    xs: false as GridSizesType,
    /**
     * A number between 1 - number of grid columns defined by defaults
     * Or "auto", to set auto width on a specific breakpoint
     */
    sm: false as GridSizesType,
    /**
     * A number between 1 - number of grid columns defined by defaults
     * Or "auto", to set auto width on a specific breakpoint
     */
    md: false as GridSizesType,
    /**
     * A number between 1 - number of grid columns defined by defaults
     * Or "auto", to set auto width on a specific breakpoint
     */
    lg: false as GridSizesType,
    /**
     * A number between 1 - number of grid columns defined by defaults
     * Or "auto", to set auto width on a specific breakpoint
     */
    xl: false as GridSizesType,
    /**
     * A number between 1 - number of grid columns defined by defaults
     * Or "auto", to set auto width on a specific breakpoint
     */
    xxl: false as GridSizesType,
    // tslint:enable:object-literal-sort-keys
}

type GridProps = {
    className?: string
    style?: React.CSSProperties
    onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void
    role?: string
}

const useStyles = makeStyles(GridStyles, "Grid", true)
export const Grid = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<GridProps & Partial<DefaultProps>>
>((props, ref) => {
    const classes = useStyles()

    const gridClassNames = classNames({
        [classes.container]: props.container,
        // @ts-ignore - @TODO
        [classes.auto]: props.auto,
        [classes.fluid]: props.fluid,
        [classes.row]: props.row,
        [classes.item]: props.item,
        [classes.noGutters]: props.noGutters,
        // @ts-ignore - @TODO
        [classes[`grow-${String(props.grow)}`]]: props.grow !== undefined && props.grow > -1,
        // @ts-ignore - @TODO
        [classes[`shrink-${String(props.shrink)}`]]:
            props.shrink !== undefined && props.shrink > -1,
        // @ts-ignore - @TODO
        [classes[`direction-${String(props.direction)}`]]:
            props.direction !== undefined && props.direction !== defaultProps.direction,
        // @ts-ignore - @TODO
        [classes[`wrap-${String(props.wrap)}`]]:
            props.wrap !== undefined && props.wrap !== defaultProps.wrap,
        // @ts-ignore - @TODO
        [classes[`align-items-${String(props.alignItems)}`]]:
            props.alignItems !== undefined && props.alignItems !== defaultProps.alignItems,
        // @ts-ignore - @TODO
        [classes[`align-content-${String(props.alignContent)}`]]:
            props.alignContent !== undefined && props.alignContent !== defaultProps.alignContent,
        // @ts-ignore - @TODO
        [classes[`align-self-${String(props.alignSelf)}`]]:
            props.alignSelf !== undefined && props.alignSelf !== defaultProps.alignSelf,
        // @ts-ignore - @TODO
        [classes[`justify-${String(props.justify)}`]]:
            props.justify !== undefined && props.justify !== defaultProps.justify,
        // @ts-ignore - @TODO
        [classes[`grid-xs-${String(props.xs)}`]]: props.xs !== undefined && props.xs !== false,
        // @ts-ignore - @TODO
        [classes[`grid-sm-${String(props.sm)}`]]: props.sm !== undefined && props.sm !== false,
        // @ts-ignore - @TODO
        [classes[`grid-md-${String(props.md)}`]]: props.md !== undefined && props.md !== false,
        // @ts-ignore - @TODO
        [classes[`grid-lg-${String(props.lg)}`]]: props.lg !== undefined && props.lg !== false,
        // @ts-ignore - @TODO
        [classes[`grid-xl-${String(props.xl)}`]]: props.xl !== undefined && props.xl !== false,
        // @ts-ignore - @TODO
        [classes[`grid-containerSize-${String(props.containerSize)}`]]:
            props.containerSize !== undefined,
    })

    const {
        alignContent,
        alignItems,
        alignSelf,
        auto,
        className,
        container,
        containerSize,
        direction,
        grow,
        item,
        justify,
        lg,
        md,
        row,
        shrink,
        sm,
        wrap,
        xl,
        xs,
        noGutters,
        fluid,
        ...other
    } = props
    const Component = (props.component ? props.component : "div") as unknown as React.ComponentType<
        React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
    >

    return <Component className={classNames(className, gridClassNames)} ref={ref} {...other} />
})

if (devMode) {
    Grid.displayName = "Grid"
}
