import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const ApplicationSubmittedStepIcon: React.FC<Icon> = (props) => {
    const { size = 24, ...otherProps } = props
    const { defaults } = useContext(ThemeContext)
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 47C37.1503 47 47 37.1503 47 25C47 12.8497 37.1503 3 25 3C12.8497 3 3 12.8497 3 25C3 37.1503 12.8497 47 25 47ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                fill={defaults.colors.neutrals[500]}
            />
            <path
                d="M40.7332 15.456L11.5202 25.5506C10.8221 25.7918 10.6517 26.7011 11.215 27.1788L16.1356 31.3511V40.0564C16.1356 40.6953 16.7516 41.1534 17.3634 40.9694L24.8312 38.7244L30.8583 43.8349C31.3663 44.2657 32.1481 44.056 32.3725 43.4289L41.9423 16.6782C42.2117 15.9249 41.4894 15.1947 40.7332 15.456Z"
                fill={defaults.colors.neutrals[500]}
            />
            <path
                d="M38.2508 11.6537L9.03783 21.7483C8.33968 21.9895 8.16924 22.8988 8.73263 23.3765L13.6531 27.5488V36.2541C13.6531 36.893 14.2692 37.3511 14.881 37.1671L22.3488 34.9221L28.3758 40.0326C28.8838 40.4634 29.6657 40.2537 29.8901 39.6266L39.4598 12.8759C39.7293 12.1225 39.007 11.3924 38.2508 11.6537Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                d="M20.6304 28.2646L31.1171 19.888C31.3945 19.6664 31.1 19.2381 30.7938 19.4179L16.8467 27.6074C16.5552 27.7785 16.3761 28.0914 16.3761 28.4295L16.3761 32.9832C16.3761 33.6422 17.0287 34.1025 17.6494 33.8813L19.2804 33.3002C19.6601 33.1648 19.9137 32.8053 19.9137 32.4021L19.9137 29.7544C19.9137 29.1746 20.1775 28.6264 20.6304 28.2646Z"
                fill={defaults.colors.neutrals[100]}
            />
        </svg>
    )
}

export { ApplicationSubmittedStepIcon }
