import { IMark } from "./Slider"

export const valueToPercentage = (value: number, min: number, max: number) =>
    ((value - min) * 100) / (max - min)

export const valueToMarkPosition = (value: number, marks: IMark[]) => {
    const markPosition = marks.reduce((currentMarkPosition: number, currentMark) => {
        if (currentMarkPosition === 0 && value === currentMark.value) {
            return currentMark.railPosition !== undefined
                ? currentMark.railPosition
                : currentMark.value
        }

        return currentMarkPosition
    }, 0)

    return markPosition
}

export const percentageToValue = (percent: number, min: number, max: number) =>
    (max - min) * (percent / 100) + min

export const percentageToMarkValue = (percent: number, marks: IMark[]) => {
    return findClosestMarkValue(marks, percent)
}

export const roundValueToStep = (value: number, step: number, min: number) =>
    Math.round((value - min) / step) * step + min

interface IClosestMark {
    distance: number
    value: number
}

export const findClosestMarkValue = (marks: IMark[], percent: number) => {
    const closestMark = marks.reduce((currentResult: IClosestMark, currentMark) => {
        const railPosition =
            currentMark.railPosition !== undefined ? currentMark.railPosition : currentMark.value
        const distance = Math.abs(percent - railPosition)

        if (
            !currentResult ||
            distance < currentResult.distance ||
            distance === currentResult.distance
        ) {
            return {
                distance,
                value: currentMark.value,
            }
        }

        return currentResult
    }, null)

    return closestMark ? closestMark.value : 0
}

interface IClosestResult {
    index: number
    distance: number
}
export const findClosest = (values: number[], value: number) => {
    const closestResult = values.reduce((currentResult: IClosestResult, currentValue, index) => {
        const distance = Math.abs(value - currentValue)

        if (
            !currentResult ||
            distance < currentResult.distance ||
            distance === currentResult.distance
        ) {
            return {
                distance,
                index,
            }
        }

        return currentResult
    }, null)

    return closestResult ? closestResult.index : 0
}

export const getCoordinates = (e: MouseEvent | TouchEvent, touchId?: number) => {
    if (window.TouchEvent && e instanceof TouchEvent) {
        if (touchId !== undefined) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < e.changedTouches.length; i++) {
                const touch = e.changedTouches[i]
                if (touch.identifier === touchId) {
                    return {
                        x: touch.clientX,
                        y: touch.clientY,
                    }
                }
            }
        }

        return {
            x: 0,
            y: 0,
        }
    } else if (e instanceof MouseEvent) {
        return {
            x: e.clientX,
            y: e.clientY,
        }
    } else {
        return {
            x: 0,
            y: 0,
        }
    }
}
