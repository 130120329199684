import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

export const AppleLogoIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { size = 24, color = defaults.colors.neutrals[900], ...otherProps } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                d="M16.9333 12.3286V12.3288C16.9578 14.9485 18.5885 16.3342 19.5408 16.9213C19.3332 17.4431 18.9757 18.2187 18.4346 19.0057C17.992 19.6494 17.5978 20.2042 17.163 20.6087C16.7447 20.9979 16.3494 21.1904 15.9067 21.1985C15.4038 21.2078 15.0889 21.0726 14.6019 20.8631C14.0901 20.643 13.4515 20.3742 12.4794 20.3742C11.5185 20.3742 10.8748 20.6226 10.3477 20.8443C10.319 20.8565 10.2908 20.8684 10.2632 20.88C9.7983 21.0762 9.48943 21.2065 9.03047 21.2247C8.65888 21.2384 8.25996 21.0598 7.79281 20.6241C7.32391 20.1868 6.88958 19.5877 6.44675 18.9511C5.61967 17.7612 4.87539 16.0523 4.55974 14.2734C4.24294 12.4882 4.37452 10.7369 5.16135 9.37729C5.93803 8.03573 7.31154 7.20875 8.77467 7.18759V7.1876L8.77841 7.18753C9.27445 7.17811 9.79478 7.3454 10.3816 7.57316C10.4495 7.59951 10.5203 7.62746 10.5926 7.65602C10.8075 7.74083 11.0361 7.83111 11.2449 7.90168C11.5233 7.99578 11.861 8.0897 12.2052 8.0897C12.5659 8.0897 12.9344 7.97371 13.2253 7.86997C13.3832 7.8136 13.5849 7.73474 13.782 7.65766C13.9267 7.60103 14.0691 7.54536 14.1899 7.50029C14.8623 7.24927 15.5031 7.07211 16.1153 7.13384L16.1395 7.13629L16.164 7.1373C16.6586 7.15779 17.855 7.31346 18.8186 8.23319C18.0368 8.92103 16.9147 10.2656 16.9333 12.3286ZM14.7335 4.06816L14.7334 4.06834C14.3195 4.56727 13.5782 5.00944 12.8567 5.18231C12.9427 4.42687 13.2575 3.68222 13.6331 3.24997L13.6363 3.24627C14.026 2.79252 14.7119 2.43429 15.4142 2.25C15.3586 2.96297 15.1274 3.59383 14.7335 4.06816Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="1.88708"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
