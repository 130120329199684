import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const MobilepayLogoIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...otherProps } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect
                x="3"
                y="6.32111"
                width="10.3771"
                height="17.2451"
                rx="2"
                transform="rotate(-24.6082 3 6.32111)"
                stroke="currentColor"
                strokeWidth="2"
            />
            <mask id="mask" fill="white">
                <path d="M11.3969 7.93155C11.3969 7.4921 11.6584 7.09481 12.0621 6.92107L12.2382 6.84527C14.9689 5.66987 18.0684 5.69925 20.7763 6.92619V6.92619C21.1542 7.0974 21.3969 7.47387 21.3969 7.88871V12.6215C21.3969 13.2598 20.73 13.6788 20.155 13.4018V13.4018C17.7802 12.2579 15.0136 12.2579 12.6388 13.4018V13.4018C12.0638 13.6788 11.3969 13.2598 11.3969 12.6215V7.93155Z" />
            </mask>
            <path
                d="M11.3969 7.93155C11.3969 7.4921 11.6584 7.09481 12.0621 6.92107L12.2382 6.84527C14.9689 5.66987 18.0684 5.69925 20.7763 6.92619V6.92619C21.1542 7.0974 21.3969 7.47387 21.3969 7.88871V12.6215C21.3969 13.2598 20.73 13.6788 20.155 13.4018V13.4018C17.7802 12.2579 15.0136 12.2579 12.6388 13.4018V13.4018C12.0638 13.6788 11.3969 13.2598 11.3969 12.6215V7.93155Z"
                fill="currentColor"
            />
            <path
                d="M12.2382 6.84527L11.6451 5.46749L12.2382 6.84527ZM19.8969 7.88871V12.6215H22.8969V7.88871H19.8969ZM12.8969 12.6215V7.93155H9.8969V12.6215H12.8969ZM12.6551 8.29885L12.8312 8.22305L11.6451 5.46749L11.469 5.54329L12.6551 8.29885ZM20.8059 12.0504C18.0198 10.7083 14.774 10.7083 11.9879 12.0504L13.2898 14.7532C15.2532 13.8074 17.5406 13.8074 19.504 14.7532L20.8059 12.0504ZM12.8312 8.22305C15.1742 7.21452 17.8338 7.23973 20.1572 8.29249L21.3954 5.5599C18.303 4.15877 14.7635 4.12522 11.6451 5.46749L12.8312 8.22305ZM9.8969 12.6215C9.8969 14.3652 11.7188 15.5099 13.2898 14.7532L11.9879 12.0504C12.4088 11.8477 12.8969 12.1543 12.8969 12.6215H9.8969ZM19.8969 12.6215C19.8969 12.1543 20.3851 11.8477 20.8059 12.0504L19.504 14.7532C21.075 15.5099 22.8969 14.3652 22.8969 12.6215H19.8969ZM22.8969 7.88871C22.8969 6.885 22.3096 5.97414 21.3954 5.5599L20.1572 8.29249C19.9987 8.22067 19.8969 8.06274 19.8969 7.88871H22.8969ZM12.8969 7.93155C12.8969 8.09129 12.8018 8.2357 12.6551 8.29885L11.469 5.54329C10.515 5.95393 9.8969 6.89292 9.8969 7.93155H12.8969Z"
                fill="currentColor"
                mask="url(#mask)"
            />
        </svg>
    )
}

export { MobilepayLogoIcon }
