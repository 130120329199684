import hex2rgba from "hex-to-rgba"
import defaultsOrg from "../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    "@keyframes rotation": {
        "0%": { transform: "rotate(0deg)" },
        "30%": { transform: "rotate(0deg)" },
        "70%": { transform: "rotate(180deg)" },
        // tslint:disable-next-line:object-literal-sort-keys
        "100%": { transform: "rotate(180deg)" },
    },
    "@keyframes slideRight": {
        "0%": { transform: "scaleY(0.2)" },
        "40%": { transform: "scaleY(1)" },
        "80%": { transform: "scaleY(0.2)" },
        // tslint:disable-next-line:object-literal-sort-keys
        "100%": { transform: "scaleY(0.2)" },
    },
    bar: {
        "&:nth-child(1)": {
            animationDelay: "0.8s",
            height: "60%",
        },
        "&:nth-child(2)": {
            animationDelay: "0.4s",
            height: "80%",
        },
        "&:nth-child(3)": {
            animationDelay: "0.0s",
            height: "100%",
        },
        "&:nth-child(4)": {
            animationDelay: "0.4s",
            height: "80%",
        },
        "&:nth-child(5)": {
            animationDelay: "0.8s",
            height: "60%",
        },
        animation: "$slideRight 1.5s infinite " + defaults.transitions.curve.accelerated,
        backgroundColor: defaults.colors.neutrals[700],
        display: "block",
        marginRight: "10%",
        transform: "scaleY(0)",
        width: "5%",

        "&$light": {
            backgroundColor: defaults.colors.neutrals[100],
        },
    },
    holder: {
        alignItems: "center",
        animationDelay: "0s",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        transform: "rotate(0deg)",
        transformOrigin: "50% 50%",
    },
    small: {
        marginRight: "20%",
        width: 2,
    },

    fillToParent: {
        backgroundColor: hex2rgba(defaults.colors.neutrals[200], 0.7),
        height: "100%",
        left: 0,
        maxHeight: "100vh",
        maxWidth: "100vw",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: 1,

        "&$noBackdrop": {
            backgroundColor: "transparent",
        },
    },

    noBackdrop: {},
    light: {},
})

export default styles
