import { AnimationControls, motion, Variants } from "framer-motion"
import hexToRgba from "hex-to-rgba"
import React, { useEffect } from "react"
import defaultsOrg from "../defaults"
import { makeStyles } from "../utils/styles/makeStyles"

const styles = (defaults: typeof defaultsOrg) => ({
    backdrop: {
        backgroundColor: hexToRgba(defaults.colors.primary[900], 0.83),
        bottom: 0,
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,

        WebkitTapHighlightColor: "transparent",
        overflowY: "auto",
        touchAction: "none",
        zIndex: defaults.zIndex.Modal,
    },
    disableScroll: {
        overflow: "hidden",
    },
})

interface IBackdropProps {
    onClick?: (e: React.MouseEvent) => void
    open: boolean
    animationControls: AnimationControls
    children?: React.ReactNode
}

const fadeVariants: Variants = {
    close: {
        opacity: [1, 0],
        transition: { duration: 0.1, when: "afterChildren" },
    },
}

const useStyles = makeStyles(styles, "Backdrop", true)
export const Backdrop: React.FC<IBackdropProps> = (props) => {
    const { onClick, open, children, animationControls } = props
    const classes = useStyles()

    useEffect(() => {
        if (open) {
            // Disable scrolling on body
            document.body.classList.add(classes.disableScroll)
        } else {
            // Reenable scrolling on body
            document.body.classList.remove(classes.disableScroll)
        }
    }, [open])

    useEffect(() => {
        return () => {
            // Reenable scrolling on body
            document.body.classList.remove(classes.disableScroll)
        }
    }, [])

    if (!open) {
        return null
    }

    return (
        <motion.div
            animate={animationControls}
            variants={fadeVariants}
            onClick={onClick}
            className={classes.backdrop}
        >
            {children}
        </motion.div>
    )
}

export default Backdrop
