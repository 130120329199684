import classNames from "classnames"
import initials from "initials"
import React, { useContext, useMemo } from "react"
import { ThemeContext } from "../ThemeProvider"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

export interface AvatarProps {
    name: string
    size?: number
    colors?: string[]
}

function sumChars(str: string) {
    let sum = 0
    for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i)
    }

    return sum
}

const useStyles = makeStyles(styles, "Avatar", true)
const Avatar: React.FC<AvatarProps> = (props) => {
    const { name, size = 24, colors } = props
    const classes = useStyles()
    const { defaults } = useContext(ThemeContext)

    const arrayOfColors = useMemo(() => {
        if (colors) {
            return colors
        }

        return [
            defaults.colors.primary[600],
            defaults.colors.accents.success[500],
            defaults.colors.accents.warning[500],
            defaults.colors.accents.error[500],
            defaults.colors.accents.notice[500],
        ]
    }, [colors])

    const initialsResult = initials(name)
    const nameInitials = Array.isArray(initialsResult)
        ? initialsResult[0].substring(0, 3)
        : initialsResult.substring(0, 3)

    // Pick a color from list, which is the same for initials every time
    const i = sumChars(nameInitials) % arrayOfColors.length
    const color = arrayOfColors[i]

    return (
        <article className={classes.container} style={{ backgroundColor: color, fontSize: size }}>
            <span className={classNames(classes.initials)}>{nameInitials}</span>
        </article>
    )
}

export { Avatar }
