// Does a given react child have any children?
export const hasChildren = (child: React.ReactNode) => {
    if (!child) {
        return false
    }
    if ((child as React.ReactElement).props && (child as React.ReactElement).props.children) {
        return true
    }

    return false
}
