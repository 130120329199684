import classNames from "classnames"
import * as React from "react"
import { SelectableText } from "../SelectableText"
import { makeStyles } from "../utils/styles/makeStyles"
import styles from "./styles"

export type TagColor = "success" | "warning" | "error" | "notice" | "neutral" | undefined
export type TagShade = "lightest" | "light" | "normal" | "dark" | "darkest"

interface ITagProps {
    children?: string | JSX.Element | Array<string | JSX.Element | null>
    /**
     * Make fontsize and padding of Tag smaller
     */
    small?: boolean
    /**
     * Make fontsize and padding of Tag even smaller
     */
    xsmall?: boolean
    /**
     * Make fontsize even smaller
     */
    xxsmall?: boolean
    /**
     * Lighten background color of Tag
     */
    light?: boolean
    /**
     * Tighten up padding of Tag
     */
    tight?: boolean
    /**
     * Choose color of Tag. One of the color names from defaults.
     */
    color?: TagColor
    /**
     * Adjust shade of background color of Tag
     */
    shade?: TagShade
    onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void
    onMouseEnter?: (e: React.MouseEvent) => void
}

const useStyles = makeStyles(styles, "Tag", true)
export const Tag: React.FC<ITagProps> = (props) => {
    const { children, onClick, small, xsmall, xxsmall, light, color, tight, shade, onMouseEnter } =
        props
    const classes = useStyles()

    if (light) {
        // tslint:disable-next-line: no-console
        console.warn(
            "The 'light' prop of Tag component in Clearhaus Design System will be deprecated in the future. Use 'shade' prop instead."
        )
    }

    const classnames = classNames(classes.tag, {
        [classes.small]: small,
        [classes.xsmall]: xsmall,
        [classes.xxsmall]: xxsmall,
        [classes.light]: light,
        [classes.tight]: tight,
        [classes.clickable]: onClick,
        // @ts-ignore - @TODO
        [classes[`shade-${shade}`]]: shade,
        // @ts-ignore - @TODO
        [classes[`color-${color}`]]: color,
    })

    return (
        <span className={classnames} onClick={onClick} onMouseEnter={onMouseEnter}>
            <SelectableText>{children}</SelectableText>
        </span>
    )
}
