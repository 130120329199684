import { motion, useAnimation, Variants } from "framer-motion"
import React, { useEffect, useRef } from "react"

import useMeasure from "../../utils/useMeasure"

interface ICollapseProps {
    children: React.ReactNode
    open: boolean
    immediate?: boolean
    className?: string
}

const collapseContainerVariants: Variants = {
    open: {
        height: "auto",
        transition: { duration: 0.25, when: "beforeChildren" },
    },
    close: {
        height: 0,
        transition: { duration: 0.25 },
    },
}

const collapseInnercontainerVariants: Variants = {
    open: { opacity: 1, transition: { duration: 0.25 }, visibility: "visible" },
    close: { opacity: 0, transition: { duration: 0.25 }, visibility: "hidden" },
}

export const Collapse = (props: ICollapseProps) => {
    const { children, className, open, immediate } = props
    const prevOpenRef = useRef(open)
    const [measureRef, { height: viewHeight }] = useMeasure()
    const animationControls = useAnimation()

    useEffect(() => {
        if (open === prevOpenRef.current) {
            return
        }

        animationControls.stop()
        if (open) {
            animationControls.start("open")
        } else {
            animationControls.start("close")
        }

        prevOpenRef.current = open
    }, [open])

    useEffect(() => {
        if (open || immediate) {
            animationControls.set("open")
        } else {
            animationControls.set("close")
        }
    }, [])

    return (
        <motion.div
            animate={animationControls}
            custom={viewHeight}
            initial={false}
            variants={collapseContainerVariants}
            className={className}
        >
            <motion.div
                ref={measureRef}
                children={children}
                variants={collapseInnercontainerVariants}
                initial={false}
            />
        </motion.div>
    )
}
