import React, { useContext, useMemo } from "react"
import { BoxEdges } from "../../BoxEdges"
import defaultsOrg from "../../defaults"
import { ThemeContext } from "../../ThemeProvider"
import { makeStyles } from "../../utils/styles/makeStyles"
import { Icon, ISO3166_1_alpha2 } from "../types"
import { FlagIcon } from "./FlagIcon"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        display: "inline-block",
        fontSize: defaults.fontSizes[18],
        overflow: "hidden",
        position: "relative",

        "&:only-child": {
            display: "block",
        },

        "& svg, & img": {
            height: "1em",
            left: "-0.18em",
            position: "absolute",
            width: "1.37em",
        },
    },
})

interface RoundedFlagIconProps extends Icon {
    countryCode: ISO3166_1_alpha2 | "international-black" | "international-white" | "international"
    shadow?: boolean
    size?: keyof typeof defaultsOrg.fontSizes
}

const useStyles = makeStyles(styles, "RoundedFlagIcon")
export const RoundedFlagIcon: React.FC<RoundedFlagIconProps> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { countryCode, shadow, size = defaults.fontSizes[18] } = props
    const classes = useStyles()

    const boxEdgeStyle = useMemo(() => {
        return {
            fontSize: size,
            height: size,
            width: size,
        }
    }, [size])

    return (
        <BoxEdges
            cornerRadius={defaults.cornerRadius.rounded}
            style={boxEdgeStyle}
            className={classes.container}
            shadow={shadow ? "neutrals" : undefined}
        >
            <FlagIcon countryCode={countryCode} width={size} height={size} />
        </BoxEdges>
    )
}
