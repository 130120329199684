import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const ApplicationNeedsSignatureStepIcon: React.FC<Icon> = (props) => {
    const { size = 24, ...otherProps } = props
    const { defaults } = useContext(ThemeContext)
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25Z"
                fill={defaults.colors.neutrals[900]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 49C38.2548 49 49 38.2548 49 25C49 11.7452 38.2548 1 25 1C11.7452 1 1 11.7452 1 25C1 38.2548 11.7452 49 25 49ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 10.0923C12.8954 10.0923 12 10.9877 12 12.0923V36.0182C12 37.1228 12.8954 38.0182 14 38.0182H15.8515V14.9812C15.8515 13.8766 16.747 12.9812 17.8515 12.9812H34.1482V12.0923C34.1482 10.9877 33.2528 10.0923 32.1482 10.0923H14Z"
                fill={defaults.colors.neutrals[600]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.778 12.9812C16.7143 12.9812 15.8521 13.8434 15.8521 14.9071V38.9812C15.8521 40.0449 16.7143 40.9072 17.778 40.9072H36.0743C37.138 40.9072 38.0003 40.0449 38.0003 38.9812V14.9071C38.0003 13.8434 37.138 12.9812 36.0743 12.9812H17.778ZM20.6669 19.7219H33.1854V17.796H20.6669V19.7219ZM33.1854 23.5738H20.6669V21.6479H33.1854V23.5738ZM20.6669 27.4257H25.6743V25.4997H20.6669V27.4257Z"
                fill={defaults.colors.neutrals[100]}
            />
        </svg>
    )
}

export { ApplicationNeedsSignatureStepIcon }
