import React, { useContext } from "react"
import { ThemeContext } from "../../ThemeProvider"
import { Icon } from "../types"

const AccountIcon: React.FC<Icon> = (props) => {
    const { defaults } = useContext(ThemeContext)
    const { color = defaults.colors.neutrals[900], size = 24, ...otherProps } = props
    return (
        <svg
            width={size}
            height={size}
            color={color}
            {...otherProps}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="11" stroke="currentcolor" strokeWidth="2" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 20.7194C4.15893 19.8507 5.28588 18.2545 6.46032 18.2545C8.01284 18.2545 9.42422 18.2545 9.56536 17.7261C9.67827 17.3033 9.80059 17.0802 9.84764 17.0215H10.7394V15.6851C9.90305 15.2154 9.30569 14.3764 9.173 13.3917H9.1452V12.9758V10.5361L9.18915 10.3939H9.14737L8.83338 8.59596C9.02077 8.36706 9.28279 8.04008 9.5753 7.67504L9.57535 7.67499L9.57536 7.67497C10.4265 6.61278 11.5359 5.22837 11.8163 5C11.8163 5.51143 11.7849 6.57264 11.6593 6.72606C11.5337 6.87949 12.3397 6.40642 12.7583 6.15071L12.6013 7.4932C13.0142 7.34188 13.5771 7.42936 13.8578 7.473C13.9329 7.48467 13.9878 7.4932 14.0143 7.4932C14.1399 7.4932 15.4273 7.95468 15.7413 8.14647L15.4273 10.3939H15.4251V12.9758V13.3917H15.3973C15.2602 14.4092 14.6269 15.2712 13.7465 15.7308V17.0215H14.7131C14.7614 17.0947 14.8871 17.3729 15.0031 17.9C15.1481 18.5588 16.5984 18.5588 18.1936 18.5588C19.3225 18.5588 19.6429 19.4862 19.7353 20.4096C17.878 22.4912 15.2216 23.8443 12.2478 23.9873L12.2477 17.5227H12.247V23.9873C12.0737 23.9956 11.8993 23.9998 11.7239 23.9998C8.69105 23.9998 5.952 22.7419 4 20.7194Z"
                fill="currentcolor"
            />
        </svg>
    )
}

export { AccountIcon }
