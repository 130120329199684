import defaultsOrg from "../../defaults"

const styles = (defaults: typeof defaultsOrg) => ({
    container: {
        fontSize: defaults.fontSizes[14],
        position: "relative",
    },
    focused: {
        backgroundColor: defaults.colors.primary[100],
    },
    header: {
        alignItems: "center",
        backgroundColor: defaults.colors.neutrals[100],
        border: `1px solid ${defaults.colors.neutrals[500]}`,
        borderRadius: defaults.cornerRadius.normal,
        boxShadow: defaults.shadows.small.tight,
        cursor: "pointer",
        display: "flex",
        outline: 0,
        padding: defaults.spacing[16],
        position: "relative",
        transition: defaults.transitions.interactions.fast,
        userSelect: "none",

        "&$disabled": {
            backgroundColor: defaults.colors.neutrals[500],
            pointerEvents: "none",
        },

        "&$tight": {
            padding: `${defaults.spacing[4]}px ${defaults.spacing[8]}px`,
        },

        "&:focus": {
            border: `1px solid ${defaults.colors.primary[400]}`,
            boxShadow: defaults.shadows.small.wide,
            opacity: 1,
            outline: 0,
        },

        "& $icon": {
            transition: defaults.transitions.interactions.fast,
        },

        "& $listItem": {
            height: "auto",
            padding: 0,
        },

        "&$active": {
            backgroundColor: defaults.colors.neutrals[100],
            border: `1px solid ${defaults.colors.primary[400]}`,
            boxShadow: defaults.shadows.small.wide,

            "& $icon": {
                transform: "rotate(-180deg)",
            },
        },
    },
    highlighted: {
        "&:hover": {
            backgroundColor: defaults.colors.primary[200],
        },

        backgroundColor: defaults.colors.primary[300],
    },
    icon: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        marginLeft: "auto",
    },
    list: {
        backgroundColor: defaults.colors.neutrals[100],
        border: `1px solid ${defaults.colors.neutrals[500]}`,
        borderRadius: defaults.cornerRadius.normal,
        boxShadow: defaults.shadows.small.tight,
        boxSizing: "border-box",
        listStyleType: "none",
        marginTop: defaults.spacing[12],
        opacity: 0,
        overflow: "auto",
        padding: 0,
        position: "absolute",
        transition: defaults.transitions.interactions.fast,
        visibility: "hidden",
        width: "100%",
        zIndex: defaults.zIndex.Select,

        "&$autoWidth": {
            right: 0,
            width: "auto",
        },
    },
    listItem: {
        "&:first-child": {
            borderRadius: `${defaults.cornerRadius.normal}px ${defaults.cornerRadius.normal}px 0 0`,
        },
        "&:last-child": {
            borderBottom: "none",
            borderRadius: `0 0 ${defaults.cornerRadius.normal}px ${defaults.cornerRadius.normal}px`,
        },

        alignItems: "center",
        color: defaults.colors.neutrals[800],
        cursor: "pointer",
        display: "flex",
        height: defaults.spacing[48],
        overflow: "hidden",
        padding: `${defaults.spacing[4]}px ${defaults.spacing[16]}px`,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: defaults.fontSizes[14],

        "&$tight": {
            height: defaults.spacing[32],
            padding: `${defaults.spacing[4]}px ${defaults.spacing[8]}px`,
        },

        "&$notSelectable": {
            cursor: "default",
        },
    },
    open: {
        boxShadow: defaults.shadows.small.wide,
        opacity: 1,
        visibility: "visible",
    },
    hidden: {
        visibility: "hidden",
    },
    placeholder: {
        color: defaults.colors.neutrals[700],
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    prefix: {
        alignItems: "center",
        borderRadius: defaults.cornerRadius.rounded,
        display: "flex",
        height: 20,
        marginRight: defaults.spacing[12],
        width: 20,

        "&> img": {
            position: "relative",
            width: "137%",
        },
    },

    hasError: {
        borderColor: defaults.colors.accents.error[400],
    },

    notice: {
        borderColor: defaults.colors.accents.notice["500"],
    },

    active: {},

    disabled: {
        opacity: 0.7,
        pointerEvents: "none",
    },

    tight: {},

    autoWidth: {},

    notSelectable: {},
})

export default styles
